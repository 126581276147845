import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RiskAssessmentService } from '../risk-assessment.service';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { RRFilterByDate } from '../risk-assessment-const';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-risk-assessment-dashboard',
  templateUrl: './risk-assessment-dashboard.component.html',
  styleUrls: ['./risk-assessment-dashboard.component.scss']
})
export class RiskAssessmentDashboardComponent implements OnInit, OnDestroy {

  isLoading:boolean;
  isLoadMore:boolean = false;
  showLoadMoreButton = true;
  isError:boolean = false;
  filter = {
    'pageSize' : 15,
    'assignedToUser' : false
  };
  activeIndex;
  loadingIndex;
  riskAssessments;
  errorMessage: string;
  RRFilterByDate = RRFilterByDate;
  RRDateFilter = "0";

  constructor(
    public riskAssessmentService: RiskAssessmentService,
    public helperService: HelperServiceService,
    public idVerificationService: IdVerificationService,
    public userService: UserService
  ) { }
    // header vars
    headerInfo = {
      headerType: "inline", // 'inline',
      headerTitle: "Risk Assessment",
      activeNavLink: "risk-assessment",
    };
    headerClasses = {
      backgroundClass: "tealish-gradient-bg",
      sectionClass: "ic_height_auto", // 'ic_height_full_page',
    };
    

  ngOnInit(): void {
    this.isLoading = true;
    // meaning the permissions call has been completed
    this.userService.getUserPermissionsSubject()
    .subscribe((response) => {
      if(response) {
        if(!this.idVerificationService.permissionRiskRatingManager()) {
          this.filter.assignedToUser = true;
        }
        this.getRiskAssessmentList();
      }
    })



    this.riskAssessmentService.detectRiskEventPreviewClose.subscribe(
      () => {
        this.loadingIndex = null;
        this.showLoadMoreButton = true;
      }
    )

    // used to refresh the current assesment after it is being changed in the preview
    this.riskAssessmentService.detectRiskEventPreviewComplete.subscribe(
      (key)=> {
        this.riskAssessmentService.getRiskAssessment(key).subscribe(
          (response)=> {
            this.riskAssessments.map
            (riskAssessment => riskAssessment['key'] === response['key'] ? response : riskAssessment )
          },
          error => {

          }
        )
      }
    )

  }

  ngOnDestroy(): void {
  }

  resetBookmark() {
    this.filter['bookmark'] = undefined;
    this.showLoadMoreButton = true;
    this.loadingIndex = null;
  }

  changeActiveSelector(value) {
    this.filter['assignedToUser'] = value;
    this.resetBookmark();
    this.getRiskAssessmentList();
  }

  onChangeRiskSortFilter(event) {
    this.filter['sortBy'] = event.target.value;
    this.loadingIndex = null;
    this.resetBookmark();
    this.getRiskAssessmentList();
   }

   onChangeRiskSortOrderFilter(event) {
    this.filter['sortOrder'] = event.target.value;
    this.loadingIndex = null;
    this.resetBookmark();
    this.getRiskAssessmentList();
   }
 

  onRiskDetails(index,key) {
    this.loadingIndex = index;
    this.showLoadMoreButton = false;

    this.riskAssessmentService.buildRiskAssessmentLightbox(
      key
    );
  }

  getRiskAssessmentList(appendList?) {
    if(!appendList) {
      this.isLoading = true
    }
    this.isError = false
    this.riskAssessmentService.getRiskAssessmentList(this.filter).subscribe(
      (response) => {
        if (response === undefined) {
          console.log("We could retrieve the list of active projects");
          return;
        }
        if(appendList) {
          this.riskAssessments = this.riskAssessments.concat(response['records']);
        } else {
          this.riskAssessments = response['records'];
        }
        if(response['records']?.length === 0 || !response['records'] ) {
          this.showLoadMoreButton = false;
        }
        this.filter['bookmark'] = response['metadata']?.bookmark;
        this.isLoading = false;
        this.isLoadMore = false;
      },
      (error) => {
        this.isLoading =  false;
        this.errorMessage = "Error loading the risk assessments";
        this.isError = true;
      },
    );
  }

  OnToggleNextReview() {
    if ($("#filterByNextReview").is(":checked")) {
      this.idVerificationService.toggleDisplayRangeFilter(true);
    } else {
      
      // reset the bookmark since we are changing persons
      this.idVerificationService.toggleDisplayRangeFilter(false);
      this.filter['beforeDate'] = undefined;
      this.filter['afterDate'] = undefined;
      this.resetBookmark();
      this.getRiskAssessmentList();
      $(".filter-element").addClass("verification-main-disabled");
    }
  }


  getRRDate(filter: string) {
    let RRDateFilter;
    if (RRDateFilter) {
      if (filter === "from" && RRDateFilter.next_review_after) {
        return this.helperService.getAsStartDate(
          this.helperService.formatISODate(
            RRDateFilter.next_review_after,
            "yyy.MM.dd",
            false,
          ),
        );
      } else if (filter === "to" && RRDateFilter.next_review_before) {
        return this.helperService.getAsStartDate(
          this.helperService.formatISODate(
            RRDateFilter.next_review_before,
            "yyy.MM.dd",
            false,
          ),
        );
      }
    }
    return null;
  }

  applyNewRange() {

    let afterDate;
    let beforeDate;
    if (this.RRDateFilter == "0") {
      afterDate = null;
      beforeDate = null;
    } else if (this.RRDateFilter == "custom") {
      
      afterDate = $("#rangeFrom input").val();
      if (afterDate) {
        afterDate = this.helperService.convertToDate(afterDate.toString());
        afterDate = new Date(afterDate.toString());
      }

      beforeDate = $("#rangeTo input").val();
      if (beforeDate) {
        beforeDate = this.helperService.convertToDate(beforeDate.toString());
        beforeDate = new Date(beforeDate.toString());
      }
    } else if (parseInt(this.RRDateFilter)) {
      afterDate = new Date();

      beforeDate = new Date();
      beforeDate.setMonth(beforeDate.getMonth() + parseInt(this.RRDateFilter));
    }
    // if (toDate && fromDate && toDate < fromDate) {
    //   this.toastNotification.newEvent({
    //     mode: "error",
    //     title: "Invalid date range selected! Please check and try again",
    //     fade: true,
    //   });
    //   return;
    // }
    // if (fromDate || toDate) {
    //   this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview =
    //     {
    //       next_review_after: fromDate ? fromDate.getTime() : null,
    //       next_review_before: toDate ? toDate.getTime() : null,
    //     };
    // } else {
    //   this.idVerificationService.getActiveLegalrepresentativesFilters().filterByRiskReview =
    //     undefined;
    // }

    if(this.RRDateFilter === '0') {
      this.filter['beforeDate'] = undefined;
      this.filter['afterDate'] = undefined;
    } else if(this.RRDateFilter == "custom") {
      if(beforeDate && afterDate) {
        if(beforeDate.getTime() < afterDate.getTime()) {
          // error
        } else {
          this.filter['beforeDate'] = beforeDate?.getTime();
          this.filter['afterDate'] = afterDate?.getTime();
          this.resetBookmark();
          this.getRiskAssessmentList();
        }
      }} else {
      this.filter['beforeDate'] = beforeDate?.getTime();
      this.filter['afterDate'] = afterDate?.getTime();
      this.resetBookmark();
      this.getRiskAssessmentList();
    }
  }

  loadMore() {
    this.isLoadMore = true;
    this.getRiskAssessmentList(true);
    }
}
