<app-header
  [headerInfo]="headerInfo"
  [headerClasses]="headerClasses"
></app-header>

<div *ngIf="isLoading && !isError" class="d-flex justify-content-center">
    <p class="result-loader" >
        <img src="assets/images/spinner-green.svg" />
    </p>
</div>

<div *ngIf="!isLoading " class="d-flex justify-content-center my-5 flex-column align-items-center">
    <div>
        <div class="d-flex justify-content-between risk-assessment-header align-items-center mb-4">
            <h1 class="mb-0">RISK ASSESSMENT</h1>
            <div class="d-flex">
                <div class="select-wrapper dashboard-select-wrapper mr-0" >
                    <div class="select-wrapper-label">Sort by:</div>
                    <select
                        class="select-input input-transparent"
                        (change)="onChangeRiskSortFilter($event)"
                    >
                        <!-- <option
                        value="created_on"
                        [selected]="filter.sortBy === 'created_on'"
                        label="Created on" >
                        </option> -->
                        <option
                        value="last_modified"
                        [selected]="filter.sortBy === 'last_modified'"
                        label="Last modified" >
                        </option>
                    </select>
                </div>
                <div class="select-wrapper dashboard-select-wrapper mr-0" >
                    <div class="select-wrapper-label"></div>
                    <select
                        style="width: auto;"
                        class="select-input input-transparent ml-2"
                        (change)="onChangeRiskSortOrderFilter($event)" >
                        <option
                        value="desc"
                        [selected]="filter.sortOrder === 'desc'"
                        label="DESC" >
                        </option>
                        <option
                        value="asc"
                        [selected]="filter.sortOrder === 'asc'"
                        label="ASC" >
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div>
        <div class="builder-filter-risk-rating-slider pb-3" >
            <div class="dividing-line"></div>
            <div>
                <label for="filterByNextReview" class="filter-next-review">
                <app-toggle-button
                    [toggleChecked]="
                    idVerificationService.getDisplayRangeFilter()
                    "
                    toggleId="filterByNextReview"
                    (toggle)="OnToggleNextReview()"
                >
                </app-toggle-button>

                <!-- ### Dodadvanje komponentata ### -->
                <h4 class="filter-by-title">
                    Filter by Next Risk Review
                </h4></label
                >
            </div>
            <div class="purposes-container filter-tags-container tags-dashboard w-100"
                *ngIf="idVerificationService.getDisplayRangeFilter()" >
                <span class="select-wrapper m-0 rr-date-filter">
                <select
                    class="select-input w-100"
                    id="RRFilterByDate"
                    (change)="applyNewRange()"
                    [(ngModel)]="RRDateFilter"
                >
                    <option
                    value="{{ option.key }}"
                    *ngFor="let option of RRFilterByDate"
                    [selected]="RRDateFilter == option.key"
                    >
                    {{ option.name }}
                    </option>
                </select>
                </span>

                <div class="date-picker mt-3" *ngIf="RRDateFilter == 'custom'">
                <app-my-date-picker
                    name="rangeFrom"
                    id="rangeFrom"
                    method="setMinEndDate"
                    dateInputId="rangeFromDate"
                    isReqiured="true"
                    value="{{ getRRDate('from') }}"
                    startDate="{{ helperService.getTodayAsMaxDate() }}"
                    minDate="{{ helperService.getTodayAsMaxDate() }}"
                    (dateChange)="applyNewRange()"
                >
                </app-my-date-picker>

                <app-my-date-picker
                    name="rangeTo"
                    id="rangeTo"
                    method="setMinEndDate"
                    dateInputId="rangeToDate"
                    isReqiured="true"
                    value="{{ getRRDate('to') }}"
                    startDate="{{ helperService.getTodayAsMaxDate() }}"
                    minDate="{{ helperService.getTodayAsMaxDate() }}"
                    (dateChange)="applyNewRange()"
                ></app-my-date-picker>
                </div>
            </div>
        </div>
        <div class="risk-assessment-cards d-flex flex-column">
            <div class="cards-selector">
                <button [ngClass]="{'active': filter.assignedToUser === false}" 
                class="form-button card-selector-button" 
                (click)="changeActiveSelector(false)">Organization risk assessments</button>

                <button [ngClass]="{'active': filter.assignedToUser === true }" 
                class="form-button card-selector-button" 
                (click)="changeActiveSelector(true)">My risk assessments</button>
            </div>
            <div  *ngIf="!isError">
                <div [ngClass]="{'active': activeIndex === i, 'loading' : loadingIndex === i || isLoadMore === true}" 
                class="risk-assessment-card mb-3" *ngFor="let riskAssessment of riskAssessments; let i = index">
                    <div class="risk-assessment-card-upper d-flex justify-content-between align-items-center">
                        <h2>{{riskAssessment.record.data?.profileName}}</h2>
                        <div>
                            <button class="form-button form-button-6 ml-0 risk-details-button py-0" (click)="onRiskDetails(i,riskAssessment.key)">
                                View risk details
                            </button>
                            <div class="mt-2 d-flex justify-content-end align-items-center risk-assessment-status {{riskAssessment.record.status.toLowerCase()}}">
                                <img class="status-image mr-1" src="assets/images/in-progress-green.svg" />
                                <h5 class="mb-0">
                                    {{
                                        this.riskAssessmentService.parseStatus(riskAssessment.record.status)
                                    }}
                                </h5>
                            </div>
    
                        </div>
    
                        <div class="button-loader" >
                            <img src="assets/images/spinner-green.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div *ngIf="showLoadMoreButton && !isError" 
                class="d-flex justify-content-center load-more-container">
            <button class="form-button form-button-6" (click)="loadMore()">Show more</button>
        </div>
    </div>
    <div *ngIf="riskAssessments && riskAssessments.length === 0" class="mt-4 empty-message">
        There are no risk assessments available
    </div>
</div>

<div *ngIf="isError" class="d-flex justify-content-center mt-5 error-message">
    <h4>{{this.errorMessage}}</h4>
</div>



<!-- <app-lightbox
  *ngIf="displayModal"
  [formType]="formType"
  [formTitle]="formTitle"
  [formClass]="formClass"
  [formSubTitle]="formSubTitle"
  [lightboxClass]="lightboxClass"
  [btnOkText]="btnOkText"
  [btnCancelText]="btnCancelText"
  [displayModal]="displayModal"
  [displayLoader]="displayLoader"
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
></app-lightbox> -->
