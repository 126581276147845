import { Injectable } from "@angular/core"; // eslint-disable-line no-use-before-define
import { Subject, of, forkJoin, concat, pipe } from "rxjs";
import {
  buffer,
  catchError,
  share,
  takeUntil,
  tap,
  toArray,
  timeout,
  single,
} from "rxjs/operators";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserService } from "../user.service";
import { HelperServiceService } from "../helper-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { PdfServerService } from "../pdf-server.service";
import QRCode from "qrcode";
import { IpServiceService } from "../ip-service.service";
import { debugActiveLegalRepresentativeResponse } from "./legal-representative-debug-response";
import { VFEs } from "src/app/consts/const";
import { PDFDocument } from "pdf-lib";
import {
  emailPattern,
  country_list_array,
  title_list,
  gender_list,
  yn_list,
  id_type_list,
  idv_locale_list,
  legalPersonTypeList,
  idv_workflow_list,
  fuzinessLevelList,
  DocumenTypetList,
  id_type_list2,
  country_list_AV,
  relatedPartyRoles,
  legal_status_list,
  templatenotOlderThan,
} from "./id-verification-const";
import { DomSanitizer } from "@angular/platform-browser";
import { each } from "jquery";
import { TranslateService } from "@ngx-translate/core";
import { UiService } from "../ui/ui.service";

@Injectable({
  providedIn: "root",
})
export class IdVerificationService {
  private debug = false;
  private baseUrl: string;
  private swaggerUrl: string;
  private activeUser: {};
  private activeProject: string; // currently active/selected project
  private activeProjectDetails: {};
  private activeProjectList: any[]; // list of all active project objects for this user
  private activeLegalRepresentative: string; // key of currently active/selected legal representative/person expanded/editing/verifying
  private activeProjectBookmark: string; // pagination on the BE
  private activeLazyLoadRepresentative: string;
  private activeLazyLoadRepresentativeDetails: any[];
  private activeLegalRepresentativeDetails: {}; // details of currently active/selected legal representative/person
  private activeLegalRepresentativesList: any[]; // list of all legal representatives in the active project
  private activeLegalRepresentativesTags: string[]; // list of tags for the activeLegalRepresentatives
  private activeLegalRepresentativesTopTags: any[]; // list of top tags for Project ( used for suggestions)
  public activeLegalRepresentativesUnadedTags: string[]; // list of tags in a list that haven't been added yet
  private activeLegalrepresentativesFilters; // object that contains all the filters the active representative has
  private activeLegalRepresentativesBookmark;
  private activeRecordsCount = 0;
  private activeVerification: string; // currently active/selected verification
  private activeAddressVerification: string; // currently active/selected verification
  private scrollToKey: string; // key of element we need to scroll to
  private idVerificationEndpoints: {};
  private activeProjectSettings: string; // id of the project we are displaying settings for
  private activeProjectSettingsDetails: {}; // details of the project we are displaying settings for
  private activeProjectSettingsLogo: File; // file object of the logo for the project we are displaying settings for
  private activeProjectSettingsContributorData: any[];
  private pendingProjectSettingsContributorData: any[];
  private activeProjectSettingsInvitationData: {};
  private activeProjectUserType: string;
  private activeDigitalVerificationDetails: {
    response: any;
    files: any;
    filesFinishedParsing: boolean;
  };
  private reinviteUser: {};
  private joinedProjectName: string;
  private activeProjectLeave: string;
  private activeProjectLeaveDetails: {};
  private forceLazyImageRefresh: boolean; // if this is set to true it will trigger lazy refresh on already expanded people records
  private projectType: string; // keeps information what is the type of the currently selected project
  private faceImage; // variable to store the person image when generating the PDF summary
  private addressData; // variable to store teh address data when generating the PDF summary
  private updatedAt;
  private displayRangeFilter = false;
  private displayFilterByVerification = false;
  private verificationFilter = {};
  private verificationFilterStatus = [""];
  private activeVerificationsFilters = [];
  // variables for tracking status of screening, necessary for managing images
  private screeningFinished = false;
  private changingProject = false;
  // subjects and observables
  private activeUserEvent = new Subject<any>();
  public activeUserStatus = this.activeUserEvent.asObservable();
  private updateUserInfo = new Subject<any>();
  public updateUserInfoStatus = this.updateUserInfo.asObservable();
  private activeProjects = new Subject<any>();
  private activeProjectsStatus = new Subject<any>();
  public detectActiveProjectsStatus = this.activeProjectsStatus.asObservable();
  private activeProjectStatus = new Subject<any>();
  public detectActiveProjectStatus = this.activeProjectStatus.asObservable();
  private toggleHeader = new Subject<any>();
  public toggleHeaderStatus = this.toggleHeader.asObservable();
  private activeProjectPeople = new Subject<any>();
  private createPerson = new Subject<any>();
  public createLegalRepresentativeStatus = this.createPerson.asObservable();
  private editPerson = new Subject<any>();
  public editLegalRepresentativeStatus = this.editPerson.asObservable();
  private activeLegalRepresentativesStatus = new Subject<any>();
  public detectActiveLegalRepresentativesStatus =
    this.activeLegalRepresentativesStatus.asObservable();
  private activePersonStatus = new Subject<any>();
  public detectActivePersonStatus = this.activePersonStatus.asObservable();
  private loadAdditionalPersonDetailsStatus = new Subject<any>();
  public detectAdditionalPersonDetailsStatus =
    this.loadAdditionalPersonDetailsStatus.asObservable();
  private generateVerificationUrlStatus = new Subject<any>();
  public detectVerificationUrlStatus =
    this.generateVerificationUrlStatus.asObservable();
  private generateAddressVerificationStatus = new Subject<any>();
  public detectAddressVerificationStatus =
    this.generateAddressVerificationStatus.asObservable();
  private loadPersonImageStatus = new Subject<any>();
  public screeningStatus = new Subject<any>();
  public detectScreeningStatus = this.screeningStatus.asObservable();
  public screeningResolveStatus = new Subject<any>();
  public detectscreeningResolveStatus = this.screeningStatus.asObservable();
  public detectLoadPersonImageStatus =
    this.loadPersonImageStatus.asObservable();
  private retrieveVerificationDetailsStatus = new Subject<any>();
  public detectRetrieveVerificationDetailsStatus =
    this.retrieveVerificationDetailsStatus.asObservable();
  private retrieveAddressVerificationDetailsStatus = new Subject<any>();
  public detectRetrieveAddressVerificationDetailsStatus =
    this.retrieveAddressVerificationDetailsStatus.asObservable();
  private displayProjectSelectorStatus = new Subject<any>();
  public detectDisplayProjectSelectorStatus =
    this.displayProjectSelectorStatus.asObservable();
  private retrieveCountryIsoStatus = new Subject<any>();
  public detectRetrieveCountryIsoStatus =
    this.retrieveCountryIsoStatus.asObservable();
  private retrieveCountryNameStatus = new Subject<any>();
  public detectRetrieveCountryNameStatus =
    this.retrieveCountryNameStatus.asObservable();
  private retrieveCountryFlagStatus = new Subject<any>();
  public detectRetrieveCountryFlagStatus =
    this.retrieveCountryFlagStatus.asObservable();
  private activeVerificationStatus = new Subject<any>();
  public detectActiveVerificationStatus =
    this.activeVerificationStatus.asObservable();
  private activeAddressVerificationStatus = new Subject<any>();
  public detectActiveAddressVerificationStatus =
    this.activeAddressVerificationStatus.asObservable();
  private pdfGenerationStatus = new Subject<any>();
  public detectPdfGenerationStatus = this.pdfGenerationStatus.asObservable();
  private addressVerificationPdfGenerationStatus = new Subject<any>();
  public detectAddressVerificationPdfGenerationStatus =
    this.addressVerificationPdfGenerationStatus.asObservable();
  private activeProjectSettingsStatus = new Subject<any>();
  public detectActiveProjectSettingsStatus =
    this.activeProjectSettingsStatus.asObservable();
  private verificationValidityCheckStatus = new Subject<any>();
  public detectVerificationValidityCheckStatus =
    this.verificationValidityCheckStatus.asObservable();
  private updateProjectBrandingStatus = new Subject<any>();
  public detectUpdateProjectBrandingStatus =
    this.updateProjectBrandingStatus.asObservable();
  private uploadProjectLogoStatus = new Subject<any>();
  public detectUploadProjectLogoStatus =
    this.uploadProjectLogoStatus.asObservable();
  private displayCustomizeProjectLightbox = new Subject<any>();
  public detectDisplayCustomizeProjectLightbox =
    this.displayCustomizeProjectLightbox.asObservable();
  private displayEditTemplateLightbox = new Subject<any>();
  public detectDisplayEditTemplateLightbox =
    this.displayEditTemplateLightbox.asObservable();
  private displayInviteContributorsLightbox = new Subject<any>();
  public detectDisplayInviteContributorsLightbox =
    this.displayInviteContributorsLightbox.asObservable();
  private displayManageContributorsLightbox = new Subject<any>();
  public detectDisplayManageContributorsLightbox =
    this.displayManageContributorsLightbox.asObservable();
  private displayGenerateReportLightbox = new Subject<any>();
  public detectDisplayGenerateReportLightbox =
    this.displayGenerateReportLightbox.asObservable();
  private displayAdvancedSettingsLightbox = new Subject<any>();
  public detectDisplayAdvancedSettingsLightbox =
    this.displayAdvancedSettingsLightbox.asObservable();
  private projectDelete = new Subject<any>();
  public detectProjectDelete = this.projectDelete.asObservable();
  private projectDeleteDialogue = new Subject<any>();
  public detectprojectDeleteDialogue =
    this.projectDeleteDialogue.asObservable();
  private generateContributorInviteUrlStatus = new Subject<any>();
  public detectGenerateContributorInviteUrlStatus =
    this.generateContributorInviteUrlStatus.asObservable();
  private addContributorStatus = new Subject<any>();
  public detectAddContributorStatus = this.addContributorStatus.asObservable();
  private loadProjectContributors = new Subject<any>();
  public detectLoadProjectContributors =
    this.loadProjectContributors.asObservable();
  private loadProjectInvitations = new Subject<any>();
  public detectLoadProjectInvitations =
    this.loadProjectInvitations.asObservable();
  private markAsOwner = new Subject<any>();
  public detectMarkedAsOwner = this.markAsOwner.asObservable(); // not in use right now
  private removeAsOwner = new Subject<any>();
  public detectRemoveAsOwner = this.removeAsOwner.asObservable; // not in use right now
  private reinviteContributorStatus = new Subject<any>();
  public detectReinviteContributorStatus =
    this.reinviteContributorStatus.asObservable();
  private joinProjectStatus = new Subject<any>();
  public detectJoinProjectStatus = this.joinProjectStatus.asObservable();
  private leaveAProject = new Subject<any>();
  public detectLeaveAProjectStatus = this.leaveAProject.asObservable();
  private activeProjectUserTypeStatus = new Subject<any>();
  public detectActiveProjectUserTypeStatus =
    this.activeProjectUserTypeStatus.asObservable();
  private authContributor = new Subject<any>();
  public authContributorStatus = this.authContributor.asObservable(); // not in use right now
  private cancelVerification = new Subject<any>();
  public cancelVerificationStatus = this.cancelVerification.asObservable();
  private resetBranding = new Subject<any>();
  public resetBrandingStatus = this.resetBranding.asObservable();
  private loadMorePersons = new Subject<any>();
  public detectLoadMorePersons = this.loadMorePersons.asObservable();
  private activeProjectChange = new Subject<any>();
  public detectActiveProjectChange = this.activeProjectChange.asObservable();
  private activeProjectSortBy = "last_modified";
  private completePDFDownload = new Subject<any>();
  public detectCompletePDFDownload = this.completePDFDownload.asObservable();
  private completeIframeData = new Subject<any>();
  public detectcompleteIframeData = this.completeIframeData.asObservable();
  public basicFieldsCompanySearch = new Subject<any>();
  public detectBasicFieldsCompanySearch =
    this.basicFieldsCompanySearch.asObservable();
  // for triggering preview on the id-verification product
  // used by component app-verification-statuses
  private triggerIDpreview = new Subject<any>();
  public detectTriggerIDPreview = this.triggerIDpreview.asObservable();
  // for triggering information about the mobile version of the uploader
  public reviewDataLoadedIn = new Subject<any>();
  public detectReviewDataLoadedIn = this.reviewDataLoadedIn.asObservable();
  public previewWindowPopUp = new Subject<any>();
  public detectPreviewWindowPopUp = this.previewWindowPopUp.asObservable();
  public reviewWindowClosed = new Subject<any>();
  public detectReviewWindowClosed = this.reviewWindowClosed.asObservable();
  public idVerificationResolveAttempt = false;
  public refreshIdVerificaitonKey;
  // FUNDS
  private activeInvestorList: any[]; // list of all legal representatives in the active project
  private activeInvestorRiskRatings: string[]; // list of risk ratings for the activeInvestorList
  private activeEvidenceKey: string; // to be used with extreme caustion it is not always guaranteed that it is properly populated
  private activeInvestorDetails;
  private activeInvestorShareData;
  private activeInvestorsStatus = new Subject<any>();
  public detectActiveInvestorsStatus =
    this.activeInvestorsStatus.asObservable();
  private activeInvestor: string; // key of currently active/selected investor expanded/editing/verifying
  private activeProjectInvestors = new Subject<any>();
  private activeInvestorFilter = "none";
  private loadAdditionalInvestorDetailsStatus = new Subject<any>();
  public detectAdditionalInvestorDetailsStatus =
    this.loadAdditionalInvestorDetailsStatus.asObservable();
  private informationRequestStepStatus = new Subject<any>();
  public detectInformationRequestStepStatus =
    this.informationRequestStepStatus.asObservable();
  private fundsOrganizationDetails;
  public fundsOrganizationTemplates;
  private displayConfirmUseOfNewInformationLightbox = new Subject<any>();
  public detectDisplayConfirmUseOfNewInformationLightbox =
    this.displayConfirmUseOfNewInformationLightbox.asObservable();
  private displayReplaceExistingDocumentLightbox = new Subject<any>();
  public detectDisplayReplaceExistingDocumentLightbox =
    this.displayReplaceExistingDocumentLightbox.asObservable();
  private displayCreateNewDocumentConfirmation = new Subject<any>();
  public detectDisplayCreateNewDocumentConfirmation =
    this.displayCreateNewDocumentConfirmation.asObservable();
  private dispalyReviewPreviewlightbox = new Subject<any>();
  public detectDispalyReviewPreviewlightbox =
    this.dispalyReviewPreviewlightbox.asObservable();
  public displayLoadInvestorReviewStatus = new Subject<any>();
  public detectisplayLoadInvestorReviewStatus =
    this.displayLoadInvestorReviewStatus.asObservable();
  private displayAccessPopUp = new Subject<any>();
  public detectDisplayAccessPopUp = this.displayAccessPopUp.asObservable();
  private resetHits = new Subject<any>();
  public detectResetHits = this.resetHits.asObservable();
  // project type flow is neccessary to differentiate between different evidence list flows
  private projectTypeFlow;
  private activeEvidenceHistory;
  private mergedEvidenceTypes;
  private uuiDUpdates = new Subject<any>();
  public uuiDUpdatesStatus = this.uuiDUpdates.asObservable();
  public jumioTypeCountries;
  public kompanyTypeCountries;
  public organizationContractTemplates;
  public localFundCreationData;
  // only used after AR is generated in a specific case
  private unaddedURLKey;
  public forceLoadingScreen;

  private intervalDuration = 7000;
  private intervalCycle = 0;
  // for testing purposes this is set to 5
  private maxIntervalCycles = 5; // retry max 25 times aprox. 3 minutes
  private doResolveIntervalCycles = false; // used to control wether we will attempt to resolve a DV more than once
  private interval: any;

  private relatedPartiesAddedToReview; // variable to make sure the building for the related parties is only made once

  private emailPattern = emailPattern;
  unsubscribe$: Subject<void> = new Subject<void>();

  country_list_array = country_list_array;
  country_list_AV = country_list_AV;
  relatedPartyRoles = relatedPartyRoles;
  title_list = title_list;
  gender_list = gender_list;
  yn_list = yn_list;
  id_type_list = id_type_list;
  id_type_list2 = id_type_list2;
  idv_locale_list = idv_locale_list;
  idv_workflow_list = idv_workflow_list;
  legalPersonTypeList = legalPersonTypeList;
  fuzinessLevelList = fuzinessLevelList;
  templatenotOlderThan = templatenotOlderThan;
  documenTypetList = this.documentTypeList.getDocumentTypeList();
  private localTemplateData;

  // which filter type is clicked: by profile type or by status
  filterType: string;
  private hideCompletedProfiles: boolean;

  // APS whitelisting
  private complianceCheckWasWhitelisted = false;
  private statusOfHits: { relevant: number; irrelevant: number };
  private showIrrelevantHits = false;
  private APSStatusses;
  private apsFields;

  private UUID;
  private allowReuseProfile = true;

  getAllowReuseProfile() {
    return this.allowReuseProfile;
  }
  setAllowReuseProfile(allowReuseProfile) {
    this.allowReuseProfile = allowReuseProfile;
  }
  changeAllowReuseProfile() {
    this.allowReuseProfile = !this.allowReuseProfile;
  }

  getAPSStatusses() {
    return this.APSStatusses;
  }
  setAPSSTatusses(APSStatusses) {
    this.APSStatusses = APSStatusses;
  }

  getAPSFields() {
    return this.apsFields;
  }
  setAPSFields(apsFields) {
    this.apsFields = apsFields;
  }

  getHideCompletedProfiles(): boolean {
    return this.hideCompletedProfiles;
  }

  setHideCompletedProfiles(hideCompletedProfiles: boolean) {
    this.hideCompletedProfiles = hideCompletedProfiles;
  }

  getComplianceCheckWasWhitelisted(): boolean {
    return this.complianceCheckWasWhitelisted;
  }

  setComplianceCheckWasWhitelisted(complianceCheckWasWhitelisted: boolean) {
    this.complianceCheckWasWhitelisted = complianceCheckWasWhitelisted;
  }

  getStatusOfHits(): { relevant: number; irrelevant: number } {
    return this.statusOfHits;
  }

  setStatusOfHits(statusOfHits: { relevant: number; irrelevant: number }) {
    this.statusOfHits = statusOfHits;
  }
  getRelevantHits(): number {
    return this.statusOfHits["relevant"];
  }

  getIrrelevantHits(): number {
    return this.statusOfHits["irrelevant"];
  }

  getShowIrrelevantHits(): boolean {
    return this.showIrrelevantHits;
  }

  setShowIrrelevantHits(showIrrelevantHits: boolean) {
    this.showIrrelevantHits = showIrrelevantHits;
  }
  changeShowIrrelevantHits() {
    this.showIrrelevantHits = !this.showIrrelevantHits;
  }

  getDocumentTypeList() {
    return this.documenTypetList;
  }

  getLocalTemplateData() {
    return this.localTemplateData;
  }

  getCountryListAV() {
    const ret_country_list_AV = [...country_list_AV];
    ret_country_list_AV.unshift({
      label: "",
      value: "emptySelect",
    });
    return ret_country_list_AV;
  }

  getCountryListAVClean() {
    const ret_country_list_AV = [...country_list_AV];
    return ret_country_list_AV;
  }
  removeRPFromParsedRelatedParties(rpType: string, rp)
  {
    if(this.localFundCreationData?.fetchedCompanyListDeatils?.parsedRelatedParties[rpType]?.length > 0)
      {
        var index = this.localFundCreationData.fetchedCompanyListDeatils.parsedRelatedParties[rpType].indexOf(rp);
        if(index != -1)
          {
            this.localFundCreationData.fetchedCompanyListDeatils.parsedRelatedParties[rpType].splice(index, 1);
          }
      }

  }

  // helper function to parse purposes of use
  parsePurposeOfUse(statePurposes) {
    const purposes = this.getDocumentTypeList().PurposesOfUse;
    const parsedPurposes = [];
    if (!statePurposes) {
      return undefined;
    }
    purposes.forEach((purpose) => {
      parsedPurposes.push({
        id: purpose,
        label: this.helperService.parseBackendName(purpose),
        checked: statePurposes.includes(purpose),
      });
    });
    return parsedPurposes;
  }

  parseCountryOptions(emptyLabel?) {
    // build country list
    const ret_country_list = [];
    this.country_list_array.forEach((elem) => {
      ret_country_list.push({
        key: elem,
        name: this.helperService.parseCountryName(elem),
      });
    });
    ret_country_list.sort((countryA, countryB) =>
      countryA.name > countryB.name ? 1 : -1,
    );

    ret_country_list.unshift({
      key: "emptySelect",
      name: emptyLabel ? emptyLabel : "",
    });
    return ret_country_list;
  }

  getIDTypeList() {
    return this.id_type_list;
  }

  getRelatedPartyRoles() {
    const relatedPartyRolesCopy = [...relatedPartyRoles];
    relatedPartyRolesCopy.forEach((role) => {
      const translationKey = "relatedPartyRoles." + role.key;
      role.name = this.translate.instant(translationKey);
    });
    return this.relatedPartyRoles;
  }

  getIDTypeList2() {
    return this.id_type_list2;
  }

  getLegalPersontypeListCopyForKYCTemplates() {
    const copy = [...this.legalPersonTypeList];
    copy.shift();
    copy.unshift({ key: "LegalPerson", name: "Legal person" });
    copy.forEach((type) => {
      const translationKey = "legalType." + type.key;
      type.name = this.translate.instant(translationKey);
    });
    copy.unshift({ key: "NaturalPersonMinor", name: "Natural person - minor" });
    copy.unshift({ key: "NaturalPerson", name: "Natural person" });
    return copy;
  }

  getLegalPersontypeListCopyAR() {
    const copy = [...this.legalPersonTypeList];
    copy.shift();
    copy.forEach((type) => {
      const translationKey = "legalType." + type.key;
      type.name = this.translate.instant(translationKey);
    });
    return copy;
  }

  getLocaleList() {
    this.idv_locale_list.sort((localeA, localeB) =>
      localeA.name > localeB.name ? 1 : -1,
    );

    return this.idv_locale_list;
  }
  getWorkFlowList() {
    return this.idv_workflow_list;
  }
  getTitleList() {
    return this.title_list;
  }

  getGenderList() {
    return this.gender_list;
  }

  getYNList() {
    return this.yn_list;
  }

  getFuzinessLevels() {
    return this.fuzinessLevelList;
  }

  getTemplatenotOlderThan() {
    return this.templatenotOlderThan;
  }

  returnNameOfCountryFromValue(countryValue) {
    const wantedCountry = this.parseCountryOptions().find(
      (country) => country.key === countryValue,
    );
    return wantedCountry ? wantedCountry.name : undefined;
  }

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private helperService: HelperServiceService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private location: Location,
    private pdf: PdfServerService,
    private ipService: IpServiceService,
    private documentTypeList: DocumenTypetList,
    private sanitized: DomSanitizer,
    public translate: TranslateService,
    public uiService: UiService,
  ) {
    this.baseUrl = environment.APIEndpoint;
    this.swaggerUrl =
      "https://virtserver.swaggerhub.com/SingleTruth/singletruth/1.0.5/";
    this.idVerificationEndpoints = {
      projects: "api/projects", // POST call creates a project and GET call retrieves all projects filter: status = active/deleted
      project: "api/projects/{key}", // GET/PUT/DELETE a project with a key = projectKey
      projectBranding: "api/projects/{key}/branding", // GET/POST a project branding (namely color) with a key = projectKey
      projectLogo: "api/projects/{key}/logo", // POST project logo for projectKey
      projectPeople: "api/projects/{key}/people", // GET/POST people for specific project
      projectPeopleEdit: "api/projects/{key}/people/{verificationKey}", // GET/POST person for specific project
      verificationLink:
        "api/projects/{key}/people/{verificationKey}/verifications/identity", //
      verificationAddressLink:
        "api/projects/{key}/people/{verificationKey}/verifications-address", //
      inviteLink: "api/projects/{projectKey}/invitations", // depercated, we no longer generate links to invite contributors, they are added directly to a project
      addContributor: "api/projects/{projectKey}/contributors/add",
      projectVerifications: "api/projects/{projectKey}/verifications/identity", // GET/POST verification under project defined by projectKey
      deletePerson: "api/projects/{projectKey}/people/{verificationKey}",
      manageVerification:
        "api/projects/{projectKey}/verifications/identity/{verificationKey}", // GET/PUT verification identified by projectKey and verificationKey
      verificationImage:
        "api/projects/{projectKey}/verifications/identity/{verificationKey}/image",
      requestInformation:
        "api/projects/{projectKey}/verifications/{verificationKey}/link",
      requestSearchHistory: "api/verifications/{verificationKey}", // GET initiate verification flow in iframe
      verificationCheck: "api/verificationcheck/{key}", // check verification validitty
      projectContributors: "api/projects/{key}/contributors", // load contributors
      projectContributorInvitations: "api/projects/{key}/invitations", // load pending invitations
      makeOwner: "api/projects/{key}/contributors/{verificationKey}/makeowner",
      removeContributor:
        "api/projects/{key}/contributors/{verificationKey}/remove",
      getInvitationDetails: "api/invitations/{key}/details", // fetch details for invitation
      projectLeave: "api/projects/{projectKey}/leave", // leave a project
      projectClearNotification: "api/projects/{key}/seen", // the endpoint doesn't exist at the moment, it needs to be updated when BE creates it
      personClearNotification: "/projects/{key}/people/{verificationKey}/seen", // the endpoint doesn't exist at the moment, it needs to be updated when BE creates it
      authorizeContributor:
        "api/projects/{key}/contributors/{verificationKey}/approve", // authorize a contributor who accepted an invitation
      brandingReset: "api/projects/{key}/branding/reset", // reset to default branding for all projects
      screeningInit:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/screenings", // initiate a person screening
      screeningResolve:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/screenings/{screeningKey}/resolve", // resolve a person screening
      generateIDReport: "api/projects/{projectKey}/reporting/verifications",
      generateCDDReport: "api/organizations/report",
      generateCDDReportAdmin: "api/admin/organizations/{projectKey}/report", // projectKey is actually organizationKey in the API
      getAddressDocumentFile:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/address/{screeningKey}/documents/{documentKey}",
      apsInit:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/aps/initiate", // call for comply advantage, create and get
      apsGet:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/aps/{screeningKey}", // call for comply advantage, create and get
      apsConfirm:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/aps/{screeningKey}/finalize",
      AVConfirm:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/address/{screeningKey}/finalize",
      contractGet:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/contract/{screeningKey}/document", // call for comply advantage, create and get
      contractGetwKey:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/contract/{screeningKey}/document/{documentKey}", // call for comply advantage, create and get
      ehnancedCheck:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/address/{screeningKey}/enhanced",
      whitelistHitVerify:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/aps/whitelist",
      commentHitVerify:
        "api/projects/{projectKey}/people/{verificationKey}/verifications/aps/comment",
      profileVerification:
        "api/projects/{projectKey}/information/requests/{requestKey}/validate",

      // funds
      investors: "api/projects/{projectKey}/information/requests", // create the initial information request / retreive all information requests
      verificationFilter:
        "api/projects/{projectKey}/information/requests/filter/records",
      getFund: "api/projects/{projectKey}/information/requests/{requestKey}",
      getInvestor:
        "api/projects/{projectKey}/information/requests/{requestKey}",
      createDocumentOutline:
        "api/projects/{projectKey}/information/requests/{requestKey}", // set up which document types and categories will be in the request
      fillEvidenceField:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}", // fill up evidence fields (textual and not image fields)
      fillEvidenceFieldDV:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/{evidenceKey}",
      deleteRequestDocument:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/documents/{documentKey}", // deletes document
      generateInvestorShareUrl:
        "api/projects/{projectKey}/information/requests/{requestKey}/share",
      informationRequestDocument:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/documents",
      investorDocuments:
        "api/projects/{projectKey}/information/requests/{requestKey}/documents",
      createRelatedParty:
        "api/projects/{projectKey}/information/requests/{requestKey}/related",
      investorRelatedParties:
        "api/projects/{projectKey}/information/requests/related",
      loadInvestorRelatedParties:
        "api/projects/{projectKey}/information/requests/{requestKey}/related",
      getEvidenceHistory:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/history",
      getDocumentsFile:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/documents/{documentKey}",
      getEvidenceAccess:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/access",
      revokeEvidenceAccess:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/access/revoke",
      reviewEvidence:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/review",
      getDocumentsFileMeta:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/documents/{documentKey}/metadata",
      acceptEvidenceAsCorrect:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/correct",
      acceptEvidenceAsCorrectRP:
        "api/projects/{projectKey}/information/requests/{requestKey}/",
      getEvidenceAccessInfo:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/access",
      acceptMine:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{toBeReplaced}/accept/mine/{toBeReplacedWith}",
      acceptTheirs:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{toBeReplaced}/accept/theirs/{toBeReplacedWith}",
      evidenceVersions:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/versions",
      setEvidenceInUse:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{toBeReplaced}/versions/{toBeReplacedWith}/inuse",
      getEvidenceVersionAccess:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/versions/previous/access",
      postEvidenceVersionAccess:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/versions/access",
      lazyResolveDigitalVerificationsFunds:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/resolve",
      lazyResolveDigitalVerificationsInvestor:
        "api/projects/{projectKey}/information/requests/resolve",
      apsInitCDD:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/aps",
      turnOffMonitoringOnProfile:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/aps/monitor",
      whitelistHitFund:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/aps/whitelist",
      commentHitFund:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/aps/comment",
      statusReport: "api/projects/{projectKey}/reporting/status",
      KYCTemplates: "api/organizations/templates",
      KYCTemplateSingle: "api/organizations/templates/{projectKey}",
      // investor claim/share screen
      claimSideRequest: "/api/investor​/requests​/{requestKey}",
      claimSideEvidence:
        "api/investor/requests/{requestKey}/evidence/{evidenceKey}",
      claimSideDocument:
        "​api/investor​/requests​/{requestKey}​/evidence​/{evidenceKey}​/documents​/{documentKey}",
      claimSideRelatedParty:
        "api/investor/requests/{requestKey}/related/{evidenceKey}",
      claimSideRelatedPartyInvestor:
        "api/projects/{projectKey}/information/requests/related/{requestKey}",
      claimSideDocumentFile:
        "api/investor/requests/{requestKey}/evidence/{evidenceKey}/documents/{documentKey}",
      markAsExpired:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/expired",
      updateInvestorFields:
        "api/projects/{projectKey}/information/requests/{requestKey}/update",
      updateRPBF:
      "api/projects/{projectKey}/information/requests/{requestKey}/related/{RPKey}/update",
      updateRPFields:
        "api/projects/{projectKey}/information/requests/{requestKey}/related/{evidenceKey}/update",
      investorShareInformation: "api/information/requests/{requestKey}/share",
      adminUUId: "/admin/organizations/{requestKey}",
      delegateRelatedParty:
        "api/projects/{projectKey}/information/requests/related/{toBeReplaced}/delegate/{toBeReplacedWith}",
      revertEvidence:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/versions/revert",
      getProjectTags: "api/projects/{projectKey}/tags", // get the tags on a PROJECT level
      addPersonTags: "api/projects/{projectKey}/people/{verificationKey}/tags", // add a tag on a PERSON level
      addPersonTagsCDD: "api/projects/{projectKey}/information/requests/{requestKey}/tags", // add a tag on a PERSON level
      deletePersonTags:
        "api/projects/{projectKey}/people/{verificationKey}/tags/{screeningKey}", // delete a tag on a PERSON level,
      deletInvestorTags:
      "api/projects/{projectKey}/information/requests/{verificationKey}/tags/{screeningKey}", // delete a tag on a PERSON level,
      getProjectRiskRating: "api/projects/{projectKey}/ratings", // get the ratings on a PROJECT level
      digitalVerificationReview:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/{evidenceKey}/initiate",
      dvAcceptReject:
        "api/projects/{projectKey}/information/requests/{requestKey}/evidence/{evidenceKey}/finalize",
      IDVdvAcceptReject:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/{evidenceKey}/overwrite",
      overwriteIdentityStatus:
        "/api/projects/{projectKey}/information/requests/{requestKey}/verifications/identity/overwrite",
      pcsRPCreate:
        "api/projects/{projectKey}/information/requests/{requestKey}/related",
      pcsIRCreate:
        "api/projects/{projectKey}/information/requests/{requestKey}/template",
      kompanySearch: "api/search/companies/",
      kompanyDetails: "api/search/companies/{projectKey}",
      coSigners:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/{screeningKey}/signers",
      removeCosigner:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/{screeningKey}/signers/remove",
      // corsany-proxy
      "corsany-proxy": "api/proxy",
      initiateDV:
        "api/projects/{projectKey}/information/requests/{requestKey}/verifications/initiate",
      //send verificaiton letter for address digital verification
      sendVerificationLetter:
      "api/digital/verifications/{cryptoKey}/address/letter",
      generatePDFReportBackend: 
      'api/projects/{projectKey}/people/{requestKey}/verifications/{screeningKey}/pdf',
      generateFullPDFReportBackend:
      'api/projects/{projectKey}/people/{requestKey}/pdf',

      // risk assesment
      riskReviewEvent: "api/risk/review/{projectKey}",
      getRiskReviewHistory: "api/risk/review/{projectKey}/history",
      finalizeRiskReview: "api/risk/review/{projectKey}/finalize",
      overrideRiskIndicator: "api/risk/review/{projectKey}/answers",
      getContributors: "api/projects/{projectKey}/contributors?status=active",
      recalculateRiskAssessment: "api/risk/review/{projectKey}/recalculate",
      assignApprovers: "api/risk/review/{projectKey}/approvers",
      
    };

    this.pdf.detectPdfGenerationStatusEvt.subscribe((pdfStatus) => {
      if (pdfStatus && pdfStatus.status && pdfStatus.type) {
        if (pdfStatus.status === "DONE" || pdfStatus.status === "ERROR") {
          let pdfBtnText = "Done!";
          let pdfBtnIcon = "/assets/images/download.svg";
          if (pdfStatus.status === "ERROR") {
            pdfBtnText = "Coudn't generate the PDF Report!";
            pdfBtnIcon = "/assets/images/ico-status-rejected.svg";
          }
          // if displayed in a lightbox:
          if ($(".pdf-gen-loading").length) {
            $(".pdf-gen-loading img")
              .attr("src", pdfBtnIcon)
              .removeClass("pdf-gen-loading");
            // $('.pdf-gen-loading span').addClass('pdf-download-error').text(pdfBtnText);
          }
          if (pdfStatus.status === "ERROR") {
            return;
          }
          const _ = this;
          switch (pdfStatus.type) {
            case "idVerification":
              $(
                '.result-container[verificationkey="' +
                  this.getActiveVerification() +
                  '"] .pdf-gen-button',
              ).html(pdfBtnText);
              setTimeout(function () {
                $(".pdf-gen-loading span").text("Download PDF Report");
                $(".pdf-gen-loading").removeClass("pdf-gen-loading");
                $(
                  '.result-container[verificationkey="' +
                    _.getActiveVerification() +
                    '"] .pdf-gen-button',
                ).html("Download");
              }, 2000);
              break;
            case "addressVerification":
              $(
                '.result-container[addressverificationkey="' +
                  this.getActiveAddressVerification() +
                  '"] .pdf-gen-button',
              ).html(pdfBtnText);
              setTimeout(function () {
                $(".pdf-gen-loading span").text("Download PDF Report");
                $(".pdf-gen-loading").removeClass("pdf-gen-loading");
                $(
                  '.result-container[addressverificationkey="' +
                    _.getActiveAddressVerification() +
                    '"] .pdf-gen-button',
                ).html("Download");
              }, 2000);
              break;
            case "aps":
              $(
                '.result-container[addressverificationkey="' +
                  this.getActiveAddressVerification() +
                  '"] .pdf-gen-button',
              ).html(pdfBtnText);
              setTimeout(function () {
                $(".pdf-gen-loading span").text("Download Report");
                $(".pdf-gen-loading").removeClass("pdf-gen-loading");
                $(
                  '.result-container[addressverificationkey="' +
                    _.getActiveAddressVerification() +
                    '"] .pdf-gen-button',
                ).html("Download");
              }, 2000);
              break;
            case "contract":
              $(
                '.result-container[addressverificationkey="' +
                  this.getActiveAddressVerification() +
                  '"] .pdf-gen-button',
              ).html(pdfBtnText);
              setTimeout(function () {
                $(".pdf-gen-loading span").text("Download Report");
                $(".pdf-gen-loading").removeClass("pdf-gen-loading");
                $(
                  '.result-container[addressverificationkey="' +
                    _.getActiveAddressVerification() +
                    '"] .pdf-gen-button',
                ).html("Download");
              }, 2000);
              break;
            default:
              // DO NOTHING!
              break;
          }
        }
      }
    });

    // handle user status
    this.userService.activeUserStatus.subscribe(
      (activeUser) => {
        if (typeof activeUser === undefined) {
          console.log("ERROR FETCHING ACTIVE USER FROM BACKEND");
          this.activeUser = 0;
          this.activeUserEvent.next(false);
          return;
        }
        this.activeUser = activeUser;
        this.activeUserEvent.next(activeUser);
      },
      (error) => {
        console.log(error);
        this.activeUser = 0;
        this.activeUserEvent.next(false);
      },
    );

    // handle update user info status
    this.userService.updateUserInfoStatus.subscribe(
      (status) => {
        // simply re-emit the status
        this.updateUserInfo.next(status);
      },
      (error) => {
        console.log(error);
        this.updateUserInfo.next(null);
      },
    );

    // make sure the forceLazyRefresh is turned off
    this.resetForceLazyImageRefresh();
  }

  /**
   * build endpoint
   * @param endpoint destination url
   * @param projectKey key of the project we are targeting
   * @param verificationKey key of the verification we are targeting
   * @param query query string params / not needed at the moment
   * @param demoUrl true | false should I use demoUrl to construct the call
   * @param screeningKey key of the screening to be resolved
   */
  buildEndPoint(
    endpoint,
    projectKey = "",
    verificationKey = "",
    query = "",
    demoUrl = true,
    screeningKey = "",
    documentKey = "",
    toBeReplaced = "",
    toBeReplacedWith = "",
    RPKey = "",
  ) {
    return (
      (demoUrl ? this.baseUrl : this.swaggerUrl) +
      this.idVerificationEndpoints[endpoint]
        .replace("{key}", projectKey)
        .replace("{projectKey}", projectKey)
        .replace("{verificationKey}", verificationKey)
        .replace("{requestKey}", verificationKey)
        .replace("{screeningKey}", screeningKey)
        .replace("{evidenceKey}", screeningKey)
        .replace("{documentKey}", documentKey)
        .replace("{toBeReplaced}", toBeReplaced)
        .replace("{toBeReplacedWith}", toBeReplacedWith)
        .replace("{RPKey}", RPKey) +
      query
    );
  }
  /**
   * retrieve active environment's endpoint baseurl
   */
  getEndPointBaseUrl() {
    return this.baseUrl;
  }

  getScreeningFinished() {
    return this.screeningFinished;
  }
  setScreeningFinished(value: boolean) {
    this.screeningFinished = value;
  }
  getChangingProject() {
    return this.changingProject;
  }
  SetChangingProject(value: boolean) {
    this.changingProject = value;
  }

  /**
   * Toggle header type
   * @param type string, enum: full | inline
   */
  /**
   * toggle the type of the header (full => inline)
   */
  toggleInlineHeader(type: string) {
    if (type === "full" || type === "inline") {
      // console.log('toggleInlineHeader', type);
      const _this = this;
      setTimeout(() => {
        _this.toggleHeader.next("inline");
      });
    }
    // do nothing
  }

  /**
   * it will set forceLazyRefresh to true
   */
  setForceLazyImageRefresh() {
    this.forceLazyImageRefresh = true;
  }

  /**
   * it will reset forceLazyRefresh back to false
   */
  resetForceLazyImageRefresh() {
    this.forceLazyImageRefresh = false;
  }

  /**
   * it will reset forceLazyRefresh back to false
   */
  getForceLazyImageRefresh() {
    return this.forceLazyImageRefresh;
  }

  getActiveProjectBookmark() {
    return this.activeProjectBookmark;
  }
  setActiveProjectBookmark(bookmark) {
    this.activeProjectBookmark = bookmark;
  }
  getActiveLegalRepresentativesBookmark() {
    return this.activeLegalRepresentativesBookmark;
  }
  getactiveRecordsCount()
  {
    return this.activeRecordsCount
  }
  setActiveLegalRepresentativesBookmark(activeLegalRepresentativesBookmark) {
    this.activeLegalRepresentativesBookmark =
      activeLegalRepresentativesBookmark;
  }
  // functions for tags
  getActiveLegalRepresentativesTags() {
    return this.activeLegalRepresentativesTags;
  }
  setactiveLegalRepresentativesTags(tags) {
    this.activeLegalRepresentativesTags = tags;
  }
  getActiveLegalRepresentativesTopTags() {
    return this.activeLegalRepresentativesTopTags;
  }
  setActiveLegalRepresentativesTopTags(tags) {
    this.activeLegalRepresentativesTopTags = tags;
  }
  getActiveLegalRepresentativesUnadedTags() {
    return this.activeLegalRepresentativesUnadedTags;
  }
  setActiveLegalRepresentativesUnadedTags(tags) {
    this.activeLegalRepresentativesUnadedTags = tags;
  }
  getActiveLegalrepresentativesFilters() {
    return this.activeLegalrepresentativesFilters;
  }
  setActiveLegalrepresentativesFilters(filters) {
    this.activeLegalrepresentativesFilters = filters;
  }
  isActiveFilterPresent(): boolean {
    return (
      (this.activeLegalrepresentativesFilters &&
        (this.helperService.VariableHasValue(
          this.activeLegalrepresentativesFilters.type,
        ) ||
          this.helperService.VariableHasValue(
            this.activeLegalrepresentativesFilters.sortBy,
          ) ||
          this.helperService.VariableHasValue(
            this.activeLegalrepresentativesFilters.status,
          ) ||
          this.helperService.VariableHasValue(
            this.activeLegalrepresentativesFilters.activeTags,
          ) ||
          this.helperService.VariableHasValue(
            this.activeLegalrepresentativesFilters.filterByRiskReview,
          ))) || this.getDisplayFilterByVerification()
    );
  }
  getactiveProjectSortBy() {
    return this.activeProjectSortBy;
  }
  setactiveProjectSortBy(sortBy) {
    this.activeProjectSortBy = sortBy;
  }
  getActiveInvestorShareData() {
    return this.activeInvestorShareData;
  }
  setActiveInvestorShareData(data) {
    this.activeInvestorShareData = data;
  }
  /**
   * sets currently active project
   * @param projects array of project objects
   * @param bookmark hash string for keeping tabs on pagination
   * @param append variable related to project pagination
   * @param lastAppend variable to let component know that all the projects have been loaded
   */
  setActiveProjects(
    projects,
    bookmark?,
    append?,
    lastAppend?,
    activeFilter?,
    loadMore?,
  ) {
    if (projects !== undefined && Array.isArray(projects)) {
      if (append) {
        this.activeProjectList = this.activeProjectList.concat(projects);
      } else {
        this.activeProjectList = projects;
      }
      // update on 04042019
      this.activeProjectList.forEach((project, index) => {
        // update the logo url with a timestamp so we avoid caching issues
        if (
          project["record"] !== undefined &&
          project["record"].projectBranding !== undefined &&
          project["record"].projectBranding.projectLogo !== undefined
        ) {
          project["record"].projectBranding.projectLogo = project[
            "record"
          ].projectBranding.projectLogo += "?" + new Date().getTime();
        }
        // check if color value is in invalid format
        if (
          project["record"] !== undefined &&
          project["record"].projectBranding !== undefined &&
          project["record"].projectBranding.projectColor !== undefined
        ) {
          if (
            !this.helperService.hexValidator(
              project["record"].projectBranding.projectColor,
            )
          ) {
            project["record"].projectBranding.projectColor = undefined;
          }
        }
        // update on 19042019
        // we need to also set the project as active if it matches
        // the this.joinedProjectName, ideally in the future we will
        // match by project.key
        // 29122022
        // project logos are returned as images so no longer do we need to retrieve logos in a separate call
        //   this.retrieveProjectLogo(project['key'], index);
        if (this.joinedProjectName === project["record"].name) {
          // set this project as active
          const _this = this;
          setTimeout(() => {
            _this.setActiveProject(project["key"]);
            _this.toggleInlineHeader("inline");
            _this.displayProjectSelector(false);
            _this.joinProjectStatus.next(true);
          });
        }
      });
      console.log(
        "SETTING NEW PROJECT LIST IT SHOULD BE PICKED UP BY CHANGE DETECTOR",
      );
      this.activeProjectBookmark = bookmark;
      // if lastAppend is false it tells us that projects have just been loaded
      if (lastAppend === undefined) {
        lastAppend = false;
      }
      this.activeProjectsStatus.next({
        bookmark: bookmark,
        lastAppend: lastAppend,
        activeFilter: activeFilter,
        loadMore: loadMore,
      });
    }
  }
  setActiveProjectsSimplified(projects) {
    if (projects !== undefined && Array.isArray(projects)) {
      this.activeProjectList = projects;
    }
  }
  /**
   * remove project with projectId from list of currently active project
   * this method will be primarely used by Leave a project to remove the
   * project from the list of acitve projects
   * @param projectId string, id of project that needs to be removed
   */
  private removeActiveProjectFromList(projectId) {
    let isFound = false;
    if (
      this.activeProjectList !== undefined &&
      Array.isArray(this.activeProjectList)
    ) {
      this.activeProjectList.forEach((project, index) => {
        if (project.key === projectId) {
          // remove this project from list of active project
          isFound = true;
          this.activeProjectList.splice(index, 1);
        }
      });
      if (isFound) {
        console.log(
          "SETTING NEW PROJECT LIST IT SHOULD BE PICKED UP BY CHANGE DETECTOR",
        );
        // update the list of projects
        this.activeProjectsStatus.next();
      }
    }
  }
  /**
   * sets project logo
   * @param projectIndex: int, the index of the project in this.activeProjectList
   * @param projectKey: string, the projectKey
   * @param logo: string: default image path or base64 image string
   */
  setProjectLogo(
    projectIndex: any,
    projectKey: string,
    logo: string | ArrayBuffer,
    scaledW = 56,
    scaledH = 52,
  ) {
    if (this.activeProjectList && this.activeProjectList[projectIndex]) {
      if (this.activeProjectList[projectIndex].key === projectKey) {
        this.activeProjectList[projectIndex].record.logo =
          logo !== "defaultLogo"
            ? logo
            : this.helperService.getDefaultProjectLogo(
                this.activeProjectList[projectIndex].record.projectColor,
              );
        this.activeProjectList[projectIndex].record.scaledW = scaledW;
        this.activeProjectList[projectIndex].record.scaledH = scaledH;
      } else {
        // fallback in case the index is no longer at the same position
        this.activeProjectList.forEach((project, index) => {
          if (this.activeProjectList[projectIndex].key === projectKey) {
            this.activeProjectList[projectIndex].record.logo =
              logo !== "defaultLogo"
                ? logo
                : this.helperService.getDefaultProjectLogo(
                    this.activeProjectList[projectIndex].record.projectColor,
                  );
            this.activeProjectList[projectIndex].record.scaledW = scaledW;
            this.activeProjectList[projectIndex].record.scaledH = scaledH;
            console.log("project list with foreach", this.activeProjectList);
          }
        });
      }
      // update the active project list on FE
      this.activeProjectsStatus.next();
    }
  }
  /**
   * resets currently active project list
   */
  resetActiveProjects() {
    this.resetActivePersons();
    this.resetActiveProject();
    delete this.activeProjectList;
    this.activeProjectBookmark = undefined;
    this.activeProjectsStatus.next();
  }
  /**
   * prepend an empty project to the activeProjectList
   * @param project object
   */
  prependEmptyProject() {
    // check if we are recovering from failed project
    const emptyProject = {
      key: "empty",
      record: {
        name:
          this.projectType === "funds" || this.projectType === "investor"
            ? "Please wait..."
            : "Please wait...",
        status: "active",
        permissionRecord: {
          type: "member", // treat all empty projects as member
        },
      },
    };
    if (
      this.activeProjectList[0] !== undefined &&
      this.activeProjectList[0].key === "failed"
    ) {
      this.activeProjectList[0] = emptyProject;
    } else {
      this.activeProjectList.unshift(emptyProject);
    }
    // notify subscribers of the change
    this.activeProjectsStatus.next();
  }
  /**
   * replace the empty project with failed
   * @param project object
   */
  prependFailedProject() {
    // the first element of activeProjectList
    // should always be an empty project when
    // prependFailedProject is called
    if (
      this.activeProjectList[0] !== undefined &&
      this.activeProjectList[0].key === "empty"
    ) {
      this.activeProjectList[0] = {
        key: "failed",
        record: {
          name: "Creating new project failed!",
          status: "active",
        },
      };
      // notify subscribers of the change
      this.activeProjectsStatus.next();
    }
  }
  /**
   * prepend the project to the activeProjectList
   * @param project object
   */
  prependProject(project) {
    if (typeof project !== "undefined") {
      // the first element of activeProjectList
      // should always be an empty project when
      // prependProject is called
      if (
        this.activeProjectList[0] !== undefined &&
        this.activeProjectList[0].key === "empty"
      ) {
        // '/assets/images/ico-project.svg'; // set default project icon
        this.activeProjectList[0] = project;
        // we no longer retrieve project logo's in a separate call
        // this.retrieveProjectLogo(project.key, 0);
        // notify subscribers of the change
        this.activeProjectsStatus.next();
      }
    }
  }

  removeProject(project) {
    const activeProjectIndex = this.returnEvidencePositionByKey(
      project,
      this.activeProjectList,
    );
    this.activeProjectList.splice(activeProjectIndex, 1);
    console.log(this.getActiveProject());
    if (project !== this.activeProject) {
      this.activeProjectsStatus.next();
    } else {
      if (this.isIdVerification()) {
        this.router.navigate(["./"], { relativeTo: this.activeRoute.parent });
      } else {
        this.router.navigate(["/funds/dashboard"]).then(() => {
          window.location.reload();
        });
      }
    }
  }
  /**
   * retrieve list of active projects for this user
   * @returns currently array of all active project objects
   */
  getActiveProjects() {
    return this.activeProjectList;
  }
  /**
   * get details for a project with projectID
   * @param projectId string, key of the project
   * we are searching details for
   */
  getProjectDetails(projectId) {
    let isFound = false;
    let ret: {};
    if (projectId !== undefined && typeof projectId === "string") {
      this.activeProjectList.forEach((project) => {
        if (project.key === projectId) {
          isFound = true;
          ret = project;
        }
      });
    }
    if (isFound) {
      return ret;
    } else {
      console.log(
        "PASSED PROJECT ID WAS NOT FOUND IN LIST OF ACTIVE PROJECTS",
        projectId,
      );
    }
  }

  /**
   * set current project type
   */
  setProjectType(type: string) {
    this.projectType = type;
  }

  /**
   * get current project type
   */
  getProjectType() {
    return this.projectType;
  }

  /**    informationRequest = this.helperService.sanitize(informationRequest);

   * sets currently active project
   * @param project object
   * @param ignoreIfNotInList a marker to let us know that the project we want to open is not in the list,
   *                          this can happene when deeplinking to a project who is not in the list yet
   */
  setActiveProject(
    projectId,
    resetImage = false,
    projectType = "id-verification",
    ignoreIfNotInList?,
  ) {
    this.unaddedURLKey = undefined;
    // we are trying to fetch a project not in the current list
    let singleRequestKey: any = false;
    if (this.router.url.includes("/request")) {
      const urlSegments = this.router.parseUrl(this.router.url).root.children
        .primary.segments;
      singleRequestKey = urlSegments[urlSegments.length - 1].path;
    }

    if (this.router.url.includes("/verifications")) {
      const urlSegments = this.router.parseUrl(this.router.url).root.children
        .primary.segments;
      singleRequestKey = urlSegments[urlSegments.length - 1].path;
    }

    if (ignoreIfNotInList) {
      // attempt to fetch the project
      this.userService
        .getEndPoint(
          this.buildEndPoint("project", projectId),
          { responseType: "json" },
          { observe: "body" },
          this.getCCType(),
        )
        .subscribe(
          (response) => {
            // if project is not found redirect
            if (typeof response === undefined) {
              this.location.go("/id-verification/dashboard/");
              $(".header-top .navbar-brand .finos-logo-img").click();
              return;
            }
            // update project list
            this.activeProjectList.push(this.helperService.sanitize(response));
            let isFound = false;
            if (projectId !== undefined && typeof projectId === "string") {
              this.activeProjectList.forEach((project) => {
                if (project.key === projectId) {
                  isFound = true;
                  this.activeProject = projectId;
                  this.activeProjectDetails = project;
                  // get people for this projectCustomize projectectId);
                  if (projectType === "id-verification") {
                    if (resetImage) {
                      this.setScreeningFinished(true);
                    }
                    this.retrieveActiveLegalRepresentatives(
                      projectId,
                      false,
                      true,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      15,
                      singleRequestKey
                    );
                  } else if (
                    projectType === "funds" ||
                    projectType === "investor"
                  ) {
                    this.retreiveActiveInvestors(
                      projectId,
                      "",
                      projectType,
                      null,
                      null,
                      true,
                      null,
                      null,
                      null,
                      null,
                      [],
                      null,
                      10,
                      singleRequestKey,
                    );
                    // this.retreiveActiveInvestors(projectId, '', projectType, null, null, true, null, null, null, null, [], null, 15, singleRequestKey );
                  }
                }
              });
            }
            if (isFound) {
              if (this.activeProjectDetails["record"].notify) {
                this.clearProjectNotification(this.activeProject);
              }
              this.activeProjectStatus.next();
            } else {
              console.log(
                "PASSED PROJECT ID WAS NOT FOUND IN LIST OF ACTIVE PROJECTS",
                projectId,
                "leaving the state of activeProject unchanged",
              );
              this.activeProject = "";
              this.activeProjectDetails = {};
            }
          },
          (error) => {
            // if project is not found redirect
            this.location.go("/id-verification/dashboard/");
            $(".header-top .navbar-brand .finos-logo-img").click();
            return;
            console.log(
              "The following error occured while updating the project name",
              error,
            );
          },
        );
    } else {
      let isFound = false;
      if (projectId !== undefined && typeof projectId === "string") {
        this.activeProjectList.forEach((project) => {
          if (project.key === projectId) {
            isFound = true;
            this.activeProject = projectId;
            this.activeProjectDetails = project;
            // get people for this projectCustomize projectectId);
            if (projectType === "id-verification") {
              if (resetImage) {
                this.setScreeningFinished(true);
              }
              this.retrieveActiveLegalRepresentatives(
                projectId,
                false,
                true,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                15,
                singleRequestKey
              
              );
            } else if (projectType === "funds" || projectType === "investor") {
              this.retreiveActiveInvestors(
                projectId,
                "",
                projectType,
                null,
                null,
                true,
                null,
                null,
                null,
                null,
                [],
                null,
                10,
                singleRequestKey,
              );
              // this.retreiveActiveInvestors(projectId, '', projectType, null, null, true, null, null, null, null, [], null, 15, singleRequestKey );
            }
          }
        });
      }
      if (isFound) {
        if (this.activeProjectDetails["record"].notify) {
          this.clearProjectNotification(this.activeProject);
        }
        this.activeProjectStatus.next();
      } else {
        console.log(
          "PASSED PROJECT ID WAS NOT FOUND IN LIST OF ACTIVE PROJECTS",
          projectId,
          "leaving the state of activeProject unchanged",
        );
        this.activeProject = "";
        this.activeProjectDetails = {};
      }
    }
  }
  /**
   * returns the ID of the currently selected project
   * @returns ID of currently selected project
   */
  getActiveProject() {
    if (
      this.activeProject === undefined ||
      this.activeProject === "" ||
      typeof this.activeProject !== "string"
    ) {
      this.attemptProjectIdRecovery();
      return this.activeProject;
    } else {
      return this.activeProject;
    }
  }
  attemptProjectIdRecovery() {
    // console.log('project key recovered');
    let url = this.router.url;
    if (url.substr(url.length - 1) === "/") {
      url = url.slice(0, -1);
    }
    if (url.substr(url.length - 1) === "/lightbox") {
      url = url.slice(0, -9);
    }
    const dashPosition = url.lastIndexOf("/");
    url = url.slice(dashPosition);
    if (url.substr(0, 1) === "/") {
      url = url.substr(1);
    }
    if (url.substr(0, 3) !== "PRJ") {
      // return undefined;
    } else {
      this.activeProject = url;
    }
  }
  /**
   * reset the details of the currently selected project
   */
  resetActiveProject() {
    // reset the activeLegalRepresentative
    this.resetActivePersons();
    delete this.activeProjectDetails; // reset the details
    delete this.activeProject; // reset the id
    this.activeProjectStatus.next();
  }
  /**
   * sets currently selected project id
   * for displaying project settings
   * @param project object
   */
  setActiveProjectSettings(projectId) {
    let isFound = false;
    if (projectId !== undefined && typeof projectId === "string") {
      this.activeProjectList.forEach((project) => {
        if (project.key === projectId) {
          isFound = true;
          this.activeProjectSettings = projectId;
          this.activeProjectSettingsDetails = project;
        }
      });
    }
    if (isFound) {
      this.activeProjectSettingsStatus.next(this.activeProjectSettingsDetails);
    } else {
      console.log(
        "PASSED PROJECT ID WAS NOT FOUND IN LIST OF ACTIVE PROJECTS",
        projectId,
        "leaving the state of activeProject unchanged",
      );
      this.activeProject = "";
      this.activeProjectDetails = {};
    }
  }
  /**
   * returns the ID of the currently selected project
   * for displaying the project settings
   * @returns ID of currently selected project
   */
  getActiveProjectSettings() {
    return this.activeProjectSettings;
  }
  /**
   * returns the details of the currently selected project
   * for displaying the project settings
   * @returns ID of currently selected project
   */
  getActiveProjectSettingsDetails() {
    return this.activeProjectSettingsDetails;
  }
  /**
   * reset the details of the currently selected project
   * for displaying the project settings
   */
  resetActiveProjectSettings() {
    delete this.activeProjectSettingsDetails; // reset the details
    delete this.activeProjectSettings; // reset the id
    this.activeProjectSettingsStatus.next();
  }
  /**
   * returns the object for the currently selected project
   * @returns currently selected project as object
   */
  getActiveProjectDetails() {
    return this.activeProjectDetails;
  }
  /**
   * retrive the permission/user type
   * for this project. It can either be owner
   * or member
   */
  retrieveUserType(projectId) {
    let isFound = false;
    if (projectId !== undefined && typeof projectId === "string") {
      this.activeProjectList.forEach((project) => {
        if (project.key === projectId) {
          isFound = true;
          this.activeProjectUserType = projectId;
        }
      });
    }
    if (isFound) {
      this.activeProjectUserTypeStatus.next(this.activeProjectUserType);
    } else {
      console.log(
        "PASSED PROJECT ID WAS NOT FOUND IN LIST OF ACTIVE PROJECTS",
        projectId,
        "leaving the state of activeProjectUserType unchanged",
      );
      this.activeProjectUserType = "";
    }
    return;
  }
  /**
   * sets a project we are about to leave. It doesn't have
   * it's own observable, it can only be invoked by checking
   * the list of contributors - detectLoadProjectContributors
   * @param project object
   */
  setActiveProjectLeave(projectId) {
    let isFound = false;
    if (projectId !== undefined && typeof projectId === "string") {
      this.activeProjectList.forEach((project) => {
        if (project.key === projectId) {
          isFound = true;
          this.activeProjectLeave = projectId;
          this.activeProjectLeaveDetails = project;
        }
      });
    }
  }
  /**
   * returns the ID for the project we are about to leave
   * @returns ID of currently selected project
   */
  getActiveProjectLeave() {
    return this.activeProjectLeave;
  }
  /**
   * returns the details for the project we are about to leave
   * @returns ID of currently selected project
   */
  getActiveProjectleaveDetails() {
    return this.activeProjectLeaveDetails;
  }
  /**
   * reset the details project we are about to leave
   */
  resetActiveProjectLeave() {
    delete this.activeProjectLeaveDetails; // reset the details
    delete this.activeProjectLeave; // reset the id
    // this.activeProjectSettingsStatus.next();
  }
  /**
   * sets the file object for the logo of the
   * currently selected project for displaying the
   * project settings
   * @param project object
   */
  setActiveProjectSettingsLogo(file: File) {
    this.activeProjectSettingsLogo = file;
  }
  /**
   * returns the file object for the logo of the
   * currently selected project for displaying the
   * project settings
   * @returns ID of currently selected project
   */
  getActiveProjectSettingsLogo() {
    return this.activeProjectSettingsLogo;
  }
  /**
   * resets currently selected (but not saved)
   * logo for the project we are changing
   * custom settings for
   */
  resetActiveProjectSettingsLogo() {
    delete this.activeProjectSettingsLogo;
  }
  /**
   * sets the contributors list for the
   * currently selected project for displaying the
   * project settings
   * @param project object
   */
  setProjectContributorsData(records: any) {
    const parsedRecords = [];
    if (records === undefined && !Array.isArray(records)) {
      return;
    }
    // parse the BE data
    records.forEach((record) => {
      parsedRecords.push({
        id: record.key,
        email: record.record.email,
        role: this.helperService.toFirstCharUppercase(record.record.type), // Member | Owner
        makeOwner: record.record.type !== "owner" ? true : false, // only if current role != Owner
        permissionPending: false, // the user accepted the invitation AND IS authorized by owner
      });
    });
    this.activeProjectSettingsContributorData = parsedRecords;
  }
  /**
   * returns the contributors list for the
   * currently selected project for displaying the
   * project settings
   * @returns ID of currently selected project
   */
  getProjectContributorsData() {
    return this.activeProjectSettingsContributorData;
  }
  /**
   * returns the list of owners for the
   * currently selected project for displaying the
   * project settings
   * @returns array containing all project owners
   */
  getProjectOwners(): any[] {
    const parsedRecords = [];
    const contributors = this.getProjectContributorsData();
    if (contributors !== undefined && Object.keys(contributors).length > 0) {
      //   for (const makeOwner in contributors) {
      //     if (contributors.hasOwnProperty(makeOwner)) {
      //       if
      //     }
      //   }
      const values = Object.values(contributors);
      for (const record of values) {
        if (!record["makeOwner"]) {
          parsedRecords.push(record);
        }
      }
    }
    return parsedRecords;
  }
  /**
   * returns the list of owners for the
   * currently selected project for displaying the
   * project settings
   * @returns array containing all project owners
   */
  getProjectMembers(): any[] {
    const parsedRecords = [];
    const contributors = this.getProjectContributorsData();
    if (contributors !== undefined && Object.keys(contributors).length > 0) {
      const values = Object.values(contributors);
      for (const record of values) {
        if (record["makeOwner"] && !record["permissionPending"]) {
          parsedRecords.push(record);
        }
      }
    }
    return parsedRecords;
  }
  /**
   * resets currently selected contributors listfor the
   * currently selected project for displaying the
   * project settings
   */
  resetProjectContributorsData() {
    delete this.activeProjectSettingsContributorData;
  }
  /**
   * sets the pending authorization contributors list
   * for the currently selected project for displaying
   * the project settings. Currently as I don't have any
   * information we will append this list to the list of
   * active contributor data, while also maintaining a
   * separate project pending contrigutor data
   * @param project object
   */
  setProjectPendingContributorsData(records: any) {
    const parsedRecords = [];
    if (records === undefined && !Array.isArray(records)) {
      return;
    }
    // parse the BE data
    records.forEach((record) => {
      parsedRecords.push({
        id: record.key,
        email: record.record.email,
        role: this.helperService.toFirstCharUppercase(record.record.type), // Member | Owner
        makeOwner: record.record.type !== "owner" ? true : false, // only if current role != Owner
        permissionPending: true, // the user accepted the invitation but is still not authorized by owner
      });
    });
    this.activeProjectSettingsContributorData =
      this.activeProjectSettingsContributorData.concat(parsedRecords);
    this.pendingProjectSettingsContributorData = parsedRecords;
  }
  /**
   * returns the pending contributors list for the
   * currently selected project for displaying the
   * project settings
   * @returns ID of currently selected project
   */
  getProjectPendingContributorsData() {
    return this.pendingProjectSettingsContributorData;
  }
  /**
   * resets currently selected pending contributors list
   * for the currently selected project for displaying the
   * project settings
   */
  resetPendingProjectContributorsData() {
    delete this.pendingProjectSettingsContributorData;
  }
  /**
   * sets the contributors list for the
   * currently selected project for displaying the
   * project settings
   * @param project object
   */
  setProjectInvitationsData(records: any) {
    const parsedRecords = [];
    // parse the BE data
    if (records === undefined && !Array.isArray(records)) {
      return;
    }
    records.forEach((record) => {
      parsedRecords.push({
        id: record.key,
        email: record.record.email,
        role: this.helperService.toFirstCharUppercase(record.record.type), // Member | Owner
        status: record.record.status, // only if current role != Owner
        url: record.record.url,
      });
    });
    this.activeProjectSettingsInvitationData = parsedRecords;
  }
  /**
   * returns the contributors list for the
   * currently selected project for displaying the
   * project settings
   * @returns ID of currently selected project
   */
  getProjectInvitationsData() {
    return this.activeProjectSettingsInvitationData;
  }
  /**
   * resets currently selected contributors listfor the
   * currently selected project for displaying the
   * project settings
   */
  resetProjectInvitationsData() {
    delete this.activeProjectSettingsInvitationData;
  }

  /**
   * sets current legal representatives for the active project
   * @param persons array of legal representatives objects
   */
  setActivePersons(persons, ignoreLazyLoad = false) {
    if (persons !== undefined && Array.isArray(persons)) {
      console.log("parsing lazy load persons");
      if (!ignoreLazyLoad) {
        // do a direct lazy load attempt, especially since we are fetching additional detials if it resolves
        this.setLazyLoadPersons(persons);
      }

      console.log("setting list for active legal representatives");
      this.activeLegalRepresentativesList = persons;

      // update the active person details
      const activePerson = this.getActivePersons().find(
        (person) => person.key === this.getActivePerson(),
      );
      if (
        this.activeLegalRepresentativeDetails &&
        activePerson &&
        activePerson["record"]
      ) {
        this.activeLegalRepresentativeDetails["record"]["lastVerification"] =
          activePerson["record"]["lastVerification"];
        if (
          this.activeLegalRepresentativeDetails["record"][
            "lastAddressVerification"
          ]
        ) {
          const documents =
            this.activeLegalRepresentativeDetails["record"][
              "lastAddressVerification"
            ].record.documents;
          activePerson["record"]["lastAddressVerification"][
            "record"
          ].documents = documents;
        }
        this.activeLegalRepresentativeDetails["record"][
          "lastAddressVerification"
        ] = activePerson["record"]["lastAddressVerification"];
        this.activeLegalRepresentativeDetails["record"]["lastApsVerification"] =
          activePerson["record"]["lastApsVerification"];
        this.activeLegalRepresentativeDetails["record"][
          "lastContractVerification"
        ] = activePerson["record"]["lastContractVerification"];
        this.activeLegalRepresentativeDetails["record"]["lastModified"] =
          activePerson["record"]["lastModified"];
        this.activeLegalRepresentativeDetails["record"][
          "verificationSubjectStatus"
        ] = activePerson["record"]["verificationSubjectStatus"];
      }
      console.log("emiting event for active legal representatives change");
      this.activeLegalRepresentativesStatus.next();
    }
  }

  /**
   * generates a list of necesssary lazy load/resolve actions that will be executed
   * one by one for passed list of legal representatives/persons for the active project
   * @param persons array of legal representatives objects
   */
  async setLazyLoadPersons(persons, ignoreLazyLoad = false) {
    this.refreshIdVerificaitonKey = undefined;
    if (persons !== undefined && Array.isArray(persons)) {
      /**
       * NOTE: we are always fetching additional peson data on click on the arrow no matter what the status is
       * this is because it would be too much load on BE to load all the data in single call when fetching all
       * people.
       *
       * RULES:
       * if isPartial === undefined => do nothing (lazy load on click)
       * if isPartial === true      => do the auto lazy load
       * if isPartial === false     => nikomu nisto vekje e resolved (I would say do the lazy load on click)
       *
       * according to the note and the rules above we only need to detect if isPartial is set to true, if it is
       * then we should attempt doing an automated lazy load of additional person data without waiting for the
       * user to click on the expanding arrow
       */

      const lazyLoadRecords = [];
      // do the parsing:
      persons.forEach((person, key) => {
        // for debugging purposes uncomment the lines below to simulate partial jumio requests
        // if (person.record.lastVerification !== undefined) {
        //   person.record.lastVerification.record.isPartial = true;
        // }
        // if (   !ignoreLazyLoad
        //     // && person.record.url !== undefined
        //     && person.record.lastVerification !== undefined
        //     && ((person.record.lastVerification.record.isPartial !== undefined
        //     && person.record.lastVerification.record.isPartial)
        //     || person.record.lastVerification.record.status === 'processing' )) {
        if (!ignoreLazyLoad) {
          VFEs.forEach((VFE) => {
            if (eval(VFE.lazyCondition)) {
              // execute lazy load
              // console.log('Queuing to execute lazy auto fetch for;', person.key, person);
              lazyLoadRecords.push({
                project: this.getActiveProject(),
                key: person.key,
                // vfe: person.record.lastVerification.key
                vfe: eval(VFE.vfe),
                vfeType: VFE.type,
                resolveEndpoint: VFE.resolveEndpoint,
              });
              // console.log('Executing lazy auto fetch for', person.key, person);
              // this.loadAdditionalPersonDetails(
              //     this.getActiveProject(),
              //     person.key,
              //     true);
            } else {
              // in case of lazyRefresh make sure it will not be stuck in indefinite refresh status
              if (person.record[VFE.type]) {
                // console.log('person', person);
                person.record[VFE.type].isRefresh = false;
                person.record[VFE.type].status = "IN_PROGRESS";
                this.updatePerson(person, false);
              }
            }
          });
        }
      });
      // do one by one lazy load for any person record that may need it
      // console.log('lazy records', lazyLoadRecords);
      // this.doOneByOneLazyLoad(lazyLoadRecords);
      // do a direct lazy load attempt, especially since we are fetching additional detials if it resolves
      await this.doOneByOneDirectLazyLoad(lazyLoadRecords);
    }
  }

  /**
   * it is a requirement if there are multiple records that need lazy loading
   * to make them load after the previous has finished, as it is expected
   * that the executing parallel lazy loads will put strain to BE server
   * @param lazyLoadRecords array of person records that need to be lazy loaded
   */
  async doOneByOneLazyLoad(lazyLoadRecords) {
    console.log("Start lazy loading ...", lazyLoadRecords);
    for (const person of lazyLoadRecords) {
      await this.userService
        .getEndPoint(
          this.buildEndPoint("projectPeopleEdit", person.project, person.key),
          { responseType: "json" },
          { observe: "body" },
          this.getCCType(),
        )
        .toPromise()
        .then((response) => {
          if (response === undefined) {
            console.log(
              `Error while attempting to lazy load person ${person.key}`,
            );
            return;
          }
          console.log("person details", response);
          console.log(`Additional detials for person: ${person.key} loaded`);
          this.loadAdditionalPersonDetailsStatus.next({
            key: person.key,
            response: this.helperService.sanitize(response),
            alwaysExecuteLazy: false,
          });
          console.log(`Attempting to do a lazy load for person: ${person.key}`);
          this.resolveActivePersonStatus(
            person.project,
            person.key,
            person.vfe,
            person.vfeType,
          );
        })
        .catch((error) => {
          console.error(
            `Error while attempting to lazy load person ${person.key}`,
            error,
          );
        });
    }
    console.log("Done!");
  }

  /**
   * it is a requirement if there are multiple records that need lazy loading
   * to make them load after the previous has finished, as it is expected
   * that the executing parallel lazy loads will put strain to BE server
   * this method will do direct lazy load, without attempt to retrieve person additional details
   * @param lazyLoadRecords array of person records that need to be lazy loaded
   * @param considerLazyDelay boolean, if set to true it
   */
  async doOneByOneDirectLazyLoad(lazyLoadRecords, considerLazyDelay = false) {
    console.log("Start lazy loading ...", lazyLoadRecords);
    for (const person of lazyLoadRecords) {
      // if considerLazyDelay is set to true then skip all VFEs that
      // - do not have set lazyDelay attribute, or
      // - have lazyDelay attribute set to 0ms
      console.log("checking if should execute delayed lazy load");
      if (
        considerLazyDelay &&
        !this.shouldExecuteLazyDelayForVFE(person.vfeType)
      ) {
        console.log(
          "skipping delayed lazy load",
          considerLazyDelay,
          person.vfeType,
          !this.shouldExecuteLazyDelayForVFE(person.vfeType),
        );
        continue;
      }

      console.log("executing lazy load ...");
      await this.userService
        .postEndPoint(
          this.getEndPointBaseUrl() + this.constructResolveEndpoint(person),
          "",
          { responseType: "json" },
          { observe: "body" },
          "verification",
        )
        .toPromise()
        .then((response) => {
          if (response === undefined) {
            console.log(
              `Error while attempting to lazy load person ${person.key}`,
            );
            this.updatePersonVerificationFailed(person.key, person.vfeType);
            return;
          }
          // update the verification of the person with personKey
          // and push an event for change in list of active legal representatives
          // we are not updating the ACTIVE person verification information
          // because by the time we got a reply from server the active person may have been changed
          this.refreshIdVerificaitonKey = undefined;
          this.updatePersonVerificationStatus(
            person.key,
            this.helperService.sanitize(response),
            person.vfeType,
          );
        })
        .catch((error) => {
          if (!this.idVerificationResolveAttempt) {
            this.updatePersonVerificationFailed(person.key, person.vfeType);
            console.error(
              `Error while attempting to lazy load person ${person.key}`,
              error,
            );
            this.refreshIdVerificaitonKey = undefined;
          } else {
            this.refreshIdVerificaitonKey = person.key;
            this.updatePersonVerificationStatus(person.key, {}, person.vfeType);
            this.idVerificationResolveAttempt = false;
          }
        });
    }
    console.log("Done!");
  }

  /**
   * checks if this VFE is allowed to be executed with a delay of lazyDelay miliseconds
   * @param vfeType string matching the type of VFE
   * @returns boolean: true if lazyDelay is defined and greater than 0, otherwise false
   */
  shouldExecuteLazyDelayForVFE(vfeType) {
    const matchingVFE: any = VFEs.filter((vfe) => vfe.type === vfeType);
    return !!matchingVFE.lazyDelay;
  }

  /**
   * construct the endpoint for current type of resolving
   * @param person Object, person's lazy load information
   */
  constructResolveEndpoint(person) {
    return person.resolveEndpoint
      .replace("{projectKey}", person.project)
      .replace("{personKey}", person.key)
      .replace("{personVfe}", person.vfe)
      .replace("{relatedIdinVerification}", person.vfe);
  }

  /**
   * resets current legal representatives for the active project
   */
  resetActivePersons() {
    this.resetActivePerson();
    delete this.activeLegalRepresentativesList;
    this.activeLegalRepresentativesStatus.next();
  }
  resetActiveInvestors() {
    this.resetActiveInvestor();
    delete this.activeInvestorList;
    this.activeInvestorsStatus.next();
  }
  resetActiveLegalRepresentativesList() {
    this.activeLegalRepresentativesList = [];
  }
  /**
   * set data for the user being re-invited
   * @param data: object, data for the user being reinvited
   */
  setReinviteUser(data: {}) {
    this.reinviteUser = data;
  }
  /**
   * get data for the user being re-invited
   */
  getReinviteUser() {
    return this.reinviteUser;
  }
  /**
   * reset data for the user being re-invited
   */
  resetReinviteUser() {
    delete this.reinviteUser;
  }
  /**
   * set the name of the project the visitor just JOINED
   * (it means they accepted the invitation for the project)
   */
  setJoinedProjectName(projectName: string) {
    if (projectName === "") {
      return;
    }
    this.joinedProjectName = projectName;
  }
  /**
   * get project name for project that we just joined
   */
  getJoinedProjectName() {
    return this.joinedProjectName;
  }
  /**
   * reset project name for project that we just joined
   */
  resetJoinedProjectName() {
    delete this.joinedProjectName;
  }
  /**
   * retrieve list of legal representatives for the active project
   * @returns current array of all legal representative objects
   */
  getActivePersons() {
    return this.activeLegalRepresentativesList;
  }
  /**
   * prepend the person to the legal representatives for the active project
   * @param person object
   */
  prependPerson(person) {
    if (typeof person !== "undefined") {
      this.activeLegalRepresentativesList.unshift(person);
      // set scroll to this person
      this.resetScrollToKey();
      this.setActivePerson(person.key);
      // Set new person image value to blank (default)
      this.updatePersonImage(person.key, "failed-to-recover");
      // notify subscribers of the change
      this.activeLegalRepresentativesStatus.next("generateLinks");
    }
  }
  /**
   * prepend the person to the legal representatives for the active project
   * @param person object
   */
  updatePerson(personData, setActive = true) {
    let isFound = false;
    const personID = personData.key;
    if (
      this.activeLegalRepresentativesList !== undefined &&
      personID !== undefined &&
      typeof personID === "string"
    ) {
      this.activeLegalRepresentativesList.forEach((person, key) => {
        // Array.prototype.forEach.call(this.activeLegalRepresentativesList, person, key => {
        if (person.key === personID) {
          isFound = true;
          this.activeLegalRepresentativesList[key] = personData;
          if (setActive) {
            this.resetScrollToKey();
            this.setActivePerson(person.key);
          }
        }
      });
    }
    if (isFound) {
      this.activeLegalRepresentativesStatus.next();
    } else {
      this.resetActivePerson();
    }
  }
  /**
   * set/update a property for the any existing person
   */
  updatePersonDetail(personID, personData) {
    let isFound = false;
    if (
      this.activeLegalRepresentativesList !== undefined &&
      personID !== undefined &&
      typeof personID === "string"
    ) {
      this.activeLegalRepresentativesList.forEach((person, key) => {
        if (person.key === personID) {
          isFound = true;
          // this call is async to call to get person image
          // we should check if the image is already retrieved
          // and add it to the personData
          if (
            this.activeLegalRepresentativesList[key]["record"] !== undefined &&
            this.activeLegalRepresentativesList[key]["record"]["image"] !==
              undefined
          ) {
            personData["record"]["image"] =
              this.activeLegalRepresentativesList[key]["record"].image;
          }
          this.activeLegalRepresentativesList[key] = personData;
          this.activeLegalRepresentativeDetails = personData;
        }
      });
    }

    if (isFound) {
      this.activeLegalRepresentativesStatus.next();
      // this.activeProjectChange.next();
    } else {
      this.resetActivePerson();
      console.log(
        "PERSON ID WAS NOT FOUND IN LIST OF ACTIVE LEGAL REPRESENTATIVES FOR THIS PROJECT",
        personID,
        "person details could not have been updated",
      );
    }
  }
  /**
   * set/update a property for the any existing person
   */
  updatePersonVerificationStatus(personID, verificationData, verificationType) {
    // console.log('updating person vs with', personID, verificationData, verificationType);
    let isFound = false;
    if (
      this.activeLegalRepresentativesList !== undefined &&
      personID !== undefined &&
      typeof personID === "string"
    ) {
      this.activeLegalRepresentativesList.forEach((person, key) => {
        if (person.key === personID) {
          isFound = true;
          // we are not concerened about the call to get person image
          // as here we are updating only part of person details
          // and not the whole details
          if (
            this.activeLegalRepresentativesList[key]["record"] !== undefined &&
            this.activeLegalRepresentativesList[key]["record"][
              verificationType
            ] !== undefined
          ) {
            // this.activeLegalRepresentativesList[key]['record'][verificationType] = verificationData;
            // 15072019 it is decided that verificationData would not contain person details
            // and this need to be refetch from BE once more
            this.loadAdditionalPersonDetails(
              this.getActiveProject(),
              personID,
              false,
            );
          }
        }
      });
    }
    if (isFound) {
      this.activeLegalRepresentativesStatus.next();
      // this.activeProjectChange.next();
    } else {
      console.log(
        " 2 PERSON ID WAS NOT FOUND IN LIST OF ACTIVE LEGAL REPRESENTATIVES FOR THIS PROJECT",
        personID,
        "person details could not have been updated",
      );
    }
  }
  /**
   * set auto fetch verification status as failed
   */
  updatePersonVerificationFailed(personID, verificationType) {
    console.log("searching for person ID", personID);
    let isFound = false;
    if (
      this.activeLegalRepresentativesList !== undefined &&
      personID !== undefined &&
      typeof personID === "string"
    ) {
      this.activeLegalRepresentativesList.forEach((person, key) => {
        if (person.key === personID) {
          isFound = true;
          // we are not concerened about the call to get person image
          // as here we are updating only part of person details
          // and not the whole details
          console.log(
            "setting auto fetch verification status as failed",
            this.activeLegalRepresentativesList[key],
          );
          // no longer resetting active person as it will cause issues with other services
          // such as generating verification request
          // this.resetActivePerson();
          if (
            this.activeLegalRepresentativesList[key]["record"] !== undefined &&
            this.activeLegalRepresentativesList[key]["record"][
              verificationType
            ] !== undefined
          ) {
            this.activeLegalRepresentativesList[key]["record"][
              verificationType
            ]["record"]["isRefresh"] = true;
            // reset if active lazy load person have been set
            if (this.getActiveLazyLoadPerson()) {
              this.resetActiveLazyLoadPerson();
            }
          }
        }
      });
    }
    if (isFound) {
      this.activeLegalRepresentativesStatus.next();
      this.activeProjectChange.next();
    } else {
      console.log(
        "3 PERSON ID WAS NOT FOUND IN LIST OF ACTIVE LEGAL REPRESENTATIVES FOR THIS PROJECT",
        personID,
        "person details could not have been updated",
      );
    }
  }
  /**
   * set/update a property for the any existing person
   */
  updatePersonVerificationTimestamp(personID, lastModified) {
    let isFound = false;
    if (
      this.activeLegalRepresentativesList !== undefined &&
      personID !== undefined &&
      typeof personID === "string"
    ) {
      this.activeLegalRepresentativesList.forEach((person, key) => {
        if (person.key === personID) {
          isFound = true;
          // we are not concerened about the call to get person image
          // as here we are updating only part of person details
          // and not the whole details
          if (
            this.activeLegalRepresentativesList[key]["record"] !== undefined &&
            this.activeLegalRepresentativesList[key]["record"][
              "lastVerification"
            ] !== undefined
          ) {
            this.activeLegalRepresentativesList[key]["record"][
              "lastVerification"
            ]["record"]["lastModified"] = lastModified;
          }
        }
      });
    }
    if (isFound) {
      this.activeLegalRepresentativesStatus.next();
      // this.activeProjectChange.next();
    } else {
      console.log(
        "4 PERSON ID WAS NOT FOUND IN LIST OF ACTIVE LEGAL REPRESENTATIVES FOR THIS PROJECT",
        personID,
        "person details could not have been updated",
      );
    }
  }
  /**
   * set/update a property for the any existing person
   */
  updatePersonImage(personID, personImageData) {
    let isFound = false;
    if (
      this.activeLegalRepresentativesList !== undefined &&
      personID !== undefined &&
      typeof personID === "string"
    ) {
      this.activeLegalRepresentativesList.forEach((person, key) => {
        if (person.key === personID) {
          isFound = true;
          // this call is async to call to get person details
          // person details would ALWAYS be populated prior to
          // image so simply add the image to the personData
          this.activeLegalRepresentativesList[key]["record"]["image"] =
            personImageData;
        }
      });
    }
    if (isFound) {
      this.activeLegalRepresentativesStatus.next();
    }
  }
  /**
   * sets currently selected person for lazy load (only if clicked)
   * @param personID // id of the person
   */
  setActiveLazyLoadPerson(personID) {
    let isFound = false;
    if (
      this.activeLegalRepresentativesList !== undefined &&
      personID !== undefined &&
      typeof personID === "string"
    ) {
      this.activeLegalRepresentativesList.forEach((person) => {
        if (person.key === personID) {
          isFound = true;
          this.activeLazyLoadRepresentative = personID;
          this.activeLazyLoadRepresentativeDetails = person;
        }
      });
    }
  }
  /**
   * returns the ID of the currently selected person for lazy load
   * @returns ID of currently selected person for lazy load
   */
  getActiveLazyLoadPerson() {
    return this.activeLazyLoadRepresentative;
  }
  /**
   * returns the full person record of the currently selected person for lazy load
   * @returns person record selected for lazy load
   */
  getActiveLazyLoadPersonDetails() {
    return this.activeLazyLoadRepresentativeDetails;
  }
  /**
   * resets the ID of the currently selected project
   */
  resetActiveLazyLoadPerson() {
    delete this.activeLazyLoadRepresentative;
  }
  /**
   * sets currently active person
   * @param project object
   */
  setActivePerson(personID) {
    let isFound = false;
    if (
      this.activeLegalRepresentativesList !== undefined &&
      personID !== undefined &&
      typeof personID === "string"
    ) {
      this.activeLegalRepresentativesList.forEach((person) => {
        if (person.key === personID) {
          isFound = true;
          this.activeLegalRepresentative = personID;
          this.activeLegalRepresentativeDetails = person;
        }
      });
    }
  }
  /**
   * set/update a property for the active person
   */
  updateActivePersonDetail(key, property, value) {
    if (this.activeLegalRepresentativeDetails["key"] === key) {
      if (property.split(".").length > 1) {
        const path = property.split(".");
        this.activeLegalRepresentativeDetails[path[0]][path[1]] = value;
      } else {
        this.activeLegalRepresentativeDetails[property] = value;
      }
      return true;
    }
    return false;
  }
  /**
   * @returns ID of currently selected (active legal representative) person
   */
  getActivePerson() {
    if (
      this.activeLegalRepresentative === undefined &&
      this.activeLegalRepresentativeDetails
    ) {
      return this.activeLegalRepresentativeDetails["key"];
    } else {
      return this.activeLegalRepresentative;
    }
  }
  /**
   * resets the ID of the currently selected (active legal representative) person
   */
  resetActivePerson() {
    delete this.activeLegalRepresentative;
    if (this.activeLegalRepresentativeDetails) {
      this.activeLegalRepresentativeDetails["key"] = undefined;
    }
  }
  /**
   * @returns currently selected (active legal representative) person details as object
   * @param refresh if set to true it will re-fetch the details from the activeLegalRepresentativeList
   *  - recommended after list have been refreshed from BE
   */
  getActivePersonDetails(refresh = false) {
    if (refresh) {
      this.setActivePerson(this.getActivePerson());
    }
    return this.activeLegalRepresentativeDetails;
  }

  setActivePersonDetails(person) {
    this.activeLegalRepresentativeDetails = person;
  }
  /**
   * sets current legal representatives for the active project
   * @param persons array of legal representatives objects
   */
  setActiveVerification(verificationKey: string) {
    this.activeVerification = verificationKey;
    this.activeVerificationStatus.next();
  }
  /**
   * resets current legal representatives for the active project
   */
  resetActiveVerification() {
    delete this.activeVerification;
    this.activeVerificationStatus.next();
  }
  /**
   * retrieve list of legal representatives for the active project
   * @returns current array of all legal representative objects
   */
  getActiveVerification() {
    return this.activeVerification;
  }
  /**
   * sets current legal representatives for the active project
   * @param persons array of legal representatives objects
   */
  setActiveAddressVerification(addressVerificationKey: string) {
    this.activeAddressVerification = addressVerificationKey;
    this.activeAddressVerificationStatus.next();
  }
  /**
   * resets current legal representatives for the active project
   */
  resetActiveAddressVerification() {
    delete this.activeAddressVerification;
    this.activeAddressVerificationStatus.next();
  }
  /**
   * retrieve list of legal representatives for the active project
   * @returns current array of all legal representative objects
   */
  getActiveAddressVerification() {
    return this.activeAddressVerification;
  }
  getActiveAddressVerificationDetails() {
    // 1. get current active legal representative (person)
    const activePerson: any = this.getActivePersonDetails();
    // console.log(activePerson, this.getActiveAddressVerification());
    // 2. check if the lastAddressVerification.key for current person matches the set activeAddressVerification
    if (
      activePerson.record &&
      activePerson.record.lastAddressVerification &&
      activePerson.record.lastAddressVerification.key ===
        this.getActiveAddressVerification()
    ) {
      return activePerson.record.lastAddressVerification;
    }
    return null;
  }
  /**
   * Determines where the next scrollToElementWithId event will scroll to
   * @param id string, id of DOM element
   */
  setScrollToKey(key: string) {
    this.scrollToKey = key;
  }

  /**
   * Retrieves the id of DOM element where the next scrollToElementWithId event will scroll to
   */
  resetScrollToKey() {
    delete this.scrollToKey;
  }

  /**
   * Retrieves the id of DOM element where the next scrollToElementWithId event will scroll to
   */
  getScrollToKey() {
    return this.scrollToKey;
  }

  /**
   * retrieves active projects for this user from BE
   * @returns subscription to retrieved active projects
   */
  retrieveActiveProjects(
    existingBookmark?,
    sortBy?,
    sortOrder?,
    loadMore?,
    pageSize = 12,
  ) {
    // build the query arguments


    let queryArguments = "?status=active&";
    if (existingBookmark) {
      queryArguments = queryArguments.concat(
        "bookmark=" + existingBookmark + "&",
      );
    }
    if (pageSize) {
      queryArguments = queryArguments.concat("pageSize=" + pageSize + "&");
    }
    if (sortBy) {
      queryArguments = queryArguments.concat("sortBy=" + sortBy + "&");
    }
    if (sortOrder) {
      queryArguments = queryArguments.concat("sortOrder=" + sortOrder + "&");
    }
    if (queryArguments === "?") {
      queryArguments = "";
    }
    if (queryArguments.slice(-1) === "&") {
      queryArguments = queryArguments.slice(0, -1);
    }
    this.userService
      .getEndPoint(
        this.buildEndPoint("projects", "", "", queryArguments),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("We could retrieve the list of active projects");
            this.activeProjects.next({ status: false });
            return;
          }
          let lastLoad = false;
          if (response["records"].length < 12) {
            lastLoad = true;
          }
          this.activeProjects.next({
            loadMore: loadMore,
            status: true,
            data: this.helperService.sanitize(response),
            append: existingBookmark,
            lastLoad: lastLoad,
            selectedFilter: sortBy,
          });
        },
        (error) => {
          console.log(
            "The following error occured when retrieving the active projects",
            error,
          );
          this.activeProjects.next({ status: false });
          this.router.navigate(["/"]);
        },
      );
    return this.activeProjects.asObservable();
  }

  /**
   * DEPERCATED: we no logner retrieve logo's in a separate call
   * Retrieves a logo if it exists in the database,
   * if not the default 'briefcase' logo will be returned
   * @param projectKey: string, the project key for wich we are retrieving a logo
   * @param index: number, the position at which the project is in activeProjectList
   */
  _depercated_retrieveProjectLogo(projectKey: string, index: number) {
    this.userService
      .getEndPoint(
        this.buildEndPoint("projectLogo", projectKey),
        { responseType: "blob" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            // set the default briefcase logo
            this.setProjectLogo(index, projectKey, "defaultLogo");
            return;
          }
          // this.setProjectLogo(index, projectKey, 'briefcase');
          this.createLogoFromBlob(response as Blob, projectKey, index);
        },
        (error) => {
          // set the default briefcase logo
          this.setProjectLogo(index, projectKey, "defaultLogo");
        },
      );

    return this.activeProjects.asObservable();
  }

  /**
   * create base64 logo string from blob
   * @param image Blob, response returned from BE
   * @param projectKey string, person key
   */
  createLogoFromBlob(image: Blob, projectKey, index) {
    const reader = new FileReader();
    // const canvas = document.createElement('canvas');
    // const ctx = canvas.getContext('2d');
    // ctx.globalAlpha = 0.0;
    // const cw = '56'; // canvas.width;
    // const ch = '52'; // canvas.height;
    const maxW = 180;
    const maxH = 90;
    reader.addEventListener(
      "load",
      () => {
        // this.loadPersonImageStatus.next({ 'key': personKey, 'type' : type, 'response': reader.result});
        const img = new Image();
        const _this = this;
        img.onload = function () {
          // console.log('Image dimensions', img.width + ', ' + img.height );
          const iw = img.width;
          const ih = img.height;
          const scale = Math.min(maxW / iw, maxH / ih);
          const iwScaled = iw * scale;
          const ihScaled = ih * scale;
          // canvas.width = iwScaled;
          // canvas.height = ihScaled;
          // ctx.drawImage(img, 0, 0 , iwScaled, ihScaled);
          // output.value = canvas.toDataURL('image/jpeg',0.5);
          _this.setProjectLogo(
            index,
            projectKey,
            reader.result,
            iwScaled,
            ihScaled,
          );
        };

        img.src = reader.result as string;
        // console.log('This is logo reader', reader);
      },
      false,
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  /**
   * method that will execute creation of a new project
   * @param projectName string, the name of the project
   */
  createProject(projectName: string, defaultTemplateKey?: string) {
    // display empty project loading
    this.prependEmptyProject();
    if (typeof projectName !== "string" || projectName.length === 0) {
      console.log(
        "could not create new project! Project name supplied was unacceptable.",
      );
      return;
    }
    projectName = this.helperService.sanitizePropery(projectName) as string;
    let payload;
    payload = { name: projectName };
    if (defaultTemplateKey !== undefined) {
      payload.defaultTemplateKey = defaultTemplateKey;
    }
    this.userService
      .postEndPoint(
        this.buildEndPoint("projects"),
        payload,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (project) => {
          if (typeof project === undefined) {
            console.log(
              "There was an unexpected error while posting a new project",
            );
            return;
          }
          this.prependProject(this.helperService.sanitize(project));
        },
        (error) => {
          console.log(
            "The following error occured while posting a new project",
            error,
          );
        },
      );
  }
  /**
   * method that will execute deletion of a new project
   * @param projectName string, the key of the project
   */
  deleteProject(projectKey: string) {
    if (projectKey.length === 0) {
      console.log(
        "could not delete the project! Project key supplied was unacceptable.",
      );
      return;
    }
    this.userService
      .deleteEndpoint(
        this.buildEndPoint("project", projectKey),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (project) => {
          if (typeof project === undefined) {
            console.log(
              "There was an unexpected error while deleting a new project",
            );
            this.projectDelete.next(null);
            return;
          }
          this.projectDelete.next(projectKey);
        },
        (error) => {
          console.log(
            "The following error occured while deleting a new project",
            error,
          );
          this.projectDelete.next(null);
        },
      );
  }

  deleteProjectDialogue(projectKey: string) {
    if (projectKey.length === 0) {
      console.log(
        "could not delete the project! Project key supplied was unacceptable.",
      );
      return;
    }
    const activeProejectData = this.returnEvidenceFieldByKey(
      projectKey,
      this.activeProjectList,
    );
    console.log(activeProejectData);
    this.projectDeleteDialogue.next(activeProejectData);
  }
  /**
   * sets new name for project for which we are
   * displaying the project settings dialog
   * @param projectName string, new name of the project
   * @param projectColor: depercated, no longer supported when updating project name
   */
  updateProjectName(projectName: string) {
    if (typeof projectName !== "string" || projectName.length === 0) {
      console.log(
        "could not create new project! Project name supplied was unacceptable.",
      );
      return;
    }
    projectName = this.helperService.sanitizePropery(projectName) as string;
    // project color is no longer mandatory when renaming a project
    const params: {} = { name: projectName };
    // this.http.put(this.buildEndPoint('project', this.getActiveProjectSettings()), ).subscribe(project => {
    this.userService
      .putEndPoint(
        this.buildEndPoint("project", this.getActiveProjectSettings()),
        params,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (project) => {
          if (typeof project === undefined) {
            console.log(
              "There was an unexpected error while updating the project name",
            );
            return;
          }
          // update project list
          this.retrieveActiveProjects();
        },
        (error) => {
          console.log(
            "The following error occured while updating the project name",
            error,
          );
        },
      );
  }

  // get the data for the specific project
  getSpecificProject(projectKey) {
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log("could not fetch project");
      return;
    }
    this.userService
      .getEndPoint(
        this.buildEndPoint("project", this.getActiveProjectSettings()),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (project) => {
          if (typeof project === undefined) {
            console.log(
              "There was an unexpected error while updating the project name",
            );
            return;
          }
          // update project list
          this.retrieveActiveProjects();
        },
        (error) => {
          console.log(
            "The following error occured while updating the project name",
            error,
          );
        },
      );
  }

  permissionUpdateProjectBranding() {
    // temporary always return true for dev purposes
    // return true;
    return this.userService.getUserPermission("write:branding");
  }
  permissionUpdateProjectScreening() {
    // return true;
    return this.userService.getUserPermission("access:screening");
  }
  permissionUpdateAddressVerification() {
    // return true;
    return this.userService.getUserPermission("access:addressverification");
  }

  permissionProfileVerification() {
    return this.userService.getUserPermission("access:profile-verification");
  }

  permissionUpdateIDVerification() {
    // return true;
    return this.userService.getUserPermission("access:verification");
  }

  permissionUpdateIDVerificationOnboard() {
    return this.userService.getUserPermission("access:identity-verification-onboard");
  }

  permissionUpdateReportGeneration() {
    // return true;
    return this.userService.getUserPermission("access:reporting");
  }
  permissionUpdateAPS() {
    return this.userService.getUserPermission("access:aps-verification");
  }
  permissionUpdateDeleteSubject() {
    return this.userService.getUserPermission("delete:verification-subject");
  }
  permissionContract() {
    return this.userService.getUserPermission("access:contract-verification");
  }
  permissionIDIN() {
    return this.userService.getUserPermission("access:idin-verification");
  }
  permissionAdmin() {
    return this.userService.getUserPermission("access:admin");
  }
  permissionKYCTemplates() {
    return this.userService.getUserPermission("access:kyc-template-management");
  }
  permissionKompany() {
    return this.userService.getUserPermission("access:company-search");
  }
  permissionJumioGo() {
    return this.userService.getUserPermission("access:jumio-go");
  }
  permissionRiskRatingManager() {
    return this.userService.getUserPermission("access:risk-manager");
  }
  automaticRiskAssesment() {
      return this.fundsOrganizationDetails && this.fundsOrganizationDetails.EnableRiskAssessment ;
  }

  returnOrganizationName() {
    return this.userService.getUserOrganization();
  }

  getFundOrganizationDetails() {
    return this.fundsOrganizationDetails;
  }


  /**
   * sets new branding for project for which we are
   * displaying the project settings dialog
   * for now, only projectColor update is supported under updateProjectBranding
   * @param projectColor: string, optional, new color of the project
   */
  updateProjectBranding(
    projectColor: string = "",
    consentMessage: string = "",
    privacyUrl: string = "",
    webhooks: { url: string; type: string }[] = [],
  ) {
    if (!this.permissionUpdateProjectBranding()) {
      console.log(
        "could not update branding for projectproject! Branding update is not permitted for user.",
      );
      this.updateProjectBrandingStatus.next({ status: false });
      return;
    }
    if (typeof projectColor !== "string" || projectColor.length === 0) {
      console.log(
        "could not update branding for projectproject! Project color supplied was unacceptable.",
      );
      this.updateProjectBrandingStatus.next({ status: false });
      return;
    }
    projectColor = this.helperService.sanitizePropery(projectColor) as string;
    if (!this.helperService.hexValidator(projectColor)) {
      projectColor = "#ffffff";
    }
    consentMessage = this.helperService.sanitizePropery(
      consentMessage,
    ) as string;
    privacyUrl = this.helperService.sanitizePropery(privacyUrl) as string;
    const params: {} = {
      projectColor: projectColor,
      consentMessage: consentMessage,
      privacyUrl: privacyUrl,
      webhooks: webhooks,
    };
    this.userService
      .postEndPoint(
        this.buildEndPoint("projectBranding", this.getActiveProjectSettings()),
        params,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (project) => {
          if (typeof project === undefined) {
            console.log(
              "There was an unexpected error while updating the project branding",
            );
            this.updateProjectBrandingStatus.next({ status: false });
            return;
          }
          this.updateProjectBrandingStatus.next({ status: true });
          // update project list
          this.retrieveActiveProjects();
        },
        (error) => {
          console.log(
            "The following error occured while updating the project branding",
            error,
          );
          this.updateProjectBrandingStatus.next({ status: false });
        },
      );
  }
  /**
   * depercated, use updateProjectBranding instead
   * sets new color for project for which we are
   * displaying the project settings dialog
   * @param projectColor: string, optional, new color of the project
   */
  updateProjectColorDepercated(projectColor: string = "") {
    console.log("entering update project color");
    if (typeof projectColor !== "string" || projectColor.length === 0) {
      console.log(
        "could not create new project! Project color supplied was unacceptable.",
      );
      return;
    }
    this.userService
      .putEndPoint(
        this.buildEndPoint("project", this.getActiveProjectSettings()),
        { projectColor: projectColor },
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (project) => {
          if (typeof project === undefined) {
            console.log(
              "There was an unexpected error while updating the project color",
            );
            return;
          }
          // update project list
          this.retrieveActiveProjects();
        },
        (error) => {
          console.log(
            "The following error occured while updating the project color",
            error,
          );
        },
      );
  }

  /**
   * retrieves all people into this project
   * @param projectKey string, key of the project
   * @param resetFilters boolean, reset the person filters (usually done on loading new project)
   * @param existingBookmark existing bookmark used to load more perons
   * @param sortBy last_modified or created_on
   * @param sortOrder desc or asc
   * @param tags tags to load by
   * @param verificationStatus verification status to load by
   * @param pageSize pagesize,

   */
  retrieveActiveLegalRepresentatives(
    projectKey,
    ingoreLazyLoad = false,
    resetFilters = true,
    existingBookmark?,
    sortBy?,
    sortOrder?,
    tags?: string[],
    verificationStatus?,
    pageSize = 15,
    singleRequestKey?
  ) {
    let queryArguments = "?";
    if (existingBookmark) {
      queryArguments = queryArguments.concat(
        "bookmark=" + existingBookmark + "&",
      );
    }
    if (pageSize) {
      queryArguments = queryArguments.concat("pageSize=" + pageSize + "&");
    }
    if (sortBy) {
      queryArguments = queryArguments.concat("sortBy=" + sortBy + "&");
      this.activeLegalrepresentativesFilters.sortBy = sortBy;
    }
    if (tags && tags.length) {
      for (let i = 0; i < tags.length; i++) {
        queryArguments = queryArguments.concat("tags=" + tags[i] + "&");
      }
    }
    if (verificationStatus) {
      if (
        verificationStatus !== "all_statuses" &&
        verificationStatus !== "none"
      ) {
        queryArguments = queryArguments.concat(
          "verificationStatus=" + verificationStatus + "&",
        );
      }
      this.activeLegalrepresentativesFilters.activeFilter = verificationStatus;
      if (verificationStatus === "none") {
        this.activeLegalrepresentativesFilters.activeFilter = "all_statuses";
      }
    }
    if (sortOrder) {
      queryArguments = queryArguments.concat("sortOrder=" + sortOrder + "&");
    }
    if (queryArguments === "?") {
      queryArguments = "";
    }
    if (queryArguments.slice(-1) === "&") {
      queryArguments = queryArguments.slice(0, -1);
    }
    if (resetFilters) {
      this.activeLegalrepresentativesFilters = {};
    }

    this.activeLegalrepresentativesFilters.lastRecord0 = false;

    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not fetch people for this project! Project key supplied was unacceptable.",
      );
      // reset the active legal representatives list
      this.resetActivePersons();
      return this.activeProjectPeople.asObservable();
    }


    let mainEndpoint =  this.buildEndPoint(
      "projectPeople",
      projectKey,
      undefined,
      queryArguments,
    );

    if(singleRequestKey) {
      mainEndpoint = this.buildEndPoint(
        "projectPeopleEdit",
        projectKey,
        singleRequestKey,
        queryArguments,
      )
    }
    
    // this.resetScrollToKey();
    this.userService
      .getEndPoint(
        mainEndpoint,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          $(".active-projects-wrapper").addClass("d-flex");
          if (this.debug) {
            response = debugActiveLegalRepresentativeResponse;
          }
          if (response === undefined) {
            console.log(
              "We could retrieve the list of persons for this project",
            );
            this.activeProjectPeople.next({ status: false });
            // reset the active legal representatives list
            this.resetActivePersons();
            return this.activeProjectPeople.asObservable();
          }

          if(singleRequestKey) {
            let records = [];
            records.push(response);
            response = {'records': records, 'metadata': {}}
            console.log(response);
          }

          this.userService
            .getEndPoint(
              this.buildEndPoint("getProjectTags", projectKey),
              { responseType: "json" },
              { observe: "body" },
              this.getCCType(),
            )
            .subscribe((response2) => {
              const tagArray = [];
              for (const tag of response2["tags"]) {
                tagArray.push(tag.tag);
              }
              this.activeLegalRepresentativesTopTags = response2["tags"];
              this.activeLegalRepresentativesTopTags.sort((a, b) =>
                a.peopleTagged < b.peopleTagged ? 1 : -1,
              );
              this.setactiveLegalRepresentativesTags(tagArray);
              this.activeLegalRepresentativesUnadedTags = undefined;
              this.activeLegalRepresentativesBookmark =
                response["metadata"]?.bookmark;
              // IF WE RESET THE FILTERS (WE LOAD A NEW PROJECT)
              if (resetFilters) {
                if (this.activeLegalrepresentativesFilters.activeTags) {
                  this.activeLegalrepresentativesFilters.activeTags = undefined;
                }
                this.activeLegalrepresentativesFilters.activeFilter =
                  "all_statuses";
                this.activeLegalrepresentativesFilters.scrollTop = undefined;
                if (response["records"] && response["records"].length < 15) {
                  this.loadMorePersons.next({ onlyOneLoad: true });
                }
                $("html, body").animate({ scrollTop: "0px" }, 0);
                if (response["records"].length === 0) {
                  this.activeLegalrepresentativesFilters.firstLoadEmpty = true;
                } else {
                  this.activeLegalrepresentativesFilters.firstLoadEmpty = false;
                }
                this.loadMorePersons.next({ resetFinalLoad: true });
                this.setActivePersons(
                  this.helperService.sanitize(response["records"]),
                  ingoreLazyLoad,
                );
                this.activeProjectPeople.next({
                  status: true,
                  data: this.helperService.sanitize(response),
                });
              } else {
                this.activeLegalrepresentativesFilters.activeTags = tags;
                this.activeLegalrepresentativesFilters.firstLoadEmpty = false;
                this.activeLegalRepresentative = undefined;
                this.scrollToKey = undefined;
                if (!this.activeLegalrepresentativesFilters.scrolTopIgnore) {
                  this.activeLegalrepresentativesFilters.scrollTop = undefined;
                }
                this.activeLegalrepresentativesFilters.scrolTopIgnore = false;
                if (response["records"].length === 0) {
                  // sign not to show loading bar after 15th loaded person because there are no more persons
                  this.activeLegalrepresentativesFilters.lastRecord0 = true;
                }
                if (this.activeLegalRepresentativesList.length > 0) {
                  const scrollKey =
                    this.activeLegalRepresentativesList[
                      this.activeLegalRepresentativesList.length - 1
                    ]["key"];
                }
                let finalLoad = false;
                if (response["records"] && response["records"].length < 15) {
                  finalLoad = true;
                }
                const activePersonArray =
                  this.activeLegalRepresentativesList.concat(
                    this.helperService.sanitize(response["records"]),
                  );
                this.setActivePersons(activePersonArray, ingoreLazyLoad);
                this.activeProjectPeople.next(activePersonArray);
                this.loadMorePersons.next({ finalLoad: finalLoad });
              }
              // //SCROLL TO TOP SINCE DATA IS RESET
              // return this.activeProjectPeople.asObservable();
            });
        },
        (error) => {
          console.log(
            "The following error occured when retrieving the people for project",
            error,
          );
          this.activeProjectPeople.next({ status: false });
          // reset the active legal representatives list
          this.resetActivePersons();
          return this.activeProjectPeople.asObservable();
        },
      );
  }

  /**
 * filter all people into this project
 * @param projectKey string, key of the project
 * @param existingBookmark existing bookmark used to load more perons
 * @param sortBy last_modified or created_on
 * @param sortOrder desc or asc
 * @param tags tags to load by
 * @param verificationStatus verification status to load by
 * @param pageSize pagesize,


  /**
 * Add tags to a person, used for sorting
 * @param projectKey string, key of the project
 * @param personKey id of person
 * @param personDetails transfering the person data, IE the record of the person
 * @param tags array of strings, tags to be added
 */
  addPersonTags(projectKey, personKey, personDetails, tags, onCreate?) {
    // format tags
    if (!tags || !tags.tags || tags.tags.length === 0) {
      this.editPerson.next({ status: true, data: personDetails });
      this.updatePerson(personDetails);
      this.activeProjectChange.next();
    } else {
      this.userService
        .postEndPoint(
          this.buildEndPoint("addPersonTags", projectKey, personKey),
          tags,
          { responseType: "json" },
          { observe: "body" },
          this.getCCType(),
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
            }
            // update project
            const projectTagSet = new Set(
              this.activeLegalRepresentativesTags.concat(tags.tags),
            );
            const projectTagArray = Array.from(projectTagSet);
            this.activeLegalRepresentativesTags = projectTagArray;
            // update person
            const personTagSet = new Set(
              personDetails.record.tags.concat(tags.tags),
            );
            const personTagArray = Array.from(personTagSet);
            //
            tags.tags.forEach((tag) => {
              const index = this.activeLegalRepresentativesTopTags.findIndex(
                (existingTag) => existingTag.tag === tag,
              );
              if (index !== -1) {
                this.activeLegalRepresentativesTopTags[index]["peopleTagged"]++;
              } else {
                this.activeLegalRepresentativesTopTags.push({
                  tag: tag,
                  peopleTagged: 1,
                });
              }
            });
            this.activeLegalRepresentativesTopTags.sort((a, b) =>
              a.peopleTagged < b.peopleTagged ? 1 : -1,
            );
            //
            personDetails.record.tags = personTagArray;
            this.setScreeningFinished(true);
            this.updatePerson(personDetails);
            this.editPerson.next({
              status: true,
              data: personDetails,
              onCreate: onCreate,
            });
            this.activeProjectChange.next();
          },
          (error) => {
            console.log(
              "The following error occured when adding tags for project",
              error,
            );
          },
        );
    }
  }


  addPersonTagsInvestor(projectKey, personKey, personDetails, tags, onCreate?) {
    // format tags
    return new Promise((resolve)=>{
      if (!tags || !tags.tags || tags.tags.length === 0) {
        // this.editPerson.next({ status: true, data: personDetails });
        // this.updatePerson(personDetails);
        // this.activeProjectChange.next();
        alert('Tags cannot be empty');
        return;
      } else {
        this.userService
          .postEndPoint(
            this.buildEndPoint("addPersonTagsCDD", projectKey, personKey),
            tags,
            { responseType: "json" },
            { observe: "body" },
            this.getCCType(),
          )
          .subscribe(
            (response) => {
              if (response === undefined) {
              }
              // update project
              const projectTagSet = new Set(
                this.activeLegalRepresentativesTags.concat(tags.tags),
              );
              const projectTagArray = Array.from(projectTagSet);
              this.activeLegalRepresentativesTags = projectTagArray;
              // update person
              if(!this.activeInvestorDetails.tags) {
                this.activeInvestorDetails.tags = [];
              }
              const personTagSet = new Set(
                this.activeInvestorDetails.tags.concat(tags.tags),
              );
              const personTagArray = Array.from(personTagSet);
              //
              tags.tags.forEach((tag) => {
                const index = this.activeLegalRepresentativesTopTags.findIndex(
                  (existingTag) => existingTag.tag === tag,
                );
                if (index !== -1) {
                  this.activeLegalRepresentativesTopTags[index]["peopleTagged"]++;
                } else {
                  this.activeLegalRepresentativesTopTags.push({
                    tag: tag,
                    peopleTagged: 1,
                  });
                }
              });
              this.activeLegalRepresentativesTopTags.sort((a, b) =>
                a.peopleTagged < b.peopleTagged ? 1 : -1,
              );
              //
              this.activeInvestorDetails.tags = personTagArray;

              resolve("The tags were added.");
              this.skipToStep(-1);

            },
            (error) => {
              console.log(
                "The following error occured when adding tags for project",
                error,
              );
              resolve("There has been an error" + error);
            },
          );
      }
    })
  }


  /**
   * Delete a tag to a person, used for sorting
   * @param projectKey string, key of the project
   * @param personKey id of person
   * @param tags tag to be deleted
   * @param target refference to tag html object, should be hidden after delete
   */
  deletePersonTag(projectKey, personKey, tag, target) {
    this.userService
      .deleteEndpoint(
        this.buildEndPoint("deletePersonTags", projectKey, personKey, tag),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          // remove tag dom element from filter list (left) but only if it's the last element
          const index = this.activeLegalRepresentativesTopTags.findIndex(
            (existingTag) => existingTag.tag === tag,
          );
          this.activeLegalRepresentativesTopTags[index]["peopleTagged"]--;
          if (
            this.activeLegalRepresentativesTopTags[index]["peopleTagged"] === 0
          ) {
            // remove tag from Tag Array
            this.activeLegalRepresentativesTags =
              this.activeLegalRepresentativesTags.filter(
                (tagE) => tagE !== tag,
              );
            // remove tag from ActiveTag Array if it exists
            if (this.activeLegalrepresentativesFilters.activeTags) {
              this.activeLegalrepresentativesFilters.activeTags =
                this.activeLegalrepresentativesFilters.activeTags.filter(
                  (tagE) => tagE !== tag,
                );
            }
            // remove the tag from the suggested tags
            if (this.activeLegalRepresentativesTopTags) {
              this.activeLegalRepresentativesTopTags =
                this.activeLegalRepresentativesTopTags.filter(
                  (tagE) => tagE.tag !== tag,
                );
            }

            if ($('.purposes-container div[tag-v="' + tag + '"]').length) {
              $('.purposes-container div[tag-v="' + tag + '"]').remove();
            }
            if (!$(".purposes-container .tag-marker").length) {
              $(".builder-filter-tags").remove();
            }
          }
          // remove tag from active legal representative details
          if (this.activeLegalRepresentativeDetails["record"].tags) {
            this.activeLegalRepresentativeDetails["record"].tags =
              this.activeLegalRepresentativeDetails["record"].tags.filter(
                (tagE) => tagE !== tag,
              );
          }
          // enable deleting other tags back
          $(".close-button").removeClass("delete-disabled");
          // remove tag dom element from collapsed verification
          $(".purpose-tag-wrapper-sub." + tag).remove();
        },
        (error) => {
          console.log(
            "The following error occured when creating the person for project",
            error,
          );
        },
      );
  }

  deleteInvestorTag(projectKey, personKey, tag, target) {
    this.userService
      .deleteEndpoint(
        this.buildEndPoint("deletInvestorTags", projectKey, personKey, tag),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          // remove tag dom element from filter list (left) but only if it's the last element
          this.deleteInvestorTagFE(tag)
        },
        (error) => {
          console.log(
            "The following error occured when creating the person for project",
            error,
          );
        },
      );
  }

  deleteInvestorTagFE(tag) {
    const index = this.activeLegalRepresentativesTopTags.findIndex(
      (existingTag) => existingTag.tag === tag,
    );
    this.activeLegalRepresentativesTopTags[index]["peopleTagged"]--;
    if (
      this.activeLegalRepresentativesTopTags[index]["peopleTagged"] === 0
    ) {
      // remove tag from Tag Array
      this.activeLegalRepresentativesTags =
        this.activeLegalRepresentativesTags.filter(
          (tagE) => tagE !== tag,
        );
      // remove tag from ActiveTag Array if it exists
      if (this.activeLegalrepresentativesFilters.activeTags) {
        this.activeLegalrepresentativesFilters.activeTags =
          this.activeLegalrepresentativesFilters.activeTags.filter(
            (tagE) => tagE !== tag,
          );
      }
      // remove the tag from the suggested tags
      if (this.activeLegalRepresentativesTopTags) {
        this.activeLegalRepresentativesTopTags =
          this.activeLegalRepresentativesTopTags.filter(
            (tagE) => tagE.tag !== tag,
          );
      }

      if ($('.purposes-container div[tag-v="' + tag + '"]').length) {
        $('.purposes-container div[tag-v="' + tag + '"]').remove();
      }
      if (!$(".purposes-container .tag-marker").length) {
        $(".builder-filter-tags").remove();
      }
    }
    // remove tag from active legal representative details
    if (this.activeInvestorDetails.tags) {
      this.activeInvestorDetails.tags =
      this.activeInvestorDetails.tags.filter(
          (tagE) => tagE !== tag,
        );
    }
    // enable deleting other tags back
    $(".close-button").removeClass("delete-disabled");
    // remove tag dom element from collapsed verification
    $(".purpose-tag-wrapper-sub." + tag).remove();
  }



  /**
   * creates a new legal representative/person
   * @param projectKey string, key of the project
   * @param person object with required param name and optional params email, dateOfBirth, relationship
   * @param tags optional array used for filtering
   */
  createLegalRepresentatives(projectKey, person, tags?) {
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not create legal representative for this project! Project key supplied was unacceptable.",
      );
      this.createPerson.next({
        status: false,
        personData: person,
        error: "Project key supplied was unacceptable",
      });
      return;
    }
    person = this.helperService.sanitize(person);
    this.userService
      .postEndPoint(
        this.buildEndPoint("projectPeople", projectKey),
        person,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("We could NOT create the legal representative");
            this.createPerson.next({
              status: false,
              personData: person,
              error: "Failed to create a person",
            });
            return;
          }
          if (tags && tags.tags.length > 0) {
            this.activeLegalrepresentativesFilters.firstLoadEmpty = false;
            response = this.helperService.sanitize(response);
            this.createPerson.next({
              status: true,
              data: response,
              error: "Failed to create a person",
            });
            this.prependPerson(response);
            this.addPersonTags(
              projectKey,
              response["key"],
              this.activeLegalRepresentativeDetails,
              tags,
              true,
            );
            this.activeProjectChange.next();
          } else {
            this.activeLegalrepresentativesFilters.firstLoadEmpty = false;
            response = this.helperService.sanitize(response);
            this.createPerson.next({
              status: true,
              data: response,
              error: "Failed to create a person",
            });
            this.prependPerson(response);
            this.activeProjectChange.next();
          }
        },
        (error) => {
          console.log(
            "The following error occured when creating the person for project",
            error,
          );
          this.createPerson.next({
            status: false,
            personData: person,
            error: "Failed to create a person",
          });
        },
      );
  }

  /**
   * edit an existing legal representative/person
   * @param projectKey string, key of the project
   * @param person object with required param name and optional params email, dateOfBirth, relationship
   */
  editLegalRepresentatives(projectKey: string, personKey: string, person) {
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not create legal representative for this project! Project key supplied was unacceptable.",
      );
      this.editPerson.next({
        status: false,
        personData: person,
        error: "Project key supplied was unacceptable",
      });
      return;
    }
    person = this.helperService.sanitize(person);
    this.userService
      .putEndPoint(
        this.buildEndPoint("projectPeopleEdit", projectKey, personKey),
        person,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("We could NOT create the legal representative");
            this.editPerson.next({
              status: false,
              personData: person,
              error: "Failed to update a person",
            });
            return;
          }
          response = this.helperService.sanitize(response);
          this.editPerson.next({ status: true, data: response });
          this.updatePerson(response);
          this.activeProjectChange.next();
          // this.retrieveActiveProjects();
        },
        (error) => {
          console.log(
            "The following error occured when creating the person for project",
            error,
          );
          this.editPerson.next({
            status: false,
            personData: person,
            error: error,
          });
        },
      );
  }
  /**
   * delete a person from the id verfification list
   * @param projectKey string, key of the project
   * @param person object with required param name and optional params email, dateOfBirth, relationship
   */
  deletePerson(projectKey: string, personKey: string) {
    if (typeof projectKey !== "string" || personKey.length === 0) {
      console.log(
        "could not create legal representative for this project! Project key supplied was unacceptable.",
      );
      return;
    }
    this.userService
      .deleteEndpoint(
        this.buildEndPoint("deletePerson", projectKey, personKey),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("We could not delete the person");
            alert("We could not delete the selected record");
            return;
          }
          this.activeLegalrepresentativesFilters.firstLoadEmpty = false;
          this.setActivePerson(undefined);
          this.resetScrollToKey();
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          // close the form
          $(".close-form-button,#lightBoxCloseImage").trigger("click");
        },
        (error) => {
          console.log(
            "The following error occured when creating the person for project",
            error,
          );
          alert("We could not delete the selected record");
        },
      );
  }

  /**
   * Load additional details like document #, date of issue etc.
   * @param projectKey string, key of the active project
   * @param personKey string, key of the legal representative we are loading the details for
   * @param executeLazy boolean, if set to true it will always trigger a lazy recover from JUMIO, default is false
   */
  loadAdditionalPersonDetails(projectKey, personKey, executeLazy = false) {
    if (projectKey === "" || personKey === "") {
      console.log(
        "Could not fetch additional person data: required parameters are missing!",
      );
      return;
    }
    this.userService
      .getEndPoint(
        this.buildEndPoint("projectPeopleEdit", projectKey, personKey),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          this.loadAdditionalPersonDetailsStatus.next({
            key: projectKey,
            response: this.helperService.sanitize(response),
            alwaysExecuteLazy: executeLazy,
          });
        },
        (error) => {
          // console.log('Following error occured while fetching record for additional data!', error);
          return;
        },
      );
  }

  /**
   * Load image data for selected person of the active project
   * @param projectKey string, key of the active project
   * @param personKey string, key of the legal representative we are loading the details for
   */
  loadPersonImage(
    projectKey,
    eventTarget,
    targetGroup,
    personKey,
    verificationKey,
    type = "",
    returnObservable = false,
  ) {
    if (projectKey === "" || personKey === "" || verificationKey === "") {
      this.loadPersonImageStatus.next({
        key: personKey,
        type: type,
        response: null,
      });
      return;
    }
    const $observable = this.userService.getEndPoint(
      "api/projects/" +
        projectKey +
        "/people/" +
        personKey +
        "/verifications/identity/" +
        verificationKey +
        "/image" +
        (type !== "" ? "?type=" + type : ""),
      { responseType: "blob" },
      { observe: "body" },
      "verification",
    );
    if (returnObservable) {
      return $observable;
    }
    $observable.subscribe(
      (response) => {
        if (response === undefined) {
          console.log("Error while fetching record for image data!");
          // emit failed attemt to recover image
          this.loadPersonImageStatus.next({
            key: personKey,
            type: type,
            response: null,
          });
          return;
        }
        // at this point we are expecting
        // to have blob response
        // create an image from blob
        this.createImageFromBlob(response as Blob, personKey, type);
      },
      (error) => {
        // console.log('Following error occured while fetching record for image data!', error);
        // emit failed attemt to recover image
        this.loadPersonImageStatus.next({
          key: personKey,
          type: type,
          response: null,
        });
        return;
      },
    );
  }
  /**
   * Load image data for selected person of the active project
   * @param projectKey string, key of the active project
   * @param personKey string, key of the legal representative we are loading the details for
   */
  loadPersonImagesAndData(
    projectKey,
    eventTarget,
    targetGroup,
    personKey,
    verificationKey,
    type = "",
    returnObservable = false,
  ) {
    if (projectKey === "" || personKey === "" || verificationKey === "") {
      // console.log('Could not fetch image data: required parameters are missing!');
      this.loadPersonImageStatus.next({
        key: personKey,
        type: type,
        response: null,
      });
      return;
    }
    const vFront: any = this.loadPersonImage(
      projectKey,
      "",
      "",
      personKey,
      verificationKey,
      "front",
      true,
    );
    const vBack: any = this.loadPersonImage(
      projectKey,
      "",
      "",
      personKey,
      verificationKey,
      "back",
      true,
    ).pipe(catchError((e) => of({})));

    this.userService
      .getEndPoint(
        "api/projects/" +
          projectKey +
          "/people/" +
          personKey +
          "/verifications/identity/" +
          verificationKey +
          "/details",
        { responseType: "json" },
        { observe: "body" },
        "verification",
      )
      .subscribe(
        (responseDetails) => {
          if (responseDetails === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          const observableBatch = [];
          let secondDocumentImage = false;
          secondDocumentImage = true;
          observableBatch.push(vFront);
          if (secondDocumentImage) {
            observableBatch.push(vBack);
          }

          let frontImg;
          let backImg;

          forkJoin(observableBatch).subscribe(
            async (response) => {
              if (response[0] instanceof Blob) {
                frontImg = await this.convertBlobToBase64Img(response[0]);
              }
              if (response[0] instanceof Blob) {
                frontImg = await this.resizeBase64Image(frontImg);
              }
              if (secondDocumentImage) {
                if (response[1] instanceof Blob) {
                  backImg = await this.convertBlobToBase64Img(response[1]);
                }
                if (response[1] instanceof Blob) {
                  backImg = await this.resizeBase64Image(backImg);
                }
              }
              this.loadPersonImageStatus.next({
                key: personKey,
                type: "both",
                frontImg: frontImg,
                backImg: backImg,
                verificationDetails: responseDetails,
              });
            },
            (error) => {
              console.log(error);
              $(".close-form-button,#lightBoxCloseImage").trigger("click");
              this.loadPersonImageStatus.next({
                key: personKey,
                type: "both",
                frontImg: undefined,
                backImg: undefined,
                verificationDetails: responseDetails,
              });
            },
          );
        },
        (error) => {
          console.log(error);
          $(".close-form-button,#lightBoxCloseImage").trigger("click");
          alert("Id verification data could not be fetched");
        },
      );

    // this.createImageFromBlob(response as Blob, personKey, type);
  }
  /**
   * Load image data for selected person of the active project
   * @param projectKey string, key of the active project
   * @param personKey string, key of the legal representative we are loading the details for
   */
  loadPersonAddressImages(
    projectKey,
    eventTarget,
    targetGroup,
    personKey,
    verificationKey,
    documentKeys,
  ) {
    if (projectKey === "" || personKey === "" || verificationKey === "") {
      // console.log('Could not fetch image data: required parameters are missing!');
      alert(this.translate.instant("errors.error"));
      return;
    }
    const observableBatch = [];

    const addressverificationDetails = this.userService.getEndPoint(
      "api/projects/" +
        projectKey +
        "/people/" +
        personKey +
        "/verifications/address/" +
        verificationKey,
      { responseType: "json" },
      { observe: "body" },
      "verification",
    );

    if (documentKeys) {
      documentKeys.map((item) => {
        observableBatch.push(
          this.userService.getEndPoint(
            this.buildEndPoint(
              "getAddressDocumentFile",
              projectKey,
              personKey,
              "",
              true,
              verificationKey,
              item.documentKey,
            ),
            { responseType: "json" },
            { observe: "response" },
            "verification",
          ),
        );
      });
    }

    observableBatch.push(addressverificationDetails);

    forkJoin(observableBatch).subscribe(async (response) => {
      try {
        // this.loadPersonImageStatus.next({ 'key': personKey, 'type' : 'address', });
        const imagesArray = response;
        this.loadPersonImageStatus.next({
          key: personKey,
          type: "address",
          imagesArray: imagesArray,
        });
      } catch (e) {
        $(".close-form-button,#lightBoxCloseImage").trigger("click");
        alert(this.translate.instant("errors.error"));
        console.warn(e.message);
      }
    });

    // this.createImageFromBlob(response as Blob, personKey, type);
  }
  /**
   * TEMP Load image data for TESTING PURPOSES
   * 24062019: leaving this for now until I check the task about image showing only after hard refresh
   * @param projectKey string, key of the active project
   * @param personKey string, key of the legal representative we are loading the details for
   */
  loadPersonImageTEMP(
    projectKey,
    eventTarget,
    targetGroup,
    personKey,
    verificationKey,
    type = "",
  ) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
    this.userService
      .getEndPoint(
        "https://dev.meshid.app/api/projects/" +
          projectKey +
          "/people/" +
          personKey +
          "/verifications/identity/" +
          verificationKey +
          "/image" +
          (type !== "" ? "?type=" + type : ""),
        { responseType: "blob" },
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for image data!");
            // emit failed attempt to recover image
            this.loadPersonImageStatus.next({
              key: personKey,
              type: type,
              response: null,
            });
            return;
          }
          this.createImageFromBlob(response as Blob, personKey, type);
        },
        (error) => {
          // console.log('Following error occured while fetching record for image data!', error);
          // emit failed attemt to recover image
          this.loadPersonImageStatus.next({
            key: personKey,
            type: type,
            response: null,
          });
          return;
        },
      );
  }

  /**
   * create base64 image string from blob
   * @param image Blob, response returned from BE
   * @param personKey string, person key
   */
  createImageFromBlob(image: Blob, personKey = "", type = "") {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.loadPersonImageStatus.next({
          key: personKey,
          type: type,
          response: reader.result,
        });
      },
      false,
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  /**
   * generate url that will be send to verification subject/
   * active representative that will take them to JUMIO for
   * verification
   * @param projectKey: string, project key where the representative is defined
   * @param personKey: string, person key for the person we are generating verification link
   * @param afterCreation: boolean, this variable tells us if the link is generated after creation of a person
   *                       or on the generate url panel
   */
  generateVerificationUrl(
    projectKey: string,
    personKey: string,
    afterCreation: boolean,
    payload?,
  ) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.generateVerificationUrlStatus.next({
        status: false,
        afterCreation: afterCreation,
      });
      return;
    }
    // make an actual call to BE
    // to retrieve the verification link/
    // temporary emit manually generated link
    // Why?
    this.userService
      .postEndPoint(
        this.buildEndPoint("verificationLink", projectKey, personKey),
        payload,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Unexpected error as we were generating the URL");
            this.generateVerificationUrlStatus.next({
              status: false,
              afterCreation: afterCreation,
            });
            return;
          }
          if (this.getActivePersonDetails()["record"]["lastVerification"]) {
            this.getActivePersonDetails()["record"][
              "lastVerification"
            ].record.status = "pending";
          } else {
            this.getActivePersonDetails()["record"]["lastVerification"] = {
              record: { status: "pending" },
            };
          }
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          this.generateVerificationUrlStatus.next({
            status: true,
            data: this.helperService.sanitize(response),
            afterCreation: afterCreation,
          });
          this.activeProjectChange.next();
        },
        (error) => {
          console.log("We could not generate URL", error);
          this.generateVerificationUrlStatus.next({
            status: false,
            afterCreation: afterCreation,
          });
        },
      );
    // this.retrieveActiveLegalRepresentatives(this.getActiveProject());
    // this.activeProjectChange.next();
  }

  /**
   * generate url for Address Verification that will be send to verification
   * subject/active representative that will take them to JUMIO for
   * verification
   * @param projectKey: string, project key where the representative is defined
   * @param personKey: string, person key for the person we are generating verification link
   */
  generateAddressVerification(
    projectKey: string,
    personKey: string,
    address: string,
    zip: string,
    city: string,
    country: string,
    location: string,
  ) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.generateAddressVerificationStatus.next({ status: false });
      return;
    }
    // make an actual call to BE
    // to retrieve the verification link
    // temporary emit manually generated link
    this.userService
      .postEndPoint(
        this.buildEndPoint("verificationAddressLink", projectKey, personKey),
        {
          address: address,
          zipCode: zip,
          city: city,
          country: country,
          location: location,
        },
        { responseType: "json" },
        { observe: "body" },
        "verification",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Unexpected error as we were generating the URL");
            this.generateAddressVerificationStatus.next({ status: false });
            return;
          }
          this.generateAddressVerificationStatus.next({
            status: true,
            data: this.helperService.sanitize(response),
          });
          this.retrieveActiveLegalRepresentatives(this.getActiveProject());
        },
        (error) => {
          console.log("We could not generate URL", error);
          this.generateAddressVerificationStatus.next({ status: false });
        },
      );
  }

  /**
   * Initiate a screening for a person with a post request. The person needs to be verified first
   * @param projectKey: string, project key where the representative is defined
   * @param personKey: string, person key for the person we are generating verification link
   */
  initiateScreening(projectKey: string, personKey: string) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    // make an call to the BE to initiate screening
    this.userService
      .postEndPoint(
        this.buildEndPoint("screeningInit", projectKey, personKey),
        {},
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
            this.screeningStatus.next({ status: false });
            this.retrieveActiveLegalRepresentatives(this.getActiveProject());
          }
          
          this.screeningStatus.next({ 'status' : true,'data': this.helperService.sanitize(response) });
          this.resolveScreening(projectKey, personKey, response.key);
          // console.log(response);
        },
        (error) => {
          console.log("The following error occured with the screening", error);
          this.screeningStatus.next({ status: false });
          this.retrieveActiveLegalRepresentatives(this.getActiveProject());
        },
      );
  }

  /**
   * Resolve a screening for a person with a get request. After a screening is initiated with a post we are sending
   * a second request to resolve the screening, in case the screening process takes too long
   * @param projectKey: string, project key where the representative is defined
   * @param personKey: string, person key for the person we are generating verification link
   */
  resolveScreening(
    projectKey: string,
    personKey: string,
    screeningKey: string,
  ) {
    let resolveSucces = false;

    setTimeout(() => {
      if (resolveSucces === false) {
        this.retrieveActiveLegalRepresentatives(this.getActiveProject());
        this.screeningStatus.next({ status: false, reason: "timeout" });
      }
    }, 5000);

    console.log("mscreening");
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    // make an call to the BE to resolve a screening
    this.userService
      .getEndPoint(
        this.buildEndPoint(
          "screeningResolve",
          projectKey,
          personKey,
          "",
          true,
          screeningKey,
        ),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.screeningStatus.next({ status: false });
          }
          this.screeningStatus.next({
            status: true,
            data: this.helperService.sanitize(response),
          });
          this.retrieveActiveLegalRepresentatives(this.getActiveProject());
          this.setScreeningFinished(true);
          resolveSucces = true;
          // console.log(response);
        },
        (error) => {
          console.log("The following error occured with the screening", error);
          this.screeningStatus.next({ status: false });
          this.retrieveActiveLegalRepresentatives(this.getActiveProject());
        },
      );
  }

  // PEP AND SANCTIONS COMPLY ADVANTAGE CHECK
  initiateAps(projectKey: string, personKey: string, personRecord) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    // make an call to the BE to initiate screening
    this.userService
      .postEndPoint(
        this.buildEndPoint("apsInit", projectKey, personKey),
        personRecord,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
            this.screeningStatus.next({ status: false });
            this.retrieveActiveLegalRepresentatives(this.getActiveProject());
          }
          this.getActivePersonDetails()["record"]["lastApsVerification"] =
            response;
          if (this.getActivePersonDetails()["record"]["lastVerification"]) {
            this.getActivePersonDetails()["record"][
              "lastVerification"
            ].record.status = "PENDING";
          } else {
            this.getActivePersonDetails()["record"]["lastVerification"] = {
              record: { status: "PENDING" },
            };
          }
          this.updateActivePersonDetail(
            personKey,
            "record.name",
            `${personRecord.firstName} ${personRecord.lastName}`,
          );
          this.updateActivePersonDetail(
            personKey,
            "record.firstName",
            personRecord.firstName,
          );
          this.updateActivePersonDetail(
            personKey,
            "record.lastName",
            personRecord.lastName,
          );
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          this.editPerson.next({ status: true });
          this.activeProjectChange.next();
        },
        (error) => {
          console.log(
            "The following error occured with the initating aps",
            error,
          );
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          alert(this.translate.instant("errors.error2"));
          this.editPerson.next({ status: true });
          this.activeProjectChange.next();
        },
      );
  }

  // PEP AND SANCTIONS COMPLY ADVANTAGE CHECK
  initiateApsCDD(projectKey: string, personKey: string, personRecord) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      return;
    }
    // make an call to the BE to initiate screening
    this.userService
      .postEndPoint(
        this.buildEndPoint("apsInitCDD", projectKey, personKey),
        personRecord,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
          }
          this.skipToStep(-1);
        },
        (error) => {
          console.log(
            "The following error occured with the initating aps",
            error,
          );
          alert(this.translate.instant("errors.error2"));
          this.skipToStep(0);
        },
      );
  }

  async getAps(
    projectKey: string,
    personKey: string,
    verificationKey,
    completePDF?,
  ) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    return new Promise((resolve, reject) => {
      // make a call to the BE to initiate screening
      this.userService
        .getEndPoint(
          this.buildEndPoint("apsGet", projectKey, personKey, verificationKey),
          { responseType: "json" },
          { observe: "body" },
          this.getCCType(),
        )
        .subscribe(
          (response: any) => {
            if (response === undefined) {
              this.screeningStatus.next({ status: false });
              reject("error");
              if (completePDF) {
                this.completePDFDownload.next("error");
              }
            }
            // this.screeningStatus.next({'status': false});
            if (!completePDF) {
              this.loadPersonImageStatus.next({
                key: personKey,
                type: "aps",
                apsDetails: response,
              });
            }
            resolve(response);
            // console.log(response);
          },
          (error) => {
            console.log(
              "The following error occured with the initating aps",
              error,
            );
            reject("error");
            if (completePDF) {
              this.completePDFDownload.next("error");
            }
            // this.screeningStatus.next({'status': false});
            alert(this.translate.instant("errors.error2"));
          },
        );
    });
  }

  // confirm APS
  confirmAPS(projectKey: string, personKey: string, verificationKey) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    // make an call to the BE to initiate screening
    let payload;
    payload = { status: "COMPLETED" };
    if ($("#actionReason").val()) {
      let commentString = $("#actionReason").val().toString();
      commentString = commentString.trim();
      if (commentString !== "") {
        payload.comment = commentString;
      }
    }
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "apsConfirm",
          projectKey,
          personKey,
          "",
          true,
          verificationKey,
        ),
        payload,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
            this.screeningStatus.next({ status: false });
          }
          // this.screeningStatus.next({'status': false});
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          $("#lightBoxCloseImage").click();
        },
        (error) => {
          console.log(
            "The following error occured with the initating aps",
            error,
          );
          // this.screeningStatus.next({'status': false});
          alert(this.translate.instant("errors.error2"));
          $("#lightBoxCloseImage").click();
        },
      );
  }
  // reject APS
  rejectAPS(projectKey: string, personKey: string, verificationKey) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    let payload;
    payload = { status: "FAILED" };
    if ($("#actionReason").val()) {
      let commentString = $("#actionReason").val().toString();
      commentString = commentString.trim();
      if (commentString !== "") {
        payload.comment = commentString;
      }
    }
    // make an call to the BE to initiate screening
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "apsConfirm",
          projectKey,
          personKey,
          "",
          true,
          verificationKey,
        ),
        payload,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
            this.screeningStatus.next({ status: false });
          }
          // this.screeningStatus.next({'status': false});
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          $("#lightBoxCloseImage").click();
        },
        (error) => {
          console.log(
            "The following error occured with the initating aps",
            error,
          );
          // this.screeningStatus.next({'status': false});
          alert(this.translate.instant("errors.error2"));
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          $("#lightBoxCloseImage").click();
        },
      );
  }

  initiateProfileVerification(projectKey: string, requestKey: any) {
    if (projectKey === undefined || requestKey === undefined) {
      console.log("Required data was missing");
      return;
    }
    this.userService
      .postEndPoint(
        this.buildEndPoint("profileVerification", projectKey, requestKey),
        "",
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
          }
          this.loadAdditionalInvestorDetail(projectKey, requestKey, true);
          this.skipToStep(0);
        },
        (error) => {
          console.log(
            "The following error occured with the initating aps",
            error,
          );
          alert(this.translate.instant("errors.error2"));
          this.skipToStep(0);
        },
      );
  }

  async generateContractPreview(
    projectKey: string,
    personKey: string,
    verificationKey,
    documentKeys,
    completePDF?,
  ) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    // make a call to the BE to initiate screening
    return new Promise((resolve, reject) => {
      const observableBatch = [];
      documentKeys.forEach((documentKey) => {
        observableBatch.push(
          this.userService.getEndPoint(
            this.buildEndPoint(
              "contractGetwKey",
              projectKey,
              personKey,
              "",
              true,
              verificationKey,
              documentKey,
            ),
            { responseType: "json" },
            { observe: "response" },
            this.getCCType(),
          ),
        );
      });
      forkJoin(observableBatch).subscribe(
        async (responseBatch) => {
          // console.log('CONTRACT GET', response); return;
          if (responseBatch === undefined) {
            this.screeningStatus.next({ status: false });
            reject("error");
            if (completePDF) {
              this.completePDFDownload.next("error");
            }
          }
          const contractFiles = [];
          responseBatch.forEach((response) => {
            contractFiles.push({
              type: "pdf",
              data:
                "data:application/pdf;base64," +
                this.helperService.toBase64(response["body"].data),
              filename: response["headers"].get("filename"),
            });
          });
          if (!completePDF) {
            this.loadPersonImageStatus.next({
              key: personKey,
              type: "contract",
              contractDetails: contractFiles,
            });
            resolve(contractFiles);
          } else {
            resolve(contractFiles);
          }
        },
        (error) => {
          console.log(
            "The following error occured with the initating aps",
            error,
          );
          reject("error");
          alert(this.translate.instant("errors.error3"));
          if (completePDF) {
            this.completePDFDownload.next("error");
          }
        },
      );
    });
  }

  // confirm AV
  confirmAV(projectKey: string, personKey: string, verificationKey) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    let payload;
    payload = { status: "COMPLETED" };
    if ($("#actionReason").val()) {
      let commentString = $("#actionReason").val().toString();
      commentString = commentString.trim();
      if (commentString !== "") {
        payload.comment = commentString;
      }
    }
    // make an call to the BE to initiate screening
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "AVConfirm",
          projectKey,
          personKey,
          "",
          true,
          verificationKey,
        ),
        payload,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
            this.screeningStatus.next({ status: false });
          }
          // this.screeningStatus.next({'status': false});
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          $("#lightBoxCloseImage").click();
        },
        (error) => {
          console.log(
            "The following error occured with the initating aps",
            error,
          );
          // this.screeningStatus.next({'status': false});
          alert(this.translate.instant("errors.error2"));
          $("#lightBoxCloseImage").click();
        },
      );
  }
  // reject AV
  rejectAV(projectKey: string, personKey: string, verificationKey) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    let payload;
    payload = { status: "FAILED" };
    if ($("#actionReason").val()) {
      let commentString = $("#actionReason").val().toString();
      commentString = commentString.trim();
      if (commentString !== "") {
        payload.comment = commentString;
      }
    }
    // make an call to the BE to initiate screening
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "AVConfirm",
          projectKey,
          personKey,
          "",
          true,
          verificationKey,
        ),
        payload,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
            this.screeningStatus.next({ status: false });
          }
          // this.screeningStatus.next({'status': false});
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          $("#lightBoxCloseImage").click();
        },
        (error) => {
          console.log(
            "The following error occured with the initating aps",
            error,
          );
          // this.screeningStatus.next({'status': false});
          alert(this.translate.instant("errors.error4"));
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          $("#lightBoxCloseImage").click();
        },
      );
  }

  // reject AV
  enhancedCheck(projectKey: string, personKey: string, verificationKey) {
    if (projectKey === undefined || personKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    // make an call to the BE to initiate screening
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "ehnancedCheck",
          projectKey,
          personKey,
          "",
          true,
          verificationKey,
        ),
        { status: "FAILED" },
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
            this.screeningStatus.next({ status: false });
          }
          // this.screeningStatus.next({'status': false});
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          $("#lightBoxCloseImage").click();
        },
        (error) => {
          console.log(
            "The following error occured with the initating aps",
            error,
          );
          // this.screeningStatus.next({'status': false});
          alert(this.translate.instant("errors.error5"));
          this.retrieveActiveLegalRepresentatives(
            this.getActiveProject(),
            false,
          );
          $("#lightBoxCloseImage").click();
        },
      );
  }

  /**
   * depercated, no longer in use, as contributors are directly added to a project
   * generate url that will be send to potential project contributor/
   * member or owner
   * @param projectKey: string, project key where we invite the contributor
   * @param contributorEmail: string, the email address of the contributor
   * @param contributorType: string, enum('member', 'owner')
   */
  generateInviteUrl(
    projectKey: string,
    contributorEmail: string,
    contributorType: string,
  ) {
    if (
      projectKey === "" ||
      contributorEmail === "" ||
      contributorType === ""
    ) {
      console.log("Required data was missing");
      this.generateContributorInviteUrlStatus.next({ status: false });
      return;
    }
    contributorEmail = this.helperService.sanitizePropery(
      contributorEmail,
    ) as string;
    contributorType = this.helperService.sanitizePropery(
      contributorType,
    ) as string;
    this.userService
      .postEndPoint(
        this.buildEndPoint("inviteLink", projectKey),
        {
          email: contributorEmail,
          type: contributorType,
        },
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Unexpected error as we were generating the URL");
            this.generateContributorInviteUrlStatus.next({ status: false });
            return;
          }
          console.log("RESPONSE FROM BE: ", response);
          this.generateContributorInviteUrlStatus.next({
            status: true,
            data: this.helperService.sanitize(response),
          });
        },
        (error) => {
          console.log("We could not generate URL", error);
          this.generateContributorInviteUrlStatus.next({ status: false });
        },
      );
  }

  /**
   * generate url that will be send to potential project contributor/
   * member or owner
   * @param projectKey: string, project key where we invite the contributor
   * @param contributorEmail: string, the email address of the contributor
   * @param contributorType: string, enum('member', 'owner')
   */
  directInviteContributorToProject(
    projectKey: string,
    contributorEmail: string,
    contributorType: string,
  ) {
    if (
      projectKey === "" ||
      contributorEmail === "" ||
      contributorType === ""
    ) {
      console.log("Required data was missing");
      this.addContributorStatus.next({ status: false });
      return;
    }
    contributorEmail = this.helperService.sanitizePropery(
      contributorEmail,
    ) as string;
    contributorType = this.helperService.sanitizePropery(
      contributorType,
    ) as string;
    this.userService
      .postEndPoint(
        this.buildEndPoint("addContributor", projectKey),
        {
          email: contributorEmail,
          type: contributorType,
        },
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.error(
              "Unexpected error as we were adding the contributor to the project",
            );
            this.addContributorStatus.next({ status: false });
            return;
          }
          this.addContributorStatus.next({
            status: true,
            data: this.helperService.sanitize(response),
          });
        },
        (error) => {
          console.error(
            "We could not add the contributor to the project",
            error,
          );
          this.addContributorStatus.next({
            status: false,
            errorMessage: error.error.message,
            errorStatus: error.status,
          });
        },
      );
  }

  /**
   * Resolve status for a person verification
   * @param projectKey string, key of the active project
   * @param personKey string, key of the legal representative we are loading the details for
   * @param verificationKey string, key of the verification we are resolivng the status for
   */
  resolveActivePersonStatus(
    projectKey,
    personKey,
    verificationKey,
    verificationType,
  ) {
    console.log("verification key", verificationKey);
    if (projectKey === "" || personKey === "" || verificationKey === "") {
      console.log(
        "Could not resolve status of a verification: required parameters are missing!",
      );
      this.updatePersonVerificationFailed(personKey, verificationType);
      return;
    }
    this.userService
      .postEndPoint(
        this.getEndPointBaseUrl() +
          "api/projects/" +
          projectKey +
          "/people/" +
          personKey +
          "/verifications/identity/" +
          verificationKey +
          "/resolve",
        "",
        { responseType: "json" },
        { observe: "body" },
        "verification",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log(
              "Error while fetching record for status of a verification!",
            );
            return;
          }
          // update the verification of the person with personKey
          // and push an event for change in list of active legal representatives
          // we are not updating the ACTIVE person verification information
          // because by the time we got a reply from server the active person may have been changed
          this.updatePersonVerificationStatus(
            personKey,
            this.helperService.sanitize(response),
            verificationType,
          );
        },
        (error) => {
          console.log(
            "Following error occured while fetching record for status of a verification!",
            error,
          );
          this.updatePersonVerificationFailed(personKey, verificationType);
          return;
        },
      );
  }

  /**
   * cancels selected verification for a person
   * @param projectKey string, key of the active project
   * @param personKey string, key of the legal representative we canceling the verification for
   * @param verificationKey string, key of the verification we are about to cancel
   */
  initiateCancelVerification(projectKey, personKey, verificationKey) {
    if (projectKey === "" || personKey === "" || verificationKey === "") {
      console.log(
        "Could not resolve status of a verification: required parameters are missing!",
      );
      this.cancelVerification.next({
        status: false,
        reason:
          "Could not resolve status of a verification: required parameters are missing!",
      });
      return;
    }
    this.userService
      .postEndPoint(
        this.getEndPointBaseUrl() +
          "api/projects/" +
          projectKey +
          "/people/" +
          personKey +
          "/verifications" +
          "/cancel",
        {},
        { responseType: "json" },
        { observe: "body" },
        "verification",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log(
              "Error while fetching record for status of a verification!",
            );
            this.cancelVerification.next({
              status: false,
              reason:
                "We were not able to cancel the verification! Reason: " +
                response["message"],
            });
            return;
          }
          // update the verification details of the person with personKey
          // and push an event for change in list of active legal representatives
          // we are not updating the ACTIVE person verification information
          // because by the time we got a reply from server the active person may have been changed
          // this.updatePersonVerificationStatus(personKey, this.helperService.sanitize(response));
          this.getActivePersonDetails()["record"]["lastVerification"] =
            undefined;
          this.getActivePersonDetails()["record"]["lastAddressVerification"] =
            undefined;
          this.retrieveActiveLegalRepresentatives(projectKey);
          this.cancelVerification.next({ status: true });
        },
        (error) => {
          console.log(
            "Following error occured while fetching record for status of a verification!",
            error.message,
          );
          this.cancelVerification.next({
            status: false,
            reason: error.message,
          });
          return;
        },
      );
  }

  /**
   * cancels selected verification for a person
   * @param projectKey string, key of the active project
   * @param personKey string, key of the legal representative we canceling the verification for
   * @param verificationKey string, key of the verification we are about to cancel
   */
  initiateCancelAVerification(projectKey, personKey, verificationKey) {
    if (projectKey === "" || personKey === "" || verificationKey === "") {
      console.log(
        "Could not resolve status of a verification: required parameters are missing!",
      );
      this.cancelVerification.next({
        status: false,
        reason:
          "Could not resolve status of a verification: required parameters are missing!",
      });
      return;
    }
    this.userService
      .postEndPoint(
        this.getEndPointBaseUrl() +
          "api/projects/" +
          projectKey +
          "/people/" +
          personKey +
          "/verifications" +
          "/cancel",
        {},
        { responseType: "json" },
        { observe: "body" },
        "verification",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log(
              "Error while fetching record for status of a verification!",
            );
            this.cancelVerification.next({
              status: false,
              reason:
                "We were not able to cancel the verification! Reason: " +
                response["message"],
            });
            return;
          }
          // update the verification details of the person with personKey
          // and push an event for change in list of active legal representatives
          // we are not updating the ACTIVE person verification information
          // because by the time we got a reply from server the active person may have been changed
          // this.updatePersonVerificationStatus(personKey, this.helperService.sanitize(response));
          this.retrieveActiveLegalRepresentatives(projectKey);
          this.cancelVerification.next({ status: true });
        },
        (error) => {
          console.log(
            "Following error occured while fetching record for status of a verification!",
            error,
          );
          this.cancelVerification.next({
            status: false,
            reason: error.message,
          });
          return;
        },
      );
  }

  /**
   * retrieves details about selected verification for a person
   * @param projectKey string, key of the active project
   * @param verificationKey string, key of the verification we are about to cancel
   */
  retrieveVerificationDetails(
    projectKey,
    personKey,
    verificationKey,
    returnObservable = false,
  ) {
    if (projectKey === "" || personKey === "" || verificationKey === "") {
      console.log(
        "Could not resolve status of a verification: required parameters are missing!",
      );
      return;
    }
    const observable$ = this.userService.getEndPoint(
      "api/projects/" +
        projectKey +
        "/people/" +
        personKey +
        "/verifications/identity/" +
        verificationKey +
        "/details",
      { responseType: "json" },
      { observe: "body" },
      "verification",
    );
    if (returnObservable) {
      return observable$;
    }
    observable$.subscribe(
      (response) => {
        if (response === undefined) {
          console.log(
            "Error while fetching record for status of a verification!",
          );
          return;
        }
        // update the verification details of the person with personKey
        // and push an event for change in list of active legal representatives
        // we are not updating the ACTIVE person verification information
        // because by the time we got a reply from server the active person may have been changed
        this.retrieveVerificationDetailsStatus.next(
          this.helperService.sanitize(response),
        );
      },
      (error) => {
        console.log(
          "Following error occured while fetching record for status of a verification!",
          error,
        );
        return;
      },
    );
  }
  /**
   * retrieves details about selected verification for a person
   * @param projectKey string, key of the active project
   * @param personKey string, key of the legal representative we canceling the verification for
   * @param addressVerificationKey string, key of the address verification we are about to retrieve
   */
  retrieveAddressVerificationDetails(
    projectKey,
    personKey,
    addressVerificationKey,
    returnObservable = false,
  ) {
    if (
      projectKey === "" ||
      personKey === "" ||
      addressVerificationKey === ""
    ) {
      console.log(
        "Could not resolve status of a verification: required parameters are missing!",
      );
      return;
    }
    const observable$ = this.userService.getEndPoint(
      "api/projects/" +
        projectKey +
        "/people/" +
        personKey +
        "/verifications/address/" +
        addressVerificationKey,
      { responseType: "json" },
      { observe: "body" },
      "verification",
    );
    if (returnObservable) {
      return observable$;
    }
    observable$.subscribe(
      (response) => {
        if (response === undefined) {
          console.log(
            "Error while fetching record for status of a verification!",
          );
          return;
        }
        // update the verification details of the person with personKey
        // and push an event for change in list of active legal representatives
        // we are not updating the ACTIVE person verification information
        // because by the time we got a reply from server the active person may have been changed
        console.log("address verification response", response);
        this.retrieveAddressVerificationDetailsStatus.next(
          this.helperService.sanitize(response),
        );
      },
      (error) => {
        console.log(
          "Following error occured while fetching record for status of a verification!",
          error,
        );
        return;
      },
    );
  }

  /**
   * retrieves document associated with an address verification by document key
   */
  retrieveAddressVerificationDocument(
    projectKey: string,
    personKey: string,
    addressVerificationKey: string,
    documentKey: string,
    returnObservable = false,
  ) {
    if (!projectKey || !personKey || !addressVerificationKey || !documentKey) {
      console.log(
        "Could not resolve an address verification document: required parameters are missing!",
      );
      return;
    }
    const observable$ = this.userService.getEndPoint(
      "api/projects/" +
        projectKey +
        "/people/" +
        personKey +
        "/verifications/address/" +
        addressVerificationKey +
        "/documents/" +
        documentKey,
      { responseType: "json" },
      { observe: "body" },
      "verification",
    );
    if (returnObservable) {
      return observable$;
    }
    observable$.subscribe(
      (response) => {
        if (response === undefined) {
          console.log(
            "Error while fetching record for status of a verification!",
          );
          return;
        }
        // update the verification details of the person with personKey
        // and push an event for change in list of active legal representatives
        // we are not updating the ACTIVE person verification information
        // because by the time we got a reply from server the active person may have been changed
        // TODO: this.retrieveAddressVerificationDetailsStatus.next(this.helperService.sanitize(response));
      },
      (error) => {
        console.log(
          "Following error occured while fetching an address verification document:",
          error,
        );
        return;
      },
    );
  }

  /**
   * retrieves iso2 country code
   * @param iso3Code string, iso3Code received from JUMIO
   */
  retrieveCountryIso(iso3Code) {
    if (iso3Code === undefined) {
      console.log("Could not resolve country iso3 code!");
      return;
    }
    // countriy.io is blocked by CORS use fallback HELPER method!
    this.userService
      .getEndPoint(
        `${this.buildEndPoint("corsany-proxy")}/:http://country.io/iso3.json`,
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log(
              "Error while fetching the list of country codes from third party service! Attempting fallback retreival",
            );
            this.retrieveCountryIsoStatus.next(
              this.helperService.retrieveCountryIso2(iso3Code),
            );
            return;
          }
          // iso countries codes were fetched match iso3 to iso2 code
          // to retrieve the flag from https://countryflags.io/
          console.log("RESPONSE", response);
          this.retrieveCountryIsoStatus.next(
            this.helperService.retrieveCountryIso2(
              iso3Code,
              this.helperService.sanitize(response),
            ),
          );
        },
        (error) => {
          console.log(
            "Following error occured while fetching the list of country codes from third party service!" +
              "Attempting fallback retreival",
            error,
          );
          this.retrieveCountryIsoStatus.next(
            this.helperService.retrieveCountryIso2(iso3Code),
          );
          return;
        },
      );
  }

  async retrieveAsyncCountryIso(iso3Code) {
    if (iso3Code === undefined) {
      console.log("Could not resolve country iso3 code!");
      return;
    }
    // countriy.io is blocked by CORS use fallback HELPER method!
    return await this.userService
      .getEndPoint(
        `${this.buildEndPoint("corsany-proxy")}/:http://country.io/iso3.json`,
      )
      .toPromise();
  }

  /**
   * retrieves country name
   * @param iso2Code string, iso3Code received from JUMIO
   */
  retrieveCountryName(iso2Code) {
    if (iso2Code === undefined) {
      console.log("Could not resolve country iso2 code!");
      return;
    }
    // countriy.io is blocked by CORS use fallback HELPER method!
    this.userService
      .getEndPoint(
        `${this.buildEndPoint("corsany-proxy")}/:http://country.io/names.json`,
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log(
              "Error while fetching the list of country names from third party service! Attempting fallback retreival",
            );
            this.retrieveCountryNameStatus.next(
              this.helperService.retrieveCountryName(iso2Code),
            );
            return;
          }
          // countries names were fetched match iso3 to name
          // to retrieve the flag from https://countryflags.io/
          this.retrieveCountryNameStatus.next(
            this.helperService.retrieveCountryName(
              iso2Code,
              this.helperService.sanitize(response),
            ),
          );
        },
        (error) => {
          console.log(
            "Following error occured while fetching the list of country names from third party service!" +
              " Attempting fallback retreival",
            error,
          );
          this.retrieveCountryNameStatus.next(
            this.helperService.retrieveCountryName(iso2Code),
          );
          return;
        },
      );
  }

  async retrieveAsyncCountryName(iso2Code) {
    if (iso2Code === undefined) {
      console.log("Could not resolve country iso2 code!");
      return;
    }
    return await this.userService
      .getEndPoint(
        `${this.buildEndPoint("corsany-proxy")}/:http://country.io/names.json`,
      )
      .toPromise();
  }

  /**
   * retrieves country flag
   * @param iso2Code string, iso3Code received from JUMIO
   */
  retrieveCountryFlag(iso2Code) {
    if (iso2Code === undefined) {
      console.log("Could not resolve country iso2 code!");
      return;
    }
    // countriy.io is blocked by CORS use fallback HELPER method!
    this.userService
      .getEndPoint(
        `${this.buildEndPoint(
          "corsany-proxy",
        )}/:https://countryflagsapi.com/png/` + iso2Code,
        { responseType: "blob" },
      )
      .pipe(timeout(5000))
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log(
              "Error while fetching the country flag from third party service!",
            );
            return;
          }
          // countries names were fetched match iso2 to name
          this.createFlagImageFromBlob(response as Blob);
        },
        (error) => {
          console.log(
            "Following error occured while fetching the country flag from third party service!",
            error,
          );
          return;
        },
      );
  }

  /**
   * retrieves async country flag
   * @param iso2Code string, iso3Code received from JUMIO
   */
  async retrieveAsyncCountryFlag(iso2Code) {
    if (iso2Code === undefined) {
      console.log("Could not resolve country iso2 code!");
      return;
    }
    // countriy.io is blocked by CORS use fallback HELPER method!
    return await this.userService
      .getEndPoint(
        `${this.buildEndPoint(
          "corsany-proxy",
        )}/:https://countryflagsapi.com/png/` + iso2Code,
        { responseType: "blob" },
      )
      .pipe(timeout(5000))
      .toPromise();
  }

  /**
   * create base64 image string from blob
   * @param image Blob, response returned from countryflags.io
   */
  createFlagImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.retrieveCountryFlagStatus.next({ response: reader.result });
      },
      false,
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  /**
   * generate base64 string from url
   * @param imageUrl string
   * @returns base64 image data
   */
  async getBase64ImageFromUrl(imageUrl: string) {
    if (!imageUrl) {
      return "";
    }
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          resolve(reader.result);
        },
        false,
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  /**
   * display/hide project selector
   */
  displayProjectSelector(status) {
    this.displayProjectSelectorStatus.next(status);
  }

  /**
   *  send a request to pdf generating server
   * @param completePDF flag to let us know wether to download the PDF report or it's jus there to merge in the complete report
   */
  async generateIdVerificationPDF(completePDF?) {
    this.pdf.setPdfGenerationStatus({
      status: "IN_PROGRESS",
      type: "idVerification",
    });
    const observableBatch = [];
    const domain = window.location.hostname;
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    const activeProjectKey = this.getActiveProject();
    const activePersonKey = this.getActivePerson();
    const activeVerificationKey = this.getActiveVerification();
    const activePerson = this.getActivePersons().find(
      (person) => person.key === this.getActivePerson(),
    );
    let skipFaceImage = false;

    if (
      activePerson.record.lastVerification.record.presets &&
      activePerson.record.lastVerification.record.presets.workflowId
    ) {
      if (
        activePerson.record.lastVerification.record.presets.workflowId <= 102
      ) {
        skipFaceImage = true;
      }
    }

    const vfeCode = activeVerificationKey
      ? activeVerificationKey.replace("VFE:", "")
      : "";
    const myAngularxQrCode = `${extendedDomain}/verificationcheck/${vfeCode}`;
    let genQrCode;
    try {
      genQrCode = await QRCode.toDataURL(myAngularxQrCode);
    } catch (err) {
      console.error(err);
    }
    let verificationDetails;
    // ask for verification details
    const vDetails = this.retrieveVerificationDetails(
      activeProjectKey,
      activePersonKey,
      activeVerificationKey,
      true,
    ).pipe(catchError((e) => of({}))); // 'VFE:dd2f5a97-0ccc-4970-a3a2-390071c171e2');
    observableBatch.push(vDetails);

    // ask for verification front image
    const vFront: any = this.loadPersonImage(
      activeProjectKey, // 'PRJ:aae224b8-106f-4c73-bd39-1d9a892b472d',
      "",
      "",
      activePersonKey, // 'VFS:2553ea28-6326-4a68-b7ca-058995b30b36',
      activeVerificationKey, // 'VFE:dd2f5a97-0ccc-4970-a3a2-390071c171e2',
      "front",
      true,
    ).pipe(catchError((e) => of({})));
    observableBatch.push(vFront);

    // ask for verification face image
    if (!skipFaceImage) {
      const vFace = this.loadPersonImage(
        activeProjectKey, // 'PRJ:aae224b8-106f-4c73-bd39-1d9a892b472d',
        "",
        "",
        activePersonKey, // 'VFS:2553ea28-6326-4a68-b7ca-058995b30b36',
        activeVerificationKey, // 'VFE:dd2f5a97-0ccc-4970-a3a2-390071c171e2',
        "face",
        true,
      ).pipe(catchError((e) => of({})));
      observableBatch.push(vFace);
    } else {
      observableBatch.push(of(""));
    }

    return new Promise((resolve, reject) => {
      forkJoin(observableBatch).subscribe(
        async (response) => {
          verificationDetails = this.helperService.sanitize(response[0]);
          let frontImg: any = `${window.location.hostname}/assets/images/pdf-default-01.svg`;
          let faceImg: any = `${window.location.hostname}/assets/images/pdf-default-02.svg`;
          try {
            frontImg = await this.convertBlobToBase64Img(response[1]);
            frontImg = await this.resizeBase64Image(frontImg);
          } catch (e) {
            console.warn(e.message);
          }
          try {
            if (!skipFaceImage) {
              faceImg = await this.convertBlobToBase64Img(response[2]);
              faceImg = await this.resizeBase64Image(faceImg);
              if (completePDF) {
                this.faceImage = faceImg;
              }
            } else {
              let faceImgT: any = `/assets/images/pdf-default-02.png`;
              faceImgT = await this.getBase64ImageFromUrl(faceImgT);
              faceImgT = await this.resizeBase64Image(faceImgT);
              faceImg = faceImgT;
              if (completePDF) {
                this.faceImage = faceImg;
              }
            }
          } catch (e) {
            console.warn(e.message);
          }
          verificationDetails.qrCode = genQrCode;
          // ask for verification back image
          let backImg: any = ``;
          await this.loadPersonImage(
            activeProjectKey, // 'PRJ:aae224b8-106f-4c73-bd39-1d9a892b472d',
            "",
            "",
            activePersonKey, // 'VFS:2553ea28-6326-4a68-b7ca-058995b30b36',
            activeVerificationKey, // 'VFE:dd2f5a97-0ccc-4970-a3a2-390071c171e2',
            "back",
            true,
          )
            .toPromise()
            .then(async (response2) => {
              try {
                backImg = await this.convertBlobToBase64Img(response2);
                backImg = await this.resizeBase64Image(backImg);
              } catch (e) {
                console.warn(e.message);
              }
            })
            .catch((error) => {
              console.log("Back image not associated with the record");
            });

          const pdfData = await this.parseVerificationDetails(
            verificationDetails,
            frontImg,
            faceImg,
            backImg,
            domain,
            vfeCode,
            completePDF,
          );
          resolve(pdfData);
        },
        (error) => {
          // TODO: show a fancy error on FE stating that we couldn't initialize PDF generation
          console.log(error);
          reject("error");
          if (completePDF) {
            this.completePDFDownload.next("error");
          }
        },
      );
    });
  }

  async generatePDFBackend (verificationKey) {
    console.log(this.getActiveVerification());
    this.userService.getEndPoint(
      this.buildEndPoint(
        "generatePDFReportBackend",
        this.getActiveProject(),
        this.getActivePerson(),
        "",
        true,
        verificationKey
      ),
      { responseType: "blob" },
      { observe: "body" },
      "verification",
      ).subscribe((response)=>
      {
        const newBlob = new Blob([response as Blob], {
          type: "application/pdf",
        });


          // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        // downloading the document
        this.helperService.downloadingDocument(data, '');
        $(".pdf-gen-loading span").text("Download PDF Report");
        $(".pdf-gen-loading").removeClass("pdf-gen-loading");
        $(".preview-download-icon").attr("src", "/assets/images/download.svg")
      })
  }

  /**
   *  send a request to pdf generating server
   * @param completePDF flag to let us know wether to download the PDF report or it's jus there to merge in the complete report
   */
  async generateDVIdVerificationPDF(completePDF?, identityGO?) {
    this.pdf.setPdfGenerationStatus({
      status: "IN_PROGRESS",
      type: "idVerification",
    });

    const dvDetails = this.activeDigitalVerificationDetails.response;
    if (!dvDetails) {
      alert(this.translate.instant("errors.error6"));
      console.error(
        "The details about the digital verification are missing",
        dvDetails,
      );
      return;
    }
    const activeVerificationKey = dvDetails.record.verificationKey
      ? dvDetails.record.verificationKey
      : dvDetails.key;
    const vfeCode = activeVerificationKey
      ? activeVerificationKey.replace("VFE:", "").replace("EVD:", "")
      : "";

    const observableBatch = [];
    const domain = window.location.hostname;
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    const myAngularxQrCode = `${extendedDomain}/verificationcheck/${vfeCode}`;

    let genQrCode;
    try {
      genQrCode = await QRCode.toDataURL(myAngularxQrCode);
    } catch (err) {
      console.error(err);
    }
    let verificationDetails;

    return new Promise(async (resolve, reject) => {
      //  forkJoin(observableBatch).subscribe( async response => {
      verificationDetails = this.helperService.sanitize(dvDetails.record);
      const files = this.activeDigitalVerificationDetails.files;
      let frontImg: any = `${window.location.hostname}/assets/images/pdf-default-01.svg`;
      let faceImg: any = `/assets/images/pdf-default-02.png`;
      faceImg = await this.getBase64ImageFromUrl(faceImg);
      faceImg = await this.resizeBase64Image(faceImg);
      let backImg: any = ``;
      await Promise.all(
        files.map(async (file) => {
          // 'data:image/png;base64,'
          try {
            if (
              file.filename[0].includes("face") ||
              file.filename[0].includes("SELFIE_FACE")
            ) {
              faceImg = await this.resizeBase64Image(file.data);
            }
            if (
              file.filename[0].includes("front") ||
              file.filename[0].includes("ID_FRONT")
            ) {
              frontImg = await this.resizeBase64Image(file.data);
            }
            if (
              file.filename[0].includes("back") ||
              file.filename[0].includes("ID_BACK")
            ) {
              backImg = await this.resizeBase64Image(file.data);
            }
          } catch (e) {
            // fail silently and proceed with the original image
            console.warn(e.message);
          }
        }),
      );

      verificationDetails.qrCode = genQrCode;

      let pdfData;
      if (!identityGO) {
        pdfData = await this.parseVerificationDetails(
          verificationDetails,
          frontImg,
          faceImg,
          backImg,
          domain,
          vfeCode,
          completePDF,
        );
      } else {
        pdfData = await this.parseVerificationDetailsGo(
          verificationDetails,
          frontImg,
          faceImg,
          backImg,
          domain,
          vfeCode,
          completePDF,
        );
      }
      resolve(pdfData);
    });
  }

  /**
   * compile content to generate address verification pdf
   * @param completePDF flag to let us know wether to download the PDF report or it's jus there to merge in the complete report
   */
  async generateAddressVerificationPDF(completePDF?) {
    this.pdf.setPdfGenerationStatus({
      status: "IN_PROGRESS",
      type: "addressVerification",
    });

    const observableBatch = [];
    const domain = window.location.hostname;
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    let avFullDetails: any = {};
    const activeProjectKey = this.getActiveProject();
    const activePersonKey = this.getActivePerson();
    const activeVerificationKey = this.getActiveAddressVerification();
    const activeVerificationDetails =
      this.getActiveAddressVerificationDetails(); // will contain most of the details but not all
    if (!activeVerificationDetails) {
      console.error("Could not fetch activeVerificationDetails");
      alert(this.translate.instant("errors.error6"));
      return;
    }
    const vfeCode = activeVerificationKey
      ? activeVerificationKey.replace("VFE:", "")
      : "";
    const myAngularxQrCode = `${extendedDomain}/verificationcheck/${vfeCode}`;
    let genQrCode;
    try {
      genQrCode = await QRCode.toDataURL(myAngularxQrCode);
    } catch (err) {
      console.error(err);
    }
    const avDetails = this.retrieveAddressVerificationDetails(
      activeProjectKey,
      activePersonKey,
      activeVerificationKey,
      true,
    ); // 'VFE:dd2f5a97-0ccc-4970-a3a2-390071c171e2');
    observableBatch.push(avDetails);
    if (activeVerificationDetails.record.documents) {
      activeVerificationDetails.record.documents.map((document) => {
        const avDocuments = this.retrieveAddressVerificationDocument(
          activeProjectKey,
          activePersonKey,
          activeVerificationKey,
          document.documentKey,
          true,
        ); // 'VFE:dd2f5a97-0ccc-4970-a3a2-390071c171e2');
        observableBatch.push(avDocuments);
      });
    }
    return new Promise((resolve, reject) => {
      forkJoin(observableBatch).subscribe(
        async (response) => {
          if (response.length > 0) {
            avFullDetails = response.shift();
            if (!avFullDetails || !avFullDetails.record) {
              reject("error");
              if (completePDF) {
                this.completePDFDownload.next("error");
              }
            }
            avFullDetails = this.helperService.sanitize(avFullDetails.record);
            avFullDetails.qrCode = genQrCode;
            // check if there are documents returned
            const documents = [];
            const mergeDocuments = [];
            if (response.length > 0) {
              response.map(async (document: any, index) => {
                const docType =
                  activeVerificationDetails.record.documents[index].contentType;
                if (docType.includes("image")) {
                  document = `data:${docType};base64,${this.toBase64(
                    document.data,
                  )}`;
                  try {
                    document = await this.resizeBase64Image(document);
                  } catch (e) {
                    // fail silently and proceed with the original image
                    console.warn(e.message);
                  }
                  documents.push({
                    src: document,
                    type: docType,
                  });
                }
                if (docType.includes("pdf")) {
                  mergeDocuments.push({
                    src: document,
                    type: docType,
                  });
                }
              });
            }
            console.log("merged documents", mergeDocuments);
            const pdfData = await this.parseAddressVerificationDetails(
              avFullDetails,
              documents,
              mergeDocuments,
              domain,
              vfeCode,
              true,
              completePDF,
            );
            resolve(pdfData);
          }
        },
        (error) => {
          // TODO: show a fancy error on FE stating that we couldn't initialize PDF generation
          console.log(error);
          reject("error");
          if (completePDF) {
            this.completePDFDownload.next("error");
          }
        },
      );
    });
  }

  /**
   * compile content to generate address verification pdf
   * @param completePDF flag to let us know wether to download the PDF report or it's jus there to merge in the complete report
   */
  async generateDVAddressVerificationPDF(completePDF?) {
    this.pdf.setPdfGenerationStatus({
      status: "IN_PROGRESS",
      type: "addressVerification",
    });

    const observableBatch = [];
    const domain = window.location.hostname;
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    let avFullDetails: any = {};

    const avDetails = this.activeDigitalVerificationDetails.response;
    const activeVerificationKey = avDetails.record.verificationKey;
    const vfeCode = activeVerificationKey
      ? activeVerificationKey.replace("VFE:", "")
      : "";
    const myAngularxQrCode = `${extendedDomain}/verificationcheck/${vfeCode}`;
    let genQrCode;
    try {
      genQrCode = await QRCode.toDataURL(myAngularxQrCode);
    } catch (err) {
      console.error(err);
    }

    return new Promise(async (resolve, reject) => {
      avFullDetails = this.helperService.sanitize(avDetails.record);
      avFullDetails["completedAt"] = avFullDetails.completedOn;
      avFullDetails["createdOn"] = avFullDetails.createOn;
      avFullDetails.qrCode = genQrCode;
      // check if there are documents returned
      const documents = [];
      const mergeDocuments = [];
      if (this.activeDigitalVerificationDetails.files.length > 0) {
        await this.activeDigitalVerificationDetails.files.map(
          async (document: any, index) => {
            console.log(document);
            // const docType = document.headers.filter(x => Object.keys(x).includes('content-type'));
            if (document.type.includes("image")) {
              // document = `data:${docType};base64,${this.toBase64(document.body)}`;
              let file = document.data;
              try {
                file = await this.resizeBase64Image(file);
              } catch (e) {
                // fail silently and proceed with the original image
                console.warn(e.message);
              }
              documents.push({
                src: file,
                type: document.type,
              });
            }
            if (document.type.includes("pdf")) {
              mergeDocuments.push({
                src: document.data,
                type: document.type,
              });
            }
          },
        );
      }
      // console.log('av details used to parse and generate pdf', avFullDetails);
      const pdfData = await this.parseAddressVerificationDetails(
        avFullDetails,
        documents,
        mergeDocuments,
        domain,
        vfeCode,
        true,
        completePDF,
      );
      resolve(pdfData);
    });
  }

  async generateDVContractPdf(details) {
    const link = document.createElement("a");
    const files = details.files;
    files.map((file) => {
      link.href = this.createFile(file.data.data);
      link.target = "_blank";
      link.download = file.filename;
      // document.body.append(link);
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      );
    });

    // in case the Blob uses a lot of memory
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.remove();
    }, 300);
  }

  createFile(file) {
    const newBlob = new Blob([new Uint8Array(file)], {
      type: "application/pdf",
    });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator["msSaveOrOpenBlob"]) {
      window.navigator["msSaveOrOpenBlob"](newBlob);
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    return window.URL.createObjectURL(newBlob);
  }

  convertBlobToBase64Img = (blob) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input blob."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(blob);
    });
  };

  // TODO: resolve bug
  resizeBase64Image(base64Str, MAX_WIDTH = 1600, MAX_HEIGHT = 1600) {
    const img = new Image();
    img.src = base64Str;

    return new Promise((resolve, reject) => {
      img.onerror = () => {
        reject(new DOMException("Problem loading image."));
      };

      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;
        if (
          width === 0 ||
          height === 0 ||
          (width <= MAX_WIDTH && height <= MAX_HEIGHT)
        ) {
          resolve(base64Str);
        }

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL("image/jpeg", 0.8));
      };
    });
  }

  private async parseVerificationDetails(
    verificationDetails: {},
    frontImage,
    faceImage,
    backImg,
    domain,
    vfeCode,
    completePDF?,
  ) {
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    let createdBy = "";
    if (verificationDetails["createdBy"].firstName) {
      createdBy = createdBy + verificationDetails["createdBy"].firstName + " ";
    }
    if (verificationDetails["createdBy"].lastName) {
      createdBy = createdBy + verificationDetails["createdBy"].lastName + " ";
    }
    if (
      !verificationDetails["createdBy"].lastName &&
      !verificationDetails["createdBy"].firstName
    ) {
      createdBy = createdBy + "" + verificationDetails["createdBy"].email + "";
    }

    verificationDetails["createdBy"] = createdBy;
    verificationDetails["document"].status = verificationDetails[
      "document"
    ].status
      .replace(/_/g, " ")
      .toLowerCase();
    if (verificationDetails["document"].type) {
      verificationDetails["document"].type = verificationDetails[
        "document"
      ].type
        .replace(/_/g, " ")
        .toLowerCase();
    }
    verificationDetails["document"].dynamicStatus = "yes";
    verificationDetails["document"].similarityStatus = "N/A";
    verificationDetails["document"].similarityMatch = "N/A";
    verificationDetails["document"].similarityValidity = "FAILED";
    // set this manually to always show OK
    let verificationPresets = verificationDetails["verificationPresets"];
    // if dont have the record loaded, calculate verified:
    let verified = false;
    if (
      verificationDetails["document"].status ===
      "APPROVED_VERIFIED".replace(/_/g, " ").toLowerCase()
    ) {
      if (
        verificationPresets?.workflowId &&
        verificationPresets.workflowId < 200
      ) {
        verified = true;
      } else {
        let identity = verificationDetails["verification"].identityVerification;
        if (identity?.validity?.toString().toUpperCase() === "TRUE") {
          if (identity.similarity?.toUpperCase() === "MATCH") {
            verified = true;
          }
        }
      }
    }
    let faceScanAvailable =
      verificationPresets?.workflowId && verificationPresets.workflowId >= 200;

    if (verified) {
      verificationDetails["document"].similarityStatus = "OK";
      verificationDetails["document"].similarityValidity = "YES";
      if (faceScanAvailable) {
        verificationDetails["document"].similarityMatch = "MATCH";
      }
    } else {
      verificationDetails["document"].rejectReason =
        this.helperService.capitalize(verificationDetails["document"].status);
      verificationDetails["document"].status = "failed";
      verificationDetails["document"].rejectReasonDetails =
        this.getRejectReasonDetails(verificationDetails);
    }
    const iso3Code = verificationDetails["document"].issuingCountry;
    let countryIso2: any;
    // ask for iso2 country code thorugh country.io api
    await this.retrieveAsyncCountryIso(iso3Code)
      .then((response) => {
        if (response === undefined) {
          console.log(
            "Error while fetching the list of country codes from third party service! Attempting fallback retreival",
          );
          countryIso2 = this.helperService.retrieveCountryIso2(iso3Code);
          return;
        }
        countryIso2 = this.helperService.retrieveCountryIso2(
          iso3Code,
          this.helperService.sanitize(response),
        );
      })
      .catch((error) => {
        console.log(
          "Error while fetching the list of country codes from third party service!" +
            " Attempting fallback retreival",
          error,
        );
        countryIso2 = this.helperService.retrieveCountryIso2(iso3Code);
        console.log(countryIso2, iso3Code);
      });
    await this.retrieveAsyncCountryName(countryIso2)
      .then((response) => {
        if (response === undefined) {
          console.log(
            "Error while fetching the list of country names from third party service! Attempting fallback retreival",
          );
          verificationDetails["document"].issuingCountry =
            this.helperService.retrieveCountryName(countryIso2);
          return;
        }
        verificationDetails["document"].issuingCountry =
          this.helperService.retrieveCountryName(
            countryIso2,
            this.helperService.sanitize(response),
          );
      })
      .catch((error) => {
        console.log(
          "Error while fetching the list of country names from third party service!" +
            "Attempting fallback retreival",
        );
        verificationDetails["document"].issuingCountry =
          this.helperService.retrieveCountryName(countryIso2);
      });

    // ask for country flag thorugh countryflags.io api
    await this.retrieveAsyncCountryFlag(countryIso2)
      .then(async (response) => {
        if (response === undefined) {
          console.log(
            "Error while fetching the country flag! Attempting fallback retreival",
          );
          verificationDetails[
            "document"
          ].countryFlag = `${extendedDomain}/assets/images/flags/${countryIso2.toLowerCase()}.svg`;
          console.log(verificationDetails["document"].countryFlag);
          return;
        }
        // countries names were fetched match iso2 to name
        const newBlob = new Blob([response as Blob], {
          type: "application/pdf",
        });
        // this.createFlagImageFromBlob(newBlob);
        verificationDetails["document"].countryFlag =
          await this.convertBlobToBase64Img(newBlob);
        verificationDetails["document"].countryFlag = verificationDetails[
          "document"
        ].countryFlag.slice(0, -2);
      })
      .catch((error) => {
        console.log(
          "Error while fetching the country flag! Attempting fallback retreival",
        );
        verificationDetails[
          "document"
        ].countryFlag = `${extendedDomain}/assets/images/flags/${countryIso2.toLowerCase()}.svg`;
        console.log(verificationDetails["document"].countryFlag);
      });

    verificationDetails["transaction"].date = this.helperService.formatISODate(
      verificationDetails["transaction"].date,
      this.helperService.getPdfFormatDate(),
      false,
    );
    verificationDetails["transaction"].updatedAt =
      this.helperService.formatISODate(
        verificationDetails["transaction"].updatedAt,
        this.helperService.getPdfFormatDate(),
        false,
      );
    this.updatedAt = verificationDetails["transaction"].updatedAt;
    verificationDetails["transaction"].source = verificationDetails[
      "transaction"
    ].source.replace(/_/g, " ");
    verificationDetails["transaction"].merchantScanReference =
      verificationDetails["transaction"].merchantScanReference.replace(
        /VFE:/g,
        " ",
      );
    verificationDetails["completedOn"] = this.helperService.formatISODate(
      this.helperService.getTodayDateTime(),
      this.helperService.getPdfFormatDate(),
      false,
    );
    const pdfScanReference =
      verificationDetails["transaction"].merchantScanReference;
    const pdfDate = this.helperService.formatISODate(
      verificationDetails["transaction"].date,
      "yyyy-MM-dd",
      false,
    );
    const pdfName = `${pdfDate}-${pdfScanReference}.pdf`;
    let stamp: any = `${extendedDomain}/assets/images/stamp.png`;
    await this.getBase64ImageFromUrl(stamp)
      .then((result) => (stamp = result))
      .catch((err) => console.error(err));
    verificationDetails["validitySign"] = stamp;

    const pdfData = await this.pdf.generateIdVerificationPDFext(
      verificationDetails,
      frontImage,
      faceImage,
      backImg,
      domain,
      vfeCode,
      pdfName,
      completePDF,
    );

    return pdfData;
  }
  private async parseVerificationDetailsGo(
    verificationDetails: any,
    frontImage,
    faceImage,
    backImg,
    domain,
    vfeCode,
    completePDF?,
  ) {
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    let createdBy = "";
    if (verificationDetails["createdBy"].firstName) {
      createdBy = createdBy + verificationDetails["createdBy"].firstName + " ";
    }
    if (verificationDetails["createdBy"].lastName) {
      createdBy = createdBy + verificationDetails["createdBy"].lastName + " ";
    }
    if (
      !verificationDetails["createdBy"].lastName &&
      !verificationDetails["createdBy"].firstName
    ) {
      createdBy = createdBy + "" + verificationDetails["createdBy"].email + "";
    }

    verificationDetails["createdBy"] = createdBy;
    verificationDetails["document"] = {};
    verificationDetails["document"].firstName =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.extraction?.data?.firstName ??
      verificationDetails["document"].firstName;
    verificationDetails["document"].lastName =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.extraction?.data?.lastName ??
      verificationDetails["document"].lastName;
    verificationDetails["document"].dob =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.extraction?.data?.dateOfBirth ??
      verificationDetails["document"].dateOfBirth;
    verificationDetails["document"].type =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.extraction?.data?.type ?? verificationDetails["document"].type;
    verificationDetails["document"].documentNumber =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.extraction?.data?.documentNumber ??
      verificationDetails["document"].documentNumber;
    verificationDetails["document"].issuingCountry =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.extraction?.data?.issuingCountry ??
      verificationDetails["document"].issuingCountry;
    verificationDetails["document"].expiry =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.extraction?.data?.expiryDate ??
      verificationDetails["document"].expiryDate;
    verificationDetails["document"].number =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.extraction?.data?.documentNumber ??
      verificationDetails["document"].documentNumber;

    verificationDetails["document"].status =
      verificationDetails["verificationData"]["results"][
        "results"
      ].decision.type;

    // if (verificationDetails['document'].type) {
    //   verificationDetails['document'].type = verificationDetails['document'].type.replace(/_/g, ' ').toLowerCase();
    // }

    verificationDetails["document"].decisionStatus = verificationDetails[
      "verificationData"
    ]["results"]["results"].decision.type
      .replace(/_/g, " ")
      .toLowerCase();
    verificationDetails["document"].dataChecksStatus =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.dataChecks?.decision?.type ??
      verificationDetails["document"].dataChecksStatus;
    verificationDetails["document"].extractionStatus =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.extraction?.decision?.type ??
      verificationDetails["document"].extractionStatus;
    verificationDetails["document"].imageChecksStatus =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.imageChecks?.decision?.type ??
      verificationDetails["document"].imageChecksStatus;
    verificationDetails["document"].livenesStatus =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.liveness?.decision?.type ?? verificationDetails["document"].liveness;
    verificationDetails["document"].similarityStatus =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.similarity?.decision?.type ??
      verificationDetails["document"].similarity;
    verificationDetails["document"].usabilityStatus =
      verificationDetails["verificationData"]?.results?.results?.capabilities
        ?.usability?.decision?.type ??
      verificationDetails["document"].usability;

    const iso3Code = verificationDetails["document"].issuingCountry;
    let countryIso2: any;
    // ask for iso2 country code thorugh country.io api
    await this.retrieveAsyncCountryIso(iso3Code)
      .then((response) => {
        if (response === undefined) {
          console.log(
            "Error while fetching the list of country codes from third party service! Attempting fallback retreival",
          );
          countryIso2 = this.helperService.retrieveCountryIso2(iso3Code);
          return;
        }
        countryIso2 = this.helperService.retrieveCountryIso2(
          iso3Code,
          this.helperService.sanitize(response),
        );
      })
      .catch((error) => {
        console.log(
          "Error while fetching the list of country codes from third party service!" +
            " Attempting fallback retreival",
          error,
        );
        countryIso2 = this.helperService.retrieveCountryIso2(iso3Code);
        console.log(countryIso2, iso3Code);
      });
    await this.retrieveAsyncCountryName(countryIso2)
      .then((response) => {
        if (response === undefined) {
          console.log(
            "Error while fetching the list of country names from third party service! Attempting fallback retreival",
          );
          verificationDetails["document"].issuingCountry =
            this.helperService.retrieveCountryName(countryIso2);
          return;
        }
        verificationDetails["document"].issuingCountry =
          this.helperService.retrieveCountryName(
            countryIso2,
            this.helperService.sanitize(response),
          );
      })
      .catch((error) => {
        console.log(
          "Error while fetching the list of country names from third party service!" +
            "Attempting fallback retreival",
        );
        verificationDetails["document"].issuingCountry =
          this.helperService.retrieveCountryName(countryIso2);
      });

    // ask for country flag thorugh countryflags.io api
    await this.retrieveAsyncCountryFlag(countryIso2)
      .then(async (response) => {
        if (response === undefined) {
          console.log(
            "Error while fetching the country flag! Attempting fallback retreival",
          );
          verificationDetails[
            "document"
          ].countryFlag = `${extendedDomain}/assets/images/flags/${countryIso2.toLowerCase()}.svg`;
          console.log(verificationDetails["document"].countryFlag);
          return;
        }
        // countries names were fetched match iso2 to name
        const newBlob = new Blob([response as Blob], {
          type: "application/pdf",
        });
        // this.createFlagImageFromBlob(newBlob);
        verificationDetails["document"].countryFlag =
          await this.convertBlobToBase64Img(newBlob);
        verificationDetails["document"].countryFlag = verificationDetails[
          "document"
        ].countryFlag.slice(0, -2);
      })
      .catch((error) => {
        console.log(
          "Error while fetching the country flag! Attempting fallback retreival",
        );
        verificationDetails[
          "document"
        ].countryFlag = `${extendedDomain}/assets/images/flags/${countryIso2.toLowerCase()}.svg`;
        console.log(verificationDetails["document"].countryFlag);
      });

    verificationDetails["transaction"] = {};
    verificationDetails["transaction"].date = this.helperService.formatISODate(
      verificationDetails["verificationData"]["results"]["results"].startedAt,
      this.helperService.getPdfFormatDate(),
      false,
    );
    verificationDetails["transaction"].updatedAt =
      this.helperService.formatISODate(
        verificationDetails["verificationData"]["results"]["results"]
          .completedAt,
        this.helperService.getPdfFormatDate(),
        false,
      );
    this.updatedAt =
      verificationDetails["verificationData"]["results"]["results"].completedAt;
    verificationDetails["completedOn"] = this.helperService.formatISODate(
      this.helperService.getTodayDateTime(),
      this.helperService.getPdfFormatDate(),
      false,
    );
    let pdfScanReference =
      verificationDetails["transaction"].merchantScanReference;
    if (!pdfScanReference) {
      pdfScanReference = "";
    }
    const pdfDate = this.helperService.formatISODate(
      verificationDetails["transaction"].date,
      "yyyy-MM-dd",
      false,
    );
    const pdfName = `${pdfDate}-${pdfScanReference}.pdf`;
    let stamp: any = `${extendedDomain}/assets/images/stamp.png`;
    await this.getBase64ImageFromUrl(stamp)
      .then((result) => (stamp = result))
      .catch((err) => console.error(err));
    verificationDetails["validitySign"] = stamp;

    const pdfData = await this.pdf.generateIdVerificationPDFext(
      verificationDetails,
      frontImage,
      faceImage,
      backImg,
      domain,
      vfeCode,
      pdfName,
      completePDF,
      true,
    );

    return pdfData;
  }

  async DownloadCDDFiles(requestKey) {
    if (!requestKey) {
      this.completePDFDownload.next("error");
      return;
    }
    const projectKey = this.getActiveProject();
    let keyData;
    keyData = {};
    this.userService
      .getEndPoint(
        this.buildEndPoint("investorDocuments", projectKey, requestKey),
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          const observableBatchDocuments = [];
          Object.entries(response).forEach(([IRkey, IRObject], index) => {
            // per request
            if (IRObject && Object.keys(IRObject).length > 0) {
              keyData[IRkey] = IRObject.name;
              Object.entries(IRObject.evidence).forEach(
                ([EVDKey, EVDObject], index) => {
                  // per evidence
                  (EVDObject["documents"] as Array<any>).forEach((DOCKEY) => {
                    keyData[EVDKey] = EVDObject["type"];
                    observableBatchDocuments.push(
                      this.userService.getEndPoint(
                        this.buildEndPoint(
                          "getDocumentsFile",
                          projectKey,
                          IRkey,
                          "",
                          true,
                          EVDKey,
                          DOCKEY,
                        ),
                        { responseType: "json" },
                        { observe: "response" },
                        "funds",
                      ),
                    );
                  });
                },
              );
            }
          });
          if (observableBatchDocuments.length) {
            concat(...observableBatchDocuments)
              .pipe(
                tap((docResponse) => {
                  this.downloadCDDImage(docResponse, keyData);
                }),
              )
              .subscribe(
                (responses) => {
                  this.completePDFDownload.next("success");
                },
                (error) => {
                  // alert(error.error.message);
                  console.log(error);
                  this.completePDFDownload.next("error");
                },
              );
          } else {
            this.completePDFDownload.next("empty");
          }
        },
        (error) => {
          this.completePDFDownload.next("error");
          return;
        },
      );
  }

  downloadCDDImage(docResponse, keyData) {
    let data;
    if (docResponse["headers"].get("content-type") === "application/pdf") {
      const bufferArray = new Uint8Array(docResponse["body"].data);
      const blob = new Blob([bufferArray], { type: "application/pdf" });
      data = window.URL.createObjectURL(blob);
    } else {
      data =
        "data:application/png;base64," +
        this.toBase64(docResponse["body"].data);
      this.completeIframeData.next({
        data: data,
        documentEvidence: docResponse,
      });
    }
    let filename = this.getFileNameFromURL(docResponse.url, keyData);
    filename += "--";
    filename += this.getFileNameFromURLType(docResponse.url, keyData);
    filename = filename + "--" + docResponse["headers"].get("filename");
    // done
    filename = this.helperService.generateDocumentFormat(docResponse, filename);
    // downloading the document
    this.helperService.downloadingDocument(data, filename);
  }

  getFileNameFromURLType(url, keyData) {
    let key = url.match(/EVD(.*?)\//i);
    if (key) {
      key = key[0];
      key = key.substring(0, key.length - 1);
      return keyData[key];
    }
    return "";
  }

  getFileNameFromURL(url, keyData) {
    let key = url.match(/IRE(.*?)\//i);
    if (key) {
      key = key[0];
    } else {
      key = url.match(/RPE(.*?)\//i)[0];
    }
    key = key.substring(0, key.length - 1);
    return keyData[key];
  }

  async downloadCompletePDF() {
    // Summary variables
    // initiate face image to default if there is id verification it will be changed during the process of PDF generation
    this.faceImage = `${window.location.hostname}/assets/images/pdf-default-02.svg`;
    // initiate address data to default if there is address verification it will be changed during the pdf generation
    this.addressData = {};
    this.updatedAt = undefined;
    const summaryVerificationArray = [];
    const activePerson = this.getActivePersons().find(
      (person) => person.key === this.getActivePerson(),
    );
    if (!activePerson) {
      this.completePDFDownload.next("error");
      return;
    }
    const individualPDFArray = [];
    // check if there's active ID verification
    if (activePerson.record && activePerson.record.lastVerification) {
      // all the verification statuses for which there can be a PDF
      const suitableVerificationStatuses =
        this.helperService.getSuitableIDVerificationStatuses();
      // check if the verification status is suitable for PDF verification
      if (
        suitableVerificationStatuses.includes(
          activePerson.record.lastVerification.record.status,
        )
      ) {
        this.setActiveVerification(activePerson.record.lastVerification.key);
        let pdfVerificationData;
        pdfVerificationData = await this.generateIdVerificationPDF(true);
        let missingTemplate = null;
        if (pdfVerificationData === "error") {
          this.completePDFDownload.next("attemptNext");
          missingTemplate = await this.insertMissingPdfTemplate(
            "identity verification",
          );
          individualPDFArray.push(await PDFDocument.load(missingTemplate));
        } else if (!missingTemplate) {
          summaryVerificationArray.push({
            verificationName: "Identity verification",
            verificationStatus:
              activePerson.record.lastVerification.record.status.toLowerCase(),
            verificationLabel:
              activePerson.record.lastVerification.record.status.toLowerCase() ===
              "verified"
                ? "Verified on "
                : "Failed on ",
            verificationDate: this.updatedAt,
          });
          individualPDFArray.push(await PDFDocument.load(pdfVerificationData));
        }
      }
    }
    if (activePerson.record && activePerson.record.lastAddressVerification) {
      // all the verification statuses for which there can be a PDF
      const suitableVerificationStatuses =
        this.helperService.getSuitableAddressVerificationStatuses();
      // check if the verification status is suitable for PDF verification
      if (
        suitableVerificationStatuses.includes(
          activePerson.record.lastAddressVerification.record.status,
        )
      ) {
        this.setActiveAddressVerification(
          activePerson.record.lastAddressVerification.key,
        );
        let pdfAddressData;
        pdfAddressData = await this.generateAddressVerificationPDF(true);
        let missingTemplate = null;
        if (pdfAddressData === "error") {
          this.completePDFDownload.next("attemptNext");
          missingTemplate = await this.insertMissingPdfTemplate(
            "address verification",
          );
          individualPDFArray.push(await PDFDocument.load(missingTemplate));
        } else if (!missingTemplate) {
          summaryVerificationArray.push({
            verificationName: "Address verification",
            verificationStatus:
              activePerson.record.lastAddressVerification.record.status.toLowerCase(),
            verificationLabel:
              activePerson.record.lastAddressVerification.record.status ===
              "ACTION_REQUIRED"
                ? "Action required"
                : activePerson.record.lastAddressVerification.record.status ===
                  "FAILED"
                ? "Rejected on "
                : "Verified on ",
            verificationDate: activePerson.record.lastAddressVerification.record
              .letterSentOn
              ? this.helperService.formatISODate(
                  activePerson.record.lastAddressVerification.record
                    .lastModified,
                  this.helperService.getPdfFormatDate(),
                  false,
                )
              : this.helperService.formatISODate(
                  activePerson.record.lastAddressVerification.record
                    .completedAt,
                  this.helperService.getPdfFormatDate(),
                  false,
                ),
          });
          individualPDFArray.push(await PDFDocument.load(pdfAddressData));
        }
      }
    }
    if (activePerson.record && activePerson.record.lastContractVerification) {
      // all the verification statuses for which there can be a PDF
      const suitableVerificationStatuses =
        this.helperService.getSuitableContractVerificationStatuses();
      // check if the verification status is suitable for PDF verification
      if (
        suitableVerificationStatuses.includes(
          activePerson.record.lastContractVerification.record.status,
        )
      ) {
        let pdfContractData;
        pdfContractData = await this.generateContractPreview(
          this.getActiveProject(),
          this.getActivePerson(),
          activePerson.record.lastContractVerification.key,
          activePerson.record.lastContractVerification.record.documentKeys,
          true,
        );
        let missingTemplate = null;
        if (pdfContractData === "error") {
          this.completePDFDownload.next("attemptNext");
          missingTemplate = await this.insertMissingPdfTemplate("contract");
          individualPDFArray.push(await PDFDocument.load(missingTemplate));
        } else if (!missingTemplate) {
          for (let i = 0; i < pdfContractData.length; i++) {
            summaryVerificationArray.push({
              verificationName: "Contract",
              verificationStatus:
                activePerson.record.lastContractVerification.record.status.toLowerCase(),
              verificationLabel: "Contract signed on ",
              verificationDate: this.helperService.formatISODate(
                activePerson.record.lastContractVerification.record.completedAt,
                this.helperService.getPdfFormatDate(),
                false,
              ),
            });
            individualPDFArray.push(
              await PDFDocument.load(pdfContractData[i].data),
            );
          }
        }
      }
    }
    if (activePerson.record && activePerson.record.lastApsVerification) {
      // all the verification statuses for which there can be a PDF
      const suitableVerificationStatuses =
        this.helperService.getSuitableAPSVerificationStatuses();
      // check if the verification status is suitable for PDF verification
      if (
        suitableVerificationStatuses.includes(
          activePerson.record.lastApsVerification.record.status,
        )
      ) {
        // FOR APS FIRST NEED TO GET THE DETAILS FROM THE CALL
        const apsDetails = await this.getAps(
          this.getActiveProject(),
          this.getActivePerson(),
          activePerson.record.lastApsVerification.key,
          true,
        );
        // THEN WE NEED TO PARSE THEM
        const parsedAPSDetails = await this.parseAPSData(apsDetails);
        const parsedAPSDetailsPDF =
          await this.parseApsDataPdf(parsedAPSDetails);
        summaryVerificationArray.push({
          verificationName: "Adverse media",
          verificationStatus:
            parsedAPSDetailsPDF.verificationStatuses[
              "adverse-media"
            ].status.toLowerCase(),
          verificationDate:
            parsedAPSDetailsPDF.verificationStatuses["adverse-media"].subtitle,
        });
        summaryVerificationArray.push({
          verificationName: "PEP",
          verificationStatus:
            parsedAPSDetailsPDF.verificationStatuses.pep.status.toLowerCase(),
          verificationDate:
            parsedAPSDetailsPDF.verificationStatuses.pep.subtitle,
        });
        summaryVerificationArray.push({
          verificationName: "Sanctions",
          verificationStatus:
            parsedAPSDetailsPDF.verificationStatuses.sanctions.status.toLowerCase(),
          verificationDate:
            parsedAPSDetailsPDF.verificationStatuses.sanctions.subtitle,
        });
        // THEN WE NEED TO GENERAT THE PDF
        const parsedAPSPDF = await this.pdf.generatePDF(
          "apsUpdated",
          this.helperService.formatISODate(
            new Date().toISOString(),
            "yyyy-MM-dd",
            false,
          ) + activePerson.record.lastApsVerification.key,
          JSON.stringify(parsedAPSDetailsPDF),
          "",
          true,
        );
        let missingTemplate = null;
        if (parsedAPSPDF === "error") {
          this.completePDFDownload.next("attemptNext");
          missingTemplate = await this.insertMissingPdfTemplate("APS");
          individualPDFArray.push(await PDFDocument.load(missingTemplate));
        } else if (!missingTemplate) {
          let parsedPDF;
          parsedPDF = parsedAPSPDF;
          individualPDFArray.push(await PDFDocument.load(parsedPDF));
        }
      }
    }

    if (individualPDFArray.length > 0) {
      // Need to prepare data for the summary page
      const createSummaryPage = true;
      if (createSummaryPage) {
        // GENERATED BY
        const userInfo = this.userService.getUserInfo();
        let createdBy;
        if (userInfo) {
          createdBy = userInfo["username"];
        }
        // PERSON INFO
        const personalInfo = {
          firstName: activePerson.record.firstName,
          lastName: activePerson.record.lastName,
          dateOfBirth: this.helperService.formatISODate(
            activePerson.record.dateOfBirth,
            "yyy.MM.dd",
            false,
          ),
        };
        // COMPLETED ON
        const completedOn = this.helperService.formatISODate(
          this.helperService.getTodayDateTime(),
          this.helperService.getPdfFormatDate(),
          false,
        );
        const extendedDomain =
          `${location.protocol}//` +
          window.location.hostname +
          (!location.port ? "" : `:${location.port}`);
        const domain =
          window.location.hostname +
          (!location.port ? "" : `:${location.port}`);
        const myAngularxQrCode = `${extendedDomain}/verificationcheck/`;
        let genQrCode;
        try {
          genQrCode = await QRCode.toDataURL(myAngularxQrCode);
        } catch (err) {
          console.error(err);
        }
        let stamp: any = `${extendedDomain}/assets/images/stamp.png`;
        await this.getBase64ImageFromUrl(stamp)
          .then((result) => (stamp = result))
          .catch((err) => console.error(err));
        const jsonData = {
          customOptions: {
            topMargin: "95px",
          },
          requestedByText: "Report generated by:",
          documentName: "Summary",
          createdBy: createdBy,
          completedOn: completedOn,
          faceImage:
            this.faceImage !== "localhost/assets/images/pdf-default-02.svg"
              ? this.faceImage
              : "",
          personalInfo: personalInfo,
          summaryVerificationArray: summaryVerificationArray,
          addressData:
            Object.keys(this.addressData).length > 0 ? this.addressData : "",
          vfeCode: "Using any of the codes in the subsequent pages",
          validitySign: stamp,
          extendedDomain: extendedDomain,
          domain: domain,
          qrCode: genQrCode,
        };
        // await summary pdf generation, true must be the last argument
        const pdfName =
          this.helperService.formatISODate(
            new Date().toISOString(),
            "yyyy-MM-dd",
            false,
          ) + activePerson.key.replace("VFS:", "_");
        const summaryPDF = await this.pdf.generatePDF(
          "summaryPage",
          pdfName,
          JSON.stringify(jsonData),
          "",
          true,
        );
        let missingTemplate = null;
        if (summaryPDF === "error") {
          this.completePDFDownload.next("attemptNext");
          missingTemplate = await this.insertMissingPdfTemplate("summary");
          individualPDFArray.push(await PDFDocument.load(missingTemplate));
        } else if (!missingTemplate) {
          let parsedPDF;
          parsedPDF = summaryPDF;
          // add to individualPDFArray (unshift)
          individualPDFArray.unshift(await PDFDocument.load(parsedPDF));
        }
      }

      const mergedPdf = await PDFDocument.create();
      for (let i = 0; i < individualPDFArray.length; i++) {
        let copiedPages = null;
        if (typeof individualPDFArray[i].getPageIndices === "function") {
          copiedPages = await mergedPdf.copyPages(
            individualPDFArray[i],
            individualPDFArray[i].getPageIndices(),
          );
        } else {
          copiedPages = await mergedPdf.copyPages(individualPDFArray[i], [0]);
        }
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }
      // Serialize the PDFDocument to bytes (a Uint8Array)
      const mergedPdfFile = await mergedPdf.save();

      // DOWNLOAD FUNCTION
      const link = document.createElement("a");
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(new Blob([mergedPdfFile]));
      link.download =
        this.helperService.formatISODate(
          new Date().toISOString(),
          "yyyy-MM-dd",
          false,
        ) +
        this.getActivePerson() +
        ".pdf";
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      );
      // in case the Blob uses a lot of memory
      setTimeout(() => {
        URL.revokeObjectURL(link.href);
        link.remove();
      }, 100);
      // give signal that the generating is a success
      this.completePDFDownload.next("success");
      // reset face image in case it takes space
      this.faceImage = `${window.location.hostname}/assets/images/pdf-default-02.svg`;
      // reset address data in case it takes space
      this.addressData = {};
      this.updatedAt = undefined;
    } else {
      this.completePDFDownload.next("error");
    }
  }

  async downloadCompletePDFBackend() {
    this.userService.getEndPoint(
      this.buildEndPoint(
        "generateFullPDFReportBackend",
        this.getActiveProject(),
        this.getActivePerson()
      ),
      { responseType: "blob" },
      { observe: "body" },
      "verification",
      ).subscribe((response)=>
      {
        const newBlob = new Blob([response as Blob], {
          type: "application/pdf",
        });


          // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        // downloading the document
        this.helperService.downloadingDocument(data, '');
        this.completePDFDownload.next("success");

      }, () => {
        this.completePDFDownload.next("error");
      })
  }

  /**
   * prepare a missing pdf template to be inserted in the full PDF report
   * @param templateName string human readable name for the template that is missing
   */
  async insertMissingPdfTemplate(templateName: string) {
    const jsonData = {
      documentName: "Missing Report",
      customOptions: true,
      missingTemplateName: templateName,
    };
    return await this.pdf.generatePDF(
      "missingTemplate",
      "missing_template",
      JSON.stringify(jsonData),
      "",
      true,
    );
  }

  /**
   * parse address verification details and generate jsonData
   * @param generatePDF - lets us know wether to generate the PDF or to return the parsed data (currently used for displaying fields)
   * @returns void
   */
  async parseAddressVerificationDetails(
    verificationDetails: any,
    documents: any[],
    mergeDocuments: any[],
    domain,
    vfeCode,
    generatePDF = true,
    completePDF?,
    ignoreIdin?
  ) {

    if (!verificationDetails.verificationData) {
      if (!verificationDetails.idinResults) {
        // it is expected not to have verificationData, but only if there are idinResults present,
        // otherwise the verification data is a must
        console.error(
          "verificationData must be present for standard Address Verification!",
        );
        if (completePDF) {
          this.completePDFDownload.next("error");
        }
        return;
      }
    }
    let idinAR = null;
    if (verificationDetails.idinResults) {
      idinAR = verificationDetails.idinResults.addressResponse;
      if(verificationDetails.idinResults && !idinAR) {
        idinAR = verificationDetails.idinResults.addressData;
      }
    }
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    console.log("###########EXTENDED DOMAIN", extendedDomain);
    const finalizedAt = verificationDetails.verificationProcessCompletedAt
      ? this.helperService.formatISODate(
          verificationDetails.verificationProcessCompletedAt,
          this.helperService.getPdfFormatDate(),
          false,
        )
      : verificationDetails.completedAt
      ? this.helperService.formatISODate(
          verificationDetails.completedAt,
          this.helperService.getPdfFormatDate(),
          false,
        )
      : "";
    const pdfUseDate = verificationDetails.completedAt
      ? verificationDetails.completedAt
      : verificationDetails.lastModified;
    let pdfDate = this.helperService.formatISODate(
      pdfUseDate,
      "yyyy-MM-dd",
      false,
    ); //  | date:'yyyy-MM-dd";
    if (verificationDetails.idinResults) {
      pdfDate = this.helperService.formatISODate(
        pdfUseDate,
        "yyyy-MM-dd",
        false,
      ); //  | date:'yyyy-MM-dd";
    }
    const pdfName = `${pdfDate}-${vfeCode}.pdf`;
    let createdBy = "";
    if (verificationDetails["createdBy"]) {
      if (verificationDetails["createdBy"].firstName) {
        createdBy =
          createdBy + verificationDetails["createdBy"].firstName + " ";
      }
      if (verificationDetails["createdBy"].lastName) {
        createdBy = createdBy + verificationDetails["createdBy"].lastName + " ";
      }
      if (
        !verificationDetails["createdBy"].lastName &&
        !verificationDetails["createdBy"].firstName
      ) {
        createdBy =
          createdBy + "" + verificationDetails["createdBy"].email + "";
      }
    }
    if (createdBy === "") {
      createdBy = "N/A";
    }
    // new case for digital verification
    if (!verificationDetails["createdBy"] && verificationDetails.requestedBy) {
      createdBy = verificationDetails.requestedBy;
    }
    // silently acquire the lat and lng for provided address
    let providedPlace: any = null;
    let providedLatLng = null;
    if (
      verificationDetails.verificationData &&
      !verificationDetails.idinResults
    ) {
      try {
        const addressData = verificationDetails.verificationData.addressData;
        const geocodeAddress =
          addressData.Address +
          (!!addressData.City ? `, ${addressData.City}` : "") +
          (!!addressData.ZipCode ? `, ${addressData.ZipCode}` : "") +
          (!!addressData.Country ? `, ${addressData.Country}` : "");
        // providedPlace = await this.ipService.geocodeFromAddress(verificationDetails.verificationData.addressData.Address);
        providedPlace = await this.ipService.geocodeFromAddress(geocodeAddress);
        if (!!providedPlace[0]) {
          providedLatLng = this.ipService.getAddrLocation(
            providedPlace[0],
            "Object",
          );
        }
      } catch (error) {
        // throw an error
        console.error(
          "We were not able to extract geocoding data from the address provided!",
          error,
        );
      }
    }

    // silently acquire the geolocated address:
    let locatedPlaces: any = null;
    let locatedPlace = null;
    let distanceInfo = null;
    if (
      verificationDetails.verificationData &&
      !verificationDetails.idinResults
    ) {
      try {
        locatedPlaces = await this.ipService.geocodeFromLatLng(
          verificationDetails.verificationData.geoLocationData?.geolocation
            .coords.latitude,
          verificationDetails.verificationData.geoLocationData?.geolocation
            .coords.longitude,
        );
        locatedPlace = locatedPlaces[0];
        if (locatedPlace && locatedPlace[0] && !!locatedPlace[0]) {
          locatedPlace = locatedPlace[0];
        }
        // silently acquire distanse information
        if (
          providedLatLng &&
          providedLatLng.lat &&
          providedLatLng.lng &&
          verificationDetails.verificationData.geoLocationData &&
          verificationDetails.verificationData.geoLocationData?.geolocation
            .coords.latitude &&
          verificationDetails.verificationData.geoLocationData?.geolocation
            .coords.longitude
        ) {
          distanceInfo = await this.ipService.calculateAerialDistanceMatrix(
            `${providedLatLng.lat},${providedLatLng.lng}`,
            `${verificationDetails.verificationData.geoLocationData.geolocation.coords.latitude},${verificationDetails.verificationData.geoLocationData.geolocation.coords.longitude}`,
          );
        }
      } catch (error) {
        // throw an error
        console.error(
          "We were not able to extract geocoding data from the address detected!",
          error,
        );
      }
    } else {
      // try to resolve located address info from iDIN results
      try {
        locatedPlaces = await this.ipService.geocodeFromAddress(
          `${idinAR.street} ${idinAR.houseNumber}, ${idinAR.postalCode}, ${idinAR.city}`,
        );
        let locatedLatLng = null;
        if (!!locatedPlaces[0]) {
          locatedPlace = locatedPlaces[0];
          locatedLatLng = this.ipService.getAddrLocation(
            locatedPlace,
            "Object",
          );
        }
        // silently acquire distanse information
        if (
          providedLatLng &&
          providedLatLng.lat &&
          providedLatLng.lng &&
          locatedLatLng &&
          locatedLatLng.lat &&
          locatedLatLng.lng
        ) {
          distanceInfo = await this.ipService.calculateAerialDistanceMatrix(
            `${providedLatLng.lat},${providedLatLng.lng}`,
            `${locatedLatLng.lat},${locatedLatLng.lng}`,
          );
        }
      } catch (error) {
        // throw an error
        console.error(
          "We were not able to extract geocoding data from the address detected!",
          error,
        );
      }
    }

    // silently acquire distance static map
    let distanceMap: any = null;
    // standard AV
    if (
      verificationDetails.verificationData &&
      !verificationDetails.idinResults
    ) {
      try {
        if (
          providedLatLng &&
          providedLatLng.lat &&
          providedLatLng.lng &&
          verificationDetails.verificationData.geoLocationData &&
          verificationDetails.verificationData.geoLocationData.geolocation
            .coords.latitude &&
          verificationDetails.verificationData.geoLocationData.geolocation
            .coords.longitude
        ) {
          distanceMap = this.ipService.getStaticMapDistanceImage(
            `${providedLatLng.lat},${providedLatLng.lng}`,
            `${verificationDetails.verificationData.geoLocationData?.geolocation.coords.latitude},${verificationDetails.verificationData.geoLocationData?.geolocation.coords.longitude}`,
          );
        } else {
          distanceMap = this.ipService.getStaticMapmage(
            `${verificationDetails.verificationData.geoLocationData?.geolocation.coords.latitude},${verificationDetails.verificationData.geoLocationData?.geolocation.coords.longitude}`,
          );
        }

        await this.getBase64ImageFromUrl(distanceMap)
          .then((result) => (distanceMap = result))
          .catch((err) => console.error(err));
      } catch (error) {
        // throw an error
        console.error("We were not able to get a distance map image!", error);
      }
    }

    // iDIN AV
    if (verificationDetails.idinResults) {
      const detectedLat = this.ipService.getAddrLocation(
        locatedPlace,
        "Object",
      )["lat"];
      const detectedLng = this.ipService.getAddrLocation(
        locatedPlace,
        "Object",
      )["lng"];
      try {
        if (detectedLat && detectedLng) {
          if (providedLatLng && providedLatLng.lat && providedLatLng.lng) {
            distanceMap = this.ipService.getStaticMapDistanceImage(
              `${providedLatLng.lat},${providedLatLng.lng}`,
              `${detectedLat},${detectedLng}`,
            );
          } else {
            distanceMap = this.ipService.getStaticMapmage(
              `${detectedLat},${detectedLng}`,
              "A",
            );
          }

          await this.getBase64ImageFromUrl(distanceMap)
            .then((result) => (distanceMap = result))
            .catch((err) => console.error(err));
        }
      } catch (error) {
        // throw an error
        console.error("We were not able to get a distance map image!", error);
      }
    }

    // silently acquire ip info map
    let ipGeoInfo = null;
    let ipMap: any = null;
    if (
      verificationDetails.verificationData &&
      verificationDetails.verificationData.geoLocationData
    ) {
      ipGeoInfo =
        verificationDetails.verificationData.geoLocationData.ipGeolocationData
          .ipGeoInfo;
      try {
        ipMap = this.ipService.getStaticMapIpImage(
          `${ipGeoInfo.location.lat},${ipGeoInfo.location.lng}`,
        );
        await this.getBase64ImageFromUrl(ipMap)
          .then((result) => (ipMap = result))
          .catch((err) => console.error(err));
      } catch (error) {
        console.error("We were not able to get a ipMap image!", error);
        if (completePDF) {
          this.completePDFDownload.next("error");
        }
      }
    }

    let stamp: any = `${extendedDomain}/assets/images/stamp.png`;
    try {
      await this.getBase64ImageFromUrl(stamp)
        .then((result) => (stamp = result))
        .catch((err) => console.error(err));
    } catch (error) {
      console.error("We were not able to get stamp image!", error);
      if (completePDF) {
        this.completePDFDownload.next("error");
      }
    }
    // TODO: calculate confidence level
    //mmarker2

    if (!verificationDetails.verificationData.addressData) {
      verificationDetails.verificationData.addressData = {};
    }

    const jsonData = {
      documentName: "Address verification",
      customOptions: {
        topMargin: "95px",
      },
      verificationPresets: verificationDetails.verificationPresets,
      results: verificationDetails.verificationData?.results,
      createdBy: createdBy,
      generatedOn: this.helperService.formatISODate(
        this.helperService.getTodayDateTime(),
        this.helperService.getPdfFormatDate(),
        false,
      ),
      completedOn: verificationDetails.completedAt
        ? this.helperService.formatISODate(
            verificationDetails.completedAt,
            this.helperService.getPdfFormatDate(),
            false,
          )
        : this.helperService.formatISODate(
            verificationDetails.lastModified,
            this.helperService.getPdfFormatDate(),
            false,
          ),
      domain: domain,
      extendedDomain: extendedDomain,
      vfeCode: vfeCode,
      iDIN: !verificationDetails.idinResults ? false : true,
      requestedOn: this.helperService.formatISODate(
        verificationDetails.createdOn,
        this.helperService.getPdfFormatDate(),
        false,
      ),
      providedBy: !verificationDetails.verificationPresets.addressRequested
        ? "Service Provider"
        : "Verification Subject",
      providedAddress:
        verificationDetails.verificationData && !verificationDetails.idinResults
          ? {
              address: !!verificationDetails.verificationData.addressData
                .Address
                ? verificationDetails.verificationData.addressData.Address
                : "N/A",
              city: !!verificationDetails.verificationData.addressData.City
                ? verificationDetails.verificationData.addressData.City
                : "N/A",
              country: !!verificationDetails.verificationData.addressData
                .Country
                ? verificationDetails.verificationData.addressData.Country
                : "N/A",
              zipCode: !!verificationDetails.verificationData.addressData
                .ZipCode
                ? verificationDetails.verificationData.addressData.ZipCode
                : "N/A",
              region: !!verificationDetails.verificationData.addressData.Region
                ? verificationDetails.verificationData.addressData.Region
                : "N/A",
              lat: providedLatLng ? providedLatLng.lat.toFixed(6) : "N/A",
              lng: providedLatLng ? providedLatLng.lng.toFixed(6) : "N/A",
              addressRequestedBy: "Device location",
            }
          : null,
      locatedAddress: !verificationDetails.idinResults
        ? {
            address: !!locatedPlace
              ? `${this.ipService.getAddress(locatedPlace)}`
              : "N/A",
            city: !!locatedPlace ? this.ipService.getCity(locatedPlace) : "N/A",
            country: !!locatedPlace
              ? this.ipService.getCountry(locatedPlace)
              : "N/A",
            zipCode: !!locatedPlace
              ? this.ipService.getPostCode(locatedPlace)
              : "N/A",
            region: !!locatedPlace
              ? this.ipService.getRegion(locatedPlace)
              : "N/A",
            lat: verificationDetails.verificationData.geoLocationData?.geolocation.coords.latitude.toFixed(
              6,
            ),
            lng: verificationDetails.verificationData.geoLocationData?.geolocation.coords.longitude.toFixed(
              6,
            ),
            device: !!verificationDetails.verificationData.geoLocationData
              ?.device
              ? verificationDetails.verificationData.geoLocationData?.device
              : "N/A",
            browser: !!verificationDetails.verificationData.geoLocationData
              ?.browser
              ? verificationDetails.verificationData.geoLocationData?.browser
              : "N/A",
            addressRequestedBy: "Device location",
          }
        : {
            address: idinAR
              ? `${idinAR.street} ${
                  idinAR.houseNumber ? ", " + idinAR.houseNumber : ""
                }`
              : !!locatedPlace
              ? `${this.ipService.getAddress(locatedPlace)}`
              : "N/A",
            city: !!locatedPlace ? this.ipService.getCity(locatedPlace) : "N/A",
            country: !!locatedPlace
              ? this.ipService.getCountry(locatedPlace)
              : "N/A",
            zipCode: idinAR
              ? idinAR.postalCode
              : !!locatedPlace && this.ipService.getPostCode(locatedPlace)
              ? this.ipService.getPostCode(locatedPlace)
              : "N/A",
            region:
              !!locatedPlace && this.ipService.getRegion(locatedPlace)
                ? this.ipService.getRegion(locatedPlace)
                : "N/A",
            lat: !!locatedPlace
              ? this.ipService
                  .getAddrLocation(locatedPlace, "Object")
                  ["lat"].toFixed(6)
              : "N/A",
            lng: !!locatedPlace
              ? this.ipService
                  .getAddrLocation(locatedPlace, "Object")
                  ["lng"].toFixed(6)
              : "N/A",
            device: "N/A",
            browser: "N/A",
            addressRequestedBy: "iDIN",
          },
      distanceInfo: {
        distanceImg: distanceMap ? distanceMap.slice(0, -2) : "",
        // distance: distanceInfo.rows[0].elements[0].distance,  // { text: '0,3km', value: 312 }
        // duration: distanceInfo.rows[0].elements[0].duration   // { text: "2 mins", value: 134 }
        distance: distanceInfo,
      },
      ipInfo: ipGeoInfo
        ? {
            ipImg: !!ipMap ? ipMap.slice(0, -2) : null,
            internetProvider: !!ipGeoInfo.isp ? ipGeoInfo.isp : "N/A",
            country: !!ipGeoInfo.location.country
              ? ipGeoInfo.location.country
              : "N/A",
            city: !!ipGeoInfo.location.city ? ipGeoInfo.location.city : "N/A",
            proxy: !!ipGeoInfo.proxy.proxy ? "NOK" : "OK",
            vpn: !!ipGeoInfo.proxy.vpn ? "NOK" : "OK",
            darkWeb: !!ipGeoInfo.proxy.tor ? "NOK" : "OK",
            ipAddress: ipGeoInfo.ip,
          }
        : null,
      documents: documents,
      qrCode: verificationDetails.qrCode,
      validitySign: stamp,
      isRejected: verificationDetails.status === "FAILED" ? true : false,
      finalizedBy: verificationDetails.finalizedBy
        ? verificationDetails.finalizedBy
        : undefined,
      finalizedAt: finalizedAt,
      finalizeComment: verificationDetails.finalizeComment
        ? verificationDetails.finalizeComment
        : verificationDetails.finalized?.comment,
      status: verificationDetails.status,
      geolocationData: verificationDetails.verificationData.geoLocationData,
      geolocationRequested:
        verificationDetails.verificationPresets.geoLocationRequested,
    };
    if (completePDF) {
      if (verificationDetails.idinResults) {
        this.addressData = jsonData.locatedAddress;
      } else {
        this.addressData = jsonData.providedAddress;
      }
    }
    if (generatePDF) {
      if (verificationDetails.idinResults) {
        const pdfData = await this.pdf.generatePDF(
          "idinTemplate",
          pdfName,
          JSON.stringify(jsonData),
          JSON.stringify(mergeDocuments),
          completePDF,
        );
        return pdfData;
      } else {
        const pdfData = await this.pdf.generatePDF(
          "addressVerificationTemplate",
          pdfName,
          JSON.stringify(jsonData),
          JSON.stringify(mergeDocuments),
          completePDF,
        );
        return pdfData;
      }
    } else {
      return jsonData;
    }
  }

  /**
   * Parse APS details to make the suitable for display in lightbox
   * @param apsDetails Object, APS detais
   */
  parseAPSData(apsDetails) {
    let parsedApsDetails;
    parsedApsDetails = {};
    parsedApsDetails.hits = [];
    let checkDate = this.helperService.formatISODate(
      apsDetails.record.createdOn,
      "yyyy-MM-dd",
      false,
    );
    parsedApsDetails.completedOn = this.helperService.formatISODate(
      this.helperService.getTodayDateTime(),
      this.helperService.getPdfFormatDate(),
      false,
    ); //  | date:'yyyy-MM-dd";
    parsedApsDetails.generalStatus = [];
    parsedApsDetails.checkDate = this.helperService.formatISODate(
      apsDetails.record.createdOn,
      this.helperService.getPdfFormatDate(),
      false,
    );

    if (!parsedApsDetails.checkDate) {
      checkDate = this.helperService.formatISODate(
        apsDetails.record.completedOn,
        "yyyy-MM-dd",
        false,
      );
      parsedApsDetails.checkDate = this.helperService.formatISODate(
        apsDetails.record.completedOn,
        this.helperService.getPdfFormatDate(),
        false,
      );
    }
    if (!parsedApsDetails.checkDate) {
      checkDate = this.helperService.formatISODate(
        apsDetails.record.updatedOn,
        "yyyy-MM-dd",
        false,
      );
      parsedApsDetails.checkDate = this.helperService.formatISODate(
        apsDetails.record.updatedOn,
        this.helperService.getPdfFormatDate(),
        false,
      );
    }
    parsedApsDetails.verificationStatuses = [
      {
        titleClass: "font-weight-bold",
        src: "/assets/images/check.svg",
        title: this.translate.instant("digitalVerifications.aps.title3"),
      },
      {
        src: "/assets/images/check.svg",
        title: this.translate.instant("digitalVerifications.aps.title2"),
      },
      {
        src: "/assets/images/check.svg",
        title: this.translate.instant("digitalVerifications.aps.title"),
      },
    ];
    const generalStatus = apsDetails.record.finalized;
    parsedApsDetails.finalized = apsDetails.record.finalized;
    if (generalStatus) {
      if (
        apsDetails.record.status === "COMPLETED" ||
        apsDetails.record.status === "Completed"
      ) {
        parsedApsDetails.generalStatus.push({
          titleClass: "verification-main-status",
          status: this.translate.instant("evidencePreview.content2", {
            date: this.helperService.formatISODate(
              apsDetails.record.finalized.on,
              "yyyy-MM-dd",
              false,
            ),
            entity: apsDetails.record.finalized.by,
          }),
          icon: "/assets/images/check.svg",
        });
      } else if (
        apsDetails.record.status === "FAILED" ||
        apsDetails.record.status === "Rejected"
      ) {
        parsedApsDetails.generalStatus.push({
          titleClass: "verification-main-status",
          status: this.translate.instant("evidencePreview.content3", {
            date: this.helperService.formatISODate(
              apsDetails.record.finalized.on,
              "yyyy-MM-dd",
              false,
            ),
            entity: apsDetails.record.finalized.by,
          }),
          icon: "/assets/images/no-entry-red.svg",
        });
      }
      if (
        apsDetails.record.verificationData &&
        apsDetails.record.verificationData.results &&
        apsDetails.record.verificationData.results.finalizeComment
      ) {
        parsedApsDetails.generalStatus.push({
          class: "verification-main-substatus",
          status: apsDetails.record.verificationData.results.finalizeComment,
        });
      }
      if (apsDetails.record && apsDetails.record.finalizeComment) {
        parsedApsDetails.generalStatus.push({
          class: "verification-main-substatus",
          status: apsDetails.record.finalizeComment,
        });
      }
      if (apsDetails.record && apsDetails.record.finalized) {
        parsedApsDetails.generalStatus.push({
          class: "verification-main-substatus",
          status: apsDetails.record.finalized.comment,
        });
      }
    }
    apsDetails.record.hits.forEach((hit) => {
      // create the element
      let parsedApsElement;
      parsedApsElement = {};
      // add in the name (main name for selection)
      parsedApsElement.id = hit.doc.id;
      parsedApsElement.type = hit.doc.entity_type;

      parsedApsElement.name = hit.doc.name;
      // add if the hit is whitelisted
      parsedApsElement.isWhitelisted = hit.is_whitelisted;
      parsedApsElement.lastUpdated = hit.doc.last_updated_utc;
      // add the history log
      parsedApsElement.historyLog = hit.history_log;
      // add if the hit is a new hit
      parsedApsElement.isReviewed = hit.reviewed;
      // add in the hit types (for displaying in the Key Data Section)
      parsedApsElement.keyData = {};
      // for display in the key data tab
      parsedApsElement.keyData.hits = [];
      // for displaying next to the hit selection screen
      parsedApsElement.keyData.hitsMinified = [];
      // add in the media
      parsedApsElement.media = hit.doc.media;
      // add in AKA
      parsedApsElement.keyData.aka = hit.doc.aka;
      //  add inassociates
      parsedApsElement.keyData.associates = hit.doc.associates;

      // parse the hits into three categories (adverse media,pep and sanctions)
      hit.doc.types.forEach((hitType) => {
        if (
          hitType.includes("adverse") &&
          !parsedApsElement.keyData.hitsMinified.includes("Adverse")
        ) {
          parsedApsElement.keyData.hits.push("Adverse media");
          parsedApsElement.keyData.hitsMinified.push("Adverse");
          if (!hit.is_whitelisted) {
            parsedApsDetails.verificationStatuses[0] = {
              src: "/assets/images/icon-triangle-orange.svg",
              title: this.translate.instant("digitalVerifications.aps.title3"),
            };
          }
        }
        if (hitType.includes("pep")) {
          const newType = this.helperService.parseRemoveDash(hitType);
          if (!parsedApsElement.keyData.hits.includes(newType)) {
            parsedApsElement.keyData.hits.push(newType);
          }
          if (!parsedApsElement.keyData.hitsMinified.includes("PEP")) {
            parsedApsElement.keyData.hitsMinified.push("PEP");
          }
          if (!hit.is_whitelisted) {
            parsedApsDetails.verificationStatuses[1] = {
              src: "/assets/images/icon-triangle-orange.svg",
              title: this.translate.instant("digitalVerifications.aps.title2"),
            };
          }
        }
        if (
          hitType.includes("sanction") ||
          hitType.includes("warning") ||
          (hitType.includes("fitness") &&
            !parsedApsElement.keyData.hits.includes("Sanctions"))
        ) {
          parsedApsElement.keyData.hits.push("Sanctions");
          parsedApsElement.keyData.hitsMinified.push("Sanctions");

          if (!hit.is_whitelisted) {
            parsedApsDetails.verificationStatuses[2] = {
              src: "/assets/images/icon-triangle-orange.svg",
              title: this.translate.instant("digitalVerifications.aps.title"),
            };
          }
        }
      });
      // add match_status
      parsedApsElement.keyData.match_status = this.helperService.parseTypeName(
        hit.match_status,
      );
      // add relevance
      if (hit.match_types.includes("aka_exact")) {
        parsedApsElement.keyData.relevance = this.translate.instant(
          "evidencePreview.apsPreview.label12",
        );
      }
      if (hit.match_types.includes("name_exact")) {
        parsedApsElement.keyData.relevance = this.translate.instant(
          "evidencePreview.apsPreview.label13",
        );
      }
      parsedApsElement.sanctions = [];
      parsedApsElement.pep = [];
      parsedApsElement.adverse = [];

      // add in the countries
      parsedApsElement.keyData.countries = [];
      hit?.doc?.fields?.forEach((element) => {
        if (element.name === "Country") {
          if (!parsedApsElement.keyData.countries.includes(element.value)) {
            parsedApsElement.keyData.countries.push(element.value);
          }
        }
      });

      hit?.doc?.sources?.forEach((source) => {
        // get the notes
        const sourceArray = hit.doc.source_notes[source];
        // add in the fields of the source to the notes
        const sourceFields = hit?.doc?.fields?.filter((field) => {
          return field.source === source;
        });
        sourceArray.sourceFields = sourceFields;
        // if it's of type sanctions add it to the sanctions
        if (
          sourceArray.aml_types?.findIndex((element) =>
            element.includes("sanction"),
          ) !== -1 ||
          sourceArray.aml_types?.findIndex((element) =>
            element.includes("fitness"),
          ) !== -1 ||
          sourceArray.aml_types?.findIndex((element) =>
            element.includes("warning"),
          ) !== -1
        ) {
          // if (!sourceArray.value.includes('url'))
          parsedApsElement.sanctions.push(sourceArray);
        }
        // if it's of type pep add it to pep
        if (
          sourceArray.aml_types?.findIndex((element) =>
            element.includes("pep"),
          ) !== -1
        ) {
          // go trough the source array and group related URLS
          const relatedURLs = [];
          sourceArray.sourceFields?.forEach((element) => {
            if (element.name === "Related URL") {
              relatedURLs.push(element);
            }
          });
          // remove them from the source array
          sourceArray.sourceFields = sourceArray.sourceFields?.filter(
            (element) => element.name !== "Related URL",
          );
          parsedApsElement.relatedUrls = relatedURLs;
          // push the source array
          parsedApsElement.pep.push(sourceArray);
        }
        // if it's of type adverse media add it to adverseMedia
        if (
          sourceArray.aml_types?.findIndex((element) =>
            element.includes("adverse"),
          ) !== -1
        ) {
          parsedApsElement.adverse.push(sourceArray);
          // need to parse the aml_types
          const parsed_aml_types = [];
          sourceArray.aml_types?.forEach((aml_type) => {
            if (
              aml_type !== "adverse-media" &&
              aml_type !== "adverse-media-v2"
            ) {
              let local_aml_type = aml_type;
              local_aml_type = local_aml_type.replace("adverse-media-v2-", "");
              local_aml_type = local_aml_type.replace("adverse-media-", "");
              parsed_aml_types.push(local_aml_type);
            }
          });
          // also add the adverse aml_types to key data
          parsedApsElement.keyData.adverseCategories = parsed_aml_types;
        }
      });
      parsedApsDetails.hits.push(parsedApsElement);
    });

    // ADD in CREATED BY INFORMATION
    let createdBy = "";
    if (
      apsDetails["record"]["createdBy"] &&
      apsDetails["record"]["createdBy"].firstName
    ) {
      createdBy = createdBy + apsDetails["record"]["createdBy"].firstName + " ";
    }
    if (
      apsDetails["record"]["createdBy"] &&
      apsDetails["record"]["createdBy"].lastName
    ) {
      createdBy = createdBy + apsDetails["record"]["createdBy"].lastName + " ";
    }
    if (
      apsDetails["record"]["createdBy"] &&
      !apsDetails["record"]["createdBy"].lastName &&
      !apsDetails["record"]["createdBy"].firstName
    ) {
      createdBy = createdBy + "" + apsDetails["record"]["createdBy"].email + "";
    }
    if (createdBy === "") {
      createdBy = "N/A";
    }
    parsedApsDetails.createdBy = createdBy;
    parsedApsDetails.checkDateText = this.translate.instant(
      "evidencePreview.content1",
      { checkDate: checkDate },
    );
    parsedApsDetails.orgId = apsDetails["record"].organizationKey.replace(
      "ORG:",
      "",
    );
    return parsedApsDetails;
  }

  /**
   * Parse already parsed APS data to make it suitable for display in PDF
   */
  async parseApsDataPdf(localApsDetail) {
    // console.log('active person', this.idVerificationService.getActivePersonDetails());
    // return;
    if (!localApsDetail) {
      alert(this.translate.instant("errors.error6"));
      console.error("APS data is not present to be parsed for PDF!");
      return;
    }

    const person = this.getActivePersonDetails();
    const domain =
      window.location.hostname + (!location.port ? "" : `:${location.port}`);
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    const vfeCode = person["record"].lastApsVerification.key.replace(
      "VFE:",
      "",
    );
    const myAngularxQrCode = `${extendedDomain}/verificationcheck/` + vfeCode;
    const verificationStatuses = {};
    const parsedAps = { ...localApsDetail };
    // TODO: handle the situation where there is no active verification
    let genQrCode;
    try {
      genQrCode = await QRCode.toDataURL(myAngularxQrCode);
    } catch (err) {
      console.error(err);
    }
    let stamp: any = `${extendedDomain}/assets/images/stamp.png`;
    try {
      await this.getBase64ImageFromUrl(stamp)
        .then((result) => (stamp = result))
        .catch((err) => console.error(err));
    } catch (error) {
      console.error("We were not able to get stamp image!", error);
    }
    parsedAps.customOptions = true;
    parsedAps.documentName = "Compliance Checks";
    parsedAps.qrCode = genQrCode;
    parsedAps.vfeCode = vfeCode;
    parsedAps.verificationSubject = person["record"].name;
    parsedAps.validitySign = stamp;
    parsedAps.isRejected =
      localApsDetail.generalStatus &&
      localApsDetail.generalStatus[0] &&
      localApsDetail.generalStatus[0].status.includes("failed by")
        ? true
        : false;
    parsedAps.isAccepted =
      localApsDetail.generalStatus &&
      localApsDetail.generalStatus[0] &&
      localApsDetail.generalStatus[0].status.includes("passed by")
        ? true
        : false;
    parsedAps.verificationStatuses.forEach((vs) => {
      vs.checkedOn = parsedAps.checkDate;
      if (vs.src === "/assets/images/icon-triangle-orange.svg") {
        vs.status = "orange";
      } else {
        vs.status = "green";
      }
      verificationStatuses[this.helperService.slugify(vs.title)] = vs;
    });
    parsedAps.verificationStatuses = verificationStatuses;
    // parsedAps.hits = this.parseHitsData(parsedAps.hits);
    this.parseHitsData(parsedAps.hits);
    // split hits into chunks
    parsedAps.firstPageHits = parsedAps.hits.slice(0, 6);
    parsedAps.secondPageHits = parsedAps.hits.slice(6);
    parsedAps.domain = domain;
    parsedAps.extendedDomain = extendedDomain;
    if (localApsDetail.finalized) {
      if (localApsDetail.generalStatus && localApsDetail.generalStatus[1]) {
        parsedAps.finalizeComment = localApsDetail.generalStatus[1].status;
      }
      parsedAps.finalizedBy = localApsDetail.finalized.by;
      parsedAps.completedOn = this.helperService.formatISODate(
        localApsDetail.finalized.on,
        this.helperService.getPdfFormatDate(),
        false,
      );
    }
    if (!parsedAps.finalizeComment && parsedAps.finalized) {
      parsedAps.finalizeComment = parsedAps.finalized.comment;
    }
    this.pdf.setPdfGenerationStatus({ status: "IN_PROGRESS", type: "aps" });
    parsedAps.generatedOn = this.helperService.formatISODate(
      this.helperService.getTodayDateTime(),
      this.helperService.getPdfFormatDate(),
      false,
    );
    return parsedAps;
  }

  /**
   * Parse DV APS data to make it suitable for display in PDF
   */
  async parseDVApsDataPdf(localApsDetail) {
    if (!localApsDetail) {
      alert(this.translate.instant("errors.error6"));
      console.error("APS data is not present to be parsed for PDF!");
      return;
    }
    localApsDetail.generalStatus =
      localApsDetail.record.fieldTypesDV.generalStatus;
    const investor = this.getActiveInvestorDetaisl();
    const domain =
      window.location.hostname + (!location.port ? "" : `:${location.port}`);
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    let vfeCode = localApsDetail.record.verificationKey;
    if (vfeCode) {
      vfeCode = localApsDetail.record.verificationKey.replace("VFE:", "");
    } else {
      vfeCode = localApsDetail.key.replace("EVD:", "");
    }
    const myAngularxQrCode = `${extendedDomain}/verificationcheck/` + vfeCode;
    const verificationStatuses = {};
    const parsedAps = { ...localApsDetail.record };
    // TODO: handle the situation where there is no active verification
    let genQrCode;
    try {
      genQrCode = await QRCode.toDataURL(myAngularxQrCode);
    } catch (err) {
      console.error(err);
    }
    let stamp: any = `${extendedDomain}/assets/images/stamp.png`;
    try {
      await this.getBase64ImageFromUrl(stamp)
        .then((result) => (stamp = result))
        .catch((err) => console.error(err));
    } catch (error) {
      console.error("We were not able to get stamp image!", error);
    }
    parsedAps.customOptions = true;
    parsedAps.documentName = "Compliance Checks";
    parsedAps.qrCode = genQrCode;
    parsedAps.vfeCode = vfeCode;
    parsedAps.verificationSubject = [
      investor.investorFields.FirstName,
      investor.investorFields.LastName,
    ].join(" ");
    if (investor.typeOfInvestor === "LegalPerson") {
      parsedAps.verificationSubject = investor.investorFields.RegisteredName;
    }
    if (parsedAps.verificationData?.results?.search_term) {
      parsedAps.verificationSubject =
        parsedAps.verificationData.results.search_term;
    }
    parsedAps.validitySign = stamp;
    parsedAps.isRejected =
      localApsDetail.record.fieldTypesDV.generalStatus &&
      localApsDetail.record.fieldTypesDV.generalStatus[0] &&
      localApsDetail.record.fieldTypesDV.generalStatus[0].status.includes(
        "failed by",
      )
        ? true
        : false;
    parsedAps.isAccepted =
      localApsDetail.record.fieldTypesDV.generalStatus &&
      localApsDetail.record.fieldTypesDV.generalStatus[0] &&
      localApsDetail.record.fieldTypesDV.generalStatus[0].status.includes(
        "passed by",
      )
        ? true
        : false;
    localApsDetail.record.fieldTypesDV.verificationStatuses.forEach((vs) => {
      vs.checkedOn = localApsDetail.record.fieldTypesDV.checkDate;
      if (vs.src === "/assets/images/icon-triangle-orange.svg") {
        vs.status = "orange";
      } else {
        vs.status = "green";
      }
      verificationStatuses[this.helperService.slugify(vs.title)] = vs;
    });
    // parsedAps.verificationStatuses = localApsDetail.record.fieldTypesDV.verificationStatuses;
    parsedAps.verificationStatuses = verificationStatuses;
    parsedAps.hits = localApsDetail.record.fieldTypesDV.hits;
    parsedAps.firstPageHits = parsedAps.hits.slice(0, 6);
    parsedAps.secondPageHits = parsedAps.hits.slice(6);
    parsedAps.domain = domain;
    parsedAps.extendedDomain = extendedDomain;
    if (
      localApsDetail &&
      localApsDetail.record &&
      localApsDetail.record.finalized
    ) {
      localApsDetail.finalized = localApsDetail.record.finalized;
    }
    if (localApsDetail.finalized) {
      if (localApsDetail.generalStatus && localApsDetail.generalStatus[1]) {
        parsedAps.finalizeComment = localApsDetail.generalStatus[1].status;
      }
      parsedAps.finalizedBy = localApsDetail.finalized.by;
      parsedAps.completedOn = this.helperService.formatISODate(
        localApsDetail.finalized.on,
        this.helperService.getPdfFormatDate(),
        false,
      );
    }
    this.pdf.setPdfGenerationStatus({ status: "IN_PROGRESS", type: "aps" });
    parsedAps.generatedOn = this.helperService.formatISODate(
      this.helperService.getTodayDateTime(),
      this.helperService.getPdfFormatDate(),
      false,
    );

    parsedAps.checkDate = localApsDetail.record.fieldTypesDV.checkDate;
    parsedAps.createdBy = localApsDetail.record.fieldTypesDV.createdBy;
    return parsedAps;
  }

  parseHitsData(hits) {
    const newHits = [];
    [...hits].forEach((hit) => {
      const newHit = hit;
      if (newHit.sanctions && newHit.sanctions.length > 0) {
        const newSanctions = [];
        newHit.sanctions.forEach((sanction) => {
          const newSourceFields = [];
          sanction.sourceFields.forEach((sf) => {
            if (newSourceFields[sf.name]) {
              newSourceFields[sf.name] = {
                name: sf.name,
                value: newSourceFields[sf.name].value + ", " + sf.value,
              };
            } else {
              newSourceFields[sf.name] = { name: sf.name, value: sf.value };
            }
          });
          sanction.sourceFields = Object.values(newSourceFields);
          newSanctions.push(sanction);
        });
        // console.log('new sanctions', newSanctions)
        newHit.sanctions = newSanctions;
      }
      newHits.push(newHit);
    });
    // return newHits;
  }

  /**
   * set PDF generation status
   */
  setPdfGenerationStatus(status: boolean) {
    this.pdfGenerationStatus.next(status);
  }

  /**
   * set address verification PDF generation status
   */
  setAddressPdfGenerationStatus(status: boolean) {
    this.addressVerificationPdfGenerationStatus.next(status);
  }

  /**
   * returns details for current/active DV that is being previewed in lightbox
   */
  getActiveDigitalVerificationDetails() {
    return this.activeDigitalVerificationDetails;
  }

  /**
   * sets details for current/active DV that is being previewed in lightbox
   * @param details object containing DV raw response and files array
   */
  setActiveDigitalVerificationDetails(details) {
    this.activeDigitalVerificationDetails = details;
  }

  /**
   * sets files for current/active DV that is being previewed in lightbox
   * @param details object containing DV raw response and files array
   */
  setActiveDigitalVerificationFiles(files) {
    this.activeDigitalVerificationDetails["files"] = files;
    this.activeDigitalVerificationDetails.filesFinishedParsing = true;
  }
  /**
   * check verification validity
   */
  checkVerificationValidity(verificationKey: string) {
    if (verificationKey === "") {
      console.log("Could not resolve verification key!");
      this.verificationValidityCheckStatus.next(null);
      return;
    }
    this.http
      .get(this.buildEndPoint("verificationCheck", verificationKey))
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log(
              "Error while fetching the validity check for the verification!",
            );
            this.verificationValidityCheckStatus.next(null);
            return;
          }
          this.verificationValidityCheckStatus.next(
            this.helperService.sanitize(response),
          );
        },
        (error) => {
          console.log(
            "Following error occured while fetching the validity check for the verification!",
            error,
          );
          this.verificationValidityCheckStatus.next(null);
          return;
        },
      );
  }

  /**
   * Handle project logo upload
   * @param projectKey: string, ID of the project that we are changing the logo for
   * @param file: file, the logo that we are uploading
   */
  uploadProjectLogo(projectKey: string, file: File) {
    if (projectKey === "" || file === undefined || !(file instanceof File)) {
      console.log("Could not upload project logo! Not enough params");
      this.uploadProjectLogoStatus.next(false);
      return;
    }
    $(".upload-status").text("Uploading ...");
    const fd = new FormData();
    fd.append("image", file, file.name);
    this.userService
      .postEndPoint(
        this.buildEndPoint("projectLogo", projectKey),
        fd,
        { responseType: "string" },
        {
          observe: "response",
        },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while uploading a project logo");
            this.uploadProjectLogoStatus.next({
              status: false,
              reason: "Error while uploading a project logo",
            });
            return;
          }
          this.uploadProjectLogoStatus.next({ status: true });
        },
        (error) => {
          if (error === "OK") {
            this.uploadProjectLogoStatus.next({ status: true });
            return;
          }
          console.log(
            "Following error occured while uploading a project logo",
            error,
          );
          this.uploadProjectLogoStatus.next({ status: false, reason: error });
          return;
        },
      );
  }

  /**
   * trigger the project customization lightbox
   */
  setDisplayCustomizeProjectLightbox() {
    this.displayCustomizeProjectLightbox.next(true);
  }

  setDisplayEditTemplateLightbox() {
    this.displayEditTemplateLightbox.next(true);
  }

  /**
   * trigger the invite contributors lightbox
   */
  setDisplayInviteContributorsLightbox() {
    this.displayInviteContributorsLightbox.next(true);
  }

  /**
   * trigger the project contributors lightbox
   */
  setDisplayManageContributorsLightbox() {
    this.displayManageContributorsLightbox.next(true);
  }

  setDisplayGenerateReportLightbox() {
    this.displayGenerateReportLightbox.next({
      status: true,
      verificationType: "verification",
      reportType: "usage",
    });
    this.userService
      .getEndPoint(
        this.buildEndPoint(
          "getProjectTags",
          this.getActiveProjectSettings()
            ? this.getActiveProjectSettings()
            : this.getActiveProject(),
        ),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe((response) => {
        if (response === undefined) {
          return;
        }
        const tagArray = [];
        for (const tag of response["tags"]) {
          tagArray.push(tag.tag);
        }
        this.displayGenerateReportLightbox.next({
          status: true,
          tags: tagArray,
          verificationType: "verification",
          reportType: "usage",
        });
      });
  }

  generateReportStatus(event, type) {
    this.displayGenerateReportLightbox.next({
      status: true,
      verificationType: type,
      reportType: "status",
    });
  }

  setDisplayGenerateReportLightboxFunds() {
    this.displayGenerateReportLightbox.next({ status: true, tags: [] });
  }

  /**
   * trigger the advanced settings lightbox
   */
  setDisplayAdvancedSettingsLightbox() {
    this.displayAdvancedSettingsLightbox.next(true);
  }

  /**
   * hide the project settings submenu on
   * click anywhere else other then the
   * menu it self
   */
  HideProjectSettingsSubmenu(event) {
    // console.log(event.target);
    $(".project-submenu").hide();
  }

  /**
   * hide the form settings submenu on
   * click anywhere else other then the
   * menu it self
   */
  HideFormSettingsSubmenu(event) {
    $(".form-submenu").hide();
  }

  /**
   * load all current contributors for
   * the selected project
   * @param projectKey: string, project key
   * @param initiatedByLeaveProject: boolean, true only when method is invoked by Leave
   */
  loadContributors(
    projectKey: string,
    initiatedByLeaveProject = false,
    highlightEmail = "",
  ) {
    if (projectKey === "") {
      console.log("Could not load project contributors! Not enough params");
      this.resetProjectContributorsData();
      this.loadProjectContributors.next(false);
      return;
    }

    // 'type': 'contributors|members', is optional parameter
    this.userService
      .getEndPoint(
        this.buildEndPoint(
          "projectContributors",
          projectKey,
          "",
          "?status=active",
        ),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.resetProjectContributorsData();
            this.loadProjectContributors.next({
              status: false,
              reason: "Error while retrieving a contributor list",
            });
            return;
          }
          console.log("CONTRIBUTION RESPONSE", response);
          this.setProjectContributorsData(response["records"]);
          // since I have no other information we will load pending contributors
          // alongside the active contributors (right below them), so don't emit
          // successful project contributor fetching just yet, instead load Pending
          // contribtutor data
          // this.loadProjectContributors.next({'status': true});
          this.loadPendingContributors(
            projectKey,
            initiatedByLeaveProject,
            highlightEmail,
          );
        },
        (error) => {
          console.log(
            "Following error occured while fetching contributor list",
            error,
          );
          this.resetProjectContributorsData();
          this.loadProjectContributors.next({ status: false, reason: error });
          return;
        },
      );
  }

  /**
   *

   */
  /**
   * load all current contributors for
   * the selected project
   * @param projectKey: string key of the project
   * @param initiatedByLeaveProject: boolean, only true when invoked by Leave project
   */
  loadPendingContributors(
    projectKey: string,
    initiatedByLeaveProject: boolean,
    highlightEmail: string = "",
  ) {
    if (projectKey === "") {
      console.log("Could not load project contributors! Not enough params");
      this.resetProjectContributorsData();
      this.loadProjectContributors.next(false);
      return;
    }

    // 'type': 'contributors|members', is optional parameter
    this.userService
      .getEndPoint(
        this.buildEndPoint(
          "projectContributors",
          projectKey,
          "",
          "?status=pending",
        ),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.resetProjectContributorsData();
            this.loadProjectContributors.next({
              status: false,
              reason: "Error while retrieving a contributor list",
            });
            return;
          }
          console.log("PENDING CONTRIBUTION RESPONSE", response);
          this.setProjectPendingContributorsData(response["records"]);
          this.loadProjectContributors.next({
            status: true,
            initiatedByLeaveProject: initiatedByLeaveProject,
            highlightEmail,
          });
        },
        (error) => {
          console.log(
            "Following error occured while fetching contributor list",
            error,
          );
          this.resetProjectContributorsData();
          this.loadProjectContributors.next({ status: false, reason: error });
          return;
        },
      );
  }

  /**
   * load ACTIVE pending contributor invitations
   * for the selected project
   */
  loadContributorInvites(projectKey: string) {
    if (projectKey === "") {
      console.log("Could not load project contributors! Not enough params");
      this.resetProjectInvitationsData();
      this.loadProjectInvitations.next(false);
      return;
    }

    this.userService
      .getEndPoint(
        this.buildEndPoint(
          "projectContributorInvitations",
          projectKey,
          "",
          "?status=pending&filter=all",
        ),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.resetProjectInvitationsData();
            this.loadProjectInvitations.next({
              status: false,
              reason: "Error while retrieving an invitation list",
            });
            return;
          }
          if (
            Array.isArray(response["records"]) &&
            response["records"].length === 0
          ) {
            console.log("resetting project invitation data");
            this.resetProjectInvitationsData();
          } else {
            this.setProjectInvitationsData(
              this.helperService.sanitize(response["records"]),
            );
          }
          this.loadProjectInvitations.next({ status: true });
        },
        (error) => {
          console.log(
            "Following error occured while fetching the invitations",
            error,
          );
          this.resetProjectInvitationsData();
          this.loadProjectInvitations.next({ status: false, reason: error });
          return;
        },
      );
  }

  /**
   * make member an owner
   * @param projectKey:string key for the active settings project
   * @param contributorKey:string key for the member we are converting
   * @param initiatedByLeaveProject: boolean, true only when method is invoked by Leave project
   */
  makeContributorOwner(
    projectKey: string,
    contributorKey: string,
    leaveProject = false,
    initiatedByLeaveProject = false,
  ) {
    if (projectKey === "" || contributorKey === "") {
      console.log("Could not mark as owner! Not enough params");
      this.markAsOwner.next({ status: false, reason: "Not enough arguments" });
      return;
    }
    this.userService
      .postEndPoint(
        this.buildEndPoint("makeOwner", projectKey, contributorKey),
        {},
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.resetProjectContributorsData();
            this.markAsOwner.next({
              status: false,
              reason: "Error while making an owner",
            });
            return;
          }

          this.loadContributors(
            this.getActiveProjectSettings(),
            initiatedByLeaveProject,
          ); // just reload contributors
          this.markAsOwner.next({ status: true });
          if (leaveProject) {
            this.leaveProject(this.getActiveProjectLeave());
            return;
          }
        },
        (error) => {
          console.log(
            "Following error occured while fetching the invitations",
            error,
          );
          this.resetProjectContributorsData();
          this.markAsOwner.next({ status: false, reason: error });
          return;
        },
      );
  }

  /**
   * remove contributor. for now only member can be removed
   * @param projectKey:string key for the active settings project
   * @param contributorKey:string key for the member we are removing
   */
  removeContributor(projectKey: string, contributorKey: string) {
    if (projectKey === "" || contributorKey === "") {
      console.log("Could not remove member! Not enough params");
      this.removeAsOwner.next({
        status: false,
        reason: "Not enough arguments",
      });
      return;
    }

    this.userService
      .postEndPoint(
        this.buildEndPoint("removeContributor", projectKey, contributorKey),
        {},
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.resetProjectContributorsData();
            this.removeAsOwner.next({
              status: false,
              reason: "Error while removing a member",
            });
            return;
          }
          this.loadContributors(this.getActiveProjectSettings()); // just reload contributors
          this.removeAsOwner.next({ status: true });
        },
        (error) => {
          console.log(
            "Following error occured while removing a contributor",
            error,
          );
          this.resetProjectContributorsData();
          this.removeAsOwner.next({ status: false, reason: error });
          return;
        },
      );
  }

  reinviteContributor(invitationKey: string) {
    if (invitationKey === "") {
      console.log("Could not reinvite member! Not enough params");
      this.reinviteContributorStatus.next({
        status: false,
        reason: "Not enough arguments",
        target: invitationKey,
      });
      return;
    }

    this.userService
      .getEndPoint(
        this.buildEndPoint("getInvitationDetails", invitationKey),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.reinviteContributorStatus.next({
              status: false,
              reason: "Error while reinviting a contributor",
              target: invitationKey,
            });
            return;
          }
          this.setReinviteUser(this.helperService.sanitize(response)); // set reinvitation user
          this.setDisplayInviteContributorsLightbox(); // display the Invite Contributor lightbox
          this.reinviteContributorStatus.next({ status: true });
        },
        (error) => {
          console.log(
            "Following error occured while reinviting a contributor",
            error,
          );
          this.resetProjectContributorsData();
          this.reinviteContributorStatus.next({
            status: false,
            reason: error,
            target: invitationKey,
          });
          return;
        },
      );
  }

  leaveProject(projectKey: string) {
    if (projectKey === "") {
      console.log("Could not leave the project! Not enough params");
      this.resetActiveProjectLeave();
      this.leaveAProject.next({
        status: false,
        reason: "Not enough arguments",
        target: projectKey,
      });
      return;
    }

    this.userService
      .postEndPoint(
        this.buildEndPoint("projectLeave", projectKey),
        "",
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.leaveAProject.next({
              status: false,
              reason: "Error while leaving a project",
              target: projectKey,
            });
            return;
          }
          this.removeActiveProjectFromList(projectKey);
          this.leaveAProject.next({ status: true });
        },
        (error) => {
          console.log(
            "Following error occured while leaving the project",
            error,
          );
          this.resetActiveProjectLeave();
          this.leaveAProject.next({
            status: false,
            reason: error,
            target: projectKey,
          });
          return;
        },
      );
  }

  /**
   * clear a notification (orange dot) for specific project
   * NOTE: we don't need to create an observable as all we
   * need to do is reload the list of active projects
   */
  clearProjectNotification(projectKey: string) {
    if (projectKey === "") {
      console.log(
        "Could not clear the notification for the project! Not enough params",
      );
      // this.clearAProject.next({'status': false, 'reason': 'Not enough arguments', 'target': projectKey});
      return;
    }

    this.userService
      .postEndPoint(
        this.buildEndPoint("projectClearNotification", projectKey),
        {},
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            return;
          }
          // update project list
          this.retrieveActiveProjects();
        },
        (error) => {
          console.log(
            "Following error occured while clearing the notification for a project",
            error,
          );
          return;
        },
      );
  }

  /**
   * clear a notification (orange dot) for specific person
   * NOTE: we don't need to create an observable as the
   * orange dot will be removed by the onclick action
   * @param contributorKey:string key for the member we are removing the notification
   * @param containerTarget:string id of the person result container where orange-dot needs to be removed
   */
  clearPersonNotification(projectKey, contributorKey, containerTarget) {
    if (projectKey === "" || contributorKey === "") {
      console.log(
        "Could not clear the notification for the contributor! Not enough params",
      );
      return;
    }

    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "personClearNotification",
          projectKey,
          contributorKey,
        ),
        {},
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            return;
          }
          // everything went well remove the orange-dot class from the container
          $("#" + $.escapeSelector(containerTarget)).removeClass("orange-dot");
        },
        (error) => {
          console.log(
            "Following error occured while clearing the notification for a contributor",
            error,
          );
          return;
        },
      );
  }

  /**
   * authorize contributor. Grant permission (access to the project)
   * to a user who has accepted an invitation
   * @param projectKey:string key for the active settings project
   * @param contributorKey:string key for the member we are removing
   */
  authorizeContributor(projectKey: string, contributorKey: string) {
    if (projectKey === "" || contributorKey === "") {
      console.log("Could not authorize contributor! Not enough params");
      this.authContributor.next({
        status: false,
        reason: "Not enough arguments",
      });
      return;
    }

    this.userService
      .postEndPoint(
        this.buildEndPoint("authorizeContributor", projectKey, contributorKey),
        {},
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.resetProjectContributorsData();
            this.authContributor.next({
              status: false,
              reason: "Error while authorize a contributor",
            });
            return;
          }
          this.loadContributors(this.getActiveProjectSettings()); // just reload contributors
          this.authContributor.next({ status: true });
        },
        (error) => {
          console.log(
            "Following error occured while authorizing a contributor",
            error,
          );
          this.resetProjectContributorsData();
          this.authContributor.next({ status: false, reason: error });
          return;
        },
      );
  }

  getUserPreferences() {
    this.userService.getUserInfo();
  }

  doBrandingReset(projectKey) {
    if (!this.permissionUpdateProjectBranding()) {
      console.log(
        "could not update branding for projectproject! Branding update is not permitted for user.",
      );
      this.updateProjectBrandingStatus.next({
        status: false,
        reason: "Operation not permittaed",
      });
      return;
    }
    if (projectKey === "") {
      console.log("Could not reset branding! Not enough params");
      this.resetBranding.next({
        status: false,
        reason: "Could not reset branding! Not enough params",
      });
      this.resetBranding.next(false);
      return;
    }

    this.userService
      .postEndPoint(
        this.buildEndPoint("brandingReset", projectKey),
        { projectColor: "#ffffff", consentMessage: "", privacyUrl: "" },
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.resetBranding.next({
              status: false,
              reason: "Could not reset branding! Internal server error.",
            });
            return;
          }
          // update project list
          this.resetActiveProjectSettingsLogo();
          this.retrieveActiveProjects();
          this.resetBranding.next({ status: true });
        },
        (error) => {
          this.resetBranding.next({
            status: false,
            reason: "Could not reset branding! " + error,
          });
          console.log(
            "Following error occured while clearing the notification for a project",
            error,
          );
          return;
        },
      );
  }

  generateUsageReport(projectKey, dateFrom, dateTo, tags) {
    // generateIDReport
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not create information request for this project! Project key supplied was unacceptable.",
      );
      return;
    }
    let queryArguments = "?";
    if (dateFrom) {
      queryArguments = queryArguments.concat("dateFrom=" + dateFrom + "&");
    }
    if (dateTo) {
      queryArguments = queryArguments.concat("dateTo=" + dateTo + "&");
    }
    if (tags && tags.length) {
      for (let i = 0; i < tags.length; i++) {
        queryArguments = queryArguments.concat("tags=" + tags[i] + "&");
      }
    }
    if (queryArguments === "?") {
      queryArguments = "";
    }
    if (queryArguments.slice(-1) === "&") {
      queryArguments = queryArguments.slice(0, -1);
    }
    $(".form-loading-screen").removeClass("d-none");
    $(".form-loading-screen").addClass("d-none");
    $(".report-generation-form .button-error-field").hide();
    $(".report-generation-form .button-error-field").removeClass("text-teal");
    $(".report-generation-form .button-error-field").html(
      "*the report generated is empty and cannot be downloaded",
    );
    this.userService
      .getEndPoint(
        this.buildEndPoint("generateIDReport", projectKey, "", queryArguments),
        { responseType: "text/csv" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined || response === "" || response === null) {
            $(".button-error-field").show();
            $(".form-loading-screen").addClass("d-none");
            $(".form-loading-screen").removeClass("d-flex");
            return;
          }
          const link = document.createElement("a");
          let data = "data:text/csv;charset=utf-8,";
          data += response.toString();
          const projectName: String = this.getActiveProjectSettingsDetails()
            ? " - " + this.getActiveProjectSettingsDetails()["record"].name
            : "";
          const name = "Mesh ID - Usage Report" + projectName + ".csv";
          link.href = encodeURI(data);
          link.download = name;
          link.style.display = "none";
          const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          document.body.appendChild(link);
          link.dispatchEvent(evt);
          document.body.removeChild(link);
          $(".form-loading-screen").addClass("d-none");
          $(".form-loading-screen").removeClass("d-flex");
          $(".button-error-field").html(
            "Usage report has been successfully generated!",
          );
          $(".button-error-field").addClass("text-teal");
          $(".button-error-field").show();

          // $('.report-generation-form .close-form-button img').click();
        },
        (error) => {
          console.log(
            "The following error occured when generating project report",
            error.error,
          );
        },
      );
  }
  // FUNDS FUNCTIONS, MIGHT BE TRANSFERED TO ANOTHER FILE

  generateStatusReport(projectKey) {
    $(".form-loading-screen").removeClass("d-none");
    $(".form-loading-screen").addClass("d-none");
    $(".report-generation-form .button-error-field").hide();
    $(".report-generation-form .button-error-field").removeClass("text-teal");
    $(".report-generation-form .button-error-field").html(
      "*the report generated is empty and cannot be downloaded",
    );
    this.userService
      .getEndPoint(
        this.buildEndPoint("statusReport", this.getActiveProject()),
        { responseType: "text/csv" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response) => {
          if (response === undefined || response === "" || response === null) {
            $(".report-generation-form .button-error-field").show();
            $(".form-loading-screen").addClass("d-none");
            $(".form-loading-screen").removeClass("d-flex");
            return;
          }
          const link = document.createElement("a");
          let data = "data:text/csv;charset=utf-8,";
          data += response.toString();
          const projectName: String = this.getActiveProjectSettingsDetails()
            ? " - " + this.getActiveProjectSettingsDetails()["record"].name
            : "";
          const name = "Mesh ID - Status Report" + projectName + ".csv";
          link.href = encodeURI(data);
          link.download = name;
          link.style.display = "none";
          const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          document.body.appendChild(link);
          link.dispatchEvent(evt);
          document.body.removeChild(link);
          $(".form-loading-screen").addClass("d-none");
          $(".form-loading-screen").removeClass("d-flex");
          $(".report-generation-form .button-error-field").html(
            "Status report has been successfully generated!",
          );
          $(".report-generation-form .button-error-field").addClass(
            "text-teal",
          );
          $(".report-generation-form .button-error-field").show();

          // $('.report-generation-form .close-form-button img').click();
        },
        (error) => {
          console.log(
            "The following error occured when generating project report",
            error.error,
          );
        },
      );
  }

  /**
   * creates a new information request
   * @param projectKey string, key of the project
   * @param informationRequest object with required param name, required param type of investor, and at least one type of proof of identity
   */
  createInformationRequest(projectKey, informationRequest) {
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not create information request for this project! Project key supplied was unacceptable.",
      );
      // this.createPerson.next({  'status': false,
      //                           'personData': informationRequest,
      //                           'error': 'Project key supplied was unacceptable' });
      return;
    }
    // informationRequest = this.helperService.sanitize(informationRequest);
    this.userService
      .postEndPoint(
        this.buildEndPoint("investors", projectKey),
        informationRequest,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("--ON INFORMATION REQUEST COMPLETED--");
            return;
          }

          this.activeInvestorShareData = undefined;

          let tags = this.helperService.sanitize( this.activeLegalRepresentativesUnadedTags);
          
          if(this.activeLegalRepresentativesUnadedTags && this.activeLegalRepresentativesUnadedTags.length) {
            // add all unnaded tags in a  call
            this.userService
            .postEndPoint(
              this.buildEndPoint("addPersonTagsCDD", projectKey,  response["key"]),
              {tags : tags},
              { responseType: "json" },
              { observe: "body" },
              this.getCCType(),
            )
            .subscribe(responseTags => {
              this.retreiveActiveInvestors(
                this.getActiveProject(),
                response["key"],
                "funds",
                response["record"].basicFieldsEvidence,
                true,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                10,
                false,
                undefined,
                [...this.activeLegalRepresentativesUnadedTags],

              );
              this.setActiveLegalRepresentativesUnadedTags(undefined);
            })
          } else {
            this.retreiveActiveInvestors(
              this.getActiveProject(),
              response["key"],
              "funds",
              response["record"].basicFieldsEvidence,
              true,
            );
          }
        },
        (error) => {
          console.log(
            "The following error occured when creating the person for project",
            error,
          );
          // alert(error.error.message);
          this.skipToStep(0);
        },
      );
  }

  /**
   * creates a new information request
   * @param projectKey string, key of the project
   * @param informationRequest object with required param name, required param type of investor, and at least one type of proof of identity
   */
  createInformationRequestAutomatic(
    projectKey,
    informationRequest,
    selectedTemplate,
    emailAddress,
    allowReuse,
  ) {
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not create information request for this project! Project key supplied was unacceptable.",
      );
      // this.createPerson.next({  'status': false,
      //                           'personData': informationRequest,
      //                           'error': 'Project key supplied was unacceptable' });
      return;
    }
    informationRequest = this.helperService.sanitize(informationRequest);
    this.userService
      .postEndPoint(
        this.buildEndPoint("investors", projectKey),
        informationRequest,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("--ON INFORMATION REQUEST COMPLETED--");
            return;
          }
          this.activeInvestorShareData = undefined;
          this.genereateShareInvestorUrlAutomatic(
            this.getActiveProject(),
            response["key"],
            {
              email: emailAddress,
              allowReuse: allowReuse,
              templateKey: selectedTemplate,
            },
            informationRequest.typeOfInvestor !== "AutomaticRequest",
          );
        },
        (error) => {
          console.log(
            "The following error occured when creating the person for project",
            error,
          );
          // alert(error.error.message);
          this.skipToStep(0);
        },
      );
  }

  genereateShareInvestorUrlAutomatic(
    projectKey,
    requestKey,
    payload,
    additionalDetails?,
  ) {
    // console.log('$$$PAYLOAD', payload, details);
    // return;
    if (projectKey === "" || requestKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    this.userService
      .postEndPoint(
        this.buildEndPoint("generateInvestorShareUrl", projectKey, requestKey),
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          // add share information to state because share call takes too much time
          const now = new Date();
          const newDate = now.getTime();

          if (this.activeInvestorShareData) {
            this.activeInvestorShareData.shareEntities.unshift({
              sharedOn: newDate.toString(),
              sharedWith: payload.email,
            });
          } else {
            this.activeInvestorShareData = {
              shareEntities: [
                {
                  sharedOn: newDate.toString(),
                  sharedWith: payload.email,
                },
              ],
            };
          }
          this.unaddedURLKey = response["url"];
          this.changeInformationRequestStep(20, {
            shareUrl: response["url"],
            email: payload.email,
            additionalDetails: additionalDetails,
          });

          // add all unnaded tags in a  call
          if(this.activeLegalRepresentativesUnadedTags && this.activeLegalRepresentativesUnadedTags.length > 0) {
            this.userService
            .postEndPoint(
              this.buildEndPoint("addPersonTagsCDD", projectKey, requestKey),
              {tags : this.activeLegalRepresentativesUnadedTags},
              { responseType: "json" },
              { observe: "body" },
              this.getCCType(),
            )
            .subscribe(response => {
              this.setActiveLegalRepresentativesUnadedTags(undefined);
              this.retreiveActiveInvestors(this.getActiveProject(), requestKey);
            })
          }
        else {
          this.retreiveActiveInvestors(this.getActiveProject(), requestKey);
        }
        },
        (error) => {
          alert(this.translate.instant("errors.error7"));
          this.skipToStep(0);
        },
      );
  }
  /**
   * update the information request basic fields such as name, address, country, etc
   * @param projectKey string, key of the project
   * @param informationRequest object with required param name, required param type of investor, and at least one type of proof of identity
   */
  updateInformationRequestBasicFields(
    projectKey,
    requestKey,
    informationRequest,
  ) {
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not create information request for this project! Project key supplied was unacceptable.",
      );
      return;
    }
    if(informationRequest?.fieldsSource)
      {
        delete informationRequest.fieldsSource;
      }
    informationRequest = this.helperService.sanitize(informationRequest);
    if(informationRequest?.relatedPartyRole && Array.isArray(informationRequest.relatedPartyRole))
      {
        informationRequest.relatedPartyRole = informationRequest.relatedPartyRole[0];
      }
    if(this.helperService.isRPKey(requestKey))
      {
        this.userService
        .postEndPoint(
          this.buildEndPoint("updateRPBF", projectKey, this.getActiveInvestor(), "", true, "", "", "", "",  requestKey),
          informationRequest,
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              return;
            }
  
            this.loadAdditionalInvestorDetail(
              this.getActiveProject(),
              requestKey,
              true,
            );
            this.skipToStep(0, {});
          },
          (error) => {
            console.log(
              "The following error occured when creating the person for project",
              error,
            );
            this.skipToStep(0);
          },
        );
      }
      else
      {
        this.userService
        .postEndPoint(
          this.buildEndPoint("updateInvestorFields", projectKey, requestKey),
          informationRequest,
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              return;
            }
  
            this.loadAdditionalInvestorDetail(
              this.getActiveProject(),
              requestKey,
              true,
            );
            this.skipToStep(0, {});
          },
          (error) => {
            console.log(
              "The following error occured when creating the person for project",
              error,
            );
            this.skipToStep(0);
          },
        );
        

      }
  
  }

  /**
   * creates a new information request, first two steps for the original information request flow (used to be one)
   * @param projectKey string, key of the project
   * @param informationRequest object with required param name, required param type of investor, and at least one type of proof of identity
   */
  skipToStep(stepNum, additionalInfo = {}) {
    this.changeInformationRequestStep(stepNum, additionalInfo);
  }

  /**
   * @param projectKey string, key of the project
   * @param documents list of document types to be uploaded later
   */
  async retreiveRP(projectKey, requestKey, projectType = "") {
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not create information request for this project! Project key supplied was unacceptable.",
      );
      return;
    }
    // close the current related party
    if (
      this.activeInvestorDetails["activeRelatedParty"] &&
      this.activeInvestorDetails["activeRelatedParty"]["key"] === requestKey
    ) {
      const partyType =
        this.activeInvestorDetails["activeRelatedParty"]["record"][
          "typeOfInvestor"
        ];
      this.activeInvestorDetails["activeRelatedParty"] = undefined;
      this.activeInvestorDetails["activeRelatedPartyDigitalVerification"] =
        undefined;
      if (this.isInvestorType()) {
        this.activeInvestorsStatus.next({
          investorType: this.activeInvestorDetails["typeOfInvestor"],
        });
      } else {
        this.loadAdditionalInvestorDetailsStatus.next();
      }
    } else {
      // load the new related party
      this.userService
        .getEndPoint(
          this.buildEndPoint("getInvestor", projectKey, requestKey),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          async (response) => {
            if (response === undefined) {
              return;
            } else {
              if (
                this.helperService.needsDigitalVerificationLazyResolve(
                  response,
                  requestKey,
                )
              ) {
                await this.doDigitalVerificationsLazyResolve(
                  projectKey,
                  requestKey,
                )
                  .then((resolve) => {
                    if (resolve["resolvedVerifications"]) {
                      let recordResolved = true;
                      response["record"].digitalVerifications.forEach(
                        (digitalVerification) => {
                          resolve["resolvedVerifications"].forEach(
                            (resolvedVerification) => {
                              if (
                                digitalVerification.key === resolvedVerification
                              ) {
                                digitalVerification.isResolved = true;
                                // digitalVerification.status = 'Completed';
                              } else {
                                recordResolved = false;
                              }
                            },
                          );
                        },
                      );
                      if (recordResolved) {
                        response["record"].isResolved = recordResolved;
                        response["record"].verificationSubjectStatus =
                          "COMPLETED";
                      }
                    }
                  })
                  .catch((error) => console.error(error));
              }
              if (this.isInvestorType()) {
                this.updateActiveInvestorRelatedParty(
                  response["key"],
                  response,
                );
              } else {
                // merge digital verificaitons with related party evidence
                response["record"]["evidenceTypes"] =
                  this.concatEvidenceTypesWithDigitalVerifications(
                    response["record"]["evidenceTypes"],
                    response["record"]["digitalVerifications"],
                  );
                this.activeInvestorDetails[
                  "activeRelatedPartyDigitalVerification"
                ] = response["record"]["digitalVerifications"];
                this.activeInvestorDetails["activeRelatedParty"] = response;
              }
            }
            if (this.isInvestorType()) {
              this.activeInvestorsStatus.next({
                investorType: this.activeInvestorDetails["typeOfInvestor"],
              });
            } else {
              this.loadAdditionalInvestorDetailsStatus.next();
            }
          },
          (error) => {
            console.log(
              "The following error occured when creating the person for project",
              error,
            );
          },
        );
    }
  }

  deleteProfile(projectKey, relatedPartyKey, isRP?) {
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not create information request for this project! Project key supplied was unacceptable.",
      );
      return;
    }

    this.userService
      .deleteEndpoint(
        this.buildEndPoint("getInvestor", projectKey, relatedPartyKey),
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            return;
          }

       

          if(this.activeInvestorDetails && this.activeInvestorDetails.tags) {
            this.activeInvestorDetails.tags.forEach(tag => {
              this.deleteInvestorTagFE(tag);
            });
          }

          if (isRP) {
            this.activeInvestorDetails["activeRelatedParty"] = undefined;
            this.loadAdditionalInvestorDetail(
              projectKey,
              this.getActiveInvestor(),
              true,
            );
            this.skipToStep(0);
          } else {
            this.skipToStep(0);
            this.retreiveActiveInvestors(
              this.getActiveProject(),
              "",
              "funds",
              false,
              false,
              false,
              undefined,
              this.getActiveLegalrepresentativesFilters().type,
              this.getActiveLegalrepresentativesFilters().sortBy,
              this.getActiveLegalrepresentativesFilters().status,
            );
          }
        },
        (error) => {
          console.log(
            "The following error occured when creating the person for project",
            error,
          );
          alert(error.message);
          this.skipToStep(0);
        },
      );
  }


  // required for CLAIM screen
  retreiveRelatedPartyClaim(requestKey, relatedpartykey) {
    if (
      this.activeInvestorDetails["activeRelatedParty"] &&
      this.activeInvestorDetails["activeRelatedParty"]["key"] ===
        relatedpartykey
    ) {
      this.activeInvestorDetails["activeRelatedParty"] = undefined;
      this.activeInvestorsStatus.next();
    } else {
      this.userService
        .getEndPoint(
          this.buildEndPoint(
            "claimSideRelatedParty",
            "",
            requestKey,
            "",
            true,
            relatedpartykey,
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              return;
            }
            // merge digital verificaitons with related party evidence
            response["request"]["record"]["evidenceTypes"] =
              this.concatEvidenceTypesWithDigitalVerifications(
                response["request"]["record"]["evidenceTypes"],
                response["request"]["record"]["digitalVerifications"],
              );
            this.activeInvestorDetails[
              "activeRelatedPartyDigitalVerification"
            ] = response["request"]["record"]["digitalVerifications"];
            this.activeInvestorDetails["activeRelatedParty"] =
              response["request"];
            this.activeInvestorsStatus.next(response["request"]);
          },
          (error) => {
            console.log(
              "The following error occured when creating the person for project",
              error,
            );
          },
        );
    }
  }

  // required for review STEP
  // decide wether to display the review process
  // will also process the digital verifications
  parseElementsForReview(projectKey: string, requestKey, relatedParties) {
    // save the original response from the BE because we need it in the review process
    if (!this.activeInvestorDetails.originalBEEvidenceTypes) {
      this.activeInvestorDetails.originalBEEvidenceTypes = [
        ...this.activeInvestorDetails.evidenceTypes,
      ];
    }

    if (!this.activeInvestorDetails.originalBEDigitalVerifications) {
      this.activeInvestorDetails.originalBEDigitalVerifications = [
        ...this.activeInvestorDetails.digitalVerifications,
      ];
    }

    this.displayLoadInvestorReviewStatus.next({
      reviewState: "loading",
      dvState: "loading",
    });

    // handling identity go as a separate step
    if (relatedParties && relatedParties.length) {
      for (const rp of relatedParties) {
        if (
          rp.record.digitalVerifications &&
          rp.record.digitalVerifications.length > 1
        ) {
          if (
            rp.record.digitalVerifications[0].verificationType ===
            "IdentityInstantAI"
          ) {
            const tempVerification = rp.record.digitalVerifications[0];
            rp.record.digitalVerifications[0] =
              rp.record.digitalVerifications[1];
            rp.record.digitalVerifications[1] = tempVerification;
          }
        }
      }
    }
    const investorEvidenceTypes =
      this.concatEvidenceTypesWithDigitalVerifications(
        this.activeInvestorDetails["evidenceTypes"],
        this.activeInvestorDetails["digitalVerifications"],
        this.activeInvestorDetails["contractStep"],
      );
    // IF AT LEAST ONE EVIDENCE TYPE HAS THE STATUS TRUE IT MEANS THAT THERE I STILL
    // REVIEWING TO BE DONE
    // no-review state returns us to the old dashboard
    // right now we will never show the old dashboard just the pcs
    // this needs to be changed back when we return to using the old dashboard
    // let reviewState = "no-review";
    let reviewState = "review";
    let dvState = "no-warning";
    investorEvidenceTypes.forEach((evidenceType) => {
      if (evidenceType.reviewState) {
        reviewState = "review";
      }
      if (
        evidenceType.type === "DigitalVerification" &&
        evidenceType.status === "In progress"
      ) {
        dvState = "warning";
      }
    });
    if (
      this.activeInvestorDetails["contractStep"] &&
      this.activeInvestorDetails["contractStep"][0]?.reviewState
    ) {
      reviewState = "review";
    }

    if (this.activeInvestorDetails["identityGoStep"]) {
      if (this.activeInvestorDetails["identityGoStep"].length) {
        if (this.activeInvestorDetails["identityGoStep"][0].reviewState) {
          reviewState = "review";
        }
      }
    }

    if (this.activeInvestorDetails["addressStep"]) {
      if (this.activeInvestorDetails["addressStep"].length) {
        if (this.activeInvestorDetails["addressStep"][0].reviewState) {
          reviewState = "review";
        }
      }
    }

    // CHECK INVESTOR BASIC FIELDS REVIEW STEP
    if (this.getActiveInvestorDetaisl()["basicFieldsEvidence"]["reviewState"]) {
      reviewState = "review";
    }
    this.getActiveInvestorDetaisl()["DigitalVerificationsMatchArray"] = {};
    this.getActiveInvestorDetaisl()["IdentityGoMatchArray"] = {};
    if (relatedParties) {
      if (relatedParties.length === 0) {
        this.displayLoadInvestorReviewStatus.next({
          reviewState: reviewState,
          dvState: dvState,
        });
      } else {
        for (const rp of relatedParties) {
          if (rp.record && rp.record.evidenceTypes) {
            if (
              rp.record.basicFieldsEvidence &&
              rp.record.basicFieldsEvidence.reviewState
            ) {
              reviewState = "review";
            }
            for (const evidenceType of rp.record.evidenceTypes) {
              if (evidenceType.reviewState) {
                reviewState = "review";
              }
            }
            if (rp.record.digitalVerifications) {
              for (const dv of rp.record.digitalVerifications) {
                if (dv.reviewState) {
                  reviewState = "review";
                }
                if (
                  dv.status === "In progress" &&
                  dv.verificationType !== "IdentityInstantAI"
                ) {
                  dvState = "warning";
                }
              }
            }
            // if (rp.record && !rp.record.isReviewed) {
            //   dvState = 'warning';
            // }
          }
          if (
            rp.record &&
            rp.record.digitalVerifications &&
            rp.record.digitalVerifications.length
          ) {
            const key = rp.record.digitalVerifications[0].key;
            let verifications = rp.record.digitalVerifications;
            let identityGoStep;
            if (verifications) {
              identityGoStep = verifications.filter(
                (type) => type.verificationType === "IdentityInstantAI",
              );
              verifications = verifications.filter(
                (type) => type.verificationType !== "IdentityInstantAI",
              );
            }
            this.getActiveInvestorDetaisl()["DigitalVerificationsMatchArray"][
              key
            ] = verifications;
            if (identityGoStep) {
              this.getActiveInvestorDetaisl()["IdentityGoMatchArray"][key] =
                identityGoStep;
            }
          }
          if (
            rp.record["contractStep"] &&
            rp.record["contractStep"][0]?.reviewState
          ) {
            reviewState = "review";
          }
      
          if ( rp.record["identityGoStep"]) {
            if ( rp.record["identityGoStep"].length) {
              if ( rp.record["identityGoStep"][0].reviewState) {
                reviewState = "review";
              }
            }
          }
      
          if ( rp.record["addressStep"]) {
            if ( rp.record["addressStep"].length) {
              if ( rp.record["addressStep"][0].reviewState) {
                reviewState = "review";
              }
            }
          }
        }
        this.displayLoadInvestorReviewStatus.next({
          reviewState: reviewState,
          dvState: dvState,
        });
      }
    } else {
      this.displayLoadInvestorReviewStatus.next({
        reviewState: reviewState,
        dvState: dvState,
      });
    }
  }


  uploadRequestDocument(
    projectKey: string,
    requestKey: string,
    evidenceKey: string,
    file: File,
    evidenceTypes,
    flowtype,
  ) {
    if (
      projectKey === "" ||
      file === undefined ||
      requestKey === "" ||
      evidenceKey === "" ||
      !(file instanceof File)
    ) {
      console.log("Could not upload evidence document. Not enough params");
      return;
    }
    $(".dropzone").addClass("dropzone-loading");
    $(".evidence-submit-button").addClass("loading");
    $(".evidence-delete-button").addClass("d-none");

    const fd = new FormData();
    fd.append("document", file, file.name);
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "informationRequestDocument",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        fd,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            return;
          }
          const evidenceField = this.returnEvidenceFieldByKey(
            evidenceKey,
            evidenceTypes,
          );
          this.saveFieldsState(evidenceKey, evidenceTypes);
          evidenceField["documents"].push({
            filename: file.name,
            Key: response["documentKey"],
          });
          // DONT NEED TO RERENDER FLOW
          // this.loadEvidenceFieldAndImages(this.getActiveProject(),requestKey,evidenceKey,flowtype,evidenceTypes);

          $(".dropzone").removeClass("dropzone-loading");
          $(".evidence-submit-button").removeClass("loading");
          $(".evidence-delete-button").removeClass("d-none");
        },
        (error) => {
          console.log(
            "The following error occured when uploading the document",
            error,
          );
          // this.createPerson.next({  'status': false,
          //                           'personData': person,
          //                           'error': error });
          alert(this.translate.instant("errors.error8"));
          // dropzone.removeClass('dropzone-loading');
          $("#evidenceDocumentUploadBox").removeClass("dropzone-loading");
          // alert(error.error.message);
          this.skipToStep(0);
        },
      );
    return undefined;
  }

  deleteRequestDocument(
    projectKey: string,
    requestKey: string,
    evidenceKey: string,
    documentKey: string,
    evidenceTypes,
  ) {
    if (
      projectKey === "" ||
      requestKey === "" ||
      evidenceKey === "" ||
      documentKey === ""
    ) {
      console.log("Could not delete evidence document. Not enough params");
      return;
    }
    const imageContainer = $(
      '.evidence-list-delete-button[data-documentKey="' + documentKey + '"]',
    ).parent();
    imageContainer.addClass("button-deleting");
    $(".dropzone").addClass("dropzone-loading");
    $(".evidence-submit-button").addClass("loading");
    $(".evidence-delete-button").addClass("d-none");
    this.userService
      .deleteEndpoint(
        this.buildEndPoint(
          "deleteRequestDocument",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
          documentKey,
        ),
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
          }
          // imageContainer.remove();
          imageContainer.removeClass("button-deleting");
          $(".dropzone").removeClass("dropzone-loading");
          $(".evidence-submit-button").removeClass("loading");
          $(".evidence-delete-button").removeClass("d-none");
          // remove document from state
          const evidenceType = this.returnEvidenceFieldByKey(
            evidenceKey,
            evidenceTypes,
          );
          const documentIndex = this.returnDocumentPositionByKey(
            documentKey,
            evidenceType["documents"],
          );
          evidenceType["documents"].splice(documentIndex, 1);
        },
        (error) => {
          console.log(
            "The following error occured when deleting the documnet",
            error,
          );
          // this.createPerson.next({  'status': false,
          //                           'personData': person,
          //                           'error': error });
          imageContainer.removeClass("button-deleting");
          this.skipToStep(0);
        },
      );
  }

  /**
   * retrieves all investors this project
   * @param projectKey string, key of the project
   * @param basicFields needed for a very specific case on the dashboard
   * @param backToDashboard to know if we need to close all lightboxes and go back to dashboard after the call is completed
   * @param existingBookmark existing bookmark used to load more perons
   */
  // pageSize = 15;
  async retreiveActiveInvestors(
    projectKey,
    activeInvestor = "",
    projectType = "funds",
    basicFields?,
    backToDashboard?,
    resetFilters = true,
    existingBookmark?,
    typeFilter?,
    sortBy?,
    status?,
    riskTag?: string[],
    filterByRiskReview?,
    pageSize = 10,
    singleRequestKey?,
    tags?,
    existingTags?
  ) {
    let hasActiveInvestor;
    let queryArguments;

    if (!this.isInvestorType()) {
      this.resetVerificationFilter();
      queryArguments = "?";
      if (existingBookmark) {
        queryArguments = queryArguments.concat(
          "bookmark=" + existingBookmark + "&",
        );
      }
      if (pageSize) {
        queryArguments = queryArguments.concat("pageSize=" + pageSize + "&");
      }
      if (sortBy) {
        queryArguments = queryArguments.concat("sortBy=" + sortBy + "&");
      }
      if (status) {
        queryArguments = queryArguments.concat("status=" + status + "&");
      }
      if (typeFilter) {
        queryArguments = queryArguments.concat("type=" + typeFilter + "&");
      }
      if (riskTag && riskTag.length) {
        riskTag.forEach(
          (tag: string) =>
            (queryArguments = queryArguments.concat(
              "risk_rating=" + tag + "&",
            )),
        );
      }
      if (tags && tags.length) {
        tags.forEach(
          (tag: string) =>
            (queryArguments = queryArguments.concat(
              "tags=" + tag + "&",
            )),
        );
      }
      if (filterByRiskReview) {
        if (filterByRiskReview.next_review_after) {
          queryArguments = queryArguments.concat(
            `next_review_after=${filterByRiskReview.next_review_after}` + "&",
          );
        }
        if (filterByRiskReview.next_review_before) {
          queryArguments = queryArguments.concat(
            `next_review_before=${filterByRiskReview.next_review_before}`,
          );
        }
      }

      // get all used risk ratings so far:
      // this is a quick call so let's wait for it instead of updating through observables
      await this.userService
        .getEndPoint(
          this.buildEndPoint("getProjectRiskRating", projectKey),
          { responseType: "json" },
          { observe: "body" },
          this.getCCType(),
        )
        .subscribe((riskResponse) => {
          if (Array.isArray(riskResponse)) {
            this.activeInvestorRiskRatings = riskResponse;
          }
        });

      if (queryArguments.slice(-1) === "&") {
        queryArguments = queryArguments.slice(0, -1);
      }
      if (resetFilters) {
        this.activeLegalrepresentativesFilters = {};
      } else {
        this.activeLegalrepresentativesFilters.activeTags = tags;

      }
      this.activeLegalrepresentativesFilters.lastRecord0 = false;
    }
    if (this.isInvestorType()) {
      this.forceLoadingScreen = true;
    }
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not fetch investors for this project! Project key supplied was unacceptable.",
      );
      // reset the active legal representatives list
      // this.resetActivePersons();
      // return this.activeProjectPeople.asObservable();
      return this.activeProjectInvestors.asObservable();
    }
    let records = [];
    if (this.isInvestorType()) {
      this.forceLoadingScreen = true;
    }

    let endpoint = this.buildEndPoint(
      "investors",
      projectKey,
      undefined,
      queryArguments,
    );
    if (singleRequestKey) {
      endpoint = this.buildEndPoint("getFund", projectKey, singleRequestKey);
    }
    this.userService
      .getEndPoint(
        endpoint,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log(
              "We could retrieve the list of persons for this project",
            );
            return this.activeProjectInvestors.asObservable();
          }

          if (singleRequestKey) {
            const responseT = response;
            response = {};

            response["records"] = [responseT];
          }
          // we need to attach the basicFields to the active investor
          // this is a small fix to avoid sweeping changes
          if (basicFields && response["records"]) {
            response["records"].forEach((record) => {
              if (record.key === activeInvestor) {
                record.record.basicFieldsEvidence = basicFields;
                record.record.evidenceTypes = [];
              }
            });
          }
          if (existingTags && response["records"]) {

            response["records"].forEach((record) => {
              if (record.key === activeInvestor) {
                record.record.tags = existingTags;
              }
            });
          }
          
          records = this.helperService.sanitize(response["records"]);

          this.userService
          .getEndPoint(
            this.buildEndPoint("getProjectTags", projectKey),
            { responseType: "json" },
            { observe: "body" },
            this.getCCType(),
          ).subscribe( responseTags => {

            const tagArray = [];
              for (const tag of responseTags["tags"]) {
                tagArray.push(tag.tag);
              }
              this.activeLegalRepresentativesTopTags = responseTags["tags"];
              this.activeLegalRepresentativesTopTags.sort((a, b) =>
                a.peopleTagged < b.peopleTagged ? 1 : -1,
              );
              this.setactiveLegalRepresentativesTags(tagArray);
              this.activeLegalRepresentativesUnadedTags = undefined;


          // Filtering only works on CDD SP side
          if (!this.isInvestorType() && !singleRequestKey) {
            this.activeLegalRepresentativesBookmark =
              response["metadata"].bookmark;
            if (resetFilters) {
              this.activeLegalrepresentativesFilters.showFilters = false;
              this.activeLegalrepresentativesFilters.activeFilter =
                "all_statuses";
              this.activeLegalrepresentativesFilters.scrollTop = undefined;
              $("html, body").animate({ scrollTop: "0px" }, 0);
              if (response["records"].length === 0) {
                this.activeLegalrepresentativesFilters.firstLoadEmpty = true;
              } else {
                this.activeLegalrepresentativesFilters.firstLoadEmpty = false;
              }
            } else {
              this.activeLegalrepresentativesFilters.showFilters = true;
              if (existingBookmark) {
                records = this.activeInvestorList.concat(
                  this.helperService.sanitize(response["records"]),
                );
              }
              hasActiveInvestor = true;
              if (response["records"].length === 0) {
                // sign not to show loading bar after 15th loaded person because there are no more persons
                this.activeLegalrepresentativesFilters.lastRecord0 = true;
              }
            }
          }
          if (response["records"]) {
            if (this.isInvestorType()) {
              this.mergedEvidenceTypes =
                response["records"][0].record.evidenceTypes;
              // it's an automatic request
              // meaning it still hasn't gone trough the PCS
              if (
                response["records"][0].record.isAutomaticRequest &&
                !response["records"][0].record.convertedOn
              ) {
                this.router.navigate([
                  `information/request/share/${this.getActiveProject()}/${
                    response["records"][0].key
                  }/pcs`,
                ]);
                return;
              }
            }
            this.setActiveInvestors(
              records,
              activeInvestor,
              response["typeOfInvestor"],
              hasActiveInvestor,
            );
          } else {
            this.setActiveInvestors(
              [this.helperService.sanitize(response)],
              activeInvestor,
              response["record"]["typeOfInvestor"],
              hasActiveInvestor,
            );
          }
          if (backToDashboard) {
            this.changeInformationRequestStep(0, response);
          }
          // show the elements only after we know they're on the right screen
          $(".active-projects-wrapper").addClass("d-flex");
          return this.activeProjectInvestors.asObservable();

          });

        },
        (error) => {
          console.log(
            "The following error occured when retrieving the investors for project",
            error,
          );
          // this.activeProjectPeople.next({ 'status': false });
          // reset the active legal representatives list
          // this.resetActivePersons();
          // return this.activeProjectPeople.asObservable();
          this.router.navigate(["/"]);
          return this.activeProjectInvestors.asObservable();
        },
      );
  }

  async retreiveActiveInvestorsWithVerificationFilter(
    projectKey,
    activeInvestor = "",
    projectType = "funds",
    payload,
    existingBookmark?,
    pageSize = 10,
  ) {
    let hasActiveInvestor;
    let queryArguments;

    queryArguments = "?";
    if (existingBookmark) {
      queryArguments = queryArguments.concat(
        "bookmark=" + existingBookmark + "&",
      );
    }

    if (pageSize) {
      queryArguments = queryArguments.concat("pageSize=" + pageSize);
    }

    if (this.isInvestorType()) {
      this.forceLoadingScreen = true;
    }
    if (typeof projectKey !== "string" || projectKey.length === 0) {
      console.log(
        "could not fetch investors for this project! Project key supplied was unacceptable.",
      );
      // reset the active legal representatives list
      // this.resetActivePersons();
      // return this.activeProjectPeople.asObservable();
      return this.activeProjectInvestors.asObservable();
    }
    let records = [];
    if (this.isInvestorType()) {
      this.forceLoadingScreen = true;
    }

    let endpoint = this.buildEndPoint(
      "verificationFilter",
      projectKey,
      undefined,
      queryArguments,
    );
    this.resetActiveInvestor();
    this.userService
      .postEndPoint(
        endpoint,
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log(
              "We could retrieve the list of persons for this project",
            );
            return this.activeProjectInvestors.asObservable();
          }

          records = this.helperService.sanitize(response["records"]);

          this.activeLegalRepresentativesBookmark =
            response["metadata"].bookmark;

          this.activeRecordsCount = response["metadata"].recordsCount;

          this.activeLegalrepresentativesFilters.showFilters = true;

          if (existingBookmark) {
            records = this.activeInvestorList.concat(
              this.helperService.sanitize(response["records"]),
            );
          }

          this.userService
          .getEndPoint(
            this.buildEndPoint("getProjectTags", projectKey),
            { responseType: "json" },
            { observe: "body" },
            this.getCCType(),
          ).subscribe( responseTags => {

            const tagArray = [];
              for (const tag of responseTags["tags"]) {
                tagArray.push(tag.tag);
              }
              this.activeLegalRepresentativesTopTags = responseTags["tags"];
              this.activeLegalRepresentativesTopTags.sort((a, b) =>
                a.peopleTagged < b.peopleTagged ? 1 : -1,
              );
              this.setactiveLegalRepresentativesTags(tagArray);
              this.activeLegalRepresentativesUnadedTags = undefined;
              
              hasActiveInvestor = true;
              if (response["records"].length === 0) {
                // sign not to show loading bar after 15th loaded person because there are no more persons
                this.activeLegalrepresentativesFilters.lastRecord0 = true;
              }
              if (response["records"]) {
                if (this.isInvestorType()) {
                  this.mergedEvidenceTypes =
                    response["records"][0].record.evidenceTypes;
                  // it's an automatic request
                  // meaning it still hasn't gone trough the PCS
                  if (
                    response["records"][0].record.isAutomaticRequest &&
                    !response["records"][0].record.convertedOn
                  ) {
                    this.router.navigate([
                      `information/request/share/${this.getActiveProject()}/${
                        response["records"][0].key
                      }/pcs`,
                    ]);
                    return;
                  }
                }
                this.setActiveInvestors(
                  records,
                  activeInvestor,
                  response["typeOfInvestor"],
                  hasActiveInvestor,
                );
              } else {
                this.setActiveInvestors(
                  [this.helperService.sanitize(response)],
                  activeInvestor,
                  response["record"]["typeOfInvestor"],
                  hasActiveInvestor,
                );
              }
              // show the elements only after we know they're on the right screen
              $(".active-projects-wrapper").addClass("d-flex");
              return this.activeProjectInvestors.asObservable();

          });

        },
      );
  }

  /**
   * sets current legal representatives for the active project
   * @param persons array of legal representatives objects
   * @param hasActiveInvestor - variable to know wether we don't want to overwite the active investor because of pagination
   */
  setActiveInvestors(
    investors,
    activeInvestor,
    typeOfInvestor,
    hasActiveInvestor?,
  ) {
    if (investors !== undefined && Array.isArray(investors)) {
      this.activeInvestorList = investors;
      if (activeInvestor !== "") {
        // console.log('setting from setActiveInvestors')
        if (!hasActiveInvestor) {
          this.setActiveInvestor(activeInvestor);
        }
      } else {
        this.activeInvestor = "";
      }
      this.activeInvestorsStatus.next({
        investorType: typeOfInvestor,
        investors: investors,
      });
    }
  }
  /**
   * Update/replace a specific active investor related party in the ActiveInvestorDetails object
   * // this function exists because we are not making the RP call for investors
   * @param relatedParty object containing relatedParty details fetched from BE
   */
  updateActiveInvestorRelatedParty(relatedPartyKey, response?) {
    this.activeInvestorDetails.relatedParties.forEach(
      (existingRelatedParty, key) => {
        if (existingRelatedParty.key === relatedPartyKey) {
          this.activeInvestorDetails.activeRelatedParty = existingRelatedParty;
          if (response) {
            this.activeInvestorDetails.activeRelatedParty.record.verificationLink =
              response.record.verificationLink;
          }
        }
      },
    );
    if (!this.activeInvestorDetails.activeRelatedParty.dvAlreadyMerged) {
      this.activeInvestorDetails.activeRelatedParty["record"]["evidenceTypes"] =
        this.concatEvidenceTypesWithDigitalVerifications(
          this.activeInvestorDetails.activeRelatedParty["record"][
            "evidenceTypes"
          ],
          this.activeInvestorDetails.activeRelatedParty["record"][
            "digitalVerifications"
          ],
        );
      this.activeInvestorDetails["activeRelatedPartyDigitalVerification"] =
        this.activeInvestorDetails.activeRelatedParty["record"][
          "digitalVerifications"
        ];
    }
    this.activeInvestorDetails.activeRelatedParty.dvAlreadyMerged = true;
  }

  /**
   * returns list of all assigned risk ratings to this project
   */
  getRiskRatingsForProject() {
    return this.activeInvestorRiskRatings;
  }

  setRiskRatingsForProject(activeInvestorRiskRatings) {
    this.activeInvestorRiskRatings = activeInvestorRiskRatings;
  }
  /**
   * Activated after user selects which documents to upload
   * @param projectKey currently active project
   */
  createUploadDocumentOutline(
    projectKey,
    requestKey,
    payload,
    documentTypes,
    investorType,
  ) {
    if (
      typeof projectKey !== "string" ||
      projectKey.length === 0 ||
      typeof requestKey !== "string" ||
      requestKey.length === 0
    ) {
      console.log(
        "could not fetch investors for this project! Project key or Request key supplied was unacceptable.",
      );
    } else {
      this.userService
        .postEndPoint(
          this.buildEndPoint("createDocumentOutline", projectKey, requestKey),
          payload,
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            // MIKI NOTE
            // CREATING NEW EVIDENCE UPLOAD OUTLINE
            // EXISTING DATA WILL BE DELETED
            // SETING ACTIVE EXPANDED EVIDENCE TO THE FIRST FIELD WHICH IS NOT CLAIMED
            // DELETING OTHER EVIDENCE DATA FROM INNER STATE
            // console.log(this.activeInvestorDetails);
            // DONT FORGET TO SAVE THE ACTIVE RELATED PARTY
            let prevActiveRelatedParty;
            let prevShareData;
            let prevActiveTabMainProfile;
            const receivedProjectType = response["key"].substring(0, 3);
            if (receivedProjectType === "RPE") {
              prevActiveRelatedParty = response;
              this.activeInvestorDetails["activeRelatedParty"] =
                prevActiveRelatedParty;
              this.activeInvestorDetails[
                "activeRelatedPartyDigitalVerification"
              ] = response["record"]["digitalVerifications"];
            } else {
              prevActiveRelatedParty =
                this.activeInvestorDetails.activeRelatedParty;
              response["record"].shareData =
                this.activeInvestorDetails.shareData;
              response["record"].activeTabMainProfile =
                this.activeInvestorDetails.activeTabMainProfile;
              this.setActiveInvestorDetails(response["record"]);
            }
            let activeEvidence;
            if (response["record"]["evidenceTypes"]) {
              response["record"]["evidenceTypes"].forEach((evidenceType) => {
                if (
                  evidenceType.subjectIsOwner === undefined && !evidenceType?.completedByServiceProvider &&
                  !activeEvidence
                ) {
                  activeEvidence = evidenceType.key;
                  return;
                }
              });
            }
            // we will merge the evidence types and digital verifications
            const evidenceTypes =
              this.concatEvidenceTypesWithDigitalVerifications(
                response["record"]["evidenceTypes"],
                response["record"]["digitalVerifications"],
              );
            if (evidenceTypes) {
              evidenceTypes.forEach((evidenceType) => {
                if (
                  evidenceType.subjectIsOwner === undefined && !evidenceType?.completedByServiceProvider &&
                  !activeEvidence
                ) {
                  activeEvidence = evidenceType.key;
                  return;
                }
              });
            }
            this.setExpandedEvidence(activeEvidence);
            if (activeEvidence) {
              this.loadEvidenceFieldAndImages(
                this.getActiveProject(),
                requestKey,
                activeEvidence,
                "Create",
                evidenceTypes,
              );
            } else {
              this.skipToStep(0);
            }
          },
          (error) => {
            //  alert(error.error.message);
            this.skipToStep(0);
          },
        );
    }
  }

  // part of the review flow
  findNextEvidenceOrder(
    currentExpandedEvidenceOrder,
    totalNumberOfEvidence,
    evidenceTypes,
  ) {
    let nextExpandedEvidenceKey;
    let nextExpandedEvidence;
    if (currentExpandedEvidenceOrder + 1 === totalNumberOfEvidence) {
      return currentExpandedEvidenceOrder + 1;
    }
    for (
      let i = parseInt(currentExpandedEvidenceOrder, 10) + 1;
      i < totalNumberOfEvidence;
      i++
    ) {
      if (i === totalNumberOfEvidence) {
        return i;
      }
      nextExpandedEvidenceKey = $(
        '.document-category-main[data-evidenceOrder="' + i + '"]',
      ).attr("data-evidenceKey");
      nextExpandedEvidence = this.returnEvidenceFieldByKey(
        nextExpandedEvidenceKey,
        evidenceTypes,
      );
      if (nextExpandedEvidence && nextExpandedEvidence.reviewState === true) {
        return i;
      }
    }
  }

  /**
   * Fill up the evidnece fields (textual fields, not evidence)
   * original key will replace the evidence type key in the call
   */
  fillEvidenceFields(
    projectKey,
    requestKey,
    evidenceKey,
    evidenceTypes,
    payload,
    originalKey?,
    additionalParam?,
  ) {
    // set activeEvidenceKey - it will be the key for evidence that we are creating/updating
    this.activeEvidenceKey = evidenceKey;
    // const activeEvidence= evidewnceKey;
    const currentExpandedEvidence = $(
      '.document-category-main[data-EvidenceKey="' + evidenceKey + '"]',
    );
    const currentExpandedEvidenceOrder = $(currentExpandedEvidence).attr(
      "data-evidenceOrder",
    );
    const numberofLabels = $(".documentUploaderOwner").length;
    const numberOfEvidenceInList = $(".document-category-main").length;
    const totalNumberOfEvidence = numberOfEvidenceInList - numberofLabels;
    let areBasicFieldsSkipped = false;
    let nextExpandedEvidenceOrder;
    if (this.isInvestorType()) {
      nextExpandedEvidenceOrder = this.findNextEvidenceOrder(
        parseInt(currentExpandedEvidenceOrder, 10),
        totalNumberOfEvidence,
        evidenceTypes,
      );
    } else {
      nextExpandedEvidenceOrder =
        parseInt(currentExpandedEvidenceOrder, 10) + 1;
    }
    const flowType = $(currentExpandedEvidence).attr("data-flowType");
    let nextExpandedEvidence = $(
      '.document-category-main[data-evidenceOrder="' +
        nextExpandedEvidenceOrder +
        '"]',
    ).attr("data-evidenceKey");
    const nextExpandedEvidenceRkey = $(
      '.document-category-main[data-evidenceOrder="' +
        nextExpandedEvidenceOrder +
        '"]',
    ).attr("data-requestKey");
    const currentEvidenceField = this.returnEvidenceFieldByKey(
      evidenceKey,
      evidenceTypes,
    );
    let rpDelegationFlow = false;
    // add the NotOlderThan field which stands separate from the others and is required
    payload["claimCorrect"] = $("#claimCorrect:checked").val();

    const notOlderThanInput = $("#notOlderThanN input");
    if (notOlderThanInput.length) {
      if (
        notOlderThanInput.val() !== null ||
        notOlderThanInput.val() !== undefined ||
        notOlderThanInput.val() !== "" ||
        notOlderThanInput.val() !== "emptySelect"
      ) {
        payload["notOlderThan"] = this.helperService.prepareDateForApi(
          $(notOlderThanInput).val() as string,
        );
      }
      currentEvidenceField.notOlderThan = this.helperService.prepareDateForApi(
        $(notOlderThanInput).val() as string,
      );
    } 
    currentEvidenceField.finalizeRequired = payload?.finalizeRequired ? true : false;
    if (
      currentEvidenceField.rpOptions &&
      currentEvidenceField.rpOptions.length > 0 &&
      !$("#continue_rp_with_new_info").prop("checked")
    ) {
      rpDelegationFlow = true;
      const relatedPartyOriginRequest =
        currentEvidenceField.relatedPartyOriginRequest;
      const delegatedKey = $(".rp-delegation-wrapper option:selected")
        .val()
        .toString();
      currentEvidenceField.reviewState = false;
      this.userService
        .postEndPoint(
          this.buildEndPoint(
            "delegateRelatedParty",
            this.getActiveProject(),
            relatedPartyOriginRequest
              ? relatedPartyOriginRequest
              : this.getActiveInvestor(),
            "",
            true,
            "",
            "",
            requestKey,
            delegatedKey,
          ),
          {},
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (nextExpandedEvidenceRkey !== requestKey) {
              requestKey = nextExpandedEvidenceRkey;
            }
            if (nextExpandedEvidenceOrder === totalNumberOfEvidence) {
              if (this.projectTypeFlow !== "review") {
                // step -1 will reload the current investor
                // we need this after delegation because the delegated party will be completely changed after the process
                // there is no better solution now than to reload all the info
                // however we can optimize this further
                const currentRoute = this.router.url;
                this.router
                  .navigateByUrl("/", { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate([currentRoute]); // navigate to same route
                  });
              } else {
                const thisBackup = this;
                const currentRoute = this.router.url;
                this.router
                  .navigateByUrl("/", { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate([currentRoute]); // navigate to same route
                  });
              }
            } else {
              // skip to next step
              const currentRoute = this.router.url;
              this.router
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([currentRoute]); // navigate to same route
                });
            }
          },
          (error) => {
            $(".document-category-main").removeClass("uploading-document");
            $(".documentUploaderSide").removeClass("uploading-document");
            const currentRoute = this.router.url;
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() => {
                this.router.navigate([currentRoute]); // navigate to same route
              });
          },
        );
    } else if (
      this.projectTypeFlow === "review" &&
      !$("#continue_with_new_info").prop("checked") &&
      $("#existingDocumentsWrapper").length > 0
    ) {
      // Handle things on Investor side - replace data provided (possibly edited) by SP with existing documents
      if (
        $(
          "#existingDocumentsWrapper input[name=replace_existing_documents]:checked",
        ).val()
      ) {
        // call accept/mine endpoint
        const toBeReplaced = evidenceKey;
        const toBeReplacedWith = $(
          "#existingDocumentsWrapper input[name=replace_existing_documents]:checked",
        ).val();
        this.userService
          .postEndPoint(
            this.buildEndPoint(
              "acceptMine",
              this.getActiveProject(),
              requestKey,
              "",
              true,
              "",
              "",
              toBeReplaced,
              toBeReplacedWith.toString(),
            ),
            {},
            { responseType: "json" },
            { observe: "body" },
            "funds",
          )
          .subscribe(
            (response) => {
              // on success directly set the reviewState = false
              const confirmedEvidenceType: any = this.getEvidenceDetails(
                this.getActiveEvidenceKey(),
              );
              confirmedEvidenceType.reviewState = false;
              const currentEvidence = $(
                '.document-category-main[data-EvidenceKey="' +
                  this.getActiveEvidenceKey() +
                  '"]',
              );
              $(currentEvidence)
                .find("#collapse_document_image_icon")
                .attr("src", "/assets/images/check.svg");
              // re-render the list
              if (nextExpandedEvidenceRkey !== requestKey) {
                requestKey = nextExpandedEvidenceRkey;
              }
              if (nextExpandedEvidenceOrder === totalNumberOfEvidence) {
                if (this.projectTypeFlow !== "review") {
                  this.skipToStep(0);
                } else {
                  const thisBackup = this;
                  setTimeout(function () {
                    thisBackup.skipToStep(0);
                  }, 2000);
                }
              } else {
                // skip to next step
                this.loadEvidenceFieldAndImages(
                  projectKey,
                  requestKey,
                  nextExpandedEvidence,
                  flowType,
                  evidenceTypes,
                );
              }
            },
            (error) => {
              $(".document-category-main").removeClass("uploading-document");
              $(".documentUploaderSide").removeClass("uploading-document");
            },
          );
      }
      return;
    } else if (
      typeof projectKey !== "string" ||
      projectKey.length === 0 ||
      typeof requestKey !== "string" ||
      requestKey.length === 0
    ) {
      console.log(
        "could not fetch investors for this project! Project key or Request key supplied was unacceptable.",
      );
    } else {
      let endpoint = "fillEvidenceField";
      if (
        currentEvidenceField.type === "DigitalVerification" &&
        this.projectType === "funds"
      ) {
        endpoint = "fillEvidenceFieldDV";
        const verificationConfig = payload.fields;
        const notOlderThan = payload.notOlderThan;
        payload = {};
        payload.verificationConfig = verificationConfig;
        payload.force = verificationConfig.force;
        if (notOlderThan) {
          payload.notOlderThan = notOlderThan;
        }
      }
      let RPrequestKey;
      let RPkey;
      if (currentEvidenceField.isBasicFields) {
        endpoint = "updateInvestorFields";
        payload.investorFields = payload.fields;
        if (
          this.localFundCreationData &&
          this.localFundCreationData.fieldsSource
        ) {
          payload.fieldsSource = [this.localFundCreationData.fieldsSource];
          this.localFundCreationData = {};
        }
        if (currentEvidenceField.relatedPartyKey) {
          endpoint = "updateRPFields";
          RPkey = requestKey;
          RPrequestKey = currentEvidenceField.relatedPartyOriginRequest
            ? currentEvidenceField.relatedPartyOriginRequest
            : requestKey;
          currentEvidenceField.reviewState = false;
          this.updateRelatedPartyFieldStatus(RPkey, evidenceTypes);
        } else {
          if (this.getActiveInvestorDetaisl()["fieldsSource"]) {
          }
          this.updateBasicFieldsStatus(requestKey, evidenceTypes);
        }
        if (
          $("#transfer_to_pcs_bf").length &&
          !$("#transfer_to_pcs_bf").is(":checked") &&
          $("#continue_with_new_info_bf").is(":checked")
        ) {
          areBasicFieldsSkipped = true; //
          payload.createVerification = true;
        } else {
          payload.createVerification = false;
        }
      }
      // need to update all the evidnece that the basic field belongs too
      if (
        this.projectType === "funds" ||
        this.projectTypeFlow === "update" ||
        (this.projectType === "investor" &&
          $("#continue_with_new_info").prop("checked"))
      ) {
        this.userService
          .postEndPoint(
            this.buildEndPoint(
              endpoint,
              projectKey,
              RPrequestKey ? RPrequestKey : requestKey,
              "",
              true,
              RPkey ? RPkey : originalKey ? originalKey : evidenceKey,
            ),
            payload,
            { responseType: "json" },
            { observe: "body" },
            "funds",
          )
          .subscribe(
            (response) => {
              // changing box position
              // Handle things on Investor side -  NO existing documents
              if (currentEvidenceField.isBasicFields) {
                if (payload.fields) {
                  if (currentEvidenceField.typeOfInvestor === "NaturalPerson") {
                    currentEvidenceField.confirmedEmail =
                      payload.fields.EmailAddress;
                    currentEvidenceField.investorReviewName =
                      payload.fields.FirstName + " " + payload.fields.LastName;
                  } else {
                    currentEvidenceField.investorReviewName =
                      payload.fields.RegisteredName;
                    currentEvidenceField.confirmedEmail =
                      payload.fields.EmailAddress;
                  }
                  if (!currentEvidenceField.relatedPartyKey) {
                    this.getActiveInvestorDetaisl()["basicFieldsEvidence"][
                      "reviewState"
                    ] = false;
                    currentEvidenceField.reviewState = false;
                  } else {
                    const currRP = this.getActiveInvestorDetaisl()[
                      "relatedParties"
                    ].findLast((rp) => (rp.key = RPkey));
                    if (currRP) {
                      currRP.record.basicFieldsEvidence.reviewState = false;
                    }
                  }
                }
              }
              if(payload.claimCorrect)
              {
                currentEvidenceField.completedByServiceProvider = true;
                this.activeInvestorDetails.evidenceTypes= this.activeInvestorDetails.evidenceTypes.filter((evidence)=> evidence.key == currentEvidenceField.key);
              }
              const oldRequest = requestKey.slice();
              if (
                this.projectTypeFlow === "review" &&
                $("#continue_with_new_info").prop("checked") &&
                $("#existingDocumentsWrapper").length === 0
              ) {
                currentEvidenceField.reviewState = false;
                const currentEvidence = $(
                  '.document-category-main[data-EvidenceKey="' +
                    this.getActiveEvidenceKey() +
                    '"]',
                );
                $(currentEvidence)
                  .find("#collapse_document_image_icon")
                  .attr("src", "/assets/images/check.svg");
                // this.displayCreateNewDocumentConfirmation.next(true);
                // this is the last one
                if (nextExpandedEvidenceRkey !== requestKey) {
                  requestKey = nextExpandedEvidenceRkey;
                }
                if (nextExpandedEvidenceOrder === totalNumberOfEvidence) {
                  if (this.projectTypeFlow !== "review") {
                    this.skipToStep(0);
                  } else {
                    const endpointCorrect = "acceptEvidenceAsCorrect";
                    if (currentEvidenceField.relatedPartyKey) {
                    }
                    if (areBasicFieldsSkipped) {
                      // const currRP = this.getActiveInvestorDetaisl()['relatedParties'].find(rp => rp.key = RPkey);
                      // if (currRP) {
                      //   currRP.record.basicFieldsEvidence.reviewState = false;
                      // }
                      this.skipToStep(0);
                    } else {
                      if (currentEvidenceField.isBasicFields) {
                        this.skipToStep(0);
                      } else {
                        this.userService
                          .postEndPoint(
                            this.buildEndPoint(
                              endpointCorrect,
                              this.getActiveProject(),
                              RPrequestKey ? RPrequestKey : oldRequest,
                              "",
                              true,
                              this.getActiveEvidenceKey(),
                            ),
                            {},
                            { responseType: "json" },
                            { observe: "body" },
                            "funds",
                          )
                          .subscribe(
                            (response2) => {
                              this.skipToStep(0);
                            },
                            (error) => {
                              // alert(error.error.message);
                              $(".document-category-main").removeClass(
                                "uploading-document",
                              );
                              $(".documentUploaderSide").removeClass(
                                "uploading-document",
                              );
                            },
                          );
                      }
                    }
                  }
                } else {
                  // skip to next step
                  const endpointCorrect = "acceptEvidenceAsCorrect";
                  if (currentEvidenceField.relatedPartyKey) {
                  }
                  if (areBasicFieldsSkipped) {
                    // const currRP = this.getActiveInvestorDetaisl()['relatedParties'].find(rp => rp.key = RPkey);
                    // if (currRP) {
                    //   currRP.record.basicFieldsEvidence.reviewState = false;
                    // }
                    this.loadEvidenceFieldAndImages(
                      projectKey,
                      requestKey,
                      nextExpandedEvidence,
                      flowType,
                      evidenceTypes,
                    );
                  } else {
                    if (currentEvidenceField.isBasicFields) {
                      this.loadEvidenceFieldAndImages(
                        projectKey,
                        requestKey,
                        nextExpandedEvidence,
                        flowType,
                        evidenceTypes,
                      );
                    } else {
                      this.userService
                        .postEndPoint(
                          this.buildEndPoint(
                            endpointCorrect,
                            this.getActiveProject(),
                            oldRequest,
                            "",
                            true,
                            this.getActiveEvidenceKey(),
                          ),
                          {},
                          { responseType: "json" },
                          { observe: "body" },
                          "funds",
                        )
                        .subscribe(
                          (response2) => {
                            this.loadEvidenceFieldAndImages(
                              projectKey,
                              requestKey,
                              nextExpandedEvidence,
                              flowType,
                              evidenceTypes,
                            );
                          },
                          (error) => {
                            // alert(error.error.message);
                            $(".document-category-main").removeClass(
                              "uploading-document",
                            );
                            $(".documentUploaderSide").removeClass(
                              "uploading-document",
                            );
                          },
                        );
                    }
                  }
                }
              } else if (
                this.projectTypeFlow === "review" &&
                $("#continue_with_new_info").prop("checked") &&
                $("#existingDocumentsWrapper").length > 0
              ) {
                // there are existing documents to replace create lightbox "Use new information"
                // 1. get access details as we will need them to construct the lightbox
                // 1.1. get access details using the evidenceKey for the FIRST evidenceType in existingEvidence list for the activeEvidence
                const activeEvidenceDetails: any =
                  this.getEvidenceDetails(evidenceKey);
                if (
                  !activeEvidenceDetails.existingDocuments ||
                  activeEvidenceDetails.existingDocuments.length === 0
                ) {
                  // alert('Could not load existing documents...');
                  return;
                }
                // call the component in loading state
                this.displayConfirmUseOfNewInformationLightbox.next({
                  true: false,
                });
                this.userService
                  .getEndPoint(
                    this.buildEndPoint(
                      "getEvidenceAccessInfo",
                      projectKey,
                      requestKey,
                      "",
                      true,
                      activeEvidenceDetails.existingDocuments[0].evidence.key,
                    ),
                    { responseType: "json" },
                    { observe: "body" },
                    "funds",
                  )
                  .subscribe(
                    (response2) => {
                      // 2. associate the access info with the current evidenceType
                      this.updateEvidenceProperty(evidenceKey, [
                        "otherPartiesAccess",
                        response2,
                      ]);

                      // 3. we are now ready display the lightbox
                      this.displayConfirmUseOfNewInformationLightbox.next({
                        true: true,
                        requestKey: requestKey,
                        evidenceField: currentEvidenceField,
                      });
                      if (nextExpandedEvidenceRkey !== requestKey) {
                        requestKey = nextExpandedEvidenceRkey;
                      }
                      if (nextExpandedEvidenceOrder === totalNumberOfEvidence) {
                        // if (this.projectType !== 'investor') {
                        //     this.skipToStep(0);
                        //   } else {
                        //     const thisBackup = this;
                        //     setTimeout(
                        //       function()
                        //       {
                        //         thisBackup.skipToStep(0);
                        //       }, 2000);
                        //   }
                      } else {
                        // skip to next step
                        this.loadEvidenceFieldAndImages(
                          projectKey,
                          requestKey,
                          nextExpandedEvidence,
                          flowType,
                          evidenceTypes,
                        );
                      }
                    },
                    (error) => {
                      // alert(error.error.message);
                      $(".document-category-main").removeClass(
                        "uploading-document",
                      );
                      $(".documentUploaderSide").removeClass(
                        "uploading-document",
                      );
                    },
                  );
              } else {
                if (
                  nextExpandedEvidenceRkey !== requestKey &&
                  currentEvidenceField.type !== "DigitalVerification"
                ) {
                  requestKey = nextExpandedEvidenceRkey;
                }
                if (currentEvidenceField.type !== "DigitalVerification") {
                  currentEvidenceField.notSharable = false;
                }
                let normalFlow = true;
                if (currentEvidenceField.type === "DigitalVerification") {
                  let digitalVerificaitonGroup;
                  if (this.isKeyRelatedParty(oldRequest)) {
                    digitalVerificaitonGroup =
                      this.activeInvestorDetails
                        .activeRelatedPartyDigitalVerification;
                  } else {
                    digitalVerificaitonGroup =
                      this.activeInvestorDetails.digitalVerifications;
                  }
                  const digitalVerificationEvidenceField =
                    this.returnEvidenceFieldByOrigKey(
                      originalKey,
                      digitalVerificaitonGroup,
                    );
                  digitalVerificationEvidenceField.notSharable = false;
                  if (!this.allEvidenceShareable(digitalVerificaitonGroup)) {
                    nextExpandedEvidence = evidenceKey;
                    normalFlow = false;
                  }
                }
                if (
                  nextExpandedEvidenceOrder === totalNumberOfEvidence &&
                  normalFlow
                ) {
                  if (this.projectTypeFlow !== "review") {
                    if (this.allEvidenceShareable(evidenceTypes)) {
                      this.skipToStep(10, requestKey);
                    } else {
                      this.skipToStep(0);
                    }
                  } else {
                    const thisBackup = this;
                    setTimeout(function () {
                      thisBackup.skipToStep(0);
                    }, 2000);
                  }
                } else {
                  // skip to next step
                  // only relevant to SP update flow
                  // check if the key is RP or SP
                  this.loadEvidenceFieldAndImages(
                    projectKey,
                    requestKey,
                    nextExpandedEvidence,
                    flowType,
                    evidenceTypes,
                  );
                }
              }
            },
            (error) => {
              const notOldwerThan = $(".element-wrapper").find(".error-field-not-older");
              notOldwerThan.attr('validatorMessage', 'Document must not be older than ' + $('#notOlderThan input').val());
              notOldwerThan.removeClass('d-none');

              $(".document-category-main").removeClass("uploading-document");
              $(".documentUploaderSide").removeClass("uploading-document");
            },
          );
      } else if (
        this.projectTypeFlow === "review" &&
        currentEvidenceField.type === "DigitalVerification"
      ) {
        // Function to call and initiate al DV in sequential order
        let payload = {};

        let digitalEvidenceTypes;
        if (this.isKeyRelatedParty(requestKey)) {
          digitalEvidenceTypes =
            this.getActiveInvestorDetaisl()["DigitalVerificationsMatchArray"][
              currentEvidenceField.key
            ];
          if (currentEvidenceField.verificationType === "IdentityInstantAI") {
            digitalEvidenceTypes =
              this.getActiveInvestorDetaisl()["IdentityGoMatchArray"][
                currentEvidenceField.originalEvidenceKey
              ];
            if (!digitalEvidenceTypes) {
              digitalEvidenceTypes =
                this.getActiveInvestorDetaisl()["IdentityGoMatchArray"][
                  currentEvidenceField.key
                ];
            }
          }
        } else {
          digitalEvidenceTypes =
            this.getActiveInvestorDetaisl()["digitalVerifications"];
          if (
            $("#dvGenerateLink").attr("data-verificationtype") ===
            "IdentityInstantAI"
          ) {
            digitalEvidenceTypes = this.activeInvestorDetails.identityGoStep;
          }
          if (
            $("#dvGenerateLink").attr("data-verificationtype") === "Contract"
          ) {
            digitalEvidenceTypes = this.activeInvestorDetails.contractStep;
            payload = { email: "mmitik@bitsia.com" };
            if ($("#ContractEmail").val().toString().trim()) {
              payload = { email: $("#ContractEmail").val().toString().trim() };
            }
          }
        }
        let arrayOfDVRequests;
        arrayOfDVRequests = [];

        let counter = 0;
        $(".digital-evidence-label-sharing")
          .eq(counter)
          .find(".evidence-label-spinner")
          .removeClass("d-none");

        digitalEvidenceTypes.forEach((DV) => {
          if (
            !DV.verificationKey ||
            DV.verificationType === "IdentityInstantAI"
          ) {
            arrayOfDVRequests.push(
              this.userService.postEndPoint(
                this.buildEndPoint(
                  "digitalVerificationReview",
                  projectKey,
                  requestKey,
                  "",
                  true,
                  DV.originalEvidenceKey,
                ),
                payload,
                { responseType: "json" },
                { observe: "body" },
                "funds",
              ),
            );
          }
        });
        // concat(...arrayOfDVRequests).pipe(toArray()).subscribe(responses => {
        //   digitalEvidenceTypes.forEach(DV => {
        //     DV.reviewState = false;
        //     DV.justReviewed = true;
        //   });
        //   currentEvidenceField.dverificationUrl = responses[0]['verificationLink'];
        //   currentEvidenceField.confirmPersonage = additionalParam;
        //   this.loadEvidenceFieldAndImages( projectKey, requestKey, currentEvidenceField.key, flowType, evidenceTypes);
        // }, error => {
        //     alert(error.error.message);
        //     $('.document-category-main').removeClass('uploading-document');
        //     $('.documentUploaderSide').removeClass('uploading-document');
        //   });
        concat(...arrayOfDVRequests)
          .pipe(
            tap((dvArray) => {
              $(".digital-evidence-label-sharing")
                .eq(counter)
                .find(".evidence-label-spinner")
                .addClass("d-none");
              $(".digital-evidence-label-sharing")
                .eq(counter)
                .find(".evidence-label-check")
                .removeClass("d-none");
              counter++;
              $(".digital-evidence-label-sharing")
                .eq(counter)
                .find(".evidence-label-spinner")
                .removeClass("d-none");
            }),
            toArray(),
          )
          .subscribe(
            (responses) => {
              digitalEvidenceTypes.forEach((DV) => {
                DV.reviewState = false;
                DV.justReviewed = true;
              });
              currentEvidenceField.dverificationUrl =
                responses[0]["verificationLink"];
              currentEvidenceField.confirmPersonage = additionalParam;
              let key = currentEvidenceField.key;
              if (
                currentEvidenceField.verificationType === "IdentityInstantAI"
              ) {
                key = currentEvidenceField.originalEvidenceKey;
              }
              this.loadEvidenceFieldAndImages(
                projectKey,
                requestKey,
                key,
                flowType,
                evidenceTypes,
                false,
                this.isKeyRelatedParty(requestKey) ? false : true,
              );
            },
            (error) => {
              // alert(error.error.message);
              $(".document-category-main").removeClass("uploading-document");
              $(".documentUploaderSide").removeClass("uploading-document");
            },
          );
      }
    }
  }

  updateBasicFieldsStatus(requestKey, evidenceTypes) {
    evidenceTypes.forEach((element) => {
      if (!element.isBasicFields && !element.relatedPartyKey) {
        element.basicFieldsNotReviewed = false;
      }
    });
  }

  updateRelatedPartyFieldStatus(rpKey, evidenceTypes) {
    evidenceTypes.forEach((element) => {
      if (!element.isBasicFields && element.relatedPartyKey === rpKey) {
        element.basicFieldsNotReviewed = false;
      }
    });
  }

  /**
   * create new document with SP provided (and/or) edited data
   */
  createNewDocumentWithSPData(requestKey, evidenceField) {
    this.displayCreateNewDocumentConfirmation.next(false);
    // console.log('I will pass the following: ',this.getActiveProject(), this.getActiveInvestor(), '' , true, this.getActiveEvidenceKey())
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "acceptEvidenceAsCorrect",
          this.getActiveProject(),
          requestKey,
          "",
          true,
          this.getActiveEvidenceKey(),
        ),
        {},
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (evidenceField) {
            evidenceField.reviewState = false;
            const evidenceKey = evidenceField.key;
            const currentEvidence = $(
              '.document-category-main[data-EvidenceKey="' +
                evidenceField.key +
                '"]',
            );
            $(currentEvidence)
              .find("#collapse_document_image_icon")
              .attr("src", "/assets/images/check.svg");
            const currentExpandedEvidence = $(
              '.document-category-main[data-EvidenceKey="' +
                this.getActiveEvidenceKey() +
                '"]',
            );
            const currentExpandedEvidenceOrder = $(
              currentExpandedEvidence,
            ).attr("data-evidenceOrder");
            // const nextExpandedEvidenceOrder = parseInt(currentExpandedEvidenceOrder) + 1;
            const numberofLabels = $(".documentUploaderOwner").length;
            const numberOfEvidenceInList = $(".document-category-main").length;
            const totalNumberOfEvidence =
              numberOfEvidenceInList - numberofLabels;
            const nextExpandedEvidenceOrder = this.findNextEvidenceOrder(
              parseInt(currentExpandedEvidenceOrder, 10),
              totalNumberOfEvidence,
              this.activeInvestorDetails["projectEvidenceType"],
            );

            if (nextExpandedEvidenceOrder === totalNumberOfEvidence) {
              this.skipToStep(0);
            }
          }
          this.displayCreateNewDocumentConfirmation.next(true);
        },
        (error) => {
          // alert(error.error.message);
          $(".document-category-main").removeClass("uploading-document");
          $(".documentUploaderSide").removeClass("uploading-document");
        },
      );
  }

  /**
   * replaice the information provided by SP with existing document
   */
  replaceTheirEvidence(
    toBeReplace,
    toBeReplaceWith,
    accessKeys,
    reasonForReplacement,
    requestKey = this.getActiveInvestor(),
  ) {
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "acceptTheirs",
          this.getActiveProject(),
          requestKey,
          "",
          true,
          "",
          "",
          toBeReplaceWith,
          toBeReplace,
        ),
        {
          reasonForReplacement: reasonForReplacement,
          accessKeys: accessKeys,
        },
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          // this.displayCreateNewDocumentConfirmation.next(true);
          // on success directly set the reviewState = false
          const confirmedEvidenceType: any = this.getEvidenceDetails(
            this.getActiveEvidenceKey(),
          );
          confirmedEvidenceType.reviewState = false;
          const currentEvidence = $(
            '.document-category-main[data-EvidenceKey="' +
              this.getActiveEvidenceKey() +
              '"]',
          );
          $(currentEvidence)
            .find("#collapse_document_image_icon")
            .attr("src", "/assets/images/check.svg");
          $(".close-form-button.top-dialog-box img").click();
          const currentExpandedEvidence = $(
            '.document-category-main[data-EvidenceKey="' +
              this.getActiveEvidenceKey() +
              '"]',
          );
          const currentExpandedEvidenceOrder = $(currentExpandedEvidence).attr(
            "data-evidenceOrder",
          );
          // const nextExpandedEvidenceOrder = parseInt(currentExpandedEvidenceOrder) + 1;
          const numberofLabels = $(".documentUploaderOwner").length;
          const numberOfEvidenceInList = $(".document-category-main").length;
          const totalNumberOfEvidence = numberOfEvidenceInList - numberofLabels;
          const nextExpandedEvidenceOrder = this.findNextEvidenceOrder(
            parseInt(currentExpandedEvidenceOrder, 10),
            totalNumberOfEvidence,
            this.activeInvestorDetails["projectEvidenceType"],
          );

          if (nextExpandedEvidenceOrder === totalNumberOfEvidence) {
            if (this.projectType !== "investor") {
              // this.skipToStep(0);
            } else {
              const thisBackup = this;
              setTimeout(function () {
                thisBackup.skipToStep(0);
              }, 2000);
            }
          }
        },
        (error) => {
          // alert(error.error.message);
          $(".document-category-main").removeClass("uploading-document");
          $(".documentUploaderSide").removeClass("uploading-document");
        },
      );
  }
  /**
   *Retreive evidence history
   It's displayed in the evidence lightbox
   */
  retreiveEvidenceHistory(projectKey, requestKey, evidenceKey) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    this.userService
      .getEndPoint(
        this.buildEndPoint(
          "getEvidenceHistory",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          this.activeInvestorDetails.historyEntries =
            response["historyEntries"];
        },
        (error) => {
          // console.log('Following error occured while fetching record for additional data!', error);
          return;
        },
      );
  }

  /**
 *Retreive evidence access
 It's displayed in the evidence lightbox
 */
  retreiveEvidenceAccess(projectKey, requestKey, evidenceKey) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    this.userService
      .getEndPoint(
        this.buildEndPoint(
          "getEvidenceAccess",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          this.activeInvestorDetails.accessEntries =
            response["providersWithPreviousAccess"];
          this.skipToStep(13);
        },
        (error) => {
          // console.log('Following error occured while fetching record for additional data!', error);
          return;
        },
      );
  }

  /**
   * Enable (add) access
   */
  addEvidenceAccess(
    projectKey,
    requestKey,
    evidenceKey,
    accessKey,
    providerId,
    eventTarget?,
  ) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "getEvidenceAccess",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        { accessKey: accessKey, providerId: providerId },
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          if (eventTarget) {
            console.log("ACCESS GRANTED");
            $(eventTarget).removeClass("disabled");
          }
        },
        (error) => {
          // console.log('Following error occured while fetching record for additional data!', error);
          return;
        },
      );
  }

  /**
   * Revoke (remove) access
   */
  revokeEvidenceAccess(
    projectKey,
    requestKey,
    evidenceKey,
    accessKey,
    providerId,
    eventTarget?,
  ) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "revokeEvidenceAccess",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        { accessKey: accessKey, providerId: providerId },
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          if (eventTarget) {
            console.log("ACCESS REVOKED");
            $(eventTarget).removeClass("disabled");
          }
        },
        (error) => {
          // console.log('Following error occured while fetching record for additional data!', error);
          return;
        },
      );
  }

  // retreive history, images and fields attempt
  buildEvidenceLightbox(
    projectKey,
    requestKey,
    evidenceKey,
    stepArguments,
    documentKeys,
  ) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    const observableBatch = [];
    const observableBatchDocuments = [];
    if (!requestKey) {
      requestKey = this.getActiveInvestor();
    }
    const history = this.userService.getEndPoint(
      this.buildEndPoint(
        "getEvidenceHistory",
        projectKey,
        requestKey,
        "",
        true,
        evidenceKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
    const fields = this.userService.getEndPoint(
      this.buildEndPoint(
        "fillEvidenceField",
        projectKey,
        requestKey,
        "",
        true,
        evidenceKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
    const versions = this.userService.getEndPoint(
      this.buildEndPoint(
        "evidenceVersions",
        projectKey,
        requestKey,
        "",
        true,
        evidenceKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
    observableBatch.push(history);
    observableBatch.push(fields);
    observableBatch.push(versions);
    if (documentKeys) {
      documentKeys.map((item) => {
        observableBatchDocuments.push(
          this.userService.getEndPoint(
            this.buildEndPoint(
              "getDocumentsFile",
              projectKey,
              requestKey,
              "",
              true,
              evidenceKey,
              item.Key ? item.Key : item.key,
            ),
            { responseType: "json" },
            { observe: "response" },
            "funds",
          ),
        );
      });
    }

    forkJoin(observableBatch).subscribe(async (response) => {
      // decide wether to show the additional fields tab for the digital verifications
      let fieldTypes;
      let statuses;
      let generalStatus;
      if (response[1]["record"].type === "DigitalVerification") {
        if (
          response[1]["record"].verificationData &&
          response[1]["record"].verificationData.isResolved === true
        ) {
          if (
            response[1]["record"].verificationData.verificationType ===
            "Address"
          ) {
            // changes that need to be made because the BE response for CDD doesn't match the BE response for IDV
            const res = this.parseAVerificationData(response[1]);
            // end
            fieldTypes = await this.parseAddressFields(res.record);
            statuses = this.resolveAvDetailsStatuses(res, true);
          } else if (
            response[1]["record"].verificationData.verificationType ===
            "Identity"
          ) {
            // changes that need to be made because the BE response for CDD doesn't match the BE response for IDV
            const res = this.parseIDVerificationData(response[1]);
            // end
            const completedAt = this.helperService.formatISODate(
              res["record"].completedOn,
              this.helperService.getPdfFormatDate(),
              false,
            );
            const lastModified = this.helperService.formatISODate(
              res.record.updatedOn,
              this.helperService.getPdfFormatDate(),
              false,
            );
            let failed = false;
            let status = `Individual has been successfully verified on <strong>${completedAt}</strong>`;
            if (res["record"] && res["record"].status === "FAILED") {
              failed = true;
              status =
                `The verification has failed` +
                (lastModified ? " on " + lastModified : "");
            }
            // if the status was overwritten by the service provider
            if (
              res["record"] &&
              res["record"].statusOverwritten &&
              res["record"].comment
            ) {
              const comment = res["record"].comment;
              const statusT = res["record"].status.toLowerCase();
              const owner = res["record"].statusOverwritten.by;
              const lastModifiedT = this.helperService.formatISODate(
                res["record"].statusOverwritten.on,
                this.helperService.getPdfFormatDate(),
                false,
              );
              status = this.translate.instant(
                "evidencePreview.mainStatus.status4",
                {
                  owner: owner,
                  overwrittenOn: lastModifiedT,
                  status: statusT,
                  comment: comment,
                },
              );
            }
            statuses = [
              {
                icon: failed
                  ? "/assets/images/icon-triangle-orange.svg"
                  : "/assets/images/check.svg",
                color: "",
                title: "",
                status: status,
              },
            ];
            fieldTypes = this.parseIDVFields(res["record"]);
          } else if (
            response[1]["record"].verificationData.verificationType === "APS"
          ) {
            const res = this.parseAPSVerificationData(response[1]);
            fieldTypes = this.parseAPSData(res);
            statuses = fieldTypes.verificationStatuses;
            generalStatus = fieldTypes.generalStatus;
            let apsData;
            apsData = {};
            apsData.hits = res["record"]["hits"];
            apsData.typeOfHits = res["record"]["typeOfHits"];
            apsData.fields = res["record"]["fields"];
            stepArguments.apsData = apsData;
          } else if (
            response[1]["record"].verificationData.verificationType ===
            "IdentityInstantAI"
          ) {
            const res = this.parseIDVerificationData(response[1]);

            const completedAt = this.helperService.formatISODate(
              res["record"].completedOn,
              this.helperService.getPdfFormatDate(),
              false,
            );
            const lastModified = this.helperService.formatISODate(
              res.record.updatedOn,
              this.helperService.getPdfFormatDate(),
              false,
            );
            let failed = false;
            let status = `Individual has been successfully verified on <strong>${completedAt}</strong>`;
            if (res["record"] && res["record"].status === "FAILED") {
              failed = true;
              status =
                `The verification has failed` +
                (lastModified ? " on " + lastModified : "");
            }
            statuses = [
              {
                icon: failed
                  ? "/assets/images/icon-triangle-orange.svg"
                  : "/assets/images/check.svg",
                color: "",
                title: "",
                status: status,
              },
            ];
            fieldTypes = this.parseIDVGOFields(res["record"]);
          }
        }
        if (
          response[1]["record"].verificationData &&
          !response[1]["record"].verificationData.isResolved &&
          response[1]["record"].verificationData.verificationType === "Address"
        ) {
          const res = this.parseAVerificationData(response[1]);
          fieldTypes = await this.parseAddressFields(res.record, true);
          statuses = this.resolveAvDetailsStatuses(res, true);
        }
      }
      stepArguments.fieldTypesDV = fieldTypes;
      stepArguments.statuses = statuses;
      stepArguments.generalStatus = generalStatus;
      stepArguments.documentKeys = documentKeys;
      response.unshift(stepArguments);
      this.skipToStep(13, response);
      // function to load in all the images
      this.loadPreviewDocumentFiles(observableBatchDocuments, documentKeys);
    });
  }

  buildEvidenceLightboxReview(
    projectKey,
    requestKey,
    evidenceKey,
    disableScroll = false,
    documentKeys,
    hideActions,
    basicFields?,
    reloadInvestor?,
    startingFilename?,
    showVersionsLightbox?,
    currentVersionKey?,
  ) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    this.dispalyReviewPreviewlightbox.next({ response: {} });
    const observableBatch = [];
    const observableBatchDocuments = [];
    if (!requestKey) {
      requestKey = this.getActiveInvestor();
    }
    const history = this.userService.getEndPoint(
      this.buildEndPoint(
        "getEvidenceHistory",
        projectKey,
        requestKey,
        "",
        true,
        evidenceKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
    const fields = this.userService.getEndPoint(
      this.buildEndPoint(
        "fillEvidenceField",
        projectKey,
        requestKey,
        "",
        true,
        evidenceKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
    const access = this.userService
      .getEndPoint(
        this.buildEndPoint(
          "getEvidenceAccess",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .pipe(catchError((e) => of({})));
    const versions = this.userService
      .getEndPoint(
        this.buildEndPoint(
          "evidenceVersions",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .pipe(catchError((e) => of({})));
    observableBatch.push(history);
    observableBatch.push(fields);
    observableBatch.push(access);
    observableBatch.push(versions);
    if (documentKeys) {
      documentKeys.map((item) => {
        observableBatchDocuments.push(
          this.userService.getEndPoint(
            this.buildEndPoint(
              "getDocumentsFile",
              projectKey,
              requestKey,
              "",
              true,
              evidenceKey,
              item.Key ? item.Key : item.key ? item.key : item,
            ),
            { responseType: "json" },
            { observe: "response" },
            "funds",
          ),
        );
      });
    }
    forkJoin(observableBatch).subscribe(async (response) => {
      // decide wether to show the additional fields tab for the digital verifications
      let fieldTypes;
      let statuses;
      let apsData;

      if (response[1]["record"].type === "DigitalVerification") {
        if (
          response[1]["record"].verificationData &&
          response[1]["record"].verificationData.isResolved === true
        ) {
          if (
            response[1]["record"].verificationData.verificationType ===
            "Address"
          ) {
            // changes that need to be made because the BE response for CDD doesn't match the BE response for IDV
            const res = this.parseAVerificationData(response[1]);

            // end
            fieldTypes = await this.parseAddressFields(res.record);
            statuses = this.resolveAvDetailsStatuses(res, true);
          } else if (
            response[1]["record"].verificationData.verificationType ===
            "Identity"
          ) {
            // changes that need to be made because the BE response for CDD doesn't match the BE response for IDV
            const res = this.parseIDVerificationData(response[1]);
            // end
            const completedAt = this.helperService.formatISODate(
              res["record"].completedOn,
              this.helperService.getPdfFormatDate(),
              false,
            );
            const lastModified = this.helperService.formatISODate(
              res.record.updatedOn,
              this.helperService.getPdfFormatDate(),
              false,
            );
            let failed = false;
            
            let status = this.translate.instant(
              "evidencePreview.mainStatus.status1",
              { completedAt: completedAt },
            );
            if (res["record"] && res["record"].status === "FAILED") {
              failed = true;
              status = lastModified
                ? this.translate.instant("evidencePreview.mainStatus.status3", {
                    completedAt: lastModified,
                  })
                : this.translate.instant("evidencePreview.mainStatus.status2");
            }
            statuses = [
              {
                icon: failed
                  ? "/assets/images/icon-triangle-orange.svg"
                  : "/assets/images/check.svg",
                color: "",
                title: "",
                status: status,
              },
            ];
            fieldTypes = this.parseIDVFields(res["record"]);
          } else if (
            response[1]["record"].verificationData.verificationType === "APS"
          ) {
            const res = this.parseAPSVerificationData(response[1]);
            fieldTypes = this.parseAPSData(res);
            statuses = fieldTypes.verificationStatuses;
            apsData = {};
            apsData.hits = res["record"]["hits"];
            apsData.typeOfHits = res["record"]["typeOfHits"];
            apsData.fields = res["record"]["fields"];
          }
        }
      }
      response.unshift({
        apsData: apsData,
        documentKeys: documentKeys,
        statuses: statuses,
        fieldTypesDV: fieldTypes,
        showVersionsLightbox: showVersionsLightbox,
        startingFilename: startingFilename,
        dsiableScroll: disableScroll,
        requestKey: requestKey,
        hideActions: hideActions,
        reloadInvestor: reloadInvestor,
      });
      this.dispalyReviewPreviewlightbox.next({ response: response });
      this.loadPreviewDocumentFiles(observableBatchDocuments, documentKeys);
    });
  }

  buildEvidenceLightBoxInvestorOnClaim(
    requestKey,
    evidenceKey,
    stepArguments,
    documentKeys,
  ) {
    if (requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    const observableBatch = [];
    const observableBatchDocuments = [];
    // const history =  this.userService.getEndPoint(this.buildEndPoint(
    //   'getEvidenceHistory', projectKey, requestKey,'',true, evidenceKey), {responseType: 'json'}, {observe: 'body'} , 'funds');
    const fields = this.userService.getEndPoint(
      this.buildEndPoint(
        "claimSideEvidence",
        "",
        requestKey,
        "",
        true,
        evidenceKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
    // const versions =  this.userService.getEndPoint(this.buildEndPoint(
    //   'evidenceVersions', 'projectKey', requestKey,'',true, evidenceKey), {responseType: 'json'}, {observe: 'body'} , 'funds');
    // observableBatch.push (history);
    observableBatch.push(fields);
    // observableBatch.push(versions);
    if (documentKeys) {
      documentKeys.map((item) => {
        observableBatchDocuments.push(
          this.userService.getEndPoint(
            this.buildEndPoint(
              "claimSideDocumentFile",
              "",
              requestKey,
              "",
              true,
              evidenceKey,
              item.Key ? item.Key : item.key,
            ),
            { responseType: "json" },
            { observe: "response" },
            "funds",
          ),
        );
      });
    }
    let fieldTypes;
    let statuses;
    forkJoin(observableBatch).subscribe(async (response) => {
      if (
        response[0]["record"].verificationData &&
        !response[0]["record"].verificationData.isResolved &&
        response[0]["record"].verificationData.verificationType === "Address"
      ) {
        const res = this.parseAVerificationData(response[0]);
        fieldTypes = await this.parseAddressFields(res.record, true);
        statuses = this.resolveAvDetailsStatuses(res, true);
      }
      stepArguments.fieldTypesDV = fieldTypes;
      stepArguments.documentKeys = documentKeys;
      response.splice(0, 0, []);
      response.splice(2, 0, []);
      response.splice(0, 0, stepArguments);
      this.skipToStep(13, response);
      this.loadPreviewDocumentFiles(observableBatchDocuments, documentKeys);
    });
  }

  loadPreviewDocumentFiles(observableBatchDocuments, documentKeys) {
    forkJoin(observableBatchDocuments).subscribe(async (documents) => {
      const documentFilesBufferPdf = [];
      documents.forEach(async (document) => {
        const documentKey = document["url"].split("/").pop();
        // html element
        const htmlElementIMG = $(
          'app-preview-image[documentkey="' + documentKey + '"]',
        );
        const gtmlElementIframe = $(
          'app-preview-iframe[documentkey="' + documentKey + '"]',
        );
        // filename
        const filename = document["headers"].get("filename");
        // data
        const documentEvidence = this.returnEvidenceFieldByKey(
          documentKey,
          documentKeys,
        );
        // done
        documentEvidence.filename = this.helperService.generateDocumentFormat(
          document,
          filename,
        );

        // add it to the list of documents
        if (document["headers"].get("content-type") === "application/pdf") {
          // pdf generator needs a buffer not a base64 string
          documentFilesBufferPdf.push({
            type: "application/pdf",
            data: {
              type: "Buffer",
              data: document["body"].data,
            },
            filename: document["headers"].headers.get("filename"),
          });
        } else {
          const imgObj = {
            type: "image",
            data:
              "data:image/png;base64," +
              this.helperService.toBase64(document["body"].data),
            filename: document["headers"].headers.get("filename"),
          };
          // documentFiles.push(imgObj);
          // the images can stay base64 strings
          documentFilesBufferPdf.push(imgObj);
        }
        // remove loading img
        $("img.preview-file-loading").remove();
        if (document["headers"].get("content-type") === "application/pdf") {
          const bufferArray = new Uint8Array(document["body"].data);
          const blob = new Blob([bufferArray], { type: "application/pdf" });
          const data = window.URL.createObjectURL(blob);
          this.completeIframeData.next({
            data: data,
            documentEvidence: documentEvidence,
          });
          gtmlElementIframe.removeClass("d-none");
          htmlElementIMG.remove();
        } else {
          const baseData =
            "data:image/png;base64," +
            this.toBase64(document["body"].data);
          this.completeIframeData.next({
            data: baseData,
            documentEvidence: documentEvidence,
          });
          gtmlElementIframe.remove();
          htmlElementIMG.removeClass("d-none");
        }
      });

      // set documents for activeDVdetails
      this.setActiveDigitalVerificationFiles(documentFilesBufferPdf);

      // remove the loading icon from buttons
      setTimeout(function () {
        $("a.download-loading").removeClass("download-loading");
      }, 250);

      // re-trigger zoom
      setTimeout(function () {
        $(".document-slide-container-imgs").trigger("zoom.destroy");
        let html;
        html = $(".document-slide-container-imgs");
        html.zoom({ on: "grab", magnify: "1.33" });
      }, 250);
    });
    if (!observableBatchDocuments || observableBatchDocuments.length === 0) {
      const _this = this;
      setTimeout(function () {
        // remove the loading icon from buttons even if there are no documents to display
        // this is needed for instance for download PDF button
        $("a.download-loading").removeClass("download-loading");
        _this.setActiveDigitalVerificationFiles([]);
      }, 10);
    }
  }

  revertEvidence(projectKey, requestKey, evidenceKey, documentKeys) {
    this.dispalyReviewPreviewlightbox.next({ response: {} });

    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "revertEvidence",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        {},
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe((response) => {
        this.buildEvidenceLightboxReview(
          this.getActiveProject(),
          requestKey,
          response["key"],
          false,
          response["record"]["documents"],
          false,
          undefined,
          true,
        );
      });
  }

  createNewVersionofEvidence(
    projectKey,
    requestKey,
    evidenceKey,
    fields,
    type,
    isBasicFields?,
  ) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    let evidenceType;
    evidenceType = [
      {
        key: evidenceKey,
        type: type,
        state: {},
        documents: [],
        reviewState: false,
      },
    ];

    $(".foremost-dialog-box, .foremost-dialog-box img").trigger("click");
    this.skipToStep(7, {
      activeEvidence: evidenceKey,
      evidenceTypes: evidenceType,
      existingFields: evidenceType["fields"],
      loadingFromBe: true,
      flowType: "Update",
      requestKey: requestKey,
      replaceFlow: true,
    });

    // make call for new version and get key
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "evidenceVersions",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        {
          reasonForReplacement: "a new version has been created",
          fields: fields,
        },
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe((response) => {
        evidenceType = [
          {
            key: response["newVersionKey"],
            type: type,
            state: {},
            documents: [],
            reviewState: false,
          },
        ];
        if (isBasicFields) {
          // This means it is the basic fields replace flow
          // Unlike all other replace flows with the basic fields we do need prefill the information
          evidenceType[0].isBasicFieldsReplace = true;
          evidenceType[0].existingFields = fields;
        }
        this.setProjectTypeFlow("update");
        this.setExpandedEvidence(evidenceKey);
        this.loadEvidenceFieldAndImages(
          this.getActiveProject(),
          requestKey,
          response["newVersionKey"],
          "Update",
          evidenceType,
          true,
        );
      });
    // create evidenceType structure for the list to work
  }

  editIncompleteVersionOfEvidence(
    projectKey,
    requestKey,
    evidenceKey,
    fields,
    type,
    documents,
    isBasicFields,
  ) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    let evidenceType;
    evidenceType = [
      {
        key: evidenceKey,
        type: type,
        state: {},
        documents: documents ? documents : [],
        reviewState: false,
        fields: fields,
      },
    ];
    if (isBasicFields) {
      // This means it is the basic fields replace flow
      // Unlike all other replace flows with the basic fields we do need prefill the information
      evidenceType[0].isBasicFieldsReplace = true;
      evidenceType[0].existingFields = fields;
    }
    $(".foremost-dialog-box, .foremost-dialog-box img").trigger("click");
    this.setProjectTypeFlow("update");
    this.setExpandedEvidence(evidenceKey);
    this.loadEvidenceFieldAndImages(
      this.getActiveProject(),
      requestKey,
      evidenceKey,
      "Update",
      evidenceType,
      true,
    );
  }

  getVersionAccess(projectKey, requestKey, evidenceKey, payload) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    // save the fields
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "fillEvidenceField",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response1) => {
          // make the call to get the access
          this.userService
            .getEndPoint(
              this.buildEndPoint(
                "getEvidenceVersionAccess",
                projectKey,
                requestKey,
                "",
                true,
                evidenceKey,
              ),
              { responseType: "json" },
              { observe: "body" },
              "funds",
            )
            .subscribe(
              (response2) => {
                if (response2 === undefined) {
                  console.log(
                    "Error while fetching record for additional data!",
                  );
                  return;
                }
                this.displayAccessPopUp.next({
                  providers: response2["providersWithPreviousAccess"],
                  evidenceKey: evidenceKey,
                  requestKey: requestKey,
                });
                $(".document-category-main").removeClass("uploading-document");
                $(".documentUploaderSide").removeClass("uploading-document");
              },
              (error) => {
                // console.log('Following error occured while fetching record for additional data!', error);
                return;
              },
            );
        },
        (error) => {
          // alert('Error with updating evidence');
        },
      );
  }

  markEvidenceAsExpired(
    projectKey,
    requestKey,
    evidenceKey,
    reason,
    documentKeys,
  ) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    // make the call to post the access
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "markAsExpired",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        { reasonForReplacement: reason },
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          this.buildEvidenceLightboxReview(
            this.getActiveProject(),
            requestKey,
            evidenceKey,
            false,
            documentKeys,
            false,
          );
        },
        (error) => {
          // console.log('Following error occured while fetching record for additional data!', error);
          $(".expire-lightbox .loading-icon").addClass("d-none");
          // $('.expire-lightbox').addClass('d-none');
          // alert(error.error.message);
          return;
        },
      );
  }

  postVersionAccess(projectKey, requestKey, evidenceKey, accessKeys) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    // make the call to post the access
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "postEvidenceVersionAccess",
          projectKey,
          requestKey,
          "",
          true,
          evidenceKey,
        ),
        { accessKeys: accessKeys },
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          $(".close-form-button.top-dialog-box img").click();
          this.skipToStep(0, {});
        },
        (error) => {
          // console.log('Following error occured while fetching record for additional data!', error);
          this.skipToStep(0, {});
          return;
        },
      );
  }

  toBase64(arr) {
    return btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), ""),
    );
  }
  /**
   * Load the additional investor details, IE the evidence that is filled/unfilled
   * @param isReload Flag to know wether we are reloading the same investor that's already collapsed
   * @param requestKey - Can be an investor key, a service provider key or a related party key for both
   * so we know to collapse the same related party and make a loading state first.
   * For example first time opening the investor isReload is false.
   * But we if we close a lightbox that changes the investor data then isReload is true.
   * Lightboxes are marked with a paramater 'reloadfund'
   */
  async loadAdditionalInvestorDetail(projectKey, requestKey, isReload = false) {
    if (projectKey === "" || requestKey === "") {
      console.log(
        "Could not fetch additional person data: required parameters are missing!",
      );
      return;
    }
    // default endpoint is set to funds
    const endpoint = "getFund";
    // project can be type investor (review process) or funds (service provider)
    // share information on service provider side

    this.loadInvestorShareData(projectKey, requestKey, isReload);
    // set the loading status on both funds and investor dashboards
    this.loadAdditionalInvestorDetailsStatus.next({
      key: projectKey,
      isLoading: true,
    });
    // load details about the fund/investor
    this.userService
      .getEndPoint(
        this.buildEndPoint(endpoint, projectKey, requestKey),
        // 'https://mocki.io/v1/5575e0fe-6e08-4ad2-be20-9defbbc545b0'
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        async (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }

          // check if it needs to lazyresove, if yes, set isLoading
          // parameter of loadAdditionalInvestorDetailsStatus to true
          let isDigitalVerificationLazyLoading = false;
          if (
            this.helperService.needsDigitalVerificationLazyResolve(
              response,
              requestKey,
            )
          ) {
            isDigitalVerificationLazyLoading = true;
            let currentStatus = this.getActiveInvestorPropertyValue(
              "verificationSubjectStatus",
            );
            this.setActiveInvestorPropertyValue(
              "verificationSubjectStatus",
              "LAZY_RESOLVE",
            );
            this.loadAdditionalInvestorDetailsStatus.next({
              key: projectKey,
              isLoading: isDigitalVerificationLazyLoading,
            });
            await this.doDigitalVerificationsLazyResolve(projectKey, requestKey)
              .then((resolve) => {
                if (resolve["resolvedVerifications"]) {
                  let recordResolved = true;
                  response["record"].digitalVerifications.forEach(
                    (digitalVerification) => {
                      resolve["resolvedVerifications"].forEach(
                        (resolvedVerification) => {
                          if (
                            digitalVerification.key === resolvedVerification
                          ) {
                            digitalVerification.isResolved = true;
                            // digitalVerification.status = 'Completed';
                          } else {
                            recordResolved = false;
                          }
                        },
                      );
                    },
                  );
                  if (recordResolved) {
                    response["record"].isResolved = recordResolved;
                    currentStatus = "COMPLETED";
                    response["record"].verificationSubjectStatus =
                      currentStatus;
                  }
                }
              })
              .catch((error) => console.error(error));
            isDigitalVerificationLazyLoading = false;
          }

          const investorID = response["key"];
          const investorData = response["record"];

          // update evidence types for the current/active investor/fund record
          if (
            this.activeInvestorList !== undefined &&
            investorID !== undefined &&
            typeof investorID === "string"
          ) {
            let isFound = false;
            this.activeInvestorList.forEach((investor, key) => {
              if (investor.key === investorID) {
                isFound = true;
                this.activeInvestorList[key].record = investorData;
                if (
                  this.isInvestorType() &&
                  !this.isKeyRelatedParty(requestKey)
                ) {
                  this.activeInvestorList[key].record.evidenceTypes =
                    investorData.evidenceTypes;
                }
              }
            });
          }

          // if the record is not a related party load the respective
          // related parties for the investor/fund record we are expanding
          if (!this.isKeyRelatedParty(requestKey)) {
            // Maybe here we can rerender all the information  again, before the related parties are loaded in
            // NOTE: endpoint for related parties is different for investor and fund side
            const endpointRelatedParties = "loadInvestorRelatedParties";
            this.loadAdditionalInvestorDetailsStatus.next({
              key: projectKey,
              response: investorData.typeOfInvestor,
              isLoading: isDigitalVerificationLazyLoading,
              relatedPartiesLoading: true,
            });
            this.userService
              .getEndPoint(
                this.buildEndPoint(
                  endpointRelatedParties,
                  projectKey,
                  requestKey,
                ),
                { responseType: "json" },
                { observe: "body" },
                "funds",
              )
              .subscribe(
                (responseRelatedParties) => {
                  if (responseRelatedParties === undefined) {
                    console.log(
                      "Error while fetching record for additional data!",
                    );
                    return;
                  }
                  investorData.relatedParties = responseRelatedParties;
                  // see if there was previously oppened related party
                  // we need this information because if we reload the data
                  // with an open related party we need to get the RP data as well
                  const prevActiveRelatedPartyKey =
                    this.activeInvestorDetails &&
                    this.activeInvestorDetails.activeRelatedParty
                      ? this.activeInvestorDetails.activeRelatedParty["key"]
                      : "";

                  let prevShareData;
                  let prevActiveTabMainProfile;

                  if (this.activeInvestorDetails) {
                    prevShareData = this.activeInvestorDetails.shareData;
                    prevActiveTabMainProfile =
                      this.activeInvestorDetails.activeTabMainProfile;
                  }

                  this.activeInvestorDetails = investorData;
                  if (isReload) {
                    this.activeInvestorDetails.shareData = prevShareData;
                    this.activeInvestorDetails.activeTabMainProfile =
                      prevActiveTabMainProfile;
                  }
                  if (isReload && prevActiveRelatedPartyKey) {
                    this.userService
                      .getEndPoint(
                        this.buildEndPoint(
                          "getInvestor",
                          projectKey,
                          prevActiveRelatedPartyKey,
                        ),
                        { responseType: "json" },
                        { observe: "body" },
                        "funds",
                      )
                      .subscribe(
                        (response3) => {
                          if (response3 === undefined) {
                            return;
                          }

                          // merge digital verificaitons with related party evidence
                          response3["record"]["evidenceTypes"] =
                            this.concatEvidenceTypesWithDigitalVerifications(
                              response3["record"]["evidenceTypes"],
                              response3["record"]["digitalVerifications"],
                            );

                          this.activeInvestorDetails["activeRelatedParty"] =
                            response3;
                          this.activeInvestorDetails[
                            "activeRelatedPartyDigitalVerification"
                          ] = response3["record"]["digitalVerifications"];
                          // For investor review process top notification
                          if (
                            this.isInvestorType() &&
                            !this.isKeyRelatedParty(requestKey)
                          ) {
                            this.parseElementsForReview(
                              projectKey,
                              requestKey,
                              investorData.relatedParties,
                            );
                            // this updates the dashboard on investor and SP side
                            this.loadAdditionalInvestorDetailsStatus.next({
                              key: projectKey,
                              response: investorData.typeOfInvestor,
                              isLoading: isDigitalVerificationLazyLoading,
                            });
                          } else if (!isReload) {
                            this.loadAdditionalInvestorDetailsStatus.next({
                              key: projectKey,
                              response: investorData.typeOfInvestor,
                              isLoading: isDigitalVerificationLazyLoading,
                            });
                          } else {
                            this.loadAdditionalInvestorDetailsStatus.next({
                              key: projectKey,
                              response: investorData.typeOfInvestor,
                              isLoading: isDigitalVerificationLazyLoading,
                            });
                          }
                        },
                        (error) => {
                          console.log(
                            "The following error occured when creating the person for project",
                            error,
                          );
                        },
                      );
                  } else {
                    if (!isReload) {
                      this.loadAdditionalInvestorDetailsStatus.next({
                        key: projectKey,
                        response: investorData.typeOfInvestor,
                        isLoading: isDigitalVerificationLazyLoading,
                      });
                    } else {
                      this.loadAdditionalInvestorDetailsStatus.next({
                        key: projectKey,
                        response: investorData.typeOfInvestor,
                        isLoading: isDigitalVerificationLazyLoading,
                      });
                    }
                  }
                },
                (error) => {
                  // console.log('Following error occured while fetching record for additional data!', error);
                  return;
                },
              );
          } else {
            // we are expanding a record for a Related Party
            // merge digital verificaitons with related party evidence
            response["record"]["evidenceTypes"] =
              this.concatEvidenceTypesWithDigitalVerifications(
                response["record"]["evidenceTypes"],
                response["record"]["digitalVerifications"],
              );

            this.activeInvestorDetails[
              "activeRelatedPartyDigitalVerification"
            ] = response["record"]["digitalVerifications"];
            this.activeInvestorDetails["activeRelatedParty"] = response;
            const rpIndex = this.activeInvestorDetails.relatedParties.findIndex(
              (rp) => rp.key === requestKey,
            );
            this.activeInvestorDetails.relatedParties[
              rpIndex
            ].record.investorFields = response["record"].investorFields;
            this.activeInvestorDetails.relatedParties[
              rpIndex
            ].record.relatedPartyRoles = response["record"].relatedPartyRoles;
            this.loadAdditionalInvestorDetailsStatus.next({
              key: projectKey,
              response: investorData.typeOfInvestor,
              isLoading: isDigitalVerificationLazyLoading,
            });
          }
        },
        (error) => {
          console.log(
            "Following error occured while fetching record for additional data!",
            error,
          );
          return;
        },
      );
  }
  // helper function for loadAdditionalInvestorDetail
  // not relevant on investor side
  // get the shareData
  async loadInvestorShareData(projectKey, requestKey, isReload) {
    if (!this.isInvestorType() && !isReload) {
      // reset the shareData
      this.activeInvestorShareData = undefined;
      this.loadAdditionalInvestorDetailsStatus.next({
        key: projectKey,
        isLoading: true,
      });
      this.userService
        .getEndPoint(
          this.buildEndPoint(
            "investorShareInformation",
            projectKey,
            requestKey,
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .toPromise()
        .then(
          (responseShareData) => {
            if (
              responseShareData === undefined ||
              responseShareData["shareEntities"].length === 0
            ) {
              return;
            }
            this.activeInvestorShareData = responseShareData;
            // this.loadAdditionalInvestorDetailsStatus
            //             .next({ 'key': projectKey});
          },
          (error) => {},
        )
        .catch();
    }
  }

  async loadAdditionalInvestorDetailInvestor(
    projectKey,
    requestKey,
    isReload = false,
  ) {
    if (projectKey === "" || requestKey === "") {
      console.log(
        "Could not fetch additional person data: required parameters are missing!",
      );
      return;
    }
    // default endpoint is set to funds
    let endpoint = "getFund";

    if (this.isInvestorType() && !this.isKeyRelatedParty(requestKey)) {
      // Updating the investor review process top notification on the dashboard to loading status
      this.displayLoadInvestorReviewStatus.next("loading");
      // if it's investor type endpoint is changed to investor
      endpoint = "investors";
    }

    // set the loading status on both funds and investor dashboards
    this.loadAdditionalInvestorDetailsStatus.next({
      key: projectKey,
      isLoading: true,
    });
    let response;
    response = {};
    response.records = [];

    response.records.push(
      JSON.parse(JSON.stringify(this.activeInvestorDetails)),
    );
    // check if it needs to lazyresove, if yes, set isLoading
    // parameter of loadAdditionalInvestorDetailsStatus to true
    let isDigitalVerificationLazyLoading = false;
    if (
      this.helperService.needsDigitalVerificationLazyResolve(
        response,
        requestKey,
      )
    ) {
      isDigitalVerificationLazyLoading = true;
      let currentStatus = this.getActiveInvestorPropertyValue(
        "verificationSubjectStatus",
      );
      this.setActiveInvestorPropertyValue(
        "verificationSubjectStatus",
        "LAZY_RESOLVE",
      );
      this.loadAdditionalInvestorDetailsStatus.next({
        key: projectKey,
        isLoading: isDigitalVerificationLazyLoading,
      });
      await this.doDigitalVerificationsLazyResolve(projectKey, requestKey)
        .then((resolve) => {
          if (resolve["resolvedVerifications"]) {
            let recordResolved = true;
            response["record"].digitalVerifications.forEach(
              (digitalVerification) => {
                resolve["resolvedVerifications"].forEach(
                  (resolvedVerification) => {
                    if (digitalVerification.key === resolvedVerification) {
                      digitalVerification.isResolved = true;
                      // digitalVerification.status = 'Completed';
                    } else {
                      recordResolved = false;
                    }
                  },
                );
              },
            );
            if (recordResolved) {
              response["record"].isResolved = recordResolved;
              currentStatus = "COMPLETED";
              response["record"].verificationSubjectStatus = currentStatus;
            }
          }
        })
        .catch((error) => console.error(error));
      isDigitalVerificationLazyLoading = false;
    }

    let investorID = response["key"];
    let investorData = response["record"];

    // handle special case for investor side
    if (this.isInvestorType() && !this.isKeyRelatedParty(requestKey)) {
      investorID = response["records"][0]["key"];
      investorData = response["records"][0]["record"];
    }

    // update evidence types for the current/active investor/fund record
    if (
      this.activeInvestorList !== undefined &&
      investorID !== undefined &&
      typeof investorID === "string"
    ) {
      let isFound = false;
      this.activeInvestorList.forEach((investor, key) => {
        if (investor.key === investorID) {
          isFound = true;
          this.activeInvestorList[key].record = investorData;
          if (this.isInvestorType() && !this.isKeyRelatedParty(requestKey)) {
            this.activeInvestorList[key].record.evidenceTypes =
              investorData.evidenceTypes;
          }
        }
      });
    }

    // if the record is not a related party load the respective
    // related parties for the investor/fund record we are expanding
    if (!this.isKeyRelatedParty(requestKey)) {
      // NOTE: endpoint for related parties is different for investor and fund side
      let endpointRelatedParties = "loadInvestorRelatedParties";
      const endpointRelatedPartiesCall = this.userService.getEndPoint(
        this.buildEndPoint(endpointRelatedParties, projectKey, requestKey),
        { responseType: "json" },
        { observe: "body" },
        "funds",
      );
      endpointRelatedPartiesCall.subscribe(
        (responseRelatedParties) => {
          if (responseRelatedParties === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }

          let relatedPartyObservable;
          if (this.isInvestorType() && investorData.isReviewed) {
            endpointRelatedParties = "investorRelatedParties";
            const arrayOfRps = [];
            const responseRelatedPartiesArray = <Array<any>>(
              responseRelatedParties
            );
            responseRelatedPartiesArray.forEach((rp) => {
              // claimSideRelatedParty
              arrayOfRps.push(
                this.userService.getEndPoint(
                  this.buildEndPoint(
                    "claimSideRelatedParty",
                    projectKey,
                    rp.record.originRequest
                      ? rp.record.originRequest
                      : requestKey,
                    rp.key,
                  ),
                  { responseType: "json" },
                  { observe: "body" },
                  "funds",
                ),
              );
            });
            relatedPartyObservable = concat(...arrayOfRps).pipe(toArray());
          } else {
            relatedPartyObservable = of(responseRelatedParties);
          }

          relatedPartyObservable.subscribe((responseRelatedParties) => {
            if (this.isInvestorType() && investorData.isReviewed) {
              responseRelatedParties = responseRelatedParties.map(
                (item) => item.request,
              );
            }
            investorData.relatedParties = responseRelatedParties;
            // see if there was previously oppened related party
            // we need this information because if we reload the data
            // with an open related party we need to get the RP data as well
            const prevActiveRelatedPartyKey =
              this.activeInvestorDetails &&
              this.activeInvestorDetails.activeRelatedParty
                ? this.activeInvestorDetails.activeRelatedParty["key"]
                : "";
            let prevShareData;
            if (this.activeInvestorDetails) {
              prevShareData = this.activeInvestorDetails.shareData;
            }
            this.activeInvestorDetails = investorData;
            if (prevShareData && isReload) {
              this.activeInvestorDetails.shareData = prevShareData;
            }
            if (isReload && prevActiveRelatedPartyKey) {
              if (!this.isInvestorType()) {
                // handle RP reload for fund side
                this.userService
                  .getEndPoint(
                    this.buildEndPoint(
                      "getInvestor",
                      projectKey,
                      prevActiveRelatedPartyKey,
                    ),
                    { responseType: "json" },
                    { observe: "body" },
                    "funds",
                  )
                  .subscribe(
                    (response3) => {
                      if (response3 === undefined) {
                        return;
                      }

                      // merge digital verificaitons with related party evidence
                      response3["record"]["evidenceTypes"] =
                        this.concatEvidenceTypesWithDigitalVerifications(
                          response3["record"]["evidenceTypes"],
                          response3["record"]["digitalVerifications"],
                        );

                      this.activeInvestorDetails["activeRelatedParty"] =
                        response3;
                      this.activeInvestorDetails[
                        "activeRelatedPartyDigitalVerification"
                      ] = response3["record"]["digitalVerifications"];
                      // For investor review process top notification
                      if (
                        this.isInvestorType() &&
                        !this.isKeyRelatedParty(requestKey)
                      ) {
                        this.parseElementsForReview(
                          projectKey,
                          requestKey,
                          investorData.relatedParties,
                        );
                        // this updates the dashboard on investor and SP side
                        this.loadAdditionalInvestorDetailsStatus.next({
                          key: projectKey,
                          response: investorData.typeOfInvestor,
                          isLoading: isDigitalVerificationLazyLoading,
                        });
                      } else if (!isReload) {
                        this.loadAdditionalInvestorDetailsStatus.next({
                          key: projectKey,
                          response: investorData.typeOfInvestor,
                          isLoading: isDigitalVerificationLazyLoading,
                        });
                      } else {
                        this.loadAdditionalInvestorDetailsStatus.next({
                          key: projectKey,
                          response: investorData.typeOfInvestor,
                          isLoading: isDigitalVerificationLazyLoading,
                        });
                      }
                    },
                    (error) => {
                      console.log(
                        "The following error occured when creating the person for project",
                        error,
                      );
                    },
                  );
              } else {
                // handle RP reload for investor side

                this.updateActiveInvestorRelatedParty(
                  prevActiveRelatedPartyKey,
                );
                this.parseElementsForReview(
                  projectKey,
                  requestKey,
                  investorData.relatedParties,
                );
                this.loadAdditionalInvestorDetailsStatus.next({
                  key: projectKey,
                  response: investorData.typeOfInvestor,
                  isLoading: isDigitalVerificationLazyLoading,
                });
              }
            } else {
              // For investor review process top notification
              if (this.isInvestorType()) {
                this.parseElementsForReview(
                  projectKey,
                  requestKey,
                  investorData.relatedParties,
                );
                this.loadAdditionalInvestorDetailsStatus.next({
                  key: projectKey,
                  response: investorData.typeOfInvestor,
                  isLoading: isDigitalVerificationLazyLoading,
                });
              } else if (!isReload) {
                this.loadAdditionalInvestorDetailsStatus.next({
                  key: projectKey,
                  response: investorData.typeOfInvestor,
                  isLoading: isDigitalVerificationLazyLoading,
                });
              } else {
                this.loadAdditionalInvestorDetailsStatus.next({
                  key: projectKey,
                  response: investorData.typeOfInvestor,
                  isLoading: isDigitalVerificationLazyLoading,
                });
              }
            }
          });
        },
        (error) => {
          // console.log('Following error occured while fetching record for additional data!', error);
          return;
        },
      );
    } else {
      // we are expanding a record for a Related Party
      // merge digital verificaitons with related party evidence
      response["record"]["evidenceTypes"] =
        this.concatEvidenceTypesWithDigitalVerifications(
          response["record"]["evidenceTypes"],
          response["record"]["digitalVerifications"],
        );

      this.activeInvestorDetails["activeRelatedPartyDigitalVerification"] =
        response["record"]["digitalVerifications"];
      this.activeInvestorDetails["activeRelatedParty"] = response;
      const rpIndex = this.activeInvestorDetails.relatedParties.findIndex(
        (rp) => rp.key === requestKey,
      );
      this.activeInvestorDetails.relatedParties[rpIndex].record.investorFields =
        response["record"].investorFields;
      this.loadAdditionalInvestorDetailsStatus.next({
        key: projectKey,
        response: investorData.typeOfInvestor,
        isLoading: isDigitalVerificationLazyLoading,
      });
    }
  }

  /**
   * depercated: do Digital Verifications lazy resolve
   */
  async doDigitalVerificationsLazyResolveDepercated(projectKey, requestKey) {
    return new Promise((resolve) => {
      this.interval = setInterval(async () => {
        if (this.intervalCycle === this.maxIntervalCycles - 1) {
          resolve(true);
          clearInterval(this.interval);
        }

        this.intervalCycle++;
        await this.resolveDigitalVerification(projectKey, requestKey)
          .then((responseLazyResolve) => {
            if (
              !this.helperService.needsDigitalVerificationLazyResolve(
                responseLazyResolve,
                requestKey,
              )
            ) {
              clearInterval(this.interval);
              resolve(responseLazyResolve);
            }
          })
          .catch();
      }, this.intervalDuration);
    });
  }

  /**
   * do Digital Verifications lazy resolve
   */
  async doDigitalVerificationsLazyResolve(projectKey, requestKey) {
    return new Promise(async (resolve) => {
      if (this.intervalCycle === this.maxIntervalCycles - 3) {
        clearInterval(this.interval);
        this.intervalCycle = 0;
        resolve(true);
      } else {
        await this.resolveDigitalVerification(projectKey, requestKey)
          .then((responseLazyResolve) => {
            if (
              !this.helperService.needsDigitalVerificationLazyResolve(
                responseLazyResolve,
                requestKey,
              )
            ) {
              clearInterval(this.interval);
              resolve(responseLazyResolve);
            } else if (this.doResolveIntervalCycles) {
              this.intervalCycle++;
              this.interval = setTimeout(async () => {
                resolve(
                  this.doDigitalVerificationsLazyResolve(
                    projectKey,
                    requestKey,
                  ),
                );
              }, this.intervalDuration);
            } else {
              clearInterval(this.interval);
              resolve(responseLazyResolve);
            }
          })
          .catch((error) => {
            console.error(error);
            resolve(true);
          });
      }
    });
  }

  /**
   * resolveDigitalVerification - resolve identity verification - usually when waitForJumioResponse == true
   * @param cryptoKey - cryptoKey for the verification
   */
  async resolveDigitalVerification(projectKey, requestKey) {
    // const lazyResolveEndpoint = this.isInvestorType()
    //                                               ? 'lazyResolveDigitalVerificationsInvestor' : 'lazyResolveDigitalVerificationsFunds';
    // attempt lazy resolve
    return await this.userService
      .postEndPoint(
        this.buildEndPoint(
          "lazyResolveDigitalVerificationsFunds",
          projectKey,
          requestKey,
        ),
        {},
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .toPromise();
  }

  /**
   * generates URL for investor sharing
   */
  genereateShareInvestorUrl(projectKey, requestKey, payload) {
    // console.log('$$$PAYLOAD', payload, details);
    // return;
    if (projectKey === "" || requestKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    this.userService
      .postEndPoint(
        this.buildEndPoint("generateInvestorShareUrl", projectKey, requestKey),
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          // add share information to state because share call takes too much time
          const now = new Date();
          const newDate = now.getTime();

          if (this.activeInvestorShareData) {
            this.activeInvestorShareData.shareEntities.unshift({
              sharedOn: newDate.toString(),
              sharedWith: payload.email,
            });
          } else {
            this.activeInvestorShareData = {
              shareEntities: [
                {
                  sharedOn: newDate.toString(),
                  sharedWith: payload.email,
                },
              ],
            };
          }

          this.skipToStep(10, {
            requestKey: requestKey,
            parsedEvidenceType:
              this.getActiveInvestorDetaisl().parsedEvidenceTypes,
            shareUrl: response["url"],
            email: payload.email,
            doNotRefreshRelatedProfiles: true,
          });

          this.loadAdditionalInvestorDetailsStatus.next({
            key: projectKey,
            response: this.activeInvestorDetails.typeOfInvestor,
          });
        },
        (error) => {
          // console.log('Following error occured while fetching record for additional data!', error);
          let errorMessage =
            "One or more documents is missing a purpose of use. Please check all documents before proceeding";
          if (error.status === 400) {
            errorMessage = "Provided email is not part of investors project";
          }
          if (error.status === 405) {
            errorMessage =
              "You may not create a share link with only a Compliance Check requested. A compliance check can only be performed if there is an identity verification or contract added. Please either remove the compliance check, or add either an identity verification or contract to the profile, and try to generate the share link again.";
          }
          this.skipToStep(10, {
            requestKey: requestKey,
            errorMessage: errorMessage,
            email: payload.email,
          });
          return;
        },
      );
  }
  /**
   * gets currently active investor
   */
  createRelatedParty(projectKey, requestKey, payload) {
    if (projectKey === "" || requestKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    this.userService
      .postEndPoint(
        this.buildEndPoint("createRelatedParty", projectKey, requestKey),
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          this.activeInvestorDetails["activeRelatedParty"] = response;
          this.loadAdditionalInvestorDetail(projectKey, requestKey, true);
          this.skipToStep(0);
        },
        (error) => {
          // alert(error.error.message);
          this.skipToStep(0);
          console.log(
            "Following error occured while fetching record for additional data!",
            error,
          );
          return;
        },
      );
  }
  /**
   * Get finos organization details from Auth0
   */
  async getFinosFundsOrganization() {
    // Get organization Data
    if (!this.fundsOrganizationDetails && !this.isInvestorType()) {
      this.userService
        .getEndPoint(
          "/api/organizations",
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              console.log("Error while fetching record for additional data!");
              return;
            }
            this.setfundsOrganizationDetails(response);
          },
          (error) => {
            // console.log('Following error occured while fetching record for additional data!', error);
            return;
          },
        );
    }
    // Get organization templates
    if (!this.fundsOrganizationTemplates) {
      this.userService
        .getEndPoint(
          this.buildEndPoint("KYCTemplates"),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              // console.log('Error while fetching record for additional data!');
              return;
            }
            this.fundsOrganizationTemplates = response;
          },
          (error) => {
            // console.log('Following error occured while fetching record for additional data!', error);
            return;
          },
        );
    }
  }
  postFinosFundsOrganization(organizationDetails) {
    this.userService
      .postEndPoint(
        "/api/organizations",
        organizationDetails,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          organizationDetails.RegistrationDate =
            this.helperService.getTimestamp(
              organizationDetails.RegistrationDate,
            );
          organizationDetails.LastVerified = this.helperService.getTimestamp(
            organizationDetails.LastVerified,
          );
          this.setfundsOrganizationDetails(organizationDetails);
          $(
            ".form-container .close-form-button, .form-container .close-form-button img",
          ).trigger("click");
        },
        (error) => {
          // alert(error.error.message);
          this.skipToStep(0);
          console.log(
            "Following error occured while fetching record for additional data!",
            error,
          );
          return;
        },
      );
  }

  setUUID(UUID) {
    this.UUID = UUID;
  }

  getUUID() {
    return this.UUID;
  }

  getAdminInfoByUUID(uuid) {
    this.userService
      .getEndPoint(
        "/api/admin/organizations/" + uuid,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          console.log("getAdminInfoByUUIDSuccess");
          this.setfundsOrganizationDetails(response);
          this.uuiDUpdates.next(uuid);
        },
        (error) => {
          console.log(
            "Following error occured while fetching record for additional data!",
            error,
          );
          this.setfundsOrganizationDetails(undefined);
          this.uuiDUpdates.next(uuid);
          return;
        },
      );
  }

  postAdminInfoByUUId(uuid, organizationDetails) {
    this.userService
      .postEndPoint(
        "/api/admin/organizations/" + uuid,
        organizationDetails,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          organizationDetails.RegistrationDate =
            this.helperService.getTimestamp(
              organizationDetails.RegistrationDate,
            );
          organizationDetails.LastVerified = this.helperService.getTimestamp(
            organizationDetails.LastVerified,
          );
          this.setfundsOrganizationDetails(organizationDetails);
          $(
            ".form-container .close-form-button, .form-container .close-form-button img",
          ).trigger("click");
        },
        (error) => {
          // alert(error.error.message);
          this.skipToStep(0);
          console.log(
            "Following error occured while fetching record for additional data!",
            error,
          );
          return;
        },
      );
  }

  /**
   * retrieve list of legal representatives for the active project
   * @returns current array of all legal representative objects
   */
  getActiveInvestors() {
    return this.activeInvestorList;
  }

  /**
   * sets currently active person
   * @param investorId string
   */
  setActiveInvestor(InvestorID) {
    let isFound = false;
    if (
      this.activeInvestorList !== undefined &&
      InvestorID !== undefined &&
      typeof InvestorID === "string"
    ) {
      this.activeInvestorList.forEach((investor) => {
        if (investor.key === InvestorID) {
          isFound = true;
          this.activeInvestor = InvestorID;
          this.activeInvestorDetails = investor;
          if (this.unaddedURLKey) {
            if (this.activeInvestorDetails.record) {
              this.activeInvestorDetails.record.shareLink =
                this.unaddedURLKey.slice();
            } else {
              this.activeInvestorDetails.shareLink = this.unaddedURLKey.slice();
            }
            this.unaddedURLKey = undefined;
          }
        }
      });
    }
    if (InvestorID === "") {
      this.activeInvestor = undefined;
    }
  }

  /**
   * need a direct version fo this function for the claim/preview screen
   */
  setActiveInvestorDirect(InvestorID, investorDetails) {
    this.activeInvestor = InvestorID;
    this.activeInvestorDetails = investorDetails;
  }

  /**
   * sets currently active person details
   * @param investorId string
   */
  setActiveInvestorDetails(details) {
    this.activeInvestorDetails = details;
  }

  /**
   * sets currently active person details
   * @param investorId string
   */
  getActiveInvestorPropertyValue(property) {
    if (
      this.activeInvestorDetails &&
      this.activeInvestorDetails.record &&
      this.activeInvestorDetails.record[property]
    ) {
      return this.activeInvestorDetails.record[property];
    }
  }

  /**
   * sets currently active person details
   * @param investorId string
   */
  setActiveInvestorPropertyValue(property, value) {
    if (
      this.activeInvestorDetails &&
      this.activeInvestorDetails.record &&
      this.activeInvestorDetails.record[property]
    ) {
      this.activeInvestorDetails.record[property] = value;
    }
  }

  /**
   * sets currently active person details even if the property does not exist
   * @param investorId string
   */
  forceSetActiveInvestorPropertyValue(property, value) {
    if (this.activeInvestorDetails) {
      this.activeInvestorDetails[property] = value;
    }
  }

  /**
   * gets currently active person details
   * @param investorId string
   */
  getActiveInvestorDetaisl() {
    return this.activeInvestorDetails;
  }
  /**
   * resets active investor
   */
  resetActiveInvestor() {
    this.activeInvestor = undefined;
    this.activeInvestorDetails = undefined;
  }
  /**
   * ACTIVE EVIDENCE STATE CONTROL FUNCTIONS
   */
  setExpandedEvidence(evidenceKey) {
    if (this.activeInvestorDetails.evidenceTypes.expandedEvidence) {
      this.activeInvestorDetails.evidenceTypes.expandedEvidence = evidenceKey;
    }
  }
  getExpandedEvidence(evidenceKey) {
    return (this.activeInvestorDetails.evidenceTypes.expandedEvidence =
      evidenceKey);
  }
  // FUNCTION TO PARSE ALREADY INPUTED EVIDENCE AND STORE IT IN STATE
  // RETURNS PAYLOAD FOR POST REQUEST IF NEEDED
  // feDateFormat is for a special case to show the date on the
  saveFieldsState(
    evidenceKey,
    evidenceTypes,
    dvType?,
    event?,
    feDateFormat?,
    switchedFromUI?,
    requestKey?,
  ) {
    let fields: any;
    let parentClass = "";
    let fieldsParsed = {};
    const payload: any = {};
    // accounts for digital verifications
    let digitalEvidenceTypes;
    let evidenceTypeDV;
    if (dvType && requestKey) {
      if (this.isKeyRelatedParty(requestKey)) {
        if (this.isInvestorType()) {
          digitalEvidenceTypes =
            this.getActiveInvestorDetaisl()["DigitalVerificationsMatchArray"][
              evidenceKey
            ];
        } else {
          digitalEvidenceTypes =
            this.getActiveInvestorDetaisl()[
              "activeRelatedPartyDigitalVerification"
            ];
        }
      } else {
        digitalEvidenceTypes =
          this.getActiveInvestorDetaisl()["digitalVerifications"];
      }
    }
    if (dvType === "Address") {
      fieldsParsed = this.parseAVData(event);
      if (fieldsParsed === undefined) {
        return;
      } else {
        const notOlderThanInput = $("#notOlderThanNAddress input");
        if (notOlderThanInput.length) {
          if (
            notOlderThanInput.val() !== null ||
            notOlderThanInput.val() !== undefined ||
            notOlderThanInput.val() !== "" ||
            notOlderThanInput.val() !== "emptySelect"
          ) {
            payload["notOlderThan"] = this.helperService.prepareDateForApi(
              $(notOlderThanInput).val() as string,
            );
          }
        }
        evidenceTypeDV = this.checkDigitalVerification(
          digitalEvidenceTypes,
          "Address",
        );
        payload["fields"] = fieldsParsed;
        if (evidenceTypeDV && evidenceTypeDV.verificationData) {
          evidenceTypeDV.force = fieldsParsed["force"];
          evidenceTypeDV.notOlderThan = notOlderThanInput.val();
          if (!evidenceTypeDV.verificationData.verificationPresets) {
            evidenceTypeDV.verificationData.verificationPresets = {};
          }
          if (payload["fields"]) {
            if (payload["fields"]["addressData"]) {
              evidenceTypeDV.verificationData.verificationPresets.addressData =
                payload["fields"]["addressData"];
            }
            if (payload["fields"]["allowedDocuments"]) {
              evidenceTypeDV.verificationData.verificationPresets.allowedDocuments =
                payload["fields"]["allowedDocuments"];
            }
            if (payload["fields"]["documentsRequested"]) {
              evidenceTypeDV.verificationData.verificationPresets.documentsRequested =
                payload["fields"]["documentsRequested"];
            }
            if (payload["fields"]["idinAllowed"]) {
              evidenceTypeDV.verificationData.verificationPresets.idinAllowed =
                payload["fields"]["idinAllowed"];
            }
            if (payload["fields"]["verificationLetterRequested"]) {
              evidenceTypeDV.verificationData.verificationPresets.verificationLetterRequested =
                payload["fields"]["verificationLetterRequested"];
            }
            if (payload["fields"]["documentNotes"]) {
              evidenceTypeDV.verificationData.verificationPresets.documentNotes =
                payload["fields"]["documentNotes"];
            }
          }
        }
        return payload;
      }
    } else if (dvType === "Identity") {
      parentClass = "#id-verification-collapsible ";
      fieldsParsed = this.parseIDVData("funds");
      if (fieldsParsed === undefined) {
        return;
      } else {
        const notOlderThanInput = $("#notOlderThanNID input");
        if (notOlderThanInput.length) {
          if (
            notOlderThanInput.val() !== null ||
            notOlderThanInput.val() !== undefined ||
            notOlderThanInput.val() !== "" ||
            notOlderThanInput.val() !== "emptySelect"
          ) {
            payload["notOlderThan"] = this.helperService.prepareDateForApi(
              $(notOlderThanInput).val() as string,
            );
          }
        }
        payload["fields"] = fieldsParsed;
        evidenceTypeDV = this.checkDigitalVerification(
          digitalEvidenceTypes,
          "Identity",
        );
        if (evidenceTypeDV && evidenceTypeDV.verificationData) {
          evidenceTypeDV.force = fieldsParsed["force"];
          evidenceTypeDV.notOlderThan = notOlderThanInput.val();
          if (!evidenceTypeDV.verificationData.verificationPresets) {
            evidenceTypeDV.verificationData.verificationPresets = {};
          }
        }
        return payload;
      }
    } else if (dvType === "IdentityInstantAI") {
      parentClass = "#id-verification-collapsible ";

      const notOlderThanInput = $("#notOlderThanNIDGO input");
      if (notOlderThanInput.length) {
        if (
          notOlderThanInput.val() !== null ||
          notOlderThanInput.val() !== undefined ||
          notOlderThanInput.val() !== "" ||
          notOlderThanInput.val() !== "emptySelect"
        ) {
          payload["notOlderThan"] = this.helperService.prepareDateForApi(
            $(notOlderThanInput).val() as string,
          );
        }
      }
      const fields: any = {};
      if (
        $("#IDVerificationCountryGo").val() &&
        $("#IDVerificationCountryGo").val() !== "emptySelect"
      ) {
        fields.country = $("#IDVerificationCountryGo").val();
      }
      payload["fields"] = fields;
      evidenceTypeDV = this.checkDigitalVerification(
        digitalEvidenceTypes,
        "IdentityInstantAI",
      );

      if (evidenceTypeDV) {
        evidenceTypeDV.force = $("#forceIDGO").prop("checked");
        evidenceTypeDV.notOlderThan = notOlderThanInput.val();
        if (!evidenceTypeDV.verificationData.verificationPresets) {
          evidenceTypeDV.verificationData.verificationPresets = {};
        }
      }
      if ($("#forceIDGO").prop("checked")) {
        payload["fields"]["force"] = true;
      }
      return payload;
    } else if (dvType === "Contract") {
      const selectedTemplate = $("#ContractTemplate").val();
      payload["fields"] = { template: selectedTemplate };
      evidenceTypeDV = this.checkDigitalVerification(
        digitalEvidenceTypes,
        "Contract",
      );
      if (evidenceTypeDV && evidenceTypeDV.verificationData) {
        if (!evidenceTypeDV.verificationData.verificationPresets) {
          evidenceTypeDV.verificationData.verificationPresets = {};
        }
        evidenceTypeDV.verificationData.verificationPresets.template =
          selectedTemplate;
      }
      return payload;
    } else if (dvType === "APS") {
      const exactMatch = $("#exactMatch:checked").length;
      const fuzinessLEvel = parseInt($("#FuzinessLevel").val().toString(), 10);
      let apsFields;
      apsFields = {};
      if (exactMatch) {
        apsFields.exactMatch = true;
      }
      if (fuzinessLEvel) {
        apsFields.fuzziness = fuzinessLEvel / 10;
      }
      payload["fields"] = apsFields;
      return payload;
    }
    if ($("#finalizeRequired").length) {
      if ($("#finalizeRequired").prop("checked")) {
        payload["finalizeRequired"] = true;
      }
    }

    // Account for Notes on the review side
    if ($("#reviewFlowNotes").length) {
      fieldsParsed["Notes"] = $("#reviewFlowNotes").text();
    }

    // Normal evidence types
    fields = $(
      ".parseEvidenceList.evidence-is-active " +
        parentClass +
        ".input-fields-wrapper",
    )
      .find("input,select,textarea")
      .not("#evidence_image_id")
      .not('[inputformid="notOlderThanN"]')
      .not("#claimCorrect")
      .not("#finalizeRequired")
      .not(".google-autocomplete-review")
      .not(".purpose-checkbox");
    for (const field of fields) {
      if ($(field).attr("skipParse")) {
        continue;
      }
      if ($(field).attr("id") === "client_notes") {
        continue;
      }
      // parsing of date fields because dates don't have id on them directly
      if ($(field).attr("id") === undefined) {
        if (
          $(field).val() !== null ||
          $(field).val() !== undefined ||
          $(field).val() !== "" ||
          $(field).val() !== "emptySelect"
        ) {
          if (!feDateFormat) {
            if (switchedFromUI) {
              if (
                ($(field).val() as string) !== "" &&
                $(field).val() !== null
              ) {
                const apitDate = this.helperService.prepareDateForApi(
                  $(field).val() as string,
                );
                const date = new Date(apitDate);
                if (date.toString() != "Invalid Date") {
                  fieldsParsed[$(field).attr("inputformId")] = date.getTime();
                }
              }
            } else if (
              ($(field).val() as string) !== "" &&
              $(field).val() !== null
            ) {
              fieldsParsed[$(field).attr("inputformId")] =
                this.helperService.prepareDateForApi($(field).val() as string);
            }
          } else {
            if (($(field).val() as string) !== "" && $(field).val() !== null) {
              const apitDate = this.helperService.prepareDateForApi(
                $(field).val() as string,
              );
              const date = new Date(apitDate);
              if (date.toString() != "Invalid Date") {
                fieldsParsed[$(field).attr("inputformId")] = date.getTime();
              }
            }
          }
        }
      } else {
        if (
          $(field).val() !== "" &&
          $(field).val() !== null &&
          $(field).val() !== "emptySelect"
        ) {
          fieldsParsed[$(field).attr("name")] = $(field).val();
        }
      }
    }
    const purposesOfUse = [];
    const purposesOfUseElements = $(
      '.evidence-is-active .purposes-checkbox-list[data-purposeEvidenceKey ="' +
        evidenceKey +
        '"] input:checked',
    );
    $.each(purposesOfUseElements, function (key, value) {
      purposesOfUse.push($(value).attr("name"));
    });
    if (evidenceKey) {
      const evidenceField = this.returnEvidenceFieldByKey(
        evidenceKey,
        evidenceTypes,
      );
      evidenceField.fields = fieldsParsed;
      // evidenceField.purposes = purposesOfUse;
      if (!this.isInvestorType()) {
        evidenceField.purposes = purposesOfUse;
      }
    }
    payload["fields"] = fieldsParsed;
    // payload["purposesOfUse"] = purposesOfUse;

    if (!this.isInvestorType()) {
      payload["purposesOfUse"] = purposesOfUse;
    }

    return payload;
  }

  // evidenceTypes tells us which evidence are in the upload list
  deleteEvidence(
    projectKey,
    requestKey,
    evidenceKey,
    evidenceTypes,
    evidenceOriginalKey?,
    digitalVerificationGroup?,
  ) {
    // add loading
    $(".document-category-main").addClass("uploading-document");
    $(".documentUploaderSide").addClass("uploading-document");
    let evidenceToSkipTo;
    const evidenceIndex = evidenceTypes.findIndex(
      (evidence) => evidence.key === evidenceKey,
    );
    console.log(evidenceIndex);
    if (evidenceIndex !== -1) {
      const evidenceToDelete = $(
        '.document-category-main-all[data-evidenceKey="' + evidenceKey + '"]',
      );
      // evidence we want to delete is only evience
      if (evidenceTypes.length === 1) {
        evidenceToSkipTo = undefined;
      } else if (evidenceIndex === 0) {
        // evidence we want to delete is first, go to evidence 2
        evidenceToSkipTo = $(
          '.document-category-main-all[data-evidenceKey="' +
            evidenceTypes[1].key +
            '"]',
        );
      } else {
        // evidence we want to delete is not first, return to the previous one
        evidenceToSkipTo = $(
          '.document-category-main-all[data-evidenceKey="' +
            evidenceTypes[evidenceIndex - 1].key +
            '"]',
        );
      }
      this.userService
        .deleteEndpoint(
          this.buildEndPoint(
            "fillEvidenceField",
            projectKey,
            requestKey,
            "",
            true,
            evidenceOriginalKey ? evidenceOriginalKey : evidenceKey,
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              console.log("Error while deleting evidence");
              return;
            }
            if (evidenceToSkipTo === undefined) {
              this.skipToStep(0, {});
            } else {
              // remove evidence from list
              // $(evidenceToDelete).remove();
              // remvove loading
              $(".document-category-main").removeClass("uploading-document");
              $(".documentUploaderSide").removeClass("uploading-document");
              evidenceTypes[evidenceIndex].hideFromList = true;
              if (evidenceOriginalKey && !this.isInvestorType()) {
                // exit the flow if it's a digital verification
                this.skipToStep(0, {});
              } else {
                // click on next evidence
                $(evidenceToSkipTo).find(".clickable-element").trigger("click");
              }
              // evidenceTypes.splice(evidenceIndex, 1);
            }
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }

  removeEvidenceByKey(evidenceKey, evidenceTypes) {
    evidenceTypes = evidenceTypes.splice(
      evidenceTypes.findIndex((evidence) => evidence.key === evidenceKey),
      1,
    );
    return evidenceTypes;
  }

  returnEvidenceFieldByKey(evidenceKey, evidenceTypes) {
    let returnValue = evidenceTypes.find(
      (evidence) => evidence.key === evidenceKey,
    );
    if (returnValue === undefined) {
      returnValue = evidenceTypes.find(
        (evidence) => evidence.Key === evidenceKey,
      );
    }
    if (returnValue === undefined) {
      returnValue = evidenceTypes.find(
        (evidence) => evidence.originalEvidenceKey === evidenceKey,
      );
    }
    return returnValue;
  }

  returnEvidenceFieldByOrigKey(evidenceKey, evidenceTypes) {
    return evidenceTypes.find(
      (evidence) => evidence.originalEvidenceKey === evidenceKey,
    );
  }

  returnEvidenceFieldByVerificationType(veriificationType, evidenceTypes) {
    return evidenceTypes.find(
      (evidence) => evidence.verificationType === veriificationType,
    );
  }

  returnEvidencePositionByKey(evidenceKey, evidenceTypes) {
    return evidenceTypes.findIndex((evidence) => evidence.key === evidenceKey);
  }

  returnDocumentPositionByKey(documentKey, documents) {
    return documents.findIndex((document) => document.Key === documentKey);
  }

  // LOAD DOCUMENTS, FIELDS FROM BE SO THEY CAN BE PUT IN THE STATE
  // THEN RENDER DOCUMENT
  loadEvidenceFieldAndImages(
    projectKey,
    requestKey,
    evidenceKey,
    flowType,
    evidenceTypes,
    replaceFlow?,
    isSingleStep?,
  ) {
    if (projectKey === "" || requestKey === "" || evidenceKey === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    // Neccesary to determine wether it's the last on in the list so we can click finalize
    const currentExpandedEvidence = $(
      '.document-category-main[data-EvidenceKey="' + evidenceKey + '"]',
    );
    const currentExpandedEvidenceOrder = $(currentExpandedEvidence).attr(
      "data-evidenceOrder",
    );
    const nextExpandedEvidenceOrder =
      parseInt(currentExpandedEvidenceOrder, 10) + 1;
    let isFinalize = false;
    let digitalEvidenceTypes;

    if (
      nextExpandedEvidenceOrder === evidenceTypes.length ||
      evidenceTypes.length === 1
    ) {
      isFinalize = true;
    }
    // Neccesary to determine wether it's the last on in the list so we can click finalize

    let step = 7;
    if (this.projectTypeFlow === "review") {
      step = 15;
    }

    // RIGHT EVIDENCE TYPES ARE NOT SENT, ITS NOT THE MERGED LIST
    const evidenceType = this.returnEvidenceFieldByKey(
      evidenceKey,
      evidenceTypes,
    );
    if (!evidenceType) {
      console.log(
        "evidence type not found, if its not digital verification might be an error",
      );
      if (this.projectTypeFlow === "review") {
        this.skipToStep(0);
      } else {
        const params = {
          requestKey: this.getActiveInvestor(),
          email:
            this.getActiveInvestorDetaisl().shareData &&
            this.getActiveInvestorDetaisl().shareData.shareEntities.length >= 1
              ? this.getActiveInvestorDetaisl().shareData.shareEntities[0]
                  .sharedWith
              : undefined,
          evidenceTypes: this.getActiveInvestorDetaisl().evidenceTypes,
        };

        this.skipToStep(10, params);
      }
      return;
    }

    this.skipToStep(step, {
      activeEvidence: evidenceKey,
      evidenceTypes: evidenceTypes,
      loadingFromBe: true,
      flowType: flowType,
      requestKey: requestKey,
      replaceFlow: replaceFlow,
      isSingleStep: isSingleStep,
    });

    // RETURNS A REFFERENCE TO THE FIELD ITSELF SO WE CAN UPDATE ITS STATE WITHOUT WORRYING IF ITS
    // RELATED PARTY EVIDENCE OR INVESTOR EVIDENCE
    const evidenceDocuments = evidenceType.documents;

    // passedFromBe tells us if we already retreived the evidence from BE
    if (!evidenceType.passedFromBe && projectKey && requestKey && evidenceKey) {
      const observableBatch = [];
      // RETREIVE FIELDS
      if (!evidenceType.isBasicFields) {
        const fields = this.userService.getEndPoint(
          this.buildEndPoint(
            "fillEvidenceField",
            projectKey,
            requestKey,
            "",
            true,
            evidenceKey,
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        );

        observableBatch.push(fields);
      }

      // if investor - review side use review endpoint
      if (this.projectTypeFlow === "review") {
        const fieldsForReview = this.userService.getEndPoint(
          this.buildEndPoint(
            "reviewEvidence",
            projectKey,
            requestKey,
            "",
            true,
            evidenceKey,
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        );
        observableBatch.push(fieldsForReview);
      }
      // RETREIVE DOCUMENT FILENAMES
      if (evidenceDocuments) {
        evidenceDocuments.map((item) => {
          observableBatch.push(
            this.userService.getEndPoint(
              this.buildEndPoint(
                "getDocumentsFileMeta",
                projectKey,
                requestKey,
                "",
                true,
                evidenceKey,
                item.Key ? item.Key : item.key,
              ),
              { responseType: "json" },
              { observe: "body" },
              "funds",
            ),
          );
        });
      }

      // if the document is digital verificaiton we need to load all the evidence of all digital verifications
      if (evidenceType.type === "DigitalVerification") {
        if (this.isKeyRelatedParty(requestKey)) {
          if (this.isInvestorType()) {
            digitalEvidenceTypes =
              this.getActiveInvestorDetaisl()["DigitalVerificationsMatchArray"][
                evidenceType.key
              ];
          } else {
            digitalEvidenceTypes =
              this.getActiveInvestorDetaisl()[
                "activeRelatedPartyDigitalVerification"
              ];
          }
        } else {
          digitalEvidenceTypes =
            this.getActiveInvestorDetaisl()["digitalVerifications"];
        }
        digitalEvidenceTypes.forEach((digitalEvidenceType) => {
          // we will skip the one already loaded
          if (digitalEvidenceType.originalEvidenceKey !== evidenceType.key) {
            const digitalVerificationFields = this.userService.getEndPoint(
              this.buildEndPoint(
                "fillEvidenceField",
                projectKey,
                requestKey,
                "",
                true,
                digitalEvidenceType.originalEvidenceKey,
              ),
              { responseType: "json" },
              { observe: "body" },
              "funds",
            );
            observableBatch.push(digitalVerificationFields);
          }
        });
      }
      let verificationKeyFirstDV;
      forkJoin(observableBatch).subscribe(
        (response: any) => {
          // ADD THE FIELDS TO THE STATE IF THEY EXIST
          // FIRST ELEMENT IS ALWAYS THE FIELD
          if (!evidenceType.isBasicFields) {
            evidenceType["fields"] = response[0].record.fields;
            evidenceType["verificationData"] =
              response[0].record.verificationData;
            evidenceType["notOlderThan"] = response[0].record.notOlderThan;
            evidenceType["finalizeRequired"] = response[0].record.finalizeRequired;
            evidenceType["force"] = response[0].record.force;
            evidenceType.verificationKey = response[0].record.verificationKey;
            verificationKeyFirstDV = response[0].record.verificationKey;
          } else {
          }
          // RELATED PARTIES DOESNT HAVE ACCESS
          if (!evidenceType.isBasicFields && response[0].record.access) {
            evidenceType["purposes"] = response[0].record.access.purposesOfUse;
          } else {
            evidenceType["purposes"] = ["ProofOfAddress"];
          }
          let documentsStartFrom = 1;
          // parse the fieldsForReview
          if (this.projectTypeFlow === "review") {
            documentsStartFrom = 2;
            evidenceType["existingDocuments"] = response[1];
          }
          // THE REST OF THE RESPONSE ARE DOCUMENTS OR DIGITAL VERIFICATIONS
          // CANT GET FILENAMES TO SHOW FROM HEADER
          const fileNameIdPairing = [];
          if (response.length > documentsStartFrom) {
            if (
              evidenceDocuments.length &&
              evidenceType.type !== "DigitalVerification"
            ) {
              // if evidenceDocuments exist add them
              for (let i = documentsStartFrom; i < response.length; i++) {
                evidenceDocuments[i - documentsStartFrom]["filename"] =
                  response[i]["metadata"]["filename"];
              }
            } else {
              // if not it means it's a digitalVerificaiton field added at the end
              for (let i = documentsStartFrom; i < response.length; i++) {
                if (
                  response[i] &&
                  response[i].record &&
                  response[i].record.verificationData
                ) {
                  const digitalEvidenceType = this.returnEvidenceFieldByOrigKey(
                    response[i].key,
                    digitalEvidenceTypes,
                  );
                  if (digitalEvidenceType) {
                    digitalEvidenceType["verificationData"] =
                      response[i].record.verificationData;
                    digitalEvidenceType["verificationKey"] =
                      response[i].record.verificationKey;
                    digitalEvidenceType["notOlderThan"] =
                      response[i].record.notOlderThan;
                    digitalEvidenceType["finalizeRequired"] =
                    response[i].record.finalizeRequired;
                    digitalEvidenceType["force"] = response[i].record.force;
                  }
                }
              }
            }
          }
          // for some reason adding it to the evidenceType object does not work
          // this is backup adding the verificationKey
          if (evidenceType.type === "DigitalVerification") {
            if (digitalEvidenceTypes && digitalEvidenceTypes[0]) {
              digitalEvidenceTypes[0].verificationKey = verificationKeyFirstDV;
            }
          }
          if (evidenceType.isBasicFieldsReplace) {
            evidenceType["fields"] = evidenceType["existingFields"];
          }
          // ADD FLAG THAT ITS TAKEN FROM BE
          evidenceType.passedFromBe = true;
          evidenceType.documents = evidenceDocuments;
          // RENDER LIST
          this.skipToStep(step, {
            activeEvidence: evidenceKey,
            evidenceTypes: evidenceTypes,
            existingFields: evidenceType["fields"],
            requestKey: requestKey,
            existingPurposes: evidenceType["purposes"],
            flowType: flowType === "Update" ? "Update" : "Create",
            isFinalize: isFinalize,
            replaceFlow: replaceFlow,
            isSingleStep: isSingleStep,
          });
        },
        (error) => {
          this.skipToStep(0);
        },
      );
    } else if (this.projectTypeFlow === "review" && evidenceType.passedFromBe) {
      // render list just with /review call
      this.userService
        .getEndPoint(
          this.buildEndPoint(
            "reviewEvidence",
            projectKey,
            requestKey,
            "",
            true,
            evidenceKey,
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            evidenceType["existingDocuments"] = response;
            this.skipToStep(step, {
              activeEvidence: evidenceKey,
              evidenceTypes: evidenceTypes,
              requestKey: requestKey,
              existingFields: evidenceType["fields"],
              existingPurposes: evidenceType["purposes"],
              flowType: flowType,
              isFinalize: isFinalize,
              replaceFlow: replaceFlow,
              isSingleStep: isSingleStep,
            });
          },
          (error) => {
            this.skipToStep(0);
          },
        );
    } else {
      // RENDER LIST WITHOUT BE CALLLS
      this.skipToStep(step, {
        activeEvidence: evidenceKey,
        evidenceTypes: evidenceTypes,
        requestKey: requestKey,
        existingFields: evidenceType["fields"],
        existingPurposes: evidenceType["purposes"],
        flowType: flowType,
        isFinalize: isFinalize,
        replaceFlow: replaceFlow,
        isSingleStep: isSingleStep,
      });
    }
  }

  /**
   * Set newest version of evidence in use.triggered trough the evidence preview screen on the SP side
   * evidenceKey must be the latest and it's not handled in this function rather sent to it
   */
  setLatestEvidenceInUse(
    projectKey,
    requestKey,
    toBeReplaced,
    toBeReplacedWith,
    stepArguments,
  ) {
    if (projectKey === "" || requestKey === "" || toBeReplaced === "") {
      console.log("Could not generate URL: required parameters are missing!");
      return;
    }
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          "setEvidenceInUse",
          projectKey,
          requestKey,
          "",
          true,
          "",
          "",
          toBeReplaced,
          toBeReplacedWith,
        ),
        {},
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log(
              "Error while fetching setting the latest version in use",
            );
            return;
          }
          stepArguments.evidence = response["record"];
          stepArguments.evidence.key = response["key"];
          this.buildEvidenceLightbox(
            projectKey,
            requestKey,
            toBeReplacedWith,
            stepArguments,
            response["record"]["documents"],
          );
          this.loadAdditionalInvestorDetail(projectKey, requestKey, true);
        },
        (error) => {
          console.log(
            "Following error occured while setting the latest version in use",
            error,
          );
          return;
        },
      );
  }
  previewNewerVersion(
    projectKey,
    requestKey,
    evidenceKey,
    stepArguments,
    documents,
  ) {
    this.buildEvidenceLightbox(
      projectKey,
      requestKey,
      evidenceKey,
      stepArguments,
      documents,
    );
  }
  /**
   * gets currently active investor
   */
  getActiveInvestor(recovery?) {
    if (this.activeInvestor) {
      return this.activeInvestor;
    } else if ($(".investor-list-entry.expanded").length && recovery) {
      return $(".investor-list-entry.expanded").attr("key");
    } else {
      return this.activeInvestor;
    }
  }
  /**
   * gets currently active investor filter
   */
  getActiveInvestorFilter() {
    return this.activeInvestorFilter;
  }
  /**
   * filters the active investors
   * @param step num
   */
  changeInformationRequestStep(step: Number, response: Object) {
    this.informationRequestStepStatus.next({ step: step, response: response });
  }

  changeCreationRequestStep(step: Number, response: Object) {
    this.informationRequestStepStatus.next({ step: step, response: response });
  }

  getfundsOrganizationDetails() {
    return this.fundsOrganizationDetails;
  }

  setfundsOrganizationDetails(fundsOrganizationDetails) {
    this.fundsOrganizationDetails = fundsOrganizationDetails;
  }

  getfundsOrganizationTemplates() {
    return this.fundsOrganizationTemplates;
  }

  settfundsOrganizationTemplates(templates) {
    this.fundsOrganizationTemplates = templates;
  }
  addOrganizationTempate(template) {
    this.fundsOrganizationTemplates.push(template);
  }
  /**
   Check to see if we should make a call for projects or funds
   */
  getCCType() {
    let cc = "verification";
    if (
      this.router.url.includes("funds") ||
      this.router.url.includes("investor")
    ) {
      cc = "funds";
    } else if (this.router.url.includes("id-verification")) {
      cc = "verification";
    }
    return cc;
  }
  // investor type means investor part of the application
  // if it's not investor type it's funds type which is the service provider side
  isInvestorType() {
    if (this.router.url.includes("investor")) {
      return true;
    }
    return false;
  }

  isIdVerification() {
    if (this.router.url.includes("id-verification")) {
      return true;
    }
    return false;
  }

  isOwner(): boolean {
    if (
      this.activeProjectDetails["record"] &&
      this.activeProjectDetails[
        "record"
      ].permissionRecord?.type?.toLocaleLowerCase() == "owner"
    ) {
      return true;
    }
    return false;
  }

  isMember(): boolean {
    if (
      this.activeProjectDetails["record"] &&
      this.activeProjectDetails[
        "record"
      ].permissionRecord?.type?.toLocaleLowerCase() == "member"
    ) {
      return true;
    }
    return false;
  }

  getActiveEvidenceKey() {
    return this.activeEvidenceKey;
  }

  /**
   * Get details for evidenceType by key
   * @param evidenceKey: string, key for the evidence we are trying to get details
   */
  getEvidenceDetails(evidenceKey: string) {
    if (!this.activeInvestorDetails) {
      console.log("no active investor details", this.activeInvestorDetails);
      return false;
    }
    // on SP side retrieve only from investor's evidenceTypes
    let typeOfEvidenceType = "evidenceTypes";
    if (this.projectType === "investor") {
      // on investor side retrieve only from merged investor's evidenceTypes and related parties evidenceTypes
      typeOfEvidenceType = "projectEvidenceType";
    }
    if (
      this.activeInvestorDetails &&
      !this.activeInvestorDetails[typeOfEvidenceType]
    ) {
      console.log(
        "no evidence types of type",
        typeOfEvidenceType,
        "found for active investor",
      );
      return false;
    }
    let evidenceType = {};
    this.activeInvestorDetails[typeOfEvidenceType].forEach((evidenceTypes) => {
      if (evidenceTypes.key === evidenceKey) {
        evidenceType = evidenceTypes;
      }
    });
    return evidenceType;
  }

  /**
   * Set or update property for evidenceType by evidenceKey
   * @param evidenceKey: string, key for the evidence we are trying to update
   * @param evidencePropertyValuePair: object, key:value pair that needs to be set/updated
   */
  updateEvidenceProperty(evidenceKey: string, evidencePropertyValuePair) {
    // if this is an investor we need to update both evidenceTypes and projectEvidenceType
    this.activeInvestorDetails.evidenceTypes.forEach((evidenceTypes) => {
      if (evidenceTypes.key === evidenceKey) {
        evidenceTypes[evidencePropertyValuePair[0]] =
          evidencePropertyValuePair[1];
      }
    });

    if (this.projectType === "investor") {
      this.activeInvestorDetails.projectEvidenceType.forEach(
        (evidenceTypes) => {
          if (evidenceTypes.key === evidenceKey) {
            evidenceTypes[evidencePropertyValuePair[0]] =
              evidencePropertyValuePair[1];
          }
        },
      );
    }
  }

  parseHistoryEntries(historyEntries, verificationType?) {
    let parsedHistoryEntries = [];
    let lastHistoryEntryType;
    let owner;

    historyEntries.forEach((historyEntry) => {
      if (
        lastHistoryEntryType === historyEntry.entry.entryEnum &&
        historyEntry.entry.entryEnum === "SHARED_ON"
      ) {
        parsedHistoryEntries.pop();
      }
      lastHistoryEntryType = historyEntry.entry.entryEnum;
      owner = historyEntry.entry.data
        ? historyEntry.entry.data.actionDoneBy
        : "";
      const date = this.helperService.formatISODate(historyEntry.timestamp);
      let label = "";
      let historyClass = "";
      switch (historyEntry.entry.entryEnum) {
        case "CLAIMED_DATA":
          label = this.translate.instant("evidencePreview.historyEntry1", {
            owner: "Owner",
          });
          if (owner) {
            label = this.translate.instant("evidencePreview.historyEntry1", {
              owner: owner,
            });
          }
          break;
        case "SHARED_ON":
          label = this.translate.instant("evidencePreview.sharedOn");
          if (owner) {
            label = this.translate.instant("evidencePreview.historyEntry2", {
              owner: owner,
            });
          }
          break;
        case "INITIATED":
          label = this.translate.instant("evidencePreview.historyEntry3");
          break;
        case "RESOLVED_SUCCESSFULLY":
          label =
            verificationType === "Contract"
              ? this.translate.instant("evidencePreview.historyEntry4")
              : this.translate.instant("evidencePreview.historyEntry5");
          break;
        case "RESOLVED_FAILED":
          label = this.translate.instant("evidencePreview.historyEntry6");
          break;
        case "RE_REQUESTED":
          label = this.translate.instant("evidencePreview.historyEntry7");
          break;
        case "CREATED_ON":
          label = this.translate.instant("evidencePreview.documentCreatedOn");
          if (owner) {
            label = this.translate.instant("evidencePreview.historyEntry8", {
              owner: owner,
            });
          }
          break;
        case "CREATED_AUTOMATICALLY_ON":
          label = this.translate.instant("evidencePreview.historyEntry9");
          break;
        case "REVIEWED":
          label = this.translate.instant("evidencePreview.historyEntry10", {
            owner: "owner",
          });
          if (owner) {
            label = this.translate.instant("evidencePreview.historyEntry10", {
              owner: owner,
            });
          }
          break;
        case "SET_IN_USE":
          label = this.translate.instant("evidencePreview.historyEntry11", {
            owner: "owner",
          });
          if (owner) {
            label = this.translate.instant("evidencePreview.historyEntry11", {
              owner: owner,
            });
          }
          break;
        case "SET_OUT_OF_USE":
          label = this.translate.instant("evidencePreview.historyEntry12", {
            owner: "owner",
          });
          historyClass = "set-out-of-use";
          if (owner) {
            label = this.translate.instant("evidencePreview.historyEntry12", {
              owner: owner,
            });
          }
          break;
        case "CREATED_BY_OWNER_ON":
          label = this.translate.instant("evidencePreview.historyEntry13", {
            owner: "owner",
          });
          if (owner) {
            label = this.translate.instant("evidencePreview.historyEntry13", {
              owner: owner,
            });
          }
          break;
        case "EXPIRED":
          label = this.translate.instant("evidencePreview.historyEntry14", {
            owner: "owner",
          });
          historyClass = "expired";
          if (owner) {
            label = this.translate.instant("evidencePreview.historyEntry13", {
              owner: owner,
            });
          }
          if (verificationType === "Contract") {
            label = label = this.translate.instant(
              "evidencePreview.historyEntry16",
            );
          }
          break;
        case "FINALIZED":
          label = this.translate.instant("evidencePreview.historyEntry15", {
            owner: "owner",
          });
          if (owner) {
            label = this.translate.instant("evidencePreview.historyEntry15", {
              owner: owner,
            });
          }
          break;
        case "STATUS_OVERWRITTEN":
          label = this.translate.instant("evidencePreview.historyEntry17", {
            owner: owner,
          });
          break;
        case "FIELDS_VERIFIED":
          label = this.translate.instant(
            "evidencePreview.profileFieldsVerified",
            {
              owner: "owner",
            },
          );
          if (owner) {
            label = this.translate.instant(
              "evidencePreview.profileFieldsVerified",
              {
                owner: owner,
              },
            );
          }
          break;
        case "COMPLETED_BY_SERVICE_PROVIDER":
          label = this.translate.instant(
            "evidencePreview.providedAndMarkedAsCompleteBy",
            {
              owner: "owner",
            },
          );
          if (owner) {
            label = this.translate.instant(
              "evidencePreview.providedAndMarkedAsCompleteBy",
              {
                owner: owner,
              },
            );
          }
          break;
        default:
        // code block
      }
      parsedHistoryEntries.push({
        class: historyClass,
        text: label + " " + date,
        owner: owner,
      });
    });
    parsedHistoryEntries = parsedHistoryEntries.reverse();
    this.activeEvidenceHistory = {
      parsedHistoryEntries: parsedHistoryEntries,
      owner: owner,
    };
    return this.activeEvidenceHistory;
  }

  // merges the evidence lists into one for review process (and investor update procecss)
  buildEvidenceListForReview(
    addEvidenceTypes?,
    addDigitalVerifications?,
    addRelatedParties?,
    disableMainProfile?,
    alwaysShowDVs?,
    singleIdentityGoStep?,
    singleContractStep?,
  ) {
    // make sure the pre-processing for the related parties is only done once becuase it causes issues if it's done multiple times in a row
    if (
      addRelatedParties &&
      this.relatedPartiesAddedToReview &&
      this.activeInvestorDetails["projectEvidenceType"]
    ) {
      this.activeInvestorDetails["projectEvidenceType"] =
        this.activeInvestorDetails["projectEvidenceType"].filter(
          (evd) =>
            evd.reviewState ||
            evd.type === "DigitalVerification" ||
            evd.justLabel,
        );
      return this.activeInvestorDetails["projectEvidenceType"];
    }
    // if another step is selected before that, the related parties will have to be pre-processed OFC
    if (!addRelatedParties) {
      this.relatedPartiesAddedToReview = false;
    }
    const rpEvidenceTypes = [];

    if (singleIdentityGoStep) {
      this.activeInvestorDetails.digitalVerifications.push(
        this.activeInvestorDetails.identityGoStep[0],
      );
    }

    if (singleContractStep) {
      this.activeInvestorDetails.digitalVerifications.push(
        this.activeInvestorDetails.contractStep[0],
      );
    }
    const digitalVerifications =
      this.activeInvestorDetails.digitalVerifications;

    if (addRelatedParties) {
      this.relatedPartiesAddedToReview = true;
    }
    let evidenceTypes = [];
    if (singleIdentityGoStep) {
      this.activeInvestorDetails.singleIdentityGoStep = true;
    }
    if (singleContractStep) {
      this.activeInvestorDetails.singleContractStep = true;
    }
    if (!addDigitalVerifications && !addEvidenceTypes) {
    } else if (addDigitalVerifications && addEvidenceTypes) {
      evidenceTypes = this.concatEvidenceTypesWithDigitalVerifications(
        this.activeInvestorDetails.evidenceTypes,
        digitalVerifications,
      );
    } else if (addDigitalVerifications && !addEvidenceTypes) {
      evidenceTypes = this.concatEvidenceTypesWithDigitalVerifications(
        [],
        digitalVerifications,
      );
    } else if (!addDigitalVerifications && addEvidenceTypes) {
      evidenceTypes = this.activeInvestorDetails.evidenceTypes;
    }

    for (let i = evidenceTypes.length - 1; i >= 0; i--) {
      // remove evidence that is reviewed
      if (!evidenceTypes[i].reviewState) {
        if (!alwaysShowDVs) {
          evidenceTypes.splice(i, 1);
        } else {
          // in case the alwaysShowDV Flag is on, we will only hide non DV verifications
          if (!(evidenceTypes[i]["type"] === "DigitalVerification")) {
            evidenceTypes.splice(i, 1);
          } else {
            if (
              !evidenceTypes[i].dverificationUrl &&
              this.activeInvestorDetails.verificationLinks
            ) {
              evidenceTypes[i].dverificationUrl =
                this.activeInvestorDetails.verificationLinks[0].link;
            }
          }
        }
      } else {
        // if basic fields are not reviewed add a flag that evidnece is disabled
        if (
          this.getActiveInvestorDetaisl()["basicFieldsEvidence"][
            "reviewState"
          ] === true
        ) {
          evidenceTypes[i].basicFieldsNotReviewed = true;
        }
      }
      if (evidenceTypes[i]) {
        evidenceTypes[i].belongsToRequest = this.getActiveInvestor();
      }
    }
    if (addRelatedParties && this.activeInvestorDetails.relatedParties) {
      this.activeInvestorDetails.relatedParties.forEach((relatedParty) => {
        // add in digital verifications
        // const displayBasicFieldDVChoiceRP = true;
        // this logic is changed the value should be true if it's a related party, leaving the logic if we revert
        let displayBasicFieldDVChoiceRP = false;
        if (
          ((relatedParty.record.evidenceTypes &&
            relatedParty.record.evidenceTypes.length === 0) ||
            relatedParty.record.evidenceTypes === undefined) &&
          relatedParty.record.digitalVerifications &&
          relatedParty.record.digitalVerifications.length > 0
        ) {
          displayBasicFieldDVChoiceRP = true;
        }
        relatedParty.record.evidenceTypes =
          this.concatEvidenceTypesWithDigitalVerifications(
            relatedParty.record.evidenceTypes,
            relatedParty.record.digitalVerifications,
          );
        // we need to compile a list of all the RP options available for deduplication (rpOptions)
        const rpOptions = [];
        this.activeInvestorDetails.relatedParties.forEach((relatedParty3) => {
          if (
            relatedParty3.record.typeOfInvestor ===
              relatedParty.record.typeOfInvestor &&
            relatedParty.record.originRequest &&
            relatedParty3.record.basicFieldsEvidence.reviewState === false &&
            relatedParty3.record.basicFieldsEvidence.status &&
            relatedParty3.record.basicFieldsEvidence.status.toLowerCase() ===
              "completed"
          ) {
            relatedParty3.investorReviewName =
              relatedParty3.record.typeOfInvestor === "NaturalPerson"
                ? `${relatedParty3.record.investorFields.FirstName} ${relatedParty3.record.investorFields.LastName}`
                : relatedParty3.record.investorFields.RegisteredName;
            rpOptions.push(relatedParty3);
          }
        });

        // add each related party fields as evidence
        if (relatedParty.record.basicFieldsEvidence.reviewState === true) {
          evidenceTypes.push({
            displayBasicFieldDVChoice: displayBasicFieldDVChoiceRP,
            key: relatedParty.record.basicFieldsEvidence.key,
            type: relatedParty.record.typeOfInvestor,
            state: { fields: "NOT_FILLED", documents: "NO_DOCUMENTS" },
            documents: [],
            reviewState: true,
            isBasicFields: true,
            isBasicRPFields: true,
            RProle: relatedParty.record.relatedPartyRoles
              ? relatedParty.record.relatedPartyRoles[0]
              : undefined,
            rpOptions: rpOptions,
            rpOptionsLength: rpOptions ? rpOptions.length : undefined,
            fields: relatedParty.record.investorFields,
            relatedPartyKey: relatedParty.key,
            relatedPartyOriginRequest: relatedParty.originRequest,
            confirmedEmail: relatedParty.record.investorFields.EmailAddress,
            typeOfInvestor: relatedParty.record.typeOfInvestor,
            investorReviewName:
              relatedParty.record.typeOfInvestor === "NaturalPerson"
                ? `${relatedParty.record.investorFields.FirstName} ${relatedParty.record.investorFields.LastName}`
                : relatedParty.record.investorFields.RegisteredName,
          });
        } else {
          // first we need to check if the label should appear.
          // It should appear only if there is at least one eviedence of the RP that's not reviewed
          let showRPLabel = false;
          relatedParty.record.evidenceTypes.forEach((rpEvidence) => {
            if (rpEvidence.reviewState === true) {
              showRPLabel = true;
            }
            if (
              rpEvidence.type === "DigitalVerification" &&
              rpEvidence.reviewState === false &&
              alwaysShowDVs &&
              !relatedParty.record.hideDVs
            ) {
              showRPLabel = true;
            }
          });
          // it should also appear if there's a DV type that's reviewed but the DV flag is set

          if (showRPLabel) {
            evidenceTypes.push({
              IRkey: relatedParty.key,
              relatedPartyKey: relatedParty.key,
              justLabel: true,
              RProle: relatedParty.record.relatedPartyRoles
                ? relatedParty.record.relatedPartyRoles[0]
                : undefined,
              type: relatedParty.record.typeOfInvestor,
              confirmedEmail: relatedParty.record.investorFields.EmailAddress,
              investorReviewName:
                relatedParty.record.typeOfInvestor === "NaturalPerson"
                  ? `${relatedParty.record.investorFields.FirstName} ${relatedParty.record.investorFields.LastName}`
                  : relatedParty.record.investorFields.RegisteredName,
            });
          }
        }
        if (relatedParty.record && relatedParty.record.evidenceTypes) {
          relatedParty.record.evidenceTypes.forEach((evidenceType) => {
            if (evidenceType.reviewState) {
              evidenceType.relatedPartyKey = relatedParty.key;
              evidenceTypes.push(evidenceType);
              if (
                relatedParty.record.basicFieldsEvidence.reviewState === true
              ) {
                evidenceType.basicFieldsNotReviewed = true;
              }
            } else {
              if (
                alwaysShowDVs &&
                (evidenceType.dverificationUrl ||
                  relatedParty.record.verificationLinks)
              ) {
                if (
                  evidenceType.type === "DigitalVerification" &&
                  !relatedParty.record.hideDVs
                ) {
                  evidenceType.relatedPartyKey = relatedParty.key;
                  evidenceTypes.push(evidenceType);
                  if (
                    !evidenceType.dverificationUrl &&
                    relatedParty.record.verificationLinks
                  ) {
                    evidenceType.dverificationUrl =
                      relatedParty.record.verificationLinks[0].link;
                  }
                  if (
                    relatedParty.record.basicFieldsEvidence.reviewState === true
                  ) {
                    evidenceType.basicFieldsNotReviewed = true;
                  }
                }
              }
            }
          });
        }
      });
    }
    // add the investor fields as evidence
    this.activeInvestorDetails["projectEvidenceType"] = evidenceTypes;
    if (
      this.getActiveInvestorDetaisl()["basicFieldsEvidence"]["reviewState"] ===
        true &&
      !disableMainProfile
    ) {
      this.activeInvestorDetails["projectEvidenceType"].unshift({
        displayBasicFieldDVChoice: false,
        key: this.getActiveInvestorDetaisl()["basicFieldsEvidence"]["key"],
        type: this.getActiveInvestorDetaisl()["typeOfInvestor"],
        state: { fields: "NOT_FILLED", documents: "NO_DOCUMENTS" },
        documents: [],
        reviewState: true,
        isBasicFields: true,
        isBasicRPFieldsInvestor: true,
        typeOfInvestor: this.getActiveInvestorDetaisl().typeOfInvestor,
        fields: this.getActiveInvestorDetaisl().investorFields,
        IRkey: this.getActiveInvestor(),
        confirmedEmail:
          this.getActiveInvestorDetaisl().investorFields.EmailAddress,
        investorReviewName:
          this.getActiveInvestorDetaisl().typeOfInvestor === "NaturalPerson"
            ? `${this.getActiveInvestorDetaisl().investorFields.FirstName} ${
                this.getActiveInvestorDetaisl().investorFields.LastName
              }`
            : this.getActiveInvestorDetaisl().investorFields.RegisteredName,
      });
    } else {
      this.activeInvestorDetails["projectEvidenceType"].unshift({
        IRkey: this.getActiveInvestor(),
        key: this.getActiveInvestor(),
        justLabel: true,
        type: this.getActiveInvestorDetaisl()["typeOfInvestor"],
        confirmedEmail:
          this.getActiveInvestorDetaisl().investorFields.EmailAddress,
        investorReviewName:
          this.getActiveInvestorDetaisl().typeOfInvestor === "NaturalPerson"
            ? `${this.getActiveInvestorDetaisl().investorFields.FirstName} ${
                this.getActiveInvestorDetaisl().investorFields.LastName
              }`
            : this.getActiveInvestorDetaisl().investorFields.RegisteredName,
      });
    }
    return this.activeInvestorDetails["projectEvidenceType"];
  }

  async prepareIdConfigurationdata(idv, contracts, kompany) {
    // the calls are independed of eachother we don't need
    if (idv) {
      this.userService
        .getEndPoint(
          "api/proxy/supportedIdTypes",
          { responseType: "json" },
          { observe: "body" },
          "verification",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
            } else {
              this.parseTypeCountries(response, "funds");
            }
          },
          (error) => {},
        );
    }
    if (contracts && !this.isInvestorType()) {
      this.userService
        .getEndPoint(
          "api/pdf/templates/organizations",
          { responseType: "json" },
          { observe: "body" },
          "verification",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
            } else {
              this.parseContractTemplates(response, "funds");
            }
          },
          (error) => {},
        );
    }
    if (kompany && this.permissionKompany()) {
      this.userService
        .getEndPoint(
          "api/search/companies/",
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
            } else {
              this.parseKompanyCountries(response);
            }
          },
          (error) => {},
        );
    }
  }

  /**
   helper function for preparation of configuartion data for jumio id-verification
   @param productType - identity verification or funds product */
  parseTypeCountries(response, productType) {
    // add the defailt option
    response.sort((countryA, countryB) =>
      countryA.countryName > countryB.countryName ? 1 : -1,
    );
    response.unshift({
      countryCode: "emptySelect",
      countryName: "All countries",
      idTypes: [],
    });
    if (productType === "id-verification") {
      this.getActivePersonDetails()["typeCountries"] = response;
    } else if (productType === "funds") {
      this.jumioTypeCountries = response;
    }
  }

  // parse country list from Kompany
  parseKompanyCountries(response) {
    // parse the name
    let removeCountryIndex;
    response.forEach((country, index) => {
      if (country.country_code.includes("US-")) {
        country.country_name = "US - " + country.country_name;
      } else if (country.country_code == "US") {
        removeCountryIndex = index;
      }
    });
    response.splice(removeCountryIndex, 1);
    // sort alphabetically
    response.sort((a, b) =>
      a.country_name > b.country_name
        ? 1
        : b.country_name > a.country_name
        ? -1
        : 0,
    );
    response.unshift({
      country_code: "emptySelect",
      country_name: this.translate.instant("kompanySearch.label"),
    });

    this.kompanyTypeCountries = response;
  }

  /**
   helper function for preparation of configuartion data for jumio id-verification
  @param productType - identity verification or funds product */
  parseContractTemplates(response, productType) {
    const contracTemplates = [];
    if (response.default) {
      response.default.forEach((template) => {
        contracTemplates.push({
          key: template,
          name: this.helperService.parseCountryName(
            this.helperService.parseBackendName2(template),
          ),
        });
      });
    }
    if (response.templates) {
      response.templates.forEach((template) => {
        contracTemplates.push({
          key: template,
          name: this.helperService.parseCountryName(
            this.helperService.parseBackendName2(template),
          ),
        });
      });
    }
    if (productType === "id-verification") {
      this.getActivePersonDetails()["ContractTemplates"] = contracTemplates;
    } else if (productType === "funds") {
      this.organizationContractTemplates = contracTemplates;
    }
  }

  /**
  helper function for preparing the tormio templates with type
   @param productType - identity verification or funds product */
  parseContractTemplatesWithTypes(response, productType) {
    const contracTemplates = [];
    if (response.defaultTypes) {
      response.defaultTypes.forEach((template) => {
        contracTemplates.push({
          key: template.template,
          name: this.helperService.parseCountryName(
            this.helperService.parseBackendName2(template.template),
          ),
          type: template.type,
          mode: template.mode,
          modes: template.modes,
        });
      });
    }
    if (response.templatesTypes) {
      response.templatesTypes.forEach((template) => {
        contracTemplates.push({
          key: template.template,
          name: this.helperService.parseCountryName(
            this.helperService.parseBackendName2(template.template),
          ),
          type: template.type,
          mode: template.mode,
          modes: template.modes,
        });
      });
    }
    if (productType === "id-verification") {
      this.getActivePersonDetails()["ContractTemplates"] = contracTemplates;
    } else if (productType === "funds") {
      this.organizationContractTemplates = contracTemplates;
    }
  }

  // project type flow is neccessary to differentiate between different evidence list flows
  getProjectTypeFlow() {
    return this.projectTypeFlow;
  }

  setProjectTypeFlow(projectTypeFlow) {
    this.projectTypeFlow = projectTypeFlow;
  }

  isKeyRelatedParty(requestKey) {
    const requestKeyTypu = requestKey.substring(0, 3);
    return requestKeyTypu === "RPE";
  }

  hideActionsMenu(event) {
    if (
      !$(event.target).hasClass("actionsMenuBtn") &&
      !$(event.target).parent().hasClass("actionsMenuBtn")
    ) {
      $(".actionsMenuWrapper ul").addClass("d-none");
      $(".actionsMenuWrapper img.action-icon").removeClass("icon-minus");
    }
  }

  hideActionsMenuForce() {
    $(".actionsMenuWrapper ul").addClass("d-none");
    $(".actionsMenuWrapper img.action-icon").removeClass("icon-minus");
  }

  evidenceIconUrl(evidenceType) {
    if (evidenceType === "Passport") {
      return;
    }
  }

  /**
    @param existingTemplate optional, if it exists that means we are entering edit template flow
   */

  createNewTemplate(existingTemplate?) {
    // reset the local template data
    this.localTemplateData = {};
    if (existingTemplate) {
      this.localTemplateData =
        this.transformTemplateToLocalData(existingTemplate);
    }
    this.localTemplateData.filledDataExists = false;
    this.localTemplateData.activeEdit = {
      mainType: "mainConfig",
      type: "none",
      step: "0",
    };
    // skip to step in projectManager
    this.skipToStep(21, this.localTemplateData);
  }

  deleteExistingTemplate(templateKey) {
    this.userService
      .deleteEndpoint(
        this.buildEndPoint("KYCTemplateSingle", templateKey),
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record for additional data!");
            return;
          }
          this.fundsOrganizationTemplates =
            this.fundsOrganizationTemplates.filter(function (template) {
              return template.key !== templateKey;
            });
          this.skipToStep(0);
        },
        (error) => {
          console.log(
            "Following error occured while fetching record for additional data!",
            error,
          );
          return;
        },
      );
  }

  transformTemplateToLocalData(template) {
    let localData;
    localData = {};
    localData.templateName = template.templateName;
    localData.richDescription = template.richDescription;
    // main template
    const mainRequestTemplates = [];
    template.templateStructure.mainRequestTemplates.forEach((mainTemplate) => {
      mainRequestTemplates.push({
        name: mainTemplate.type,
        config: mainTemplate,
      });
    });
    // related party template
    const relatedPartyTemplates = [];
    if (template.templateStructure.relatedPartyTemplates) {
      template.templateStructure.relatedPartyTemplates.forEach(
        (relatedTemplate) => {
          relatedPartyTemplates.push({
            name: relatedTemplate.type,
            config: relatedTemplate,
          });
        },
      );
    }

    localData.mainRequestTemplates = mainRequestTemplates;
    localData.relatedPartyTemplates = relatedPartyTemplates;
    localData.workflowSteps = template.templateStructure.workflowSteps;
    localData.editMode = true;
    localData.published = template.published;
    localData.inUse = template.inUse;
    localData.existingTemplateKey = template.existingTemplateKey;
    return localData;
  }

  /**
   * used to parse and prepare evidence fields for presentation in content builder
   * @param evidenceType LegalPerson | NaturalPerson
   * @param existingFields Obejct containing key:value pairs for fields that are already populated
   * @param previewMode boolean set to true if the fields will be used in preview mode only
   * @param hideGoogleAddress boolean,to know wether to hide the google auto
   * @returns Object with parsed evidence fields
   */
  parseEvidenceFields(
    evidenceType: string,
    existingFields = {},
    previewMode = false,
    hideEmpty = false,
    isAutomaticRequest?,
    hideGoogleAddress?,
    showAddressFields?,
    addressNotRequired?,
    addDateField?,
    profileVerificationPreview = false
  ) {
    const evidenceFields = JSON.parse(
      JSON.stringify(this.getDocumentTypeList().evidenceFields),
    );

    if (!evidenceFields) {
      return [];
    }
    let evidenceFieldsType;

    evidenceFieldsType = evidenceFields[evidenceType];

    if (!evidenceFieldsType && addDateField) {
      evidenceFieldsType = evidenceFields["Charter"];
    }
    if (!evidenceFieldsType) {
      return [];
    }
    // make registration number optional for legaPerson if it's a trust type
    if (evidenceType === "LegalPerson" && existingFields["Type"] === "Trust") {
      const registrationNumberObject = evidenceFieldsType.find(
        (option) => option.id === "RegistrationNumber",
      );
      registrationNumberObject.optional = true;
    }
    const parsedEvidenceFieldsType = [];
    evidenceFieldsType.forEach((element) => {
      if (hideGoogleAddress) {
        if (
          [
            "RegisteredAddressPostalCode",
            "RegisteredAddressStreet",
            "RegisteredAddressCity",
            "RegisteredAddressCountry",
          ].includes(element.id)
        ) {
          element.displayCondition = undefined;
          element.hideCondition = undefined;
        }
        if ("useClassicAddress" === element.id) {
          element.class = "d-none";
        }
        if ("inputGoogleAutocomplete" === element.type) {
          element.class = "d-none";
        }
      }
      if (addressNotRequired && "inputGoogleAutocomplete" === element.type) {
        element.optional = true;
      }
      if (showAddressFields) {
        if ("useClassicAddress" === element.id) {
          element.checked = true;
        }
      }
      let evidenceDisabled = false;
      if (
        existingFields[element.id] !== "" &&
        existingFields[element.id] !== null &&
        existingFields[element.id] !== false &&
        existingFields[element.id] !== undefined
      ) {
        let value;
        if (element.type === "date") {
          if(profileVerificationPreview)
            {
              value =  existingFields[element.id].replaceAll("-", ".");;
            }
          else if (
            new Date(parseInt(existingFields[element.id], 10)).getTime() >
              100000 ||
            new Date(parseInt(existingFields[element.id], 10)).getTime() <
              100000
          ) {
            if (!previewMode) {
              if (Number.isInteger(existingFields[element.id])) {
                value = this.helperService.getAsStartDateFromTimestamp(
                  existingFields[element.id],
                );
              } else {
                value = this.helperService.getAsStartDate(
                  existingFields[element.id],
                );
              }
            } else {
              value = this.helperService.formatISODate(
                existingFields[element.id], "yyy.MM.dd", false
              );
            }
          } else {
            value = this.helperService.getAsStartDate(
              existingFields[element.id],
            );
          }
        } else {
          value = existingFields[element.id];
        }
        if (value && String(value)) {
          if (isAutomaticRequest && element.id === "Type") {
            evidenceDisabled = true;
          }
          parsedEvidenceFieldsType.push({
            name: element.id,
            id: evidenceType + element.id,
            label: this.helperService.translateEvidenceField(element.id),
            subtitle: element.subtitle ? element.subtitle : null,
            type: element.type,
            selectOptionsList: element.dynamicSelect
              ? this.returnTranslatedList(element.dynamicSelect)
              : element.selectOptionsList,
            maxLength: element.maxLength,
            minLength: element.minLength,
            value: previewMode
              ? this.helperService.parseCountryName(String(value))
              : value,
            optional: element.optional,
            startDate: element.startDate,
            maxDate: element.maxDate,
            minDate: element.minDate,
            toolTip: element.tooltip,
            disabled: evidenceDisabled,
            displayCondition: element.displayCondition,
            hideCondition: (element?.hideCondition || (element?.profileVerification == true && !profileVerificationPreview)),
            skipParse: element.skipParse,
            hidden: element.hidden,
            forcedValue: element.forcedValue,
            showAsRequired: element.showAsRequired,
            changemethod: element.changemethod,
            class: element.class,
            checked: element.checked,
            placeholder: element.placeholder,
          });
        }
      } else {
        // without value
        parsedEvidenceFieldsType.push({
          name: element.id,
          id: evidenceType + element.id,
          label: this.helperService.translateEvidenceField(element.id),
          subtitle: element.subtitle ? element.subtitle : null,
          selectOptionsList: element.dynamicSelect
            ? this.returnTranslatedList(element.dynamicSelect)
            : element.selectOptionsList,
          maxLength: element.maxLength,
          minLength: element.minLength,
          type: element.type,
          optional: element.optional,
          startDate: element.startDate,
          maxDate: element.maxDate,
          minDate: element.minDate,
          toolTip: element.tooltip,
          disabled: evidenceDisabled,
          displayCondition: element.displayCondition,
          hideCondition:  (element?.hideCondition || (element?.profileVerification == true && !profileVerificationPreview)),
          skipParse: element.skipParse,
          hidden: element.hidden,
          forcedValue: element.forcedValue,
          showAsRequired: element.showAsRequired,
          changemethod: element.changemethod,
          class: element.class,
          checked: element.checked,
          placeholder: element.placeholder,
        });
      }
    });
    return parsedEvidenceFieldsType;
  }

  returnTranslatedList(listName) {
    let list;
    let translationObjName;
    switch (listName) {
      case "title_list":
        list = [...title_list];
        translationObjName = "title";
        break;
      case "gender_list":
        list = [...gender_list];
        translationObjName = "gender";
        break;
      case "legalPersonTypeList":
        list = [...legalPersonTypeList];
        translationObjName = "legalType";
        break;
      case "yn_list":
        list = [...yn_list];
        translationObjName = "yn";
        break;
      case "legal_status_list":
        list = [...legal_status_list];
        translationObjName = "legalStatus";
        break;
      default:
    }
    list.forEach((elem) => {
      const translationKey = translationObjName + "." + elem.key;
      elem.name = this.translate.instant(translationKey);
    });
    return list;
  }

  getPDFStatus() {
    return this.pdf.getPdfGenerationStatus();
  }

  // helper function to determine wethere there is a digital verificaiton present of certain typ
  checkDigitalVerification(digitalVerifications, type) {
    let returnVal;
    if (digitalVerifications) {
      digitalVerifications.forEach((verification) => {
        if (verification.verificationType === type) {
          returnVal = verification;
        }
      });
    }
    return returnVal;
  }

  // function to determine wether all evidence types are shareable
  allEvidenceShareable(evidenceTypes) {
    let allShareable = true;
    evidenceTypes.forEach((evidenceType) => {
      if (evidenceType.notSharable && !evidenceType.hideFromList) {
        allShareable = false;
      }
    });
    return allShareable;
  }

  allEvidenceReviewed(evidenceTypes) {
    let allReviewed = true;
    if (evidenceTypes) {
      evidenceTypes.forEach((evidenceType) => {
        if (evidenceType.reviewState) {
          allReviewed = false;
        }
      });
    }
    return allReviewed;
  }

  // parse AV data response to the BE
  // used in IDV and Funds
  parseAVData(event) {
    // HIDE THE ERROR FIELDS IF THEY ARE SHOWN
    $("#AVDocumentsNotSelected").hide();
    $("#AVEmptyCustomDocument").hide();
    let payload;
    let emptyDocumentError;
    const documentsRequested = false;
    payload = {
      verificationLetterRequested: false,
      documentsRequested: documentsRequested,
    };
    if ($("#addressVerificaitonLetter").is(":checked")) {
      payload.verificationLetterRequested = true;
    }
    if ($("#geoLocationRequested").is(":checked")) {
      payload.geoLocationRequested = true;
    } else {
      payload.geoLocationRequested = false;
    }
    if ($("#idAddressVerificationManual").is(":checked")) {
      payload.addressData = {
        //  'Address': $('.google-autocomplete').val(),
        Address: $("#NumberInputField").val()
          ? `${$("#AddressInputField").val()}, ${$("#NumberInputField").val()}`
          : $("#AddressInputField").val(),
        Number: $("#NumberInputField").val(),
        ZipCode: $("#ZipInputField").val(),
        City: $("#CityInputField").val(),
        Country: $("#CountryInputField option:selected").text().trim(),
      };
    }
    if ($("#avNotes").val().toString().trim() !== "") {
      payload.documentNotes = this.helperService.sanitize(
        $("#avNotes").val().toString().trim(),
      );
    }
    let counters;
    if ($("#addressSupportingDocument").is(":checked")) {
      counters = $("form")
        .find(".custom-counter-count")
        .filter(function () {
          return $(this).val() !== "0";
        });
      if (counters.length !== 0) {
        payload.documentsRequested = true;
        const evidenceTypes = [];
        let order = 0;
        const otherCount = 0;
        counters.each(function () {
          order++;
          const fieldType = $(this).attr("id");
          if (fieldType === "Other") {
            for (let t = 1; t <= this.value; t++) {
              if ($("#customEvidenceType" + t).val() === "") {
                emptyDocumentError = true;
              }
              evidenceTypes.push($("#customEvidenceType" + t).val());
            }
          } else {
            evidenceTypes.push(fieldType);
          }
        });
        payload.allowedDocuments = evidenceTypes;
      } else {
        // display error and return
        if (!this.isInvestorType()) {
          $("#AVDocumentsNotSelected").show();
          $(".document-category-main").removeClass("uploading-document");
          $(".documentUploaderSide").removeClass("uploading-document");
        }
        return undefined;
      }
      if (emptyDocumentError) {
        // IF THERE IS AT LEAST ONE EMPTY FIELD
        $("#AVEmptyCustomDocument").show();
        $(".document-category-main").removeClass("uploading-document");
        $(".documentUploaderSide").removeClass("uploading-document");
        return undefined;
      }
    }
    if ($("#addressVerificationiDN").is(":checked")) {
      payload.idinAllowed = true;
    }
    if ($("#forceAV").prop("checked")) {
      payload.force = true;
    } else {
      payload.force = false;
    }
    return payload;
  }

  // used during id verification configuartion
  // if alternate workflow id checkmark is checked, then the value is changed
  resolveAlternateWorkflowID(workflowVal) {
    if (workflowVal === "200") {
      workflowVal = "100";
    } else if (workflowVal === "201") {
      workflowVal = "101";
    } else if (workflowVal === "202") {
      workflowVal = "102";
    }
    return workflowVal;
  }

  // parse IDV data response to the BE
  // used in IDV and Funds
  parseIDVData(projectType) {
    let payload;
    let verificationPresets;
    verificationPresets = {};
    if (
      $("#IDVerificationCountry").val() &&
      $("#IDVerificationCountry").val() !== "emptySelect"
    ) {
      verificationPresets.country = $("#IDVerificationCountry").val();
    }
    if (
      $("#IDVerificationType").val() &&
      $("#IDVerificationType").val() !== "emptySelect" &&
      $("#IDVerificationCountry").val() !== "emptySelect"
    ) {
      verificationPresets.type = $("#IDVerificationType").val();
    }
    if ($("#Locale").val() && $("#Locale").val() !== "emptySelect") {
      verificationPresets.locale = $("#Locale").val();
    }
    if ($("#WorkflowID").val() && $("#WorkflowID").val() !== "emptySelect") {
      let workflowVal;
      workflowVal = $("#WorkflowID").val();
      // check if the alternate checkmark is checked
      if ($("#idVerificationAlternate").prop("checked")) {
        workflowVal = this.resolveAlternateWorkflowID(workflowVal);
      }
      verificationPresets.workflowId = parseInt(workflowVal, 10);
    }
    if (projectType === "identity") {
      payload = {
        idVerificationPresets: {},
      };
      payload.verificationPresets = verificationPresets;
    } else {
      payload = verificationPresets;
    }
    if ($("#forceID").prop("checked") || $("#forceIDGO").prop("checked")) {
      payload.force = true;
    } else {
      payload.force = false;
    }
    return payload;
  }

  /**
   * @param rpKey - used only in special case in the SP share process
   * @param originalEvidenceTypes - used only in special case in the SP share process
   * @param skipContract - skips the contract steps because it needs to be in it's own separate step in the review flow
   */
  concatEvidenceTypesWithDigitalVerifications(
    evidenceTypes,
    digitalVerifications,
    rpKey?,
    originalEvidenceTypes?,
    skipContract?,
  ) {
    if (digitalVerifications) {
      evidenceTypes = evidenceTypes.concat(digitalVerifications);
      if (this.getActiveInvestorDetaisl() && digitalVerifications.length) {
        const newKey = digitalVerifications[0]["key"];
        evidenceTypes.forEach((evidenceType) => {
          if (
            evidenceType.type === "DigitalVerification" &&
            !evidenceType.originalEvidenceKey
          ) {
            const originaKey = evidenceType.key.slice();
            evidenceType.key = newKey;
            evidenceType.originalEvidenceKey = originaKey;
            if (rpKey) {
              evidenceType.originalRequestKey = rpKey;
            }
            if (originalEvidenceTypes) {
              evidenceType.originalEvidenceTypes = originalEvidenceTypes;
            }
          }
        });
      }
    }
    return evidenceTypes;
  }
  // used for status of top level of verification / investor
  buildVerificationStatusObject(status, tooltip?) {
    let generalStatus;

    if (status === "NOT_REQUESTED") {
      generalStatus = {
        content: "not requested",
        class: "status-not-started",
        notificationInfo: {
          // tslint:disable-next-line:max-line-length
          content: "not requested",
          buttonAction: "OnDisplayImageInLightbox",
          showButton: false,
          title: "Identity verification",
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-gray",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: false,
      };
    } else if (status === "IN_PROGRESS" || status === "In progress") {
      generalStatus = {
        content: "in progress",
        class: "status-pending",
        notificationInfo: {
          content: "in progress",
          title: "Identity verification",
          buttonAction: "OnDisplayImageInLightbox",
          showButton: false,
          svgIcon: {
            src: "/assets/images/icon-refresh-prepared.svg",
            class: "svg-blue",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: false,
      };
    } else if (status === "LAZY_RESOLVE") {
      generalStatus = {
        content: "please wait",
        class: "status-pending",
        notificationInfo: {
          content: "please wait",
          title: "Identity verification",
          buttonAction: "OnDisplayImageInLightbox",
          showButton: false,
          svgIcon: {
            src: "/assets/images/icon-refresh-prepared.svg",
            class: "svg-blue",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: false,
      };
    } else if (status === "ACTION_REQUIRED" || status === "Action required") {
      generalStatus = {
        content: "action required",
        class: "status-pending",
        notificationInfo: {
          content: "action required",
          title: "Identity verification",
          buttonAction: "OnDisplayImageInLightbox",
          showButton: false,
          svgIcon: {
            src: "/assets/images/icon-refresh-prepared.svg",
            class: "svg-blue",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: false,
      };
    } else if (status === "RESOLVE_REQUIRED" || status === "Resolve required") {
      generalStatus = {
        content: "resolve required",
        class: "status-pending",
        notificationInfo: {
          content: "resolve required",
          title: "Identity verification",
          buttonAction: "OnDisplayImageInLightbox",
          showButton: false,
          svgIcon: {
            src: "/assets/images/icon-refresh-prepared.svg",
            class: "svg-blue",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: false,
      };
    } else if (status === "COMPLETED" || status === "Completed") {
      generalStatus = {
        content: "Completed",
        class: "status-verified",
        notificationInfo: {
          // tslint:disable-next-line:max-line-length
          // 'content': 'Checks performed on ' + this.helper.formatISODate( record.resolvedAt ),
          title: "Identity verification",
          buttonAction: "OnDisplayImageInLightbox",
          showButton: true,
          svgIcon: {
            src: "/assets/images/icon-checkmark-prepared.svg",
            class: "svg-green",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: true,
      };
    } else if (status === "FAILED" || status === "Failed") {
      generalStatus = {
        content: "failed",
        class: "status-failure",
        notificationInfo: {
          // tslint:disable-next-line:max-line-length
          // 'content': 'Checks performed on ' + this.helper.formatISODate( record.resolvedAt ),
          title: "Identity verification",
          buttonAction: "OnDisplayImageInLightbox",
          showButton: true,
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-orange",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: true,
      };
    } else if (status === "EXPIRED" || status === "Expired") {
      generalStatus = {
        content: "Expired",
        class: "status-failure",
        notificationInfo: {
          // tslint:disable-next-line:max-line-length
          // 'content': 'Checks performed on ' + this.helper.formatISODate( record.resolvedAt ),
          title: "Identity verification ",
          buttonAction: "OnDisplayImageInLightbox",
          showButton: true,
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-orange",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: true,
      };
    } else if (status === "REJECTED" || status === "Rejected") {
      generalStatus = {
        content: "rejected",
        class: "status-failure",
        notificationInfo: {
          // tslint:disable-next-line:max-line-length
          // 'content': 'Checks performed on ' + this.helper.formatISODate( record.resolvedAt ),
          title: "Identity verification",
          buttonAction: "OnDisplayImageInLightbox",
          showButton: true,
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-orange",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: true,
      };
    } else {
      generalStatus = {
        content: "not started",
        class: "status-not-started",
        notificationInfo: {
          // tslint:disable-next-line:max-line-length
          content: "not started",
          buttonAction: "OnDisplayImageInLightbox",
          showButton: false,
          title: "Identity verification",
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-gray",
            width: 16, // required
            height: 16, // required
          },
        },
        showHistory: false,
      };
    }

    if (tooltip) {
      generalStatus.tooltip = tooltip;
    }
    return generalStatus;
  }

  /**
     @param relatedParties related party basic information like keys
     @param evidenceTypes the current evidence types
     we will need to retreive the other information in multiple calls
  */
  buildEvidenceForSharing(
    relatedParties,
    evidenceTypes,
    shareURL,
    email,
    requestKey,
  ) {
    // remove the loading element
    // we must build the forkjoin call
    if (relatedParties) {
      const observableBatch = [];
      relatedParties.forEach((relatedParty) => {
        const observable = this.userService.getEndPoint(
          this.buildEndPoint(
            "getFund",
            this.getActiveProject(),
            relatedParty.key,
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        );
        observableBatch.push(observable);
      });

      forkJoin(observableBatch).subscribe(async (response) => {
        // remove the loading element
        evidenceTypes.pop();
        const responseCopy = JSON.parse(JSON.stringify(response));
        response.forEach((relatedPartyDetails) => {
          if (
            (relatedPartyDetails["record"].evidenceTypes &&
              relatedPartyDetails["record"].evidenceTypes.length) ||
            (relatedPartyDetails["record"].digitalVerifications &&
              relatedPartyDetails["record"].digitalVerifications.length)
          ) {
            const evidenceTypesCopy = JSON.parse(
              JSON.stringify(relatedPartyDetails["record"].evidenceTypes),
            );
            const dvCopy = JSON.parse(
              JSON.stringify(
                relatedPartyDetails["record"].digitalVerifications,
              ),
            );

            evidenceTypes.push({
              type:
                relatedPartyDetails["record"].typeOfInvestor === "NaturalPerson"
                  ? relatedPartyDetails["record"].investorFields.FirstName +
                    " " +
                    relatedPartyDetails["record"].investorFields.LastName
                  : relatedPartyDetails["record"].investorFields.RegisteredName,
              justLabel: true,
            });

            const originalEvidenceTypes =
              this.concatEvidenceTypesWithDigitalVerifications(
                evidenceTypesCopy,
                dvCopy,
              );

            relatedPartyDetails["record"].evidenceTypes.forEach(
              (rpEvidenceType) => {
                rpEvidenceType.originalRequestKey = relatedPartyDetails["key"];
                rpEvidenceType.originalEvidenceTypes = originalEvidenceTypes;
              },
            );

            evidenceTypes = evidenceTypes.concat(
              this.concatEvidenceTypesWithDigitalVerifications(
                relatedPartyDetails["record"].evidenceTypes,
                relatedPartyDetails["record"].digitalVerifications,
                relatedPartyDetails["key"],
                originalEvidenceTypes,
              ),
            );
          }
        });

        // force set the parsed evidence types as we will need them
        // for when shared link is created
        this.forceSetActiveInvestorPropertyValue(
          "parsedEvidenceTypes",
          evidenceTypes,
        );
        this.skipToStep(10, {
          parsedEvidenceType: evidenceTypes,
          requestKey: requestKey,
          shareUrl: shareURL,
          email: email,
          relatedPartyObject: responseCopy,
        });
      });
    } else {
      evidenceTypes.pop();
      this.resetBEStatus(evidenceTypes);
      return evidenceTypes;
    }
  }

  resetBEStatus(evidenceTypes) {
    if (evidenceTypes) {
      evidenceTypes.forEach((evidenceType) => {
        evidenceType.passedFromBe = undefined;
      });
    }
  }

  parseIDVGOFields(verificationDetails) {
    const ret = [];
    // CREATED BY
    if (verificationDetails.createdBy) {
      ret.push({
        label: this.translate.instant("evidencePreview.idvPreview.label1"),
        type: "title",
        titleClass: "title-big pl-0",
      });
      if (verificationDetails.createdBy.firstName) {
        ret.push({
          label: this.translate.instant("evidenceFields.FirstName"),
          type: "field",
          value: verificationDetails.createdBy.firstName,
        });
      }
      if (verificationDetails.createdBy.lastName) {
        ret.push({
          label: this.translate.instant("evidenceFields.LastName"),
          type: "field",
          value: verificationDetails.createdBy.lastName,
        });
      }
      if (verificationDetails.createdBy.email) {
        ret.push({
          label: this.translate.instant("evidenceFields.EmailAddress"),
          type: "field",
          value: verificationDetails.createdBy.email,
        });
      }
    }
    const results = verificationDetails.verificationData.results.results;
    const capabilities = results.capabilities;
    const decision = results.decision;
    ret.push({
      label: "Checks performed",
      type: "title",
      titleClass: "title-big pl-0",
    });
    if (decision) {
      const decisionType = decision.type;
      ret.push({
        label: "Decision",
        type: "title",
        titleClass: this.returnClassNameFromType(decisionType),
      });
      if (decision.details) {
        ret.push({
          label: "Details",
          type: "field",
          value: this.helperService.parseIDVGoLabel(decision.details.label),
        });
        ret.push({
          label: "Risk",
          type: "field",
          value: decision.risk.score,
        });
      }
    }

    if (capabilities.dataChecks) {
      const decisionType = capabilities.dataChecks.decision.type;
      ret.push({
        label: "Data checks",
        type: "title",
        titleClass: this.returnClassNameFromType(decisionType),
      });
      if (decisionType.toLowerCase() !== "passed") {
        ret.push({
          label: "Details",
          type: "field",
          value: this.helperService.parseIDVGoLabel(
            capabilities.dataChecks.decision.details.label,
          ),
        });
      }
    }

    if (capabilities.extraction) {
      const decisionType = capabilities.extraction.decision.type;
      ret.push({
        label: "Extraction",
        type: "title",
        titleClass: this.returnClassNameFromType(decisionType),
      });
      if (decisionType.toLowerCase() !== "passed") {
        ret.push({
          label: "Details",
          type: "field",
          value: this.helperService.parseIDVGoLabel(
            capabilities.extraction.decision.details.label,
          ),
        });
      }
      if (capabilities.extraction.data) {
        if (capabilities.extraction.data.currentAge) {
          ret.push({
            label: "Current age",
            type: "field",
            value: this.helperService.parseIDVGoLabel(
              capabilities.extraction.data.currentAge,
            ),
          });
        }
        if (capabilities.extraction.data.dateOfBirth) {
          ret.push({
            label: "Date of birth",
            type: "field",
            value: this.helperService.parseBackendName2(
              capabilities.extraction.data.dateOfBirth,
            ),
          });
        }
        if (capabilities.extraction.data.documentNumber) {
          ret.push({
            label: "Document number",
            type: "field",
            value: this.helperService.parseIDVGoLabel(
              capabilities.extraction.data.documentNumber,
            ),
          });
        }
        if (capabilities.extraction.data.expiryDate) {
        let currentDate = new Date();
        let expiryDate =  new Date(capabilities.extraction.data.expiryDate)
          if(currentDate > expiryDate)
            {
              ret.push({
                label: "Expiry date",
                type: "field",
                value: this.helperService.parseBackendName2(
                  capabilities.extraction.data.expiryDate,
                ),
                icon:"/assets/images/icon-triangle-orange.svg",
                valueColor: "#c74848",
              });
            }
            else
            {
              ret.push({
                label: "Expiry date",
                type: "field",
                value: this.helperService.parseBackendName2(
                  capabilities.extraction.data.expiryDate,
                ),
              });
            }
         
        }
        if (capabilities.extraction.data.firstName) {
          ret.push({
            label: "First name",
            type: "field",
            value: this.helperService.parseIDVGoLabel(
              capabilities.extraction.data.firstName,
            ),
          });
        }
      }
      if (capabilities.extraction.data.lastName) {
        ret.push({
          label: "Last name",
          type: "field",
          value: this.helperService.parseIDVGoLabel(
            capabilities.extraction.data.lastName,
          ),
        });
      }
      if (capabilities.extraction.data.issuingCountry) {
        ret.push({
          label: "Issuing country",
          type: "field",
          value: this.helperService.parseIDVGoLabel(
            capabilities.extraction.data.issuingCountry,
          ),
        });
      }
      if (capabilities.extraction.data.subType) {
        ret.push({
          label: "Type",
          type: "field",
          value: this.helperService.parseIDVGoLabel(
            capabilities.extraction.data.subType,
          ),
        });
      }
      if (capabilities.extraction.data.issuingDate) {
        ret.push({
          label: "Issuing date",
          type: "field",
          value: this.helperService.parseIDVGoLabel(
            capabilities.extraction.data.issuingDate,
          ),
        });
      }
    }

    if (capabilities.imageChecks) {
      const decisionType = capabilities.imageChecks.decision.type;
      ret.push({
        label: "Image checks",
        type: "title",
        titleClass: this.returnClassNameFromType(decisionType),
      });
      if (decisionType.toLowerCase() !== "passed") {
        ret.push({
          label: "Details",
          type: "field",
          value: this.helperService.parseIDVGoLabel(
            capabilities.imageChecks.decision.details.label,
          ),
        });
      }
    }

    if (capabilities.liveness && capabilities.liveness.data) {
      const decisionType = capabilities.liveness.decision.type;
      ret.push({
        label: "Liveness",
        type: "title",
        titleClass: this.returnClassNameFromType(decisionType),
      });
      if (decisionType.toLowerCase() !== "passed") {
        ret.push({
          label: "Details",
          type: "field",
          value: this.helperService.parseIDVGoLabel(
            capabilities.liveness.decision.details.label,
          ),
        });
      }
      if (capabilities.liveness.data.predictedAge) {
        ret.push({
          label: "Predicted age",
          type: "field",
          value: capabilities.liveness.data.predictedAge,
        });
      }
      if (capabilities.liveness.data.ageConfidenceRange) {
        ret.push({
          label: "Age confidence range",
          type: "field",
          value: this.helperService.parseBackendName2(
            capabilities.liveness.data.ageConfidenceRange,
          ),
        });
      }
    }

    if (capabilities.similarity) {
      const decisionType = capabilities.similarity.decision.type;
      ret.push({
        label: "Similarity",
        type: "title",
        titleClass: this.returnClassNameFromType(decisionType),
      });
      if (decisionType.toLowerCase() !== "passed") {
        ret.push({
          label: "Details",
          type: "field",
          value: this.helperService.parseIDVGoLabel(
            capabilities.similarity.decision.details.label,
          ),
        });
      }
    }

    if (capabilities.usability) {
      const decisionType = capabilities.usability.decision.type;
      ret.push({
        label: "Usability",
        type: "title",
        titleClass: this.returnClassNameFromType(decisionType),
      });
      if (decisionType.toLowerCase() !== "passed") {
        ret.push({
          label: "Details",
          type: "field",
          value: this.helperService.parseIDVGoLabel(
            capabilities.usability.decision.details.label,
          ),
        });
      }
    }

    return ret;
    // END CREATED BY
  }

  returnClassNameFromType(decisionType) {
    let titleClass: string;
    if (
      decisionType.toLowerCase() === "warning" ||
      decisionType.toLowerCase() === "technical_error"
    ) {
      titleClass = "warning";
    }
    if (decisionType.toLowerCase() === "not_executed") {
      titleClass = "not-executed";
    }
    if (decisionType.toLowerCase() === "technical_error") {
      titleClass = "technical-error";
    }
    if (decisionType.toLowerCase() === "passed") {
      titleClass = "passed";
    }
    if (decisionType.toLowerCase() === "rejected") {
      titleClass = "rejected";
    }
    return titleClass;
  }

  getRejectReasonDetails(verificationDetails): string {
    let rejectReason = verificationDetails["verification"]?.rejectReason;
    let value: string = "";

    if (rejectReason) {
      if (rejectReason.hasOwnProperty("rejectReasonDescription")) {
        value =
          this.helperService.capitalize(
            rejectReason["rejectReasonDescription"].replace(/_/g, " "),
          ) + " - ";
      }

      if (
        rejectReason.rejectReasonDetails &&
        Array.isArray(rejectReason.rejectReasonDetails)
      ) {
        for (let i = 0; i < rejectReason.rejectReasonDetails.length; i++) {
          if (
            rejectReason.rejectReasonDetails[i].hasOwnProperty(
              "detailsDescription",
            )
          ) {
            value += this.helperService.capitalize(
              rejectReason.rejectReasonDetails[i]["detailsDescription"].replace(
                /_/g,
                " ",
              ),
            );
          }
          if (i < rejectReason.rejectReasonDetails.length - 1) {
            value += ", ";
          }
        }
      } else if (
        rejectReason.rejectReasonDetails &&
        typeof rejectReason.rejectReasonDetails === "object"
      ) {
        if (
          rejectReason.rejectReasonDetails.hasOwnProperty("detailsDescription")
        ) {
          value += this.helperService.capitalize(
            rejectReason.rejectReasonDetails["detailsDescription"].replace(
              /_/g,
              " ",
            ),
          );
        }
      } else {
        value = value.slice(0, -3);
      }
    }

    return value;
  }
  /**
   * handle click on preview image
   * display image lightbox
   */
  // manually parse the IDV fields
  parseIDVFields(verificationDetails) {
    const ret = [];
    // CREATED BY
    if (verificationDetails.createdBy) {
      ret.push({
        label: this.translate.instant("evidencePreview.idvPreview.label1"),
        type: "title",
      });
      if (verificationDetails.createdBy.firstName) {
        ret.push({
          label: this.translate.instant("evidenceFields.FirstName"),
          type: "field",
          value: verificationDetails.createdBy.firstName,
        });
      }
      if (verificationDetails.createdBy.lastName) {
        ret.push({
          label: this.translate.instant("evidenceFields.LastName"),
          type: "field",
          value: verificationDetails.createdBy.lastName,
        });
      }
      if (verificationDetails.createdBy.email) {
        ret.push({
          label: this.translate.instant("evidenceFields.EmailAddress"),
          type: "field",
          value: verificationDetails.createdBy.email,
        });
      }
    }
    // END CREATED BY
    // REQUESTED DOCUMENT TYPE
    if (verificationDetails.verificationPresets) {
      ret.push({
        label: this.translate.instant("evidencePreview.idvPreview.label17"),
        type: "title",
      });
      if (verificationDetails.verificationPresets.type) {
        ret.push({
          label: this.translate.instant("common.type"),
          type: "field",
          value: verificationDetails.verificationPresets.type.replace(
            /_/g,
            " ",
          ),
        });
      } else {
        ret.push({
          label: this.translate.instant("common.type"),
          type: "field",
          value: "Any",
        });
      }
      if (verificationDetails.verificationPresets.country) {
        ret.push({
          label: this.translate.instant("evidenceFields.AddressCountry"),
          type: "field",
          value: verificationDetails.verificationPresets.country,
        });
      }
      if (verificationDetails.verificationPresets.locale) {
        ret.push({
          label: this.helperService.parseBackendName(
            this.translate.instant("translation.Language"),
          ),
          type: "field",
          value: verificationDetails.verificationPresets.locale,
        });
      } else {
        ret.push({
          label: this.helperService.parseBackendName(
            this.translate.instant("translation.Language"),
          ),
          type: "field",
          value: "Any",
        });
      }

      if (verificationDetails.verificationPresets.workflowId) {
        ret.push({
          label: "Capture method",
          type: "field",
          value: this.getWorkFlowList().find(
            (item) =>
              item.key ===
              verificationDetails.verificationPresets.workflowId.toString(),
          )?.name,
        });
      }
    }
    // END REQUESTED DOCUMENT TYPE
    // DOCUMENT INFORMATION
    if (verificationDetails.document) {
      ret.push({
        label: this.translate.instant("evidencePreview.idvPreview.label2"),
        type: "title",
      });
      if (verificationDetails.document.firstName) {
        ret.push({
          label: this.translate.instant("evidenceFields.FirstName"),
          type: "field",
          value: verificationDetails.document.firstName,
        });
      }
      if (verificationDetails.document.lastName) {
        ret.push({
          label: this.translate.instant("evidenceFields.LastName"),
          type: "field",
          value: verificationDetails.document.lastName,
        });
      }
      if (verificationDetails.document.dob) {
        ret.push({
          label: this.translate.instant("evidenceFields.DateOfBirth"),
          type: "field",
          value: verificationDetails.document.dob,
        });
      }
      ret.push({
        label: this.translate.instant("evidencePreview.idvPreview.label3"),
        type: "title",
      });
      if (verificationDetails.document.type) {
        ret.push({
          label: this.translate.instant("evidenceFields.Type"),
          type: "field",
          value: verificationDetails.document.type.replace(/_/g, " "),
        });
      }
      if (verificationDetails.document.number) {
        ret.push({
          label: this.translate.instant("evidenceFields.AddressNumber"),
          type: "field",
          value: verificationDetails.document.number,
        });
      }
      if (verificationDetails.document.expiry) {
        let currentDate = new Date();
        let expiryDate =  new Date(verificationDetails.document.expiry)
        if(currentDate > expiryDate)
        {
          ret.push({
            label: this.translate.instant("evidenceFields.ExpiryDate"),
            type: "field",
            value: verificationDetails.document.expiry,
            icon:"/assets/images/icon-triangle-orange.svg",
            valueColor: "#c74848",
          });
        }
        else
        {
          ret.push({
            label: this.translate.instant("evidenceFields.ExpiryDate"),
            type: "field",
            value: verificationDetails.document.expiry,
          });

        }
      }
    }
    // END DOCUMENT INFORMATION
    // SECURITY FEATURES
    let hardCodedSfValue = "OK";
    if (
      verificationDetails["verification"] &&
      verificationDetails["verification"].identityVerification &&
      verificationDetails["verification"].identityVerification.similarity
    ) {
      if (
        verificationDetails[
          "verification"
        ].identityVerification.similarity.toLowerCase() !== "match"
      ) {
        hardCodedSfValue = "N/A";
      }
    }
    //mmarker
    if (
      !verificationDetails["verification"].identityVerification &&
      verificationDetails["verification"].mrzCheck !== "OK"
    ) {
      hardCodedSfValue = "N/A";
    }
    if (verificationDetails.document) {
      ret.push({
        label: this.translate.instant("evidencePreview.idvPreview.label4"),
        type: "title",
      });
      if (verificationDetails["document"]) {
        ret.push({
          label: this.translate.instant("evidencePreview.idvPreview.label5"),
          type: "field",
          value: hardCodedSfValue,
        });
      }
      if (verificationDetails["document"]) {
        ret.push({
          label: this.translate.instant("evidencePreview.idvPreview.label6"),
          type: "field",
          value: hardCodedSfValue,
        });
      }
      if (verificationDetails["document"]) {
        ret.push({
          label: this.translate.instant("evidencePreview.idvPreview.label7"),
          type: "field",
          value: hardCodedSfValue,
        });
      }
      if (
        verificationDetails["verification"] &&
        verificationDetails["verification"].identityVerification &&
        verificationDetails["verification"].identityVerification.validity
      ) {
        if (hardCodedSfValue === "N/A") {
          verificationDetails["verification"].identityVerification.validity =
            "false";
        }
        ret.push({
          label: this.translate.instant("evidencePreview.idvPreview.label8"),
          type: "field",
          value:
            verificationDetails["verification"].identityVerification.validity &&
            verificationDetails[
              "verification"
            ].identityVerification.validity.toLowerCase() == "true"
              ? this.translate.instant("common.yes")
              : this.translate.instant("common.no"),
        });
      }
      if (verificationDetails["document"]) {
        ret.push({
          label: this.translate.instant("evidencePreview.idvPreview.label9"),
          type: "field",
          value: hardCodedSfValue,
        });
      }
      if (verificationDetails["document"]) {
        ret.push({
          label: this.translate.instant("evidencePreview.idvPreview.label10"),
          type: "field",
          value: hardCodedSfValue,
        });
      }
      if (verificationDetails["document"]) {
        ret.push({
          label: this.translate.instant("evidencePreview.idvPreview.label11"),
          type: "field",
          value: hardCodedSfValue,
        });
      }
      if (verificationDetails["document"]) {
        ret.push({
          label: this.translate.instant("evidencePreview.idvPreview.label2"),
          type: "field",
          value: hardCodedSfValue,
        });
      }
    }
    // END SECURITY FEATURES
    // VERIFICATION INFORMATION
    ret.push({
      label: this.translate.instant("evidencePreview.idvPreview.label13"),
      type: "title",
    });
    ret.push({
      label: this.translate.instant("evidencePreview.idvPreview.label14"),
      type: "field",
      value: "Done",
    });
    if (
      verificationDetails["transaction"] &&
      verificationDetails["transaction"].source
    ) {
      ret.push({
        label: this.translate.instant("evidencePreview.idvPreview.label15"),
        type: "field",
        value: this.helperService.capitalize(
          verificationDetails["transaction"].source.replace(/_/g, " "),
        ),
      });
    }
    if (
      verificationDetails["document"] &&
      verificationDetails["document"].status
    ) {
      if (
        verificationDetails["verification"] &&
        verificationDetails["verification"].identityVerification &&
        verificationDetails["verification"].identityVerification.similarity
      ) {
        if (
          verificationDetails[
            "verification"
          ].identityVerification.similarity.toLowerCase() !== "match"
        ) {
          verificationDetails["document"].status = "failed";
        }
      }
      ret.push({
        label: this.translate.instant("evidencePreview.idvPreview.label16"),
        type: "field",
        value: this.helperService.capitalize(
          verificationDetails["document"].status.replace(/_/g, " "),
        ),
      });
    }

    let value = this.getRejectReasonDetails(verificationDetails);
    if (value != "") {
      ret.push({
        label: this.translate.instant("evidencePreview.idvPreview.reason"),
        type: "field",
        value: value,
      });
    }
    // VERIFICATION INFORMATION
    return ret;
  }

  async parseAddressFields(verificationDetails, onlyPresets?) {
    const ret = [];
    if (true) {
      verificationDetails = await this.parseAddressVerificationDetails(
        verificationDetails,
        [],
        [],
        undefined,
        undefined,
        false,
        true
      );
    }

    // CREATED BY
    if (verificationDetails.createdBy) {
      if (verificationDetails.createdBy.email) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label1",
          ),
          type: "title",
          value: verificationDetails.createdBy.email,
        });
      } else {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label1",
          ),
          type: "title",
          value: verificationDetails.createdBy,
        });
      }
    }
    // END CREATED BY


    // Verification presets
    if (verificationDetails.verificationPresets) {
      ret.push({
        label: this.translate.instant("evidencePreview.addressPreview.label2"),
        type: "title",
      });
      if (
        verificationDetails.verificationPresets.addressRequested !== undefined
      ) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label3",
          ),
          type: "field",
          value: verificationDetails.verificationPresets.addressRequested
            ? this.translate.instant("common.yes")
            : this.translate.instant("common.no"),
        });
      }
      if (
        verificationDetails.verificationPresets.documentsRequested !== undefined
      ) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label4",
          ),
          type: "field",
          value: verificationDetails.verificationPresets.documentsRequested
            ? this.translate.instant("common.yes")
            : this.translate.instant("common.no"),
        });
      }
      if (
        verificationDetails.results && verificationDetails.results.confirmationCode ) {
        ret.push({
          label: "Confirmation code",
          type: "field",
          value: verificationDetails.results.confirmationCode
        });
      }
      if (verificationDetails.iDIN === true) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label5",
          ),
          type: "field",
          value: this.translate.instant("common.yes"),
        });
      }
      if (
        verificationDetails.verificationPresets.verificationLetterRequested !==
        undefined
      ) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label6",
          ),
          type: "field",
          value: verificationDetails.verificationPresets
            .verificationLetterRequested
            ? this.translate.instant("common.yes")
            : this.translate.instant("common.no"),
        });
      }
    }
    // End verification presets
    // ADDRESS BEING VERIFIIED
    if (verificationDetails.providedAddress) {
      if (verificationDetails.providedAddress.address) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label7",
          ),
          type: "title",
        });
        if (verificationDetails.providedAddress.country) {
          ret.push({
            label: this.translate.instant("evidenceFields.AddressCountry"),
            type: "field",
            value: verificationDetails.providedAddress.country,
          });
        }
        if (verificationDetails.providedAddress.region) {
          ret.push({
            label: this.translate.instant("evidenceFields.AddressRegion"),
            type: "field",
            value: verificationDetails.providedAddress.region,
          });
        }
        if (verificationDetails.providedAddress.city) {
          ret.push({
            label: this.translate.instant("evidenceFields.AddressCity"),
            type: "field",
            value: verificationDetails.providedAddress.city,
          });
        }
        if (verificationDetails.providedAddress.zipCode) {
          ret.push({
            label: this.translate.instant("evidenceFields.AddressPostalCode"),
            type: "field",
            value: verificationDetails.providedAddress.zipCode,
          });
        }
        if (verificationDetails.providedBy.street) {
          ret.push({
            label: this.translate.instant("evidenceFields.AddressStreet"),
            type: "field",
            value: verificationDetails.providedBy.street,
          });
        }
        if (verificationDetails.providedAddress.address) {
          ret.push({
            label: this.translate.instant("evidenceFields.AddressStreet"),
            type: "field",
            value: verificationDetails.providedAddress.address,
          });
        }
        if (verificationDetails.providedBy) {
          ret.push({
            label: this.translate.instant(
              "evidencePreview.addressPreview.label8",
            ),
            type: "field",
            value: verificationDetails.providedBy,
          });
        }
      }
    }
    // END ADDRESS BEING VERIFIIED
    // GPS ADDSRESS

    if (verificationDetails.locatedAddress) {
      if (verificationDetails.iDIN) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label9",
          ),
          type: "title",
        });
      } else {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label10",
          ),
          type: "title",
        });
      }
      if (verificationDetails.locatedAddress.country) {
        ret.push({
          label: this.translate.instant("evidenceFields.AddressCountry"),
          type: "field",
          value: verificationDetails.locatedAddress.country,
        });
      }
      if (verificationDetails.locatedAddress.region) {
        ret.push({
          label: this.translate.instant("evidenceFields.AddressRegion"),
          type: "field",
          value: verificationDetails.locatedAddress.region,
        });
      }
      if (verificationDetails.locatedAddress.city) {
        ret.push({
          label: this.translate.instant("evidenceFields.AddressCity"),
          type: "field",
          value: verificationDetails.locatedAddress.city,
        });
      }
      if (verificationDetails.locatedAddress.zipCode) {
        ret.push({
          label: this.translate.instant("evidenceFields.AddressPostalCode"),
          type: "field",
          value: verificationDetails.locatedAddress.zipCode,
        });
      }
      if (verificationDetails.locatedAddress.address) {
        ret.push({
          label: this.translate.instant("evidenceFields.Address"),
          type: "field",
          value: verificationDetails.locatedAddress.address,
        });
      }
      if (verificationDetails.iDIN) {
        if (verificationDetails.locatedAddress.lat) {
          ret.push({
            label: this.translate.instant(
              "evidencePreview.addressPreview.label11",
            ),
            type: "field",
            value: verificationDetails.locatedAddress.lat,
          });
        }
        if (verificationDetails.locatedAddress.lng) {
          ret.push({
            label: this.translate.instant(
              "evidencePreview.addressPreview.label12",
            ),
            type: "field",
            value: verificationDetails.locatedAddress.lng,
          });
        }
      }
    }
    // END GPS ADDRESS
    // START IP INFO
    if (verificationDetails.ipInfo) {
      ret.push({
        label: this.translate.instant("evidencePreview.addressPreview.label13"),
        type: "title",
      });
      if (verificationDetails.ipInfo.internetProvider) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label14",
          ),
          type: "field",
          value: verificationDetails.ipInfo.internetProvider,
        });
      }
      if (verificationDetails.ipInfo.country) {
        ret.push({
          label: this.translate.instant("evidenceFields.AddressCountry"),
          type: "field",
          value: verificationDetails.ipInfo.country,
        });
      }
      if (verificationDetails.ipInfo.city) {
        ret.push({
          label: this.translate.instant("evidenceFields.AddressCity"),
          type: "field",
          value: verificationDetails.ipInfo.city,
        });
      }
      if (verificationDetails.ipInfo.proxy) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label15",
          ),
          type: "field",
          value: verificationDetails.ipInfo.proxy,
        });
      }
      if (verificationDetails.ipInfo.vpn) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label6",
          ),
          type: "field",
          value: verificationDetails.ipInfo.vpn,
        });
      }
      if (verificationDetails.ipInfo.darkWeb) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label17",
          ),
          type: "field",
          value: verificationDetails.ipInfo.darkWeb,
        });
      }
      if (verificationDetails.ipInfo.ipAddress) {
        ret.push({
          label: this.translate.instant(
            "evidencePreview.addressPreview.label18",
          ),
          type: "field",
          value: verificationDetails.ipInfo.ipAddress,
        });
      }
    }
    ret.push({
      label: this.translate.instant("evidencePreview.addressPreview.label19"),
      type: "title",
    });
    ret.push({
      label: this.translate.instant("evidencePreview.addressPreview.label20"),
      type: "field",
      value: verificationDetails.status ? this.helperService.parseCountryName(verificationDetails.status) : "DONE",
    });
    if (verificationDetails.requestedOn) {
      ret.push({
        label: this.translate.instant("evidencePreview.addressPreview.label21"),
        type: "field",
        value: verificationDetails.requestedOn,
      });
    }
    if (verificationDetails.completedOn) {
      ret.push({
        label: this.translate.instant("evidencePreview.addressPreview.label22"),
        type: "field",
        value: verificationDetails.completedOn,
      });
    }
    return ret;
  }

  triggerIDPreview(event) {
    this.triggerIDpreview.next(event);
  }

  resolveAvDetailsStatuses(avDetails, isDigitalVerification?) {
    let ret = null;
    let icon = null;
    let completedAt = null;
    let status = null;
    let finalizedBy;
    let finalizeComment;

    finalizedBy = avDetails.record.finalizedBy;
    finalizeComment = avDetails.record.finalizeComment;
    if (!finalizedBy) {
      finalizedBy = avDetails.record.finalized?.by;
    }
    if (!finalizeComment) {
      finalizeComment = avDetails.record.finalized?.comment;
    }

    if (avDetails.record.idinResults) {
      completedAt = this.helperService.formatISODate(
        avDetails.record.idinResults.completedAt,
        this.helperService.getPdfFormatDate(),
        false,
      );

      if(!completedAt) {
        completedAt = this.helperService.formatISODate(
          avDetails.record.completedOn,
          this.helperService.getPdfFormatDate(),
          false,
        );
      }

      icon = "/assets/images/check.svg";
      status = this.translate.instant(
        "evidencePreview.addressPreview.status1",
        { completedAt: completedAt },
      );
    } else {
      // TODO: see how to distinguish if extended check is needed/started/finished
      // will there be a differenece between extended check finished and status === 'COMPLETED'
      completedAt = this.helperService.formatISODate(
        avDetails.record.lastModified,
        this.helperService.getPdfFormatDate(),
        false,
      );
      if (completedAt) {
        icon = "/assets/images/check-gray.svg";
        status = this.translate.instant(
          "evidencePreview.addressPreview.status2",
          { completedAt: completedAt },
        );
      }
      // TODO:
      if (avDetails.record.status === "EXTENDED_CHECK_REQUESTED") {
        icon = "/assets/images/icon-informaton-prepared-teal.svg";
        status = this.translate.instant(
          "evidencePreview.addressPreview.status3",
          { completedAt: completedAt },
        );
      }
      if (avDetails.record.status === "COMPLETED") {
        icon = "/assets/images/check.svg";
        completedAt = this.helperService.formatISODate(
          avDetails.record.completedAt,
          this.helperService.getPdfFormatDate(),
          false,
        );
        if (!completedAt) {
          completedAt = this.helperService.formatISODate(
            avDetails.record.completedOn,
            this.helperService.getPdfFormatDate(),
            false,
          );
        }
        if (finalizedBy) {
          status = this.translate.instant(
            "evidencePreview.addressPreview.status4",
            { completedAt: completedAt, finalizedBy: finalizedBy },
          );
        } else {
          status = this.translate.instant(
            "evidencePreview.addressPreview.status5",
            { completedAt: completedAt },
          );
        }
        if (finalizedBy && isDigitalVerification) {
          status = this.translate.instant(
            "evidencePreview.addressPreview.status6",
            { completedAt: completedAt, finalizedBy: finalizedBy },
          );
        } else {
          status = this.translate.instant(
            "evidencePreview.addressPreview.status7",
            { completedAt: completedAt },
          );
        }
      }
      if (
        avDetails.record.status === "COMPLETED" &&
        avDetails.record.verificationPresets.verificationLetterRequested
      ) {
        icon = "/assets/images/check.svg";
        completedAt = this.helperService.formatISODate(
          avDetails.record.verificationProcessCompletedAt,
          this.helperService.getPdfFormatDate(),
          false,
        );
        if (!completedAt) {
          completedAt = this.helperService.formatISODate(
            avDetails.record.completedAt,
            this.helperService.getPdfFormatDate(),
            false,
          );
        }
        if (!completedAt) {
          completedAt = this.helperService.formatISODate(
            avDetails.record.completedOn,
            this.helperService.getPdfFormatDate(),
            false,
          );
        }

        status = this.translate.instant(
          "evidencePreview.addressPreview.status8",
          { completedAt: completedAt },
        );
      }
      if (avDetails.record.status === "FAILED") {
        icon = "/assets/images/no-entry-red.svg";
        completedAt = this.helperService.formatISODate(
          avDetails.record.completedAt,
          this.helperService.getPdfFormatDate(),
          false,
        );
        if (!completedAt) {
          completedAt = this.helperService.formatISODate(
            avDetails.record.completedOn,
            this.helperService.getPdfFormatDate(),
            false,
          );
        }
        if (finalizedBy) {
          status = this.translate.instant(
            "evidencePreview.addressPreview.status9",
            { completedAt: completedAt, finalizedBy: finalizedBy },
          );
        } else {
          status = this.translate.instant(
            "evidencePreview.addressPreview.status10",
            { completedAt: completedAt },
          );
        }
      }
    }
    ret = [
      {
        icon: icon,
        color: "",
        title: "",
        status: status,
      },
    ];
    if (finalizeComment) {
      ret.push({
        title: "",
        class: "verification-main-substatus",
        status: finalizeComment,
      });
    }
    return ret;
  }

  parseFundDVStatuses(digitalVerifications) {
    const ret = [];
    digitalVerifications.forEach((verification) => {
      if (verification.verificationType === "Identity") {
        let status;
        status = this.parseIdVerificaitonStatus(verification, "funds");
        if (status) {
          status["notificationInfo"].originalEvidenceKey =
            verification.originalEvidenceKey;
          status["notificationInfo"].evidence = verification;
          status["notificationInfo"].key = verification.key;
          status["notificationInfo"].showButton = true;
          status["notificationInfo"].requestedBy = verification.createdBy
            ? verification.createdBy.email
            : undefined;
          const canUpdate = !verification.subjectIsOwner ? true : false;
          if (canUpdate) {
            status["notificationInfo"].canUpdate = canUpdate;
          }
          ret.push(status["notificationInfo"]);
        }
      }
      if (verification.verificationType === "IdentityInstantAI") {
        let status;
        status = this.parseIdVerificaitonStatusGo(verification, "funds");
        if (status) {
          status["notificationInfo"].originalEvidenceKey =
            verification.originalEvidenceKey;
          status["notificationInfo"].evidence = verification;
          status["notificationInfo"].key = verification.key;
          status["notificationInfo"].showButton = true;
          status["notificationInfo"].requestedBy = verification.createdBy
            ? verification.createdBy.email
            : undefined;
          const canUpdate = !verification.subjectIsOwner ? true : false;
          if (canUpdate) {
            status["notificationInfo"].canUpdate = canUpdate;
          }
          ret.push(status["notificationInfo"]);
        }
      }
      if (verification.verificationType === "Address") {
        const status: any = this.parseAddressStatus(
          verification,
          "funds",
          true,
          undefined,
        );
        status.evidence = verification;
        status.key = verification.key;
        status.originalEvidenceKey = verification.originalEvidenceKey;
        status.requestedBy = verification.createdBy
          ? verification.createdBy.email
          : undefined;
        const canUpdate = !verification.subjectIsOwner ? true : false;
        status.showButton = true;
        if (canUpdate) {
          status.canUpdate = canUpdate;
        }
        ret.push(status);
      }
      if (verification.verificationType === "Contract") {
        const status: any = this.parseContractStatus(
          verification,
          "funds",
          true,
          undefined,
        );
        status.evidence = verification;
        status.key = verification.key;
        status.originalEvidenceKey = verification.originalEvidenceKey;
        status.requestedBy = verification.createdBy
          ? verification.createdBy.email
          : undefined;
        const canUpdate = !verification.subjectIsOwner ? true : false;
        status.showButton = true;
        if (canUpdate) {
          status.canUpdate = canUpdate;
        }
        ret.push(status);
      }
      if (verification.verificationType === "APS") {
        let statusEs;
        if (
          verification.verificationData &&
          verification.verificationData.publicVerificationData
        ) {
          verification.typeOfHits =
            verification.verificationData.publicVerificationData.typeOfHits;
        } else {
          verification.typeOfHits = [];
        }
        statusEs = this.parseAPSStatus(verification, "funds", true, "");
        statusEs.forEach((status) => {
          status.evidence = verification;
          status.key = verification.key;
          status.originalEvidenceKey = verification.originalEvidenceKey;
          status.requestedBy = verification.createdBy
            ? verification.createdBy.email
            : undefined;
          const canUpdate = !verification.subjectIsOwner ? true : false;
          status.showButton = true;
          // if (canUpdate) {
          //   status.canUpdate = canUpdate;
          // }
          ret.push(status);
        });
      }
      if (verification.verificationType === "ProfileVerification") {
        const profileVerification = {
          content:"Completed with " +
            this.helperService.getStatusForProfileVerification( verification.fieldsSourceStatus) 
            +
            " found on " +
            this.helperService.formatISODate(
              verification.fieldsSourceValidationDate,
              "yyyy-MM-dd hh:mm z",
              false,
            ),
          title: this.translate.instant("digitalVerifications.pv.title"),
          buttonAction: "OnDisplayAPSInLightbox",
          showButton: true,
          showButtonWithIcon: false,
          svgIcon: {
            src: this.helperService.getIconForProfileVerification(
              verification.fieldsSourceStatus,
            ),
            width: 16,
            height: 16,
          },
          evidence: verification,
          key: verification.key,
          originalEvidenceKey: verification.originalEvidenceKey,
          requestedBy: verification.createdBy
            ? verification.createdBy.email
            : undefined,
        };
        const canUpdate = !verification.subjectIsOwner ? true : false;
        ret.push(profileVerification);
      }
    });
    return ret;
  }

  // returns a status to be used in the dashboard
  parseIdVerificaitonStatus(result, projectType) {
    let status;
    let record;
    if (projectType === "id") {
      record = result.lastVerification.record;
    } else if (projectType === "funds") {
      record = result;
    }
    // Date for Complited and Verified
    const date =
      projectType === "funds"
        ? this.helperService.formatISODate(record.completedOn)
        : this.helperService.formatISODate(record.lastModified);
    switch (record.status) {
      case "Not requested":
      case "Requested":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status"),
          class: "status-not-started",
          notificationInfo: {
            // tslint:disable-next-line:max-line-length
            content: this.translate.instant("digitalVerifications.idv.status2"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            title: this.translate.instant("digitalVerifications.idv.title"),
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-gray",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "pending":
      case "In progress":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status3"),
          class: "status-pending",
          notificationInfo: {
            content: this.translate.instant("digitalVerifications.idv.status4"),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            svgIcon: {
              src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
              class: "svg-blue",
              width: 16, // required
              height: 13.5, // required
            },
          },
          showHistory: false,
        };
        // handle expired pending link
        if (
          record.expiresOn &&
          this.helperService.getTimestamp("current") >
            this.helperService.getTimestamp(record.expiresOn)
        ) {
          const date = this.helperService.formatISODate(record.expiresOn);
          status = {
            content: "expired",
            class: "status-rejected",
            notificationInfo: {
              content: date
                ? this.translate.instant("digitalVerifications.idv.status6", {
                    date: date,
                  })
                : "",
              title: this.translate.instant("digitalVerifications.idv.title"),
              buttonAction: "OnDisplayImageInLightbox",
              showButton: true,
              svgIcon: {
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                class: "svg-orange",
                width: 16, // required
                height: 13.5, // required
              },
            },
            showHistory: false,
          };
        }
        // handle consentedAt flag
        if (record.consentedAt) {
          status = {
            content: this.translate.instant("digitalVerifications.idv.status7"),
            class: "status-pending",
            notificationInfo: {
              content: this.translate.instant(
                "digitalVerifications.idv.status4",
              ),
              title: this.translate.instant("digitalVerifications.idv.title"),
              buttonAction: "OnDisplayImageInLightbox",
              showButton: false,
              svgIcon: {
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                class: "svg-blue",
                width: 16, // required
                height: 13.5, // required
              },
            },
            showHistory: false,
          };
        }
        break;
      case "processing":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status4"),
          class: "status-pending",
          notificationInfo: {
            content: this.translate.instant("digitalVerifications.idv.status4"),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            svgIcon: {
              src: "/assets/images/icon-refresh-prepared.svg",
              class: "svg-blue",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "rejected": // excel file no longer contains rejected status but I will keep it for
        status = {
          content: this.translate.instant("digitalVerifications.idv.status8"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant("digitalVerifications.idv.status9"),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-orange",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "rejected_error":
      case "Failed":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status8"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status10",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-orange",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "failed_not_found":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status8"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status11",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-orange",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "rejected_failed":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status12"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status13",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: true,
            svgIcon: {
              src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
              class: "svg-red",
              width: 16, // required
              height: 13.5, // required
            },
          },
          showHistory: false,
        };
        break;
      case "rejected_id_type":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status14"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status15",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: true,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-orange",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "rejected_id_country":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status16"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status17",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: true,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-orange",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "rejected_id_not_readable":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status18"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status19",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: true,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-orange",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "rejected_id_none":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status20"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status21",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-orange",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "verified":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status22"),
          class: "status-verified",
          notificationInfo: {
            // tslint:disable-next-line:max-line-length
            content: !record.isPartial
              ? date
                ? this.translate.instant("digitalVerifications.idv.status23", {
                    date: date,
                  })
                : ""
              : this.translate.instant("digitalVerifications.idv.status30"),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: !record.isPartial ? true : false,
            svgIcon: {
              src: "/assets/images/icon-checkmark-prepared.svg",
              class: "svg-green",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: true,
        };
        break;
      case "Completed":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status22"),
          class: "status-verified",
          notificationInfo: {
            // tslint:disable-next-line:max-line-length
            content: date
              ? this.translate.instant("digitalVerifications.idv.status23", {
                  date: date,
                })
              : "",
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: true,
            svgIcon: {
              src: "/assets/images/icon-checkmark-prepared.svg",
              class: "svg-green",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: true,
        };
        break;
      case "cancel":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status24"),
          class: "status-verified",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status25",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-gray",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: true,
        };
        break;
      case "rejected_similarity":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status26"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status27",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: true,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-orange",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "rejected_validity":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status26"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status27",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: true,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-orange",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      // although tecnically not a verification JUMIO status returned from BE lazy refresh is needed
      case "lazy_refresh":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status28"),
          class: "status-refresh",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status29",
            ),
            title: this.translate.instant("digitalVerifications.idv.title"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            svgIcon: {
              src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
              class: "svg-blue",
              width: 0, // required
              height: 0, // required
            },
          },
          showHistory: false,
        };
        break;
    }
    return status;
  }

  parseIdVerificaitonStatusGo(result, projectType) {
    let status;
    let record;
    if (projectType === "id") {
      record = result.lastVerification.record;
    } else if (projectType === "funds") {
      record = result;
    }
    // Date for Complited and Verified
    const date =
      projectType === "funds"
        ? this.helperService.formatISODate(record.completedOn)
        : this.helperService.formatISODate(record.lastModified);
    switch (record.status) {
      case "Not requested":
      case "Requested":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status"),
          class: "status-not-started",
          notificationInfo: {
            // tslint:disable-next-line:max-line-length
            content: this.translate.instant("digitalVerifications.idv.status2"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            title:
              this.helperService.translateVerificationType("IdentityInstantAI"),
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-gray",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
      case "pending":
      case "In progress":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status3"),
          class: "status-pending",
          notificationInfo: {
            content: this.translate.instant("digitalVerifications.idv.status4"),
            title:
              this.helperService.translateVerificationType("IdentityInstantAI"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            svgIcon: {
              src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
              class: "svg-blue",
              width: 16, // required
              height: 13.5, // required
            },
          },
          showHistory: false,
        };
        // handle expired pending link
        if (
          record.expiresOn &&
          this.helperService.getTimestamp("current") >
            this.helperService.getTimestamp(record.expiresOn)
        ) {
          const date = this.helperService.formatISODate(record.expiresOn);
          status = {
            content: "expired",
            class: "status-rejected",
            notificationInfo: {
              content: date
                ? this.translate.instant("digitalVerifications.idv.status6", {
                    date: date,
                  })
                : "",
              title:
                this.helperService.translateVerificationType(
                  "IdentityInstantAI",
                ),
              buttonAction: "OnDisplayImageInLightbox",
              showButton: true,
              svgIcon: {
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                class: "svg-orange",
                width: 16, // required
                height: 13.5, // required
              },
            },
            showHistory: false,
          };
        }
        // handle consentedAt flag
        if (record.consentedAt) {
          status = {
            content: this.translate.instant("digitalVerifications.idv.status7"),
            class: "status-pending",
            notificationInfo: {
              content: this.translate.instant(
                "digitalVerifications.idv.status4",
              ),
              title:
                this.helperService.translateVerificationType(
                  "IdentityInstantAI",
                ),
              buttonAction: "OnDisplayImageInLightbox",
              showButton: false,
              svgIcon: {
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                class: "svg-blue",
                width: 16, // required
                height: 13.5, // required
              },
            },
            showHistory: false,
          };
        }
        break;
      case "Completed":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status22"),
          class: "status-verified",
          notificationInfo: {
            // tslint:disable-next-line:max-line-length
            content: date
              ? this.translate.instant("digitalVerifications.idv.status23", {
                  date: date,
                })
              : "",
            title:
              this.helperService.translateVerificationType("IdentityInstantAI"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: true,
            svgIcon: {
              src: "/assets/images/icon-checkmark-prepared.svg",
              class: "svg-green",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: true,
        };
        break;
      // although tecnically not a verification JUMIO status returned from BE lazy refresh is needed
      case "lazy_refresh":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status28"),
          class: "status-refresh",
          notificationInfo: {
            content: this.translate.instant(
              "digitalVerifications.idv.status29",
            ),
            title:
              this.helperService.translateVerificationType("IdentityInstantAI"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: false,
            svgIcon: {
              src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
              class: "svg-blue",
              width: 0, // required
              height: 0, // required
            },
          },
          showHistory: false,
        };
        break;

      case "Failed":
        status = {
          content: this.translate.instant("digitalVerifications.idv.status8"),
          class: "status-rejected",
          notificationInfo: {
            content: this.translate.instant("digitalVerifications.idv.status8"),
            title:
              this.helperService.translateVerificationType("IdentityInstantAI"),
            buttonAction: "OnDisplayImageInLightbox",
            showButton: true,
            svgIcon: {
              src: "/assets/images/icon-information-prepared.svg",
              class: "svg-orange",
              width: 16, // required
              height: 16, // required
            },
          },
          showHistory: false,
        };
        break;
    }
    return status;
  }

  // returns a status to be used in the dashboard
  parseAddressStatus(
    result,
    projectType,
    showAddressVerificationElements,
    verificationSubjectStatus,
  ) {
    let isFunds;
    let avStatus = {};
    let lastAddressVerification = result.lastAddressVerification;
    let lastAddressVerificationRecord = lastAddressVerification
      ? lastAddressVerification.record
      : undefined;
    if (verificationSubjectStatus !== undefined) {
      verificationSubjectStatus = verificationSubjectStatus.toUpperCase();
    }
    if (projectType === "funds") {
      isFunds = true;
      lastAddressVerification = result;
      lastAddressVerificationRecord = result;
      if (lastAddressVerificationRecord.status === "Completed") {
        lastAddressVerificationRecord.status = "COMPLETED";
      }
      if (lastAddressVerificationRecord.status === "Action required") {
        lastAddressVerificationRecord.status = "ACTION_REQUIRED";
      }
    }

    if (
      isFunds &&
      (lastAddressVerificationRecord.status === "Not requested" ||
        lastAddressVerificationRecord.status === "Requested")
    ) {
      avStatus = {
        content: this.translate.instant("digitalVerifications.address.status"),
        title: this.translate.instant("digitalVerifications.address.title"),
        svgIcon: {
          src: "/assets/images/icon-information-prepared.svg",
          class: "svg-gray",
          width: 16, // required
          height: 16, // required
        },
      };
    } else if (showAddressVerificationElements || lastAddressVerification) {
      if (
        !lastAddressVerification &&
        (verificationSubjectStatus === "NOT_STARTED" ||
          !verificationSubjectStatus)
      ) {
        avStatus = {
          content: this.translate.instant(
            "digitalVerifications.address.status",
          ),
          title: this.translate.instant("digitalVerifications.address.title"),
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-gray",
            width: 16, // required
            height: 16, // required
          },
        };
      } else if (
        !lastAddressVerification &&
        verificationSubjectStatus !== "NOT_STARTED" &&
        verificationSubjectStatus !== undefined
      ) {
        avStatus = {
          content: this.translate.instant(
            "digitalVerifications.address.status2",
          ),
          title: this.translate.instant("digitalVerifications.address.title"),
          statusClass: "status-not-included",
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-light-gray",
            width: 16, // required
            height: 16, // required
          },
        };
      } else {
        if (
          lastAddressVerificationRecord.status !== "COMPLETED" &&
          lastAddressVerificationRecord.status !== "ACTION_REQUIRED" &&
          lastAddressVerificationRecord.status.toUpperCase() !== "FAILED"
        ) {
          avStatus = {
            content: this.translate.instant(
              "digitalVerifications.address.status3",
            ),
            title: this.translate.instant("digitalVerifications.address.title"),
            svgIcon: {
              src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
              class: "svg-blue",
              width: 16, // required
              height: 16, // required
            },
          };
        } else if (
          lastAddressVerificationRecord.status.toUpperCase() === "FAILED" ||
          lastAddressVerificationRecord.status === "COMPLETED" ||
          lastAddressVerificationRecord.status === "ACTION_REQUIRED"
        ) {
          let completedAt = "";
          let isStandard = false;
          let isEnhanced = false;
          if (
            lastAddressVerification &&
            lastAddressVerificationRecord.verificationPresets &&
            lastAddressVerificationRecord.verificationPresets
              .verificationLetterRequested
          ) {
            isEnhanced = true;
          }
          if (
            lastAddressVerification &&
            lastAddressVerificationRecord.idinInitiatedOn
          ) {
            completedAt = this.helperService.formatISODate(
              lastAddressVerificationRecord.lastModified,
            );
          }
          if (
            lastAddressVerification &&
            !lastAddressVerificationRecord.idinInitiatedOn
          ) {
            isStandard = true;
            completedAt = this.helperService.formatISODate(
              lastAddressVerificationRecord.lastModified,
            );
          }
          if (!completedAt) {
            completedAt = this.helperService.formatISODate(
              lastAddressVerification.completedOn,
            );
          }
          avStatus = {
            content:
              lastAddressVerificationRecord.status === "ACTION_REQUIRED"
                ? this.isInvestorType()
                  ? this.translate.instant(
                      "digitalVerifications.address.statusInReview",
                    )
                  : this.translate.instant(
                      "digitalVerifications.address.status4",
                    )
                : lastAddressVerificationRecord.status.toUpperCase() ===
                  "FAILED"
                ? this.translate.instant(
                    "digitalVerifications.address.status6",
                    { date: completedAt },
                  )
                : this.translate.instant(
                    "digitalVerifications.address.status5",
                    { date: completedAt },
                  ),
            title:
              !isStandard || lastAddressVerificationRecord.completedWithIdin
                ? this.translate.instant("digitalVerifications.address.title2")
                : isEnhanced
                ? this.translate.instant("digitalVerifications.address.title3")
                : this.translate.instant("digitalVerifications.address.title4"),
            // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
            buttonAction: "OnDisplayImageAddressInLightboxLoading",
            showButton: true,
            // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
            showButtonWithIcon: false,
            svgIcon: {
              src:
                lastAddressVerificationRecord.status === "In progress"
                  ? "/assets/images/icon-alert-triangle-ng-prepared.svg"
                  : lastAddressVerificationRecord.status.toUpperCase() ===
                    "FAILED"
                  ? "/assets/images/no-entry-red.svg"
                  : "/assets/images/icon-checkmark-prepared.svg",
              class:
                lastAddressVerificationRecord.status === "ACTION_REQUIRED"
                  ? "svg-gray"
                  : lastAddressVerificationRecord.status === "In progress"
                  ? "svg-blue"
                  : lastAddressVerificationRecord.status.toUpperCase() ===
                    "FAILED"
                  ? "svg-red"
                  : "svg-green",
              width: 16, // required
              height: 16, // required
            },
          };
        }
      }
    } else if (!showAddressVerificationElements) {
      avStatus = {
        content: this.translate.instant("digitalVerifications.address.status7"),
        title: this.translate.instant("digitalVerifications.address.title"),
        statusClass: "status-not-included",
        svgIcon: {
          src: "/assets/images/icon-checkmark-prepared.svg",
          class: "svg-light-gray",
          width: 16, // required
          height: 16, // required
        },
      };
    }
    if (projectType === "funds") {
      if (lastAddressVerificationRecord.status === "COMPLETED") {
        lastAddressVerificationRecord.status = "Completed";
      }
      if (lastAddressVerificationRecord.status === "ACTION_REQUIRED") {
        lastAddressVerificationRecord.status = "Action required";
      }
    }

    return avStatus;
  }

  // returns a status to be used in the dashboard
  parseAPSStatus(
    result,
    projectType,
    showAPSVerificationElements,
    verificationSubjectStatus,
  ) {
    let isFunds;
    let apsStatuses;

    let lastApsVerification = result.lastApsVerification;
    let lastApsVerificationRecord = lastApsVerification
      ? lastApsVerification.record
      : undefined;
    let originalStatus;

    if (projectType === "funds") {
      isFunds = true;
      lastApsVerification = result;
      lastApsVerificationRecord = result;
      originalStatus = lastApsVerificationRecord.status;
      if (lastApsVerificationRecord.status === "Completed") {
        lastApsVerificationRecord.status = "COMPLETED";
      }
      if (lastApsVerificationRecord.status === "Action required") {
        lastApsVerificationRecord.status = "ACTION_REQUIRED";
      }
      if (lastApsVerificationRecord.status === "In progress") {
        lastApsVerificationRecord.status = "PENDING";
      }
      if (lastApsVerificationRecord.status === "Not requested") {
        lastApsVerificationRecord.status = "NOT_STARTED";
      }
      if (lastApsVerificationRecord.status === "Failed") {
        lastApsVerificationRecord.status = "FAILED";
      }
    }

    if (showAPSVerificationElements || lastApsVerification) {
      // THERE IS A PREVIOUS APS VERIFICATION
      if (
        lastApsVerificationRecord &&
        lastApsVerificationRecord.status !== "NOT_STARTED"
      ) {
        // IF THE STATUS IS ACTION_REQUIRED OR COMPLETED
        if (
          lastApsVerificationRecord.status === "ACTION_REQUIRED" ||
          lastApsVerificationRecord.status === "COMPLETED"
        ) {
          const aprovedOnDate = this.helperService.formatISODate(
            lastApsVerificationRecord.completedOn
              ? lastApsVerificationRecord.completedOn
              : lastApsVerificationRecord.finalized
              ? lastApsVerificationRecord.finalized.on
              : "",
          );
          const screenedOnDate = this.helperService.formatISODate(
            lastApsVerificationRecord.completedOn
              ? lastApsVerificationRecord.completedOn
              : lastApsVerificationRecord.createdOn,
          );
          apsStatuses = [
            {
              content:
                lastApsVerificationRecord.status === "ACTION_REQUIRED" &&
                (lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                  element.includes("sanction"),
                ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("fitness"),
                  ) !== -1)
                  ? this.translate.instant("digitalVerifications.aps.status")
                  : lastApsVerificationRecord.finalized ||
                    lastApsVerificationRecord.completedOn
                  ? aprovedOnDate
                    ? this.translate.instant(
                        "digitalVerifications.aps.status2",
                        { date: aprovedOnDate },
                      )
                    : ""
                  : screenedOnDate
                  ? this.translate.instant("digitalVerifications.aps.status4", {
                      date: screenedOnDate,
                    })
                  : "",
              title: this.translate.instant("digitalVerifications.aps.title"),
              buttonAction: "OnDisplayAPSInLightbox",
              showButton: true,
              showButtonWithIcon: false,
              svgIcon: {
                src:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("sanction"),
                  ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("fitness"),
                  ) !== -1
                    ? "/assets/images/icon-triangle-orange.svg"
                    : "/assets/images/icon-checkmark-prepared.svg",
                class:
                  lastApsVerificationRecord.typeOfHits.findIndex(
                    (element) =>
                      element.includes("sanction") ||
                      lastApsVerificationRecord.typeOfHits.findIndex(
                        (element) => element.includes("fitness"),
                      ) !== -1,
                  ) !== -1
                    ? "svg-orange"
                    : "svg-green",
                width: 16, // required
                height: 16, // required
              },
            },
            {
              content:
                lastApsVerificationRecord.status === "ACTION_REQUIRED" &&
                lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                  element.includes("pep"),
                ) !== -1
                  ? this.translate.instant("digitalVerifications.aps.status")
                  : lastApsVerificationRecord.finalized ||
                    lastApsVerificationRecord.completedOn
                  ? aprovedOnDate
                    ? this.translate.instant(
                        "digitalVerifications.aps.status2",
                        { date: aprovedOnDate },
                      )
                    : ""
                  : screenedOnDate
                  ? this.translate.instant("digitalVerifications.aps.status4", {
                      date: screenedOnDate,
                    })
                  : "",
              title: this.translate.instant("digitalVerifications.aps.title2"),
              // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
              buttonAction: "OnDisplayAPSInLightbox",
              showButton: true,
              // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
              showButtonWithIcon: false,
              svgIcon: {
                src:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("pep"),
                  ) !== -1
                    ? "/assets/images/icon-triangle-orange.svg"
                    : "/assets/images/icon-checkmark-prepared.svg",
                class:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("pep"),
                  ) !== -1
                    ? "svg-orange"
                    : "svg-green",
                width: 16, // required
                height: 16, // required
              },
            },
            {
              content:
                lastApsVerificationRecord.status === "ACTION_REQUIRED" &&
                (lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                  element.includes("adverse"),
                ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("warning"),
                  ) !== -1)
                  ? this.translate.instant("digitalVerifications.aps.status")
                  : lastApsVerificationRecord.finalized ||
                    lastApsVerificationRecord.completedOn
                  ? aprovedOnDate
                    ? this.translate.instant(
                        "digitalVerifications.aps.status2",
                        { date: aprovedOnDate },
                      )
                    : ""
                  : screenedOnDate
                  ? this.translate.instant("digitalVerifications.aps.status4", {
                      date: screenedOnDate,
                    })
                  : "",
              title: this.translate.instant("digitalVerifications.aps.title3"),
              // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
              buttonAction: "OnDisplayAPSInLightbox",
              showButton: true,
              // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
              showButtonWithIcon: false,
              svgIcon: {
                src:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("adverse"),
                  ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("warning"),
                  ) !== -1
                    ? "/assets/images/icon-triangle-orange.svg"
                    : "/assets/images/icon-checkmark-prepared.svg",
                class:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("adverse"),
                  ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("warning"),
                  ) !== -1
                    ? "svg-orange"
                    : "svg-green",
                width: 16, // required
                height: 16, // required
              },
            },
          ];
        } else if (lastApsVerificationRecord.status === "PENDING") {
          apsStatuses = [
            {
              content: this.translate.instant(
                "digitalVerifications.aps.status5",
              ),
              title: this.translate.instant("digitalVerifications.aps.title"),
              buttonAction: "OnDisplayAPSInLightbox",
              showButton: false,
              showButtonWithIcon: false,
              svgIcon: {
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                class: "svg-blue",
                width: 16, // required
                height: 16, // required
              },
            },
            {
              content: this.translate.instant(
                "digitalVerifications.aps.status5",
              ),
              title: this.translate.instant("digitalVerifications.aps.title2"),
              buttonAction: "OnDisplayAPSInLightbox",
              showButton: false,
              showButtonWithIcon: false,
              svgIcon: {
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                class: "svg-blue",
                width: 16, // required
                height: 16, // required
              },
            },
            {
              content: this.translate.instant(
                "digitalVerifications.aps.status5",
              ),
              title: this.translate.instant("digitalVerifications.aps.title3"),
              buttonAction: "OnDisplayAPSInLightbox",
              showButton: false,
              showButtonWithIcon: false,
              svgIcon: {
                src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
                class: "svg-blue",
                width: 16, // required
                height: 16, // required
              },
            },
          ];
        } else if (lastApsVerificationRecord.status === "FAILED") {
          const rejectedOnDate = this.helperService.formatISODate(
            lastApsVerificationRecord.completedOn
              ? lastApsVerificationRecord.completedOn
              : lastApsVerificationRecord.finalized
              ? lastApsVerificationRecord.finalized.on
              : "",
          );
          const screenedOnDate = this.helperService.formatISODate(
            lastApsVerificationRecord.completedOn
              ? lastApsVerificationRecord.completedOn
              : lastApsVerificationRecord.createdOn,
          );
          apsStatuses = [
            {
              content:
                lastApsVerificationRecord.status === "FAILED" &&
                (lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                  element.includes("sanction"),
                ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("fitness"),
                  ) !== -1) &&
                (lastApsVerificationRecord.finalized ||
                  lastApsVerificationRecord.completedOn)
                  ? rejectedOnDate
                    ? this.translate.instant(
                        "digitalVerifications.aps.status3",
                        { date: rejectedOnDate },
                      )
                    : ""
                  : screenedOnDate
                  ? this.translate.instant("digitalVerifications.aps.status4", {
                      date: screenedOnDate,
                    })
                  : "",
              title: this.translate.instant("digitalVerifications.aps.title"),
              // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
              buttonAction: "OnDisplayAPSInLightbox",
              showButton:
                lastApsVerificationRecord.typeOfHits.length > 0 ? true : false,
              // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
              showButtonWithIcon: false,
              svgIcon: {
                src:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("sanction"),
                  ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("fitness"),
                  ) !== -1
                    ? "/assets/images/icon-triangle-orange.svg"
                    : "/assets/images/icon-checkmark-prepared.svg",
                class:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("sanction"),
                  ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("fitness"),
                  ) !== -1
                    ? "svg-orange"
                    : "svg-green",
                width: 16, // required
                height: 16, // required
              },
            },
            {
              content:
                lastApsVerificationRecord.status === "FAILED" &&
                lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                  element.includes("pep"),
                ) !== -1 &&
                (lastApsVerificationRecord.finalized ||
                  lastApsVerificationRecord.completedOn)
                  ? rejectedOnDate
                    ? this.translate.instant(
                        "digitalVerifications.aps.status3",
                        { date: rejectedOnDate },
                      )
                    : ""
                  : screenedOnDate
                  ? this.translate.instant("digitalVerifications.aps.status4", {
                      date: screenedOnDate,
                    })
                  : "",
              title: this.translate.instant("digitalVerifications.aps.title2"),
              // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
              buttonAction: "OnDisplayAPSInLightbox",
              showButton:
                lastApsVerificationRecord.typeOfHits.length > 0 ? true : false,
              // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
              showButtonWithIcon: false,
              svgIcon: {
                src:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("pep"),
                  ) !== -1
                    ? "/assets/images/icon-triangle-orange.svg"
                    : "/assets/images/icon-checkmark-prepared.svg",
                class:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("pep"),
                  ) !== -1
                    ? "svg-orange"
                    : "svg-green",
                width: 16, // required
                height: 16, // required
              },
            },
            {
              content:
                lastApsVerificationRecord.status === "FAILED" &&
                (lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                  element.includes("adverse"),
                ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("warning"),
                  ) !== -1) &&
                (lastApsVerificationRecord.finalized ||
                  lastApsVerificationRecord.completedOn)
                  ? rejectedOnDate
                    ? this.translate.instant(
                        "digitalVerifications.aps.status3",
                        { date: rejectedOnDate },
                      )
                    : ""
                  : screenedOnDate
                  ? this.translate.instant("digitalVerifications.aps.status4", {
                      date: screenedOnDate,
                    })
                  : "",
              title: this.translate.instant("digitalVerifications.aps.title3"),
              // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
              buttonAction: "OnDisplayAPSInLightbox",
              showButton:
                lastApsVerificationRecord.typeOfHits.length > 0 ? true : false,
              // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
              showButtonWithIcon: false,
              svgIcon: {
                src:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("adverse"),
                  ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("warning"),
                  ) !== -1
                    ? "/assets/images/icon-triangle-orange.svg"
                    : "/assets/images/icon-checkmark-prepared.svg",
                class:
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("adverse"),
                  ) !== -1 ||
                  lastApsVerificationRecord.typeOfHits.findIndex((element) =>
                    element.includes("warning"),
                  ) !== -1
                    ? "svg-orange"
                    : "svg-green",
                width: 16, // required
                height: 16, // required
              },
            },
          ];
        }
      } else {
        // IF GENERAL VERIFICATION STATUS IS NOT STARTED THEN THE STATUS IS NOT STARTED
        if (
          verificationSubjectStatus === "NOT_STARTED" ||
          verificationSubjectStatus === undefined
        ) {
          apsStatuses = [
            {
              content: this.translate.instant(
                "digitalVerifications.aps.status6",
              ),
              title: this.translate.instant("digitalVerifications.aps.title"),
              svgIcon: {
                src: "/assets/images/icon-information-prepared.svg",
                class: "svg-gray",
                width: 16, // required
                height: 16, // required
              },
            },
            {
              content: this.translate.instant(
                "digitalVerifications.aps.status6",
              ),
              title: this.translate.instant("digitalVerifications.aps.title2"),
              svgIcon: {
                src: "/assets/images/icon-information-prepared.svg",
                class: "svg-gray",
                width: 16, // required
                height: 16, // required
              },
            },
            {
              content: this.translate.instant(
                "digitalVerifications.aps.status6",
              ),
              title: this.translate.instant("digitalVerifications.aps.title3"),
              svgIcon: {
                src: "/assets/images/icon-information-prepared.svg",
                class: "svg-gray",
                width: 16, // required
                height: 16, // required
              },
            },
          ];
        } else {
          apsStatuses = [
            {
              content: this.translate.instant(
                "digitalVerifications.aps.status7",
              ),
              title: this.translate.instant("digitalVerifications.aps.title"),
              // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
              buttonAction: "OnDisplayAPSInLightbox",
              showButton: false,
              // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
              showButtonWithIcon: false,
              statusClass: "status-not-included",
              svgIcon: {
                src: "/assets/images/icon-information-prepared.svg",
                class: "svg-light-gray",
                width: 16, // required
                height: 16, // required
              },
            },
            {
              content: this.translate.instant(
                "digitalVerifications.aps.status7",
              ),
              title: this.translate.instant("digitalVerifications.aps.title2"),
              // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
              buttonAction: "OnDisplayAPSInLightbox",
              showButton: false,
              // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
              showButtonWithIcon: false,
              statusClass: "status-not-included",
              svgIcon: {
                src: "/assets/images/icon-information-prepared.svg",
                class: "svg-light-gray",
                width: 16, // required
                height: 16, // required
              },
            },
            {
              content: this.translate.instant(
                "digitalVerifications.aps.status7",
              ),
              title: this.translate.instant("digitalVerifications.aps.title3"),
              // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
              buttonAction: "OnDisplayAPSInLightbox",
              showButton: false,
              // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
              showButtonWithIcon: false,
              statusClass: "status-not-included",
              svgIcon: {
                src: "/assets/images/icon-information-prepared.svg",
                class: "svg-light-gray",
                width: 16, // required
                height: 16, // required
              },
            },
          ];
        }
      }
    } else if (!showAPSVerificationElements) {
      apsStatuses = [
        {
          content: this.translate.instant("digitalVerifications.aps.status8"),
          title: this.translate.instant("digitalVerifications.aps.title"),
          // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
          buttonAction: "OnDisplayAPSInLightbox",
          showButton: false,
          // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
          showButtonWithIcon: false,
          statusClass: "status-not-included",
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-light-gray",
            width: 16, // required
            height: 16, // required
          },
        },
        {
          content: this.translate.instant("digitalVerifications.aps.status8"),
          title: this.translate.instant("digitalVerifications.aps.title2"),
          // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
          buttonAction: "OnDisplayAPSInLightbox",
          showButton: false,
          // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
          showButtonWithIcon: false,
          statusClass: "status-not-included",
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-light-gray",
            width: 16, // required
            height: 16, // required
          },
        },
        {
          content: this.translate.instant("digitalVerifications.aps.status8"),
          title: this.translate.instant("digitalVerifications.aps.title3"),
          // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
          buttonAction: "OnDisplayAPSInLightbox",
          showButton: false,
          // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
          showButtonWithIcon: false,
          statusClass: "status-not-included",
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-light-gray",
            width: 16, // required
            height: 16, // required
          },
        },
      ];
    }

    if (projectType === "funds") {
      lastApsVerificationRecord.status = originalStatus;
    }

    return apsStatuses;
  }

  // returns a status to be used in the dashbboard
  parseContractStatus(
    result,
    projectType,
    showContractVerificationElements,
    verificationSubjectStatus,
  ) {
    let isFunds;
    let contractStatus = {};
    let lastContractVerification = result.lastContractVerification;
    let lastContractVerificationRecord = lastContractVerification
      ? lastContractVerification.record
      : undefined;
    if (projectType === "funds") {
      isFunds = true;
      lastContractVerification = result;
      lastContractVerificationRecord = result;
      if (lastContractVerificationRecord.status === "Completed") {
        lastContractVerificationRecord.status = "COMPLETED";
      }
    }
    if (showContractVerificationElements || result.lastContractVerification) {
      if (
        (!lastContractVerification &&
          (verificationSubjectStatus === "NOT_STARTED" ||
            verificationSubjectStatus === undefined)) ||
        (lastContractVerificationRecord &&
          (lastContractVerificationRecord.status === "Not requested" ||
            lastContractVerificationRecord.status === "Requested"))
      ) {
        contractStatus = {
          content: this.translate.instant(
            "digitalVerifications.contract.status",
          ),
          title: this.translate.instant("digitalVerifications.contract.title"),
          // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
          buttonAction: "OnDisplayAPSInLightbox",
          showButton: false,
          // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
          showButtonWithIcon: false,
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-gray",
            width: 16, // required
            height: 16, // required
          },
        };
      } else if (
        !lastContractVerification &&
        verificationSubjectStatus !== "NOT_STARTED"
      ) {
        contractStatus = {
          content: this.translate.instant(
            "digitalVerifications.contract.status2",
          ),
          title: this.translate.instant("digitalVerifications.contract.title"),
          // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
          buttonAction: "OnDisplayAPSInLightbox",
          showButton: false,
          // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
          showButtonWithIcon: false,
          statusClass: "status-not-included",
          svgIcon: {
            src: "/assets/images/icon-information-prepared.svg",
            class: "svg-light-gray",
            width: 16, // required
            height: 16, // required
          },
        };
      } else {
        if (
          lastContractVerification &&
          (lastContractVerificationRecord.status === "PENDING" ||
            lastContractVerificationRecord.status === "In progress")
        ) {
          contractStatus = {
            content: this.translate.instant(
              "digitalVerifications.contract.status3",
            ),
            title: this.translate.instant(
              "digitalVerifications.contract.title",
            ),
            svgIcon: {
              src: "/assets/images/icon-alert-triangle-ng-prepared.svg",
              class: "svg-blue",
              width: 16, // required
              height: 16, // required
            },
            contractTemplate: lastContractVerificationRecord?.template
              ? this.helperService.parseCountryName(
                  this.helperService.parseBackendName2(
                    lastContractVerificationRecord.template,
                  ),
                )
              : "",
          };
        } else if (
          lastContractVerification &&
          lastContractVerificationRecord.status === "COMPLETED"
        ) {
          const date = this.helperService.formatISODate(
            lastContractVerificationRecord.completedAt
              ? lastContractVerificationRecord.completedAt
              : lastContractVerificationRecord.completedOn,
          );
          contractStatus = {
            content: date
              ? this.translate.instant(
                  "digitalVerifications.contract.status4",
                  { date: date },
                )
              : "",
            title: this.translate.instant(
              "digitalVerifications.contract.title",
            ),
            buttonAction: "OnDisplayContractInLightbox",
            showButton: true,
            svgIcon: {
              src: "/assets/images/icon-checkmark-prepared.svg",
              class: "svg-green",
              width: 16, // required
              height: 16, // required
            },
          };
        }
      }
    } else if (!showContractVerificationElements) {
      contractStatus = {
        content: this.translate.instant(
          "digitalVerifications.contract.status5",
        ),
        title: this.translate.instant("digitalVerifications.contract.title"),
        // 'buttonAction' : (result.lastAddressVerification.record.verificationPresets.documentsRequested) ? 'OnDisplayImageAddressInLightbox' : 'OnGenerateAddressVerificationPDF',
        buttonAction: "OnDisplayAPSInLightbox",
        showButton: false,
        // 'showButtonWithIcon' : (!result.lastAddressVerification.record.verificationPresets.documentsRequested),
        showButtonWithIcon: false,
        statusClass: "status-not-included",
        svgIcon: {
          src: "/assets/images/icon-information-prepared.svg",
          class: "svg-light-gray",
          width: 16, // required
          height: 16, // required
        },
      };
    }
    if (projectType === "funds") {
      if (lastContractVerificationRecord.status === "COMPLETED") {
        lastContractVerificationRecord.status = "Completed";
      }
    }
    return contractStatus;
  }

  // we need these functions because the data is organized differently on the BE for id verification and CDD,
  // we need to make them them similar so the other functions will work
  parseAVerificationData(response) {
    if (response["record"]["verificationData"]["results"]) {
      response["record"]["verificationData"]["geoLocationData"] =
        response["record"]["verificationData"]["results"]["geoLocationData"];

        response["record"]["verificationData"]["results"] = response["record"]["verificationData"]["results"];
    }
    response["record"]["verificationPresets"] =
      response["record"]["verificationData"]["verificationPresets"];
    if (
      response["record"]["verificationData"]["results"] &&
      response["record"]["verificationData"]["results"].completedWithIdin
    ) {
      response["record"]["idinResults"] =
        response["record"]["verificationData"]["results"];
    } else if (
      response["record"]["verificationData"]["results"] &&
      response["record"]["verificationData"]["results"].addressResponse
    ) {
      response["record"]["idinResults"] =
        response["record"]["verificationData"]["results"];
    } else {
      if (response["record"]["verificationData"]["results"]) {
        response["record"]["verificationData"]["addressData"] =
          response["record"]["verificationData"]["results"]["addressData"];
      }
    }
    if(response["record"]["results"]) {
      response["record"]["verificationData"]["results"] = response["record"]["results"];
    }
    return response;
  }

  parseAPSVerificationData(response) {
    response["record"]["hits"] =
      response["record"]["verificationData"]["results"]["hits"];
    response["record"]["typeOfHits"] =
      response["record"]["verificationData"]["results"]["typeOfHits"];
    return response;
  }

  parseIDVerificationData(response) {
    response["record"]["document"] =
      response["record"]["verificationData"].results?.document;
    response["record"]["transaction"] =
      response["record"]["verificationData"].results?.transaction;
    response["record"]["verification"] =
      response["record"]["verificationData"].results?.verification;
    return response;
  }
  // end

  returnimgrlByType(type) {
    let url;
    switch (type) {
      case "Passport":
      case "IdentityCard":
      case "DriversLicense":
      case "Other":
      case "DigitalVerification":
        url = "/assets/images/evidenceTypes/id.png";
        break;
      case "ArticlesOfAssociation":
      case "Authorisation":
      case "TaxRegistration":
      case "MemorandumOfArticlesAndAssociation":
      case "TrustDeed/Agreement":
      case "AmendmentToDeed":
      case "SignedLetterFromTrustee":
      case "Charter":
      case "PartnershipAgreement":
      case "InsuranceAgreement":
      case "IntroductoryLetter":
      case "TenancyDeclaration":
      case "LetterFromRetirementVillage":
      case "LeaseRentalAgreement":
      case "CourtOrder":
      case "DeclarationOfVisit":
      case "BoardResolution":
      case "CommitteeResolution":
      case "MeetingMinutes":
      case "InvestmentCommitteeResolution":
      case "SourceOfFundsDeclaration":
      case "SourceOfWealthDeclaration":
      case "GovernmentIssuedCorrespondence":
      case "LLCOperatingAgreement":
      case "LocatorAddressLetter":
      case "AMLAssurancesLetter":
      case "RoyalDecree":
      case "PowerOfAttorney":
      case "LastWillAndTestament":
      case "InvestmentManagementAgreement":
      case "ProofOfRegulation":
      case "Prospectus/OfferingMemorandum":
      case "StockListingEvidence":
        url = "/assets/images/evidenceTypes/signed.png";
        break;
      case "TaxReturn":
      case "UtilityBill":
      case "BankStatement":
      case "CreditCardStatement":
      case "Investment/SecuritiesStatement":
      case "MortgageStatement":
      case "TaxBill":
      case "TaxRateNotice":
      case "MunicipalityBill":
      case "SignedTaxReturns":
        url = "/assets/images/evidenceTypes/statement.png";
        break;
      case "CertificateOfIncorporation":
      case "CertificateOfIncumbency":
      case "CertificationOfRegistration":
      case "CertificateOfFormation":
        url = "/assets/images/evidenceTypes/certificate.png";
        break;
      case "LatestFinancialStatements/Accounts":
        url = "/assets/images/evidenceTypes/sheet.png";
        break;
      case "RegistryExtract":
      case "ExtractOfTrustInstrument":
      case "MotorVehicleLicenceRenewal":
        url = "/assets/images/evidenceTypes/extract.png";
        break;
      case "AuthorizedSignatoryList":
      case "RegisterOfDirectors":
      case "RegisterOfPartners":
      case "ShareRegister/RegisterOfMembers":
        url = "/assets/images/evidenceTypes/list.png";
        break;
      case "Structure/OrganizationChart":
        url = "/assets/images/evidenceTypes/chart.png";
        break;
      default:
    }
    return url;
  }

  resolveAvDetailsButtons(avDetails, type = "") {
    let ret = null;
    if (avDetails.record.idinResults) {
      ret = [
        {
          icon: "/assets/images/download.svg",
          title: this.translate.instant("evidencePreview.button11"),
          class: "preview-download-icon",
          action: !type
            ? "OnGenerateAddressVerificationPDF"
            : "OnGenerateDVAddressVerificationPDF",
          showSpinner: true,
        },
      ];
    } else {
      // TODO: see how to distinguish if extended check is needed/started/finished
      // will there be a differenece between extended check finished and status === 'COMPLETED'
      ret = [
        {
          icon: "/assets/images/ico-accept.svg",
          title: this.translate.instant("evidencePreview.button9"),
          class: "preview-download-icon",
          action: type === "dv" ? "onAcceptDv" : "onConfirmAddress",
        },
        {
          icon: "/assets/images/ico-reject.svg",
          title: this.translate.instant("evidencePreview.button10"),
          class: "preview-download-icon",
          action: type === "dv" ? "onRejectDv" : "onRejectAddress",
        },
        {
          icon: "/assets/images/download.svg",
          title: this.translate.instant("evidencePreview.button11"),
          class: "preview-download-icon",
          action: !type
            ? "OnGenerateAddressVerificationPDF"
            : "OnGenerateDVAddressVerificationPDF",
          showSpinner: true,
        },
      ];
      if (avDetails.record.status === "EXTENDED_CHECK_REQUESTED") {
        ret = [
          {
            icon: "/assets/images/download.svg",
            title: this.translate.instant("evidencePreview.button11"),
            class: "preview-download-icon",
            action: !type
              ? "OnGenerateAddressVerificationPDF"
              : "OnGenerateDVAddressVerificationPDF",
            showSpinner: true,
          },
        ];
      }
      if (
        avDetails.record.status === "COMPLETED" ||
        avDetails.record.status === "FAILED"
      ) {
        ret = [
          {
            icon: "/assets/images/download.svg",
            title: this.translate.instant("evidencePreview.button11"),
            class: "preview-download-icon",
            action: !type
              ? "OnGenerateAddressVerificationPDF"
              : "OnGenerateDVAddressVerificationPDF",
            showSpinner: true,
          },
        ];
      }
      if (
        type !== "dv" &&
        avDetails.record.verificationPresets &&
        avDetails.record.verificationPresets.verificationLetterRequested ===
          false &&
        !avDetails.record.idinResults
      ) {
        ret.unshift({
          icon: "/assets/images/ico-mail-check.svg",
          title: this.translate.instant("evidencePreview.button12"),
          class: "preview-download-icon",
          action: "OnGenerateEnhancedAvRequest",
        });
      }
      if (avDetails.record.documents && avDetails.record.documents.length > 0) {
        ret.push({
          icon: "/assets/images/download.svg",
          title: this.translate.instant("evidencePreview.button13"),
          class: "preview-download-icon",
          action: "downloadPreviewFiles",
          showSpinner: true,
        });
      }
    }

    return ret;
  }

  /**
   * Helper function for building the document list
   * Takes the required list that's a static list of elements in the service
   * @param investorType string
   */
  // UDD4
  buildDocumentList(investorType: string, existingEvidenceTypes) {
    let investorFields;
    if (investorType === "NaturalPerson") {
      investorFields = JSON.parse(
        JSON.stringify(this.getDocumentTypeList().NaturalPerson.checkbox_list),
      );
      investorFields[2].params.checkboxes.sort(function (a, b) {
        if (a.params.label < b.params.label) {
          return -1;
        }
        if (a.params.label > b.params.label) {
          return 1;
        }
        return 0;
      });
    } else if (investorType === "LegalPerson") {
      investorFields = JSON.parse(
        JSON.stringify(this.getDocumentTypeList().LegalPerson.checkbox_list),
      );
      investorFields[1].params.checkboxes.sort(function (a, b) {
        if (a.params.label < b.params.label) {
          return -1;
        }
        if (a.params.label > b.params.label) {
          return 1;
        }
        return 0;
      });
    }
    /**
     * Need to go over the fields and flag evidence checxbox that's already marked
     */
    const existingCustomTypes = [];
    if (existingEvidenceTypes) {
      investorFields.forEach((fieldgroup) => {
        if (fieldgroup.type === "counter_group") {
          fieldgroup.params.checkboxes.sort(function (a, b) {
            if (!a.params || !b.params) {
              return 0;
            }
            if (a.params.label < b.params.label) {
              return -1;
            }
            if (a.params.label > b.params.label) {
              return 1;
            }
            return 0;
          });
          fieldgroup.params.checkboxes.forEach((checkbox) => {
            existingEvidenceTypes.forEach((existingEvidenceType) => {
              if (existingEvidenceType.type === checkbox.params.id) {
                if (!checkbox.params.dataEvidenceKeys) {
                  checkbox.params.dataEvidenceKeys = [existingEvidenceType.key];
                } else {
                  checkbox.params.dataEvidenceKeys.push(
                    existingEvidenceType.key,
                  );
                }
                checkbox.params.counter = checkbox.params.counter
                  ? parseInt(checkbox.params.counter, 10) + 1
                  : "1";
              }
            });
          });
        }
      });
    }
    const otherDataEvidenceKeys = [];
    if (existingEvidenceTypes) {
      existingEvidenceTypes.forEach((existingEvidenceType) => {
        if (existingEvidenceType.type === "Other") {
          otherDataEvidenceKeys.push(existingEvidenceType.key);
          existingCustomTypes.push({
            customEvidenceType: existingEvidenceType.customEvidenceType,
          });
        }
      });
    }
    return {
      existingCustomTypes: existingCustomTypes,
      investorFields: investorFields,
      otherDataEvidenceKeys: otherDataEvidenceKeys ? otherDataEvidenceKeys : [],
    };
  }

  resolveApsDetailsButtons(apsDetails, isLoading, type = "") {
    let ret = null;
    if (apsDetails) {
      ret = [
        {
          action: type === "dv" ? "onAcceptDv" : "OnConfirmAPS",
          class: "",
          icon: "/assets/images/ico-accept.svg",
          title: this.translate.instant("evidencePreview.button9"),
          hideButton:
            !isLoading &&
            apsDetails.record.status !== "COMPLETED" &&
            apsDetails.record.status !== "FAILED"
              ? false
              : true,
        },
        {
          action: type === "dv" ? "onRejectDv" : "onRejectAPS",
          class: "",
          icon: "/assets/images/ico-reject.svg",
          title: this.translate.instant("evidencePreview.button10"),
          hideButton:
            !isLoading &&
            apsDetails.record.status !== "COMPLETED" &&
            apsDetails.record.status !== "FAILED"
              ? false
              : true,
        },
        {
          action: type === "dv" ? "OnGenerateDVApsPDF" : "onDownloadAPS",
          class: "preview-download-icon",
          icon: "/assets/images/download.svg",
          title: this.translate.instant("evidencePreview.button11"),
          hideButton: !!isLoading,
        },
        {
          action: "turnOffMonitoring",
          class: "",
          icon: "/assets/images/power-off-icon.svg",
          title: this.translate.instant("evidencePreview.turnOffMonitoring"),
          hideButton:
            !isLoading &&
            type === "dv" &&
            apsDetails?.record?.verificationData?.publicVerificationData
              ?.isMonitored
              ? false
              : true,
        },
      ];
    }

    return ret;
  }

  async acceptOrRejectDV(
    projectKey: string,
    requestKey: string,
    originalKey,
    accept = true,
    isEvidence = true,
  ) {
    if (projectKey === undefined || originalKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    let payload;
    payload = { status: accept ? "COMPLETED" : "FAILED" };
    if ($("#actionReason").val()) {
      let commentString = $("#actionReason").val().toString();
      commentString = commentString.trim();
      if (commentString !== "") {
        payload.comment = commentString;
      }
    }
    return new Promise((resolve, reject) => {
      // make a call to the BE to initiate screening
      this.userService
        .postEndPoint(
          this.buildEndPoint(
            "dvAcceptReject",
            projectKey,
            requestKey,
            "",
            true,
            originalKey,
          ),
          payload,
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              this.screeningStatus.next({ status: false });
              reject("error");
              alert(
                `We were not able to ${
                  accept ? "accept" : "reject"
                } the digital (av or aps) verification at this time!`,
              );
            }
            // this will close the preview window and reload the current investor
            this.skipToStep(-1);
            resolve(response);
          },
          (error) => {
            console.log(
              `The following error occured with the ${
                accept ? "accepting" : "rejecting"
              } DV (av or aps)`,
              error,
            );
            reject("error");
            alert(
              `We were not able to ${
                accept ? "accept" : "reject"
              } the digital (av or aps) verification at this time!`,
            );
          },
        );
    });
  }

  async acceptOrRejectIDVDV(
    projectKey: string,
    requestKey: string,
    originalKey,
    accept = true,
    isEvidence = true,
  ) {
    if (projectKey === undefined || originalKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    let payload;
    payload = { status: accept ? "COMPLETED" : "FAILED" };
    if ($("#actionReason").val()) {
      let commentString = $("#actionReason").val().toString();
      commentString = commentString.trim();
      if (commentString !== "") {
        payload.comment = commentString;
      }
    }
    return new Promise((resolve, reject) => {
      // make a call to the BE to initiate screening
      this.userService
        .postEndPoint(
          this.buildEndPoint(
            "IDVdvAcceptReject",
            projectKey,
            requestKey,
            "",
            true,
            originalKey,
          ),
          payload,
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              this.screeningStatus.next({ status: false });
              reject("error");
              alert(
                `We were not able to ${
                  accept ? "accept" : "reject"
                } the digital (av or aps) verification at this time!`,
              );
            }
            // this will close the preview window and reload the current investor
            this.skipToStep(-1);
            resolve(response);
          },
          (error) => {
            console.log(
              `The following error occured with the ${
                accept ? "accepting" : "rejecting"
              } DV (av or aps)`,
              error,
            );
            reject("error");
            alert(
              `We were not able to ${
                accept ? "accept" : "reject"
              } the digital (av or aps) verification at this time!`,
            );
          },
        );
    });
  }

  async turnOffMonitoring(projectKey: string, requestKey: string) {
    if (projectKey === undefined || requestKey === undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    return new Promise((resolve, reject) => {
      this.userService
        .deleteEndpoint(
          this.buildEndPoint(
            "turnOffMonitoringOnProfile",
            projectKey,
            requestKey,
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              return;
            } else {
              this.skipToStep(-1);
              resolve(response);
            }
          },
          (error) => {
            console.log(
              "The following error occured when turning off the monitoring: ",
              error,
            );
            alert(error.message);
            this.skipToStep(0);
          },
        );
    });
  }
  async deleteEvidenceInPreview(projectKey: string, requestKey: string, evidenceKey: string) {
    if (projectKey === undefined || requestKey === undefined || evidenceKey == undefined) {
      console.log("Required data was missing");
      this.screeningStatus.next({ status: false });
      return;
    }
    return new Promise((resolve, reject) => {
      this.userService
        .deleteEndpoint(
          this.buildEndPoint(
            "fillEvidenceField",
            projectKey,
            requestKey,
            evidenceKey
          ),
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              return;
            } else {
              this.skipToStep(-1);
              resolve(response);
            }
          },
          (error) => {
            console.log(
              "The following error occured when turning off the monitoring: ",
              error,
            );
            alert(error.message);
            this.skipToStep(0);
          },
        );
    });
  }

  getDisplayRangeFilter() {
    return this.displayRangeFilter;
  }

  getDisplayFilterByVerification() {
    return this.displayFilterByVerification;
  }

  getVerificationFilter() {
    return this.verificationFilter;
  }

  getVerificationFilterStatus() {
    return this.verificationFilterStatus;
  }
  getActiveVerificationsFilters() {
    return this.activeVerificationsFilters;
  }

  setVerificationFilter(filters) {
    this.verificationFilter = filters;
  }

  setVerificationFilterStatus(verificationFilterStatus) {
    this.verificationFilterStatus = verificationFilterStatus;
  }
  setActiveVerificationsFilters(activeVerificationsFilters) {
    this.activeVerificationsFilters = activeVerificationsFilters;
  }

  toggleDisplayRangeFilter(toggle) {
    this.displayRangeFilter = toggle;
  }

  toggleDisplayFilterByVerification(toggle) {
    this.displayFilterByVerification = toggle;
  }

  resetVerificationFilter() {
    this.activeRecordsCount = 0;
    this.displayFilterByVerification = false;
    this.verificationFilter = {};
    this.verificationFilterStatus = [""];
    this.activeVerificationsFilters = [];
  }

  // whitelisting
  sortingHitsOnComplianceCheck() {
    this.apsFields?.hits?.sort((hit1, hit2) => {
      if (hit1.isWhitelisted === hit2.isWhitelisted) {
        return hit1.lastUpdated > hit2.lastUpdated ? -1 : 1;
      } else {
        return hit1.isWhitelisted ? 1 : -1;
      }
    });
  }

  calculatingWhitelistedHits() {
    this.statusOfHits = { relevant: 0, irrelevant: 0 };
    this.apsFields?.hits?.forEach((hit) =>
      hit.isWhitelisted
        ? (this.statusOfHits["irrelevant"] += 1)
        : (this.statusOfHits["relevant"] += 1),
    );
  }

  recalculatingAPSStatuses() {
    const typeOfNonWhitelistedHits = new Set();
    this.apsFields?.hits?.forEach((hit) => {
      if (!hit.isWhitelisted) {
        hit.keyData?.hitsMinified?.forEach((type) =>
          typeOfNonWhitelistedHits.add(type.toLowerCase()),
        );
      }
    });

    this.APSStatusses = [
      {
        titleClass: "font-weight-bold",
        src: typeOfNonWhitelistedHits.has("adverse")
          ? "/assets/images/icon-triangle-orange.svg"
          : "/assets/images/check.svg",
        title: "Adverse Media",
      },
      {
        src: typeOfNonWhitelistedHits.has("pep")
          ? "/assets/images/icon-triangle-orange.svg"
          : "/assets/images/check.svg",
        title: "PEP",
      },
      {
        src: typeOfNonWhitelistedHits.has("sanctions")
          ? "/assets/images/icon-triangle-orange.svg"
          : "/assets/images/check.svg",
        title: "Sanctions",
      },
    ];
  }

  whitelistHit(payload, index, requestKey) {
    if (
      $(event.target).attr("type") &&
      $(event.target).attr("type") === "submit"
    ) {
      if (!this.uiService.validateLightboxFields(event)) {
        console.log("**not all fields are valid");
        event.preventDefault();
        return;
      }
    }

    $(".expire-lightbox").addClass("d-none");
    $(".document-preview-container").addClass("isLoading");

    const endpoint =
      this.getProjectType() === "id-verification"
        ? "whitelistHitVerify"
        : "whitelistHitFund";

    if ($("#actionReason").val()) {
      let commentString = $("#actionReason").val().toString();
      commentString = commentString.trim();
      if (commentString !== "") {
        payload.comment = commentString;
      }
    }
    $("#actionReason").val("");
    this.resetHits.next(false);
    this.userService
      .postEndPoint(
        this.buildEndPoint(endpoint, this.getActiveProject(), requestKey),
        payload,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
            return;
          }
          this.apsFields.hits[index].isWhitelisted =
            !this.apsFields.hits[index].isWhitelisted;

          if (
            this.apsFields.hits[index].isWhitelisted &&
            !this.showIrrelevantHits
          ) {
            this.showIrrelevantHits = true;
          }
          if (!this.apsFields.hits[index].historyLog) {
            this.apsFields.hits[index].historyLog = [];
          }
          this.apsFields.hits[index].historyLog.push({
            entryEnum: "WHITELIST_STATE_CHANGED",
            isWhitelisted: this.apsFields.hits[index].isWhitelisted,
            timestamp: this.helperService.getTimestamp("current"),
            actionDoneBy: this.userService.getUserInfo()["username"],
            comment: payload.comment,
          });

          this.sortingHitsOnComplianceCheck();
          this.calculatingWhitelistedHits();
          this.recalculatingAPSStatuses();
          this.setComplianceCheckWasWhitelisted(true);
          this.resetHits.next(true);
          $(".document-preview-container").removeClass("isLoading");
        },
        (error) => {
          console.log(
            "The following error occured with whitelisting hit",
            error,
          );
        },
      );
  }
  commentHit(index, requestKey) {
    if (
      $(event.target).attr("type") &&
      $(event.target).attr("type") === "submit"
    ) {
      if (!this.uiService.validateLightboxFields(event)) {
        console.log("**not all fields are valid");
        event.preventDefault();
        $(".submit-fields-general-error").show();
        $(".button-error-field").show();
        $(".button-group-error-message").show();
        return;
      }
    }
    $(".expire-lightbox").addClass("d-none");
    $(".document-preview-container").addClass("isLoading");

    const endpoint =
      this.getProjectType() === "id-verification"
        ? "commentHitVerify"
        : "commentHitFund";
    const payload = {
      hits: [this.apsFields.hits[index].id],
      comment: "",
    };

    if ($("#actionReason").val()) {
      let commentString = $("#actionReason").val().toString();
      commentString = commentString.trim();
      if (commentString !== "") {
        payload.comment = commentString;
      }
    }
    this.resetHits.next(false);
    this.userService
      .postEndPoint(
        this.buildEndPoint(endpoint, this.getActiveProject(), requestKey),
        payload,
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (response: any) => {
          if (response === undefined) {
            return;
          }
          if (!this.apsFields.hits[index].historyLog) {
            this.apsFields.hits[index].historyLog = [];
          }
          this.apsFields.hits[index].historyLog.push({
            entryEnum: "COMMENT",
            timestamp: this.helperService.getTimestamp("current"),
            actionDoneBy: this.userService.getUserInfo()["username"],
            comment: payload.comment,
          });
          this.resetHits.next(true);
          $(".document-preview-container").removeClass("isLoading");
        },
        (error) => {
          console.log(
            "The following error occured with whitelisting hit",
            error,
          );
        },
      );
  }

  generateEntityName(typeOfInvestor: string, investor): string {
    let entityName: string = "";

    if (typeOfInvestor == "LegalPerson") {
      entityName = investor.RegisteredName;
    } else if (typeOfInvestor == "NaturalPerson") {
      entityName = [investor.FirstName, investor.LastName].join(" ");
    }
    return entityName;
  }

  generateAllEvidenceForProfile(profile)
  {
    let evidenceTypes : any[] = [];
    const profileRecordDeepCopy = JSON.parse(
      JSON.stringify(profile),
    );
    if (
      profileRecordDeepCopy["evidenceTypes"] ||
      profileRecordDeepCopy["digitalVerifications"]
    ) {
      evidenceTypes = [
        ...this.concatEvidenceTypesWithDigitalVerifications(
          profileRecordDeepCopy["evidenceTypes"],
          profileRecordDeepCopy["digitalVerifications"],
        ),
      ];
    }
    if (profileRecordDeepCopy["basicFieldsEvidence"]) {
      evidenceTypes.push(profileRecordDeepCopy["basicFieldsEvidence"]);
    }
    if (profileRecordDeepCopy["relatedParties"]) {
      profileRecordDeepCopy["relatedParties"].forEach((relatedParty) => {
        if (
          relatedParty["record"]["digitalVerifications"]?.length > 0 ||
          relatedParty["record"]["evidenceTypes"]?.length > 0
        ) {
          const tmp =
            this.concatEvidenceTypesWithDigitalVerifications(
              relatedParty["record"]["digitalVerifications"],
              relatedParty["record"]["evidenceTypes"],
            );
          evidenceTypes.push(...tmp);
        }
        if (relatedParty["record"]["basicFieldsEvidence"]) {
          evidenceTypes.push(
            relatedParty["record"]["basicFieldsEvidence"],
          );
        }
      });
    }
    
    // filter duplicate evidence
    evidenceTypes = evidenceTypes.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.originalEvidenceKey === value.originalEvidenceKey &&
            t.key === value.key,
        ),
    );

    return evidenceTypes; 
  }

  generateProfileStatusInformationObject(evidenceTypes) {

    let statuses = {};
    evidenceTypes?.forEach((evidence) => {
      let status = evidence.status.toLowerCase();
      if (
        this.isInvestorType() &&
        status === "action required"
      ) {
        status = "in review";
      }
      // Count the evidences in that status
      statuses[status] =
        1 + (statuses[evidence.status.toLowerCase()] || 0);
    });

    return statuses;
  }
  getPVTooltip(record) : string
  {
    if(this.permissionProfileVerification())
    {
      return this.helperService.getTooltipForVerifyProfile(
        record,
      );
    }
    else
    {
      return `This will check the profile details against external objectives sources, 
                like voter registers and telecom databases. 
                Your organisation has not subscribed to this service, 
                or this has not been enabled for you.
                Please reach out to us should you be interested in making use of profile verification.`
    }
  }
}
