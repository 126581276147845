import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { UiService } from 'src/app/ui/ui.service';
import { UserService } from 'src/app/user.service';
import { environment } from "src/environments/environment";
import { takeUntil, tap } from "rxjs/operators";
import { Subject } from 'rxjs';
import { HelperServiceService } from 'src/app/helper-service.service';
import QRCode from "qrcode";
import { IpServiceService } from 'src/app/ip-service.service';
import { Router } from '@angular/router';
import { VerificationsService } from 'src/app/verifications/verifications.service';

@Component({
  selector: 'app-address-share-dialog',
  templateUrl: './address-share-dialog.component.html',
  styleUrls: ['./address-share-dialog.component.scss']
})
export class AddressShareDialogComponent implements OnInit {

  constructor(
    public userService: UserService,
    public idVerificationService: IdVerificationService,
    public translate: TranslateService,
    private uiService: UiService,
    private http: HttpClient,
    private helper: HelperServiceService,
    private ipService: IpServiceService,
    private router: Router,
    private verificationsService: VerificationsService,

  ) { }
  @Input() profile;
  @Input() currentEvidenceKey;
  @Input() currentEvidence;
  @Input() currentRequestKey;
  @Input() currentEvidenceStatus;
  @Input () verificationLink;
  @Input () logo;
  @Input () projectColor;
  @Output() changeEvidenceStatus: EventEmitter<any> = new EventEmitter();

  originalVerificaitonLink;
  // starting parameters
  headerInfo;
  headerClasses;
  projectTextColorClass: string;
  refreshedData;
  evdEndpoint;
  stepsArray; // information on what steps there are in the lightbox
  displayLoader: boolean;
  lightboxContent: any[];
  displayModal: boolean;
  baseUrl = environment.APIEndpoint;
  unsubscribe$: Subject<void> = new Subject<void>();
  verificationRecord;
  verificationKey: string;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  map: google.maps.Map;
  position: any;
  ipAddress: string;
  geoIpAddress: any;
  ipInfo: any;
  addressProcessStarted: any;
  addressProcessEnded: any;
  separatePage ;
  idinChoice : boolean;;
  idinTransactionURL : string;
  entityName: string;

  @ViewChildren("mapWrapper") mapElement: ElementRef;
  errorState = false;

  btnContentElements: string;
  cryptoKey: string;


  ngOnInit(): void {


    this.entityName = this.idVerificationService.generateEntityName(this.profile.typeOfInvestor, this.profile.investorFields);
   // compononent is separate page and not  part of the pcs if the verificationlink is not passed
  if( this.currentEvidenceStatus === 'Completed') {
    this.onAddressVerificationEnd();
  }
   

  if(!this.verificationLink) {
    this.separatePage = true;
    this.verificationLink = this.router.url;
    this.addressProcessStarted = true;
  }
    this.originalVerificaitonLink = this.verificationLink.toString();
   this.verificationLink = this.verificationLink?.replace('/digital/', '/api/digital/');
   this.retreiveEvidenceData();
  }

  checkDVEvidenceStatus() {
       this.retreiveEvidenceData();
  }

  startAddressProcess() {
    if(this.idinTransactionURL) {
      window.open(this.idinTransactionURL, '_blank');

    } else {
      this.addressProcessStarted = true;
    }
  }

  retreiveEvidenceData() {
    this.headerInfo = {
      headerType: "full", // 'inline',
      headerTitle: "Integrity Check",
      activeNavLink: "integrityCheck",
    };
    this.headerClasses = {
      backgroundClass: "tealish-gradient-bg",
      sectionClass: "ic_height_full_page", // 'ic_height_auto',
    };

    this.projectTextColorClass = 'light-color';
    if(!this.projectColor) {
      this.projectColor = "#00889c";
    }
    this.projectTextColorClass = this.helper.detectTextColor(this.projectColor);
    this.verificationsService.setProjectColor(this.projectColor);
    this.projectTextColorClass =
      this.verificationsService.getProjectTextColorClass();
    this.verificationKey = this.currentEvidenceKey;

    let url =      "api/projects/" +
    this.idVerificationService.getActiveProject() +
    "/information/requests/" +
    this.currentRequestKey +
    "/evidence/" +
    this.currentEvidenceKey;

    if(this.separatePage) {
      url = this.verificationLink;
    }
    
    let  getcallObservable = this.userService
    .getEndPoint(
      url,
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );

    if(this.separatePage) {
      getcallObservable = 
      this.http
      .get(
        url,
        { responseType: "json", observe: "body" });
    }

    getcallObservable.subscribe((response) => {
      // this.displayEvidence = true;
      if(!response['record']) {
        this.addressProcessEnded = true;
      } else {

        if(!this.separatePage) {
          if(response['record'].reviewState === false) {
            this.changeEvidenceStatus.emit('COMPLETED');
          }
        }

        this.currentEvidence = response['record'];
        this.idinTransactionURL = this.currentEvidence.idinTransactionURL;
        this.currentEvidence.key = this.currentEvidenceKey;
        if(this.currentEvidence.type === 'BASIC_REQUEST_INFO') {
          if(this.currentEvidence.fields.FirstName) {
            this.currentEvidence.type = 'NaturalPerson';
          } else {
            this.currentEvidence.type = 'LegalPerson';
  
          }
        }
        this.currentEvidence.purposesOfUse =
        this.idVerificationService.parsePurposeOfUse(this.currentEvidence.access?.purposesOfUse)
  
        ///
        this.verificationRecord = response["record"]['verificationData'];
        if(!this.verificationRecord.results) {
          this.verificationRecord.results = {};
        }
        this.verificationRecord.consentedAt = Date.now();
        if (!response["record"].projectBranding) {
          // this.projectColor = "#00889c";
          // this.projectTextColorClass = "light-color";
        } else {
          if (response["record"].projectBranding.projectColor) {
            this.projectColor =
            response["record"].projectBranding.projectColor;
            this.verificationsService.setProjectColor(this.projectColor);
            this.projectTextColorClass =
            this.verificationsService.getProjectTextColorClass();
          }
          if (response["record"].projectBranding.projectLogo) {
            this.logo = response["record"].projectBranding.projectLogo;
          }
        }

        this.checkVerificaitonLetter();

       // if there are documents to be uploaded set the fisrt as the active one
        if (
          this.verificationRecord &&
          this.verificationRecord.verificationPresets.allowedDocuments &&
          this.verificationRecord.verificationPresets.allowedDocuments
            .length >= 1
        ) {
          // local representation of the documents because we need more than just a string
          const expandedDocumentsArray = [];
          this.verificationRecord.verificationPresets.allowedDocuments.forEach(
            (documentType) => {
              expandedDocumentsArray.push({
                type: documentType,
              });
            },
          );
          this.verificationRecord.verificationPresets.allowedDocuments =
            expandedDocumentsArray;
          this.verificationRecord.verificationPresets.allowedDocuments[0].documentActive =
            true;
        }
        this.decideAddressVerificationStep(this.verificationRecord )
      }
    });
  };


    checkVerificaitonLetter() {
      // if the letter is requested but the letter is not sent activate endpoint to send letter
      if (
        this.verificationRecord &&
        (this.verificationRecord.results.addressDataCapturedOn ||  !this.verificationRecord.verificationPresets.addressRequested)  &&
        this.verificationRecord.verificationPresets.verificationLetterRequested &&
        !this.verificationRecord.results.letterSentOn
      ) 
        {
        this.userService.postEndPoint(
          this.verificationLink + '/letter',
          {},
          { responseType: "json" },
          { observe: "body" },
          "funds",
        ).subscribe((letterResponse) => {
          this.verificationRecord.confirmedAt = new Date();
        },error => { 
          this.verificationRecord.confirmedAt= new Date();
        })
        }
    }

    // function to decide what step we are on and to load the proper component
    async decideAddressVerificationStep(verificationRecord) {
      // this.displayLoader = false;


      // build steps array (just to visually display the existing steps has no other functionality)
      let counter = 1;
      // {name: 'Terms and conditions', count: counter++, id:"terms"}
      this.stepsArray = [];
      if(this.verificationRecord.verificationPresets.idinAllowed 
        && !(this.verificationRecord.results.addressDataCapturedOn || this.verificationRecord.addressDataCapturedOn )) {
          this.stepsArray.push({
            
            name: this.translate.instant(
            "IDPCS.landing.pills.lastAddressVerification.idinSubstepLabel",
            ),
            count: counter++,
            id: "idin_choice",
          });
      }
       else if (verificationRecord.verificationPresets.verificationLetterRequested) {
        if (verificationRecord.verificationPresets.addressRequested) {
          this.stepsArray.push({
            name: this.translate.instant("IDPCS.address.provideAddress"),
            count: counter++,
            id: "address_input",
          });
        }
        if(verificationRecord.verificationPresets.geoLocationRequested) 
        {
          this.stepsArray.push({
            name: this.translate.instant("IDPCS.address.shareLocation"),
            count: counter++,
            id: "address_lookup",
          });
        }
        if (verificationRecord.verificationPresets.documentsRequested) {
          this.stepsArray.push({
            name: this.translate.instant("IDPCS.address.provideDocument"),
            count: counter++,
            id: "document_upload",
          });
        }
        this.stepsArray.push({
          name: this.translate.instant("IDPCS.address.verification"),
          count: counter++,
          id: "verification",
        });
        // this.stepsArray.push({
        //   name: this.translate.instant("IDPCS.address.confirmation"),
        //   count: counter++,
        //   id: "confirmation",
        // });
      } else {
        if (verificationRecord.verificationPresets.addressRequested) {
          this.stepsArray.push({
            name: this.translate.instant("IDPCS.address.provideAddress"),
            count: counter++,
            id: "address_input",
          });
          if(verificationRecord.verificationPresets.geoLocationRequested) 
          {
            this.stepsArray.push({
              name: this.translate.instant("IDPCS.address.shareLocation"),
              count: counter++,
              id: "address_lookup",
            });
          }
          if (verificationRecord.verificationPresets.documentsRequested) {
            this.stepsArray.push({
              name: this.translate.instant("IDPCS.address.provideDocument"),
              count: counter++,
              id: "document_upload",
            });
          }
          // this.stepsArray.push({
          //   name: this.translate.instant("IDPCS.address.confirmation"),
          //   count: counter++,
          //   id: "confirmation",
          // });
        } else if (!verificationRecord.verificationPresets.addressRequested) {
          if(verificationRecord.verificationPresets.geoLocationRequested) 
          {
            this.stepsArray.push({
              name: this.translate.instant("IDPCS.address.shareLocation"),
              count: counter++,
              id: "address_lookup",
            });
          }

          if (verificationRecord.verificationPresets.documentsRequested) {
            this.stepsArray.push({
              name: this.translate.instant("IDPCS.address.provideDocument"),
              count: counter++,
              id: "document_upload",
            });
          }
          // this.stepsArray.push({
          //   name: this.translate.instant("IDPCS.address.confirmation"),
          //   count: counter++,
          //   id: "confirmation",
          // });
        }
      }
      // end build steps array



      // decide what step we are on
      if (false && !verificationRecord.consentedAt) {
        // NEVER show the terms and conditions - consent screen
        // if there is no record of consenting we are at the consent step
      }  else if(this.verificationRecord.verificationPresets.idinAllowed 
        && !(this.verificationRecord.results.addressDataCapturedOn || this.verificationRecord.addressDataCapturedOn ))
        {
          this.onIdinChoice();
        } else if (
        // if user has consented
        verificationRecord.consentedAt &&
        // letter was NOT requested
        (!verificationRecord.verificationPresets.verificationLetterRequested ||
          // or verification letter was requested
          (verificationRecord.verificationPresets.verificationLetterRequested &&
            // BUT verification letter was NOT confirmed
            !verificationRecord.confirmedAt)) &&
        // address is requested
        verificationRecord.verificationPresets.addressRequested &&
        // address was not inputed
        !(verificationRecord.results.addressDataCapturedOn || verificationRecord.addressDataCapturedOn )
      ) {
        // go to address input screen
        console.log('mmarker1');
        this.onAddressVerificationAdressInput();
      }else if (
        // user has consented
        verificationRecord.consentedAt &&
        // letter was NOT requested
        !verificationRecord.verificationPresets.verificationLetterRequested &&
        // address was requested
        verificationRecord.verificationPresets.addressRequested &&
        // address was not uploadted
        !(verificationRecord.results.addressDataCapturedOn || verificationRecord.addressDataCapturedOn) 
      ) {
        // go to address input screen
        console.log('mmarker3');

        this.onAddressVerificationAdressInput();
      } else if (
        // geolocaiton was requested
        verificationRecord.verificationPresets.geoLocationRequested &&
        // user has consented
        verificationRecord.consentedAt &&
        // address was NOT requested
        (!verificationRecord.verificationPresets.addressRequested ||
          // address was uploaded
          (verificationRecord.verificationPresets.addressRequested &&
            (verificationRecord.results.addressDataCapturedOn || verificationRecord.addressDataCapturedOn))) &&
        // address was inputed
        !verificationRecord.results.geoLocationDataCapturedOn 
      ) {

        // if it's not mobile geolocaiton cannot be inputed, go to simple message screen
        if (!this.helper.isMobile()) {
          // let extendedDomain = `${location.protocol}//` + window.location.hostname + ((!location.port) ? '' : `:${location.port}`);
          let extendedDomain = this.originalVerificaitonLink;
          if(this.separatePage) {
            extendedDomain = window.location.origin + this.router.url;
          }


          let genQrCode;
          try {
            genQrCode = await QRCode.toDataURL(extendedDomain);
          } catch (err) {
            console.error(err);
          }
          console.log('mmarker4');

          this.geolocationScreenNonMobile(genQrCode);
        } else {
          console.log('mmarker5');

          this.geolocationScreenMobile();
          // go to geolocation screen mobile
        }
      } 
      else if (
        // if user has consented
        verificationRecord.consentedAt &&
        // verification letter is requested
        verificationRecord.verificationPresets.verificationLetterRequested &&
        // verification letter is not confirmed
        !verificationRecord.confirmedAt
        // // address is requested
        // && verificationRecord.verificationPresets.addressRequested
        // //adress was inputed
        // && verificationRecord.addressFieldsUploadedAt
      ) {
        // user needs to input the six digit code
        console.log('mmarker2');

        this.onAddressVerificationVerificaitonCode();
      } 
      
      else if (
        // user has consented
        verificationRecord.consentedAt &&
        // letter was NOT requested
        (!verificationRecord.verificationPresets.verificationLetterRequested ||
          // or verification letter was requested
          (verificationRecord.verificationPresets.verificationLetterRequested &&
          // BUT verification letter was confirmed
          verificationRecord.confirmedAt
          )) &&
        // documents were requested
        verificationRecord.verificationPresets.documentsRequested &&
        // documents were not uploaded
        !(
          verificationRecord.documents ||
          verificationRecord.documentFieldsUploadedAt
        )
      ) {
        // go to address input screen
        console.log('mmarker6');

        this.onDocumentsUpload(
          undefined,
          this.verificationRecord.verificationPresets.allowedDocuments,
        );
      } else if (
        // user has consented
        verificationRecord.consentedAt &&
        // letter was NOT requested
        (!verificationRecord.verificationPresets.verificationLetterRequested ||
          // or verification letter was requested
          (verificationRecord.verificationPresets.verificationLetterRequested &&
            // BUT verification letter was confirmed
            verificationRecord.confirmedAt)) &&
        // geolocation was uploaded
        (verificationRecord.results.geoLocationDataCapturedOn || !verificationRecord.geoLocationRequested)
      ) {
        // go to last step verification is over

        this.onAddressVerificationEnd();
      } else if (
        // user has consented
        verificationRecord.consentedAt &&
        // leter was not requested
        !verificationRecord.verificationPresets.verificationLetterRequested &&
        // address was NOT requested
        !verificationRecord.verificationPresets.addressRequested &&
        // geolocation was uploaded
        (verificationRecord.results.geoLocationDataCapturedOn || !verificationRecord.geoLocationRequested)
      ) {
        console.log('mmarker8');

        this.onAddressVerificationEnd();
      }
    }

  
  // idin choice
  onIdinChoice(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const thisStep = this.stepsArray.find(
      (step) => step.id === "idin_choice",
    );
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    // const isMobile = true;
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.formClass = isMobile ? "form-container-mobile" : "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = {
      class: lightboxClass,
      loaderClass: "address-loading-full-screen",
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "input-label-large increased-margin-top pb-3",
              params: {
                content: this.translate.instant(
                  "IDPCS.landing.pills.lastAddressVerification.name",
                ),
              },
            },
            {
              type: "paragraph",
              class: "input-label-large pb-3",
              params: {
                content: this.translate.instant(
                  "IDPCS.landing.pills.lastAddressVerification.subStepsLabel",
                ),
              },
            },
            {
              type: "radiobutton_group",
              class: "d-flex flex-column mt-0",
              params: [
                {
                  type: "radio",
                  params: {
                    id: "face_to_face",
                    name: "activity",
                    label: this.translate.instant(
                      "IDPCS.landing.pills.lastAddressVerification.substeps.standard"
                    ),
                    description: this.translate.instant("IDPCS.landing.pills.lastAddressVerification.substeps.descriptionStandard"),
                    value: "standard verification",
                    selected: true,
                  },
                },
                {
                  type: "radio",
                  params: {
                    id: "phone_call",
                    name: "activity",
                    description: this.translate.instant("IDPCS.landing.pills.lastAddressVerification.substeps.descriptioniDIN"),
                    label: this.translate.instant(
                      "IDPCS.landing.pills.lastAddressVerification.substeps.iDIN"
                      ,
                    ),
                    value: "idin verification",
                  },
                },
              ],
            },
            {
              type: "button",
              class: "mt-5",
              params: {
                content: this.translate.instant("common.next"),
                class: "form-button form-button-1 form-button-full-width ",
                display: true,
                action: "makeIdinDecision",
                type: "submit",
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  makeIdinDecision() {
    let value = $('input[name=activity]:checked').val();
    // this.verificationRecord.
    if(value === 'standard verification') {
      this.verificationRecord.verificationPresets.idinAllowed = false;
      this.displayLoader = false;
      this.decideAddressVerificationStep(this.verificationRecord);
    } 
    else {
      this.displayLoader = true;
      this.http
      .post(
        this.verificationLink + '/idin',
        {},
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.refreshDataAddress();
          window.open(response.toString(), '_blank');
        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
        },
      );
    }
  }

  // ADDRESS INPUT
  onAddressVerificationAdressInput(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const thisStep = this.stepsArray.find(
      (step) => step.id === "address_input",
    );
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    // const isMobile = true;
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.formClass = isMobile ? "form-container-mobile" : "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = {
      class: lightboxClass,
      loaderClass: "address-loading-full-screen",
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "input-label-large increased-margin-top pb-3",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.label14",
                ),
              },
            },
            {
              type: "inputGoogleAutocomplete",
              class: "addressVerificationManualField ",
              params: {
                label: this.translate.instant(
                  "IDPCS.address.stepShareLocation.label15",
                ),
                id: "AddressInputField",
                labelClass: "font-weight-bold",
                required: true,
                placeholder: this.translate.instant(
                  "evidencePreview.addressLabel2",
                ),
              },
            },
            {
              type: "checkbox",
              class: "checkboxUseClassicAddress  ",
              params: {
                id: "useClassicAddress",
                name: "useClassicAddress",
                label: this.translate.instant(
                  "evidenceFields.useClassicAddress",
                ),
                value: "useClassicAddress",
                changemethod: "useClassicAddressToggle",
              },
            },
            {
              type: "inputText",
              class: "addressVerificationManualField manualInput d-none",
              params: {
                label: this.translate.instant(
                  "evidenceFields.useClassicAddress",
                ),
                labelClass: "font-weight-bold",
                id: "AddressInputField",
                required: true,
              },
            },
            {
              type: "inputText",
              class: "addressVerificationManualField manualInput d-none",
              params: {
                label: this.translate.instant(
                  "evidenceFields.AddressPostalCode",
                ),
                labelClass: "font-weight-bold",
                id: "ZipInputField",
                required: true,
              },
            },
            {
              type: "inputText",
              class: "addressVerificationManualField manualInput d-none",
              params: {
                label: this.translate.instant("evidenceFields.AddressCity"),
                labelClass: "font-weight-bold",
                id: "CityInputField",
                required: true,
              },
            },
            {
              type: "selectCountryAv",
              class:
                "input-transparent addressVerificationManualField manualInput reduced-margin-top  d-none",
              params: {
                id: "CountryInputField",
                label: this.translate.instant("evidenceFields.AddressCountry"),
                required: true,
                labelClass: "font-weight-bold",
                options: this.idVerificationService.getCountryListAV(),
                validatorMessage: "this field is required",
              },
            },
            {
              type: "inputHidden",
              params: {
                id: "LocationInputField",
              },
            },
            {
              type: "button",
              class: "mt-5",
              params: {
                content: this.translate.instant("common.next"),
                class: "form-button form-button-1 form-button-full-width",
                display: true,
                action: "addressVerificationAddressInput",
                type: "submit",
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  useClassicAddressToggle() {
    if ($("#useClassicAddress").is(":checked")) {
      $(".manualInput").removeClass("d-none");
      $("#googleAutoComplete").attr("isRequired", "false");
      $("#AddressInputField").attr("isRequired", "true");
      $("#ZipInputField").attr("isRequired", "true");
      $("#CityInputField").attr("isRequired", "true");
      $("#CountryInputField").attr("isRequired", "true");
      this.uiService.clearInvalidationTarget($(`[id="googleAutoComplete"]`));
    } else {
      $(".manualInput").addClass("d-none");
      $("#googleAutoComplete").attr("isRequired", "true");
      // $("#AddressInputField").attr("isRequired", "false");
      // $("#ZipInputField").attr("isRequired", "false");
      // $("#CityInputField").attr("isRequired", "false");
      // $("#CountryInputField").attr("isRequired", "false");
      this.uiService.clearInvalidationTarget($(`[id="AddressInputField"]`));
      this.uiService.clearInvalidationTarget($(`[id="ZipInputField"]`));
      this.uiService.clearInvalidationTarget($(`[id="CityInputField"]`));
      this.uiService.clearInvalidationTarget($(`[id="CountryInputField"]`));
    }
  }

  addressVerificationAddressInput() {
    let payload;
    payload = {
      addressData: {
        // 'Address': $('.google-autocomplete').val(),
        Address: $("#AddressInputField").val(),
        ZipCode: $("#ZipInputField").val(),
        City: $("#CityInputField").val(),
        Country: $("#CountryInputField option:selected").text().trim(),
      },
    };
    this.displayLoader = true;
    this.http
      .post(
        this.verificationLink,
        payload,
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => { 
          this.verificationRecord.results.addressDataCapturedOn = true;
          this.displayLoader = false;
          this.decideAddressVerificationStep(this.verificationRecord);
          this.checkVerificaitonLetter();

        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
        },
      );
  }
  // END ADDRESS INPUT

    // START VERIFICAITON CODE
    onAddressVerificationVerificaitonCode(event?) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      const isMobile = this.helper.isMobile();
      let lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
      if (isMobile) {
        lightboxClass =
          "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
          " not-closable form-lightbox-fullscreen-mobile";
      }
      const thisStep = this.stepsArray.find((step) => step.id === "verification");
      thisStep.class = "form-step-active";
      this.formClass = "";
      this.formType = "";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = {
        class: lightboxClass,
        stepsTitle: this.translate.instant("IDPCS.address.title"),
        stepsSubtitle: "",
        showStepsMobile: isMobile,
        logoImage: this.logo,
        projectColor: this.projectColor,
        projectTextColorClass: this.projectTextColorClass,
        steps: this.stepsArray,
      };
      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "image",
                class: "my-5",
                params: {
                  src: "/assets/images/mail-verification.svg",
                  class: "full-max-size",
                },
              },
              {
                type: "subtitle-alternate",
                class: "input-label-large increased-margin-top",
                params: {
                  content: this.translate.instant(
                    "IDPCS.address.stepVerificationCode.label1",
                  ),
                },
              },
              {
                type: "paragraph",
                class: "mt-2",
                params: {
                  paragraphClass: "mt-2",
                  content: this.translate.instant(
                    "IDPCS.address.stepVerificationCode.content1",
                  ),
                },
              },
              {
                type: "verification_code",
                params: {
                  errorMessage: this.translate.instant(
                    "IDPCS.address.stepVerificationCode.error",
                  ),
                },
              },
              {
                type: "button",
                class: "mt-5",
                params: {
                  content: this.translate.instant(
                    "IDPCS.address.stepShareLocation.button2",
                  ),
                  display: true,
                  action: "addressVerificationConfirmCode",
                },
              },
            ],
          },
        ],
      };

      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);

      this.displayModal = true;
    }
    addressVerificationConfirmCode() {
      // find confirmation code
      let code = "";
      let codeInvadid = false;
      const letters = $("#SMSArea")
        .find(".smsCode")
        .each(function () {
          if ($(this).val() === "") {
            // trigger message for inavlid code
            codeInvadid = true;
          } else {
            code += $(this).val().toString().toUpperCase();
          }
        });
      // find confirmation code
      if (codeInvadid) {
        // show message
        $(".sms-area-wrapper .button-error-field").addClass("d-flex");
      } else {
        // hide message
        $(".sms-area-wrapper .button-error-field").removeClass("d-flex");
        this.displayLoader = true;
        this.http
          .post(
            this.verificationLink + "/confirm",
            {
              confirmationCode: code,
            },
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (response) => {
              this.verificationRecord.confirmedAt = true;
              this.decideAddressVerificationStep(this.verificationRecord);
            },
            (error) => {
              console.log("Error occured while fetching the access token", error);
              this.displayLoader = false;
              $(".sms-area-wrapper .button-error-field").addClass("d-flex");
            },
          );
      }
    }
    // END VERIFICAITON CODE



      // START DETECT LOCATION
  geolocationScreenNonMobile(qrCode) {
    const thisStep = this.stepsArray.find(
      (step) => step.id === "address_lookup",
    );
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    this.formClass = "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = {
      class: lightboxClass,
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "input-label-large increased-margin-top",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.title",
                ),
              },
            },
            {
              type: "subtitle-alternate",
              class: "increased-margin-top text-center px-4 mt-5 location-text",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.title2",
                ),
              },
            },
            {
              type: "numberedList",
              params: {
                entries: [
                  {
                    text: this.translate.instant(
                      "IDPCS.address.stepShareLocation.content",
                    ),
                    count: "1",
                  },
                  {
                    text: this.translate.instant(
                      "IDPCS.address.stepShareLocation.content2",
                    ),
                    count: "2",
                  },
                  {
                    text: this.translate.instant(
                      "IDPCS.address.stepShareLocation.content3",
                    ),
                    count: "3",
                  },
                ],
              },
            },
            {
              type: "paragraph",
              class: "text-center mb-3",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.content4",
                ),
              },
            },
            {
              type: "button_group",
              class: "justify-content-center d-flex mt-1",
              params: [
                {
                  content: this.translate.instant(
                    "IDPCS.address.stepShareLocation.button",
                  ),
                  action: "refreshDataAddress",
                  class: "form-button-1 mx-auto",
                  display: true,
                },
              ],
            },
            {
              type: "image",
              class: "image-reduced-top",
              params: {
                width: "196px",
                height: "196px",
                src: qrCode,
              },
            },
            {
              type: "subtitle-alternate",
              class:
                "increased-margin-top text-center px-4 mt-3 location-text-2",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.label",
                ),
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  refreshDataAddress() {

    this.refreshedData = true;
    const currentRoute = this.router.url;

    this.router
    .navigateByUrl("/verifications", { skipLocationChange: true })
    .then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }

  /**
   * detect geolocation on Mobile/Tablet device
   */
  async geolocationScreenMobile() {
    let elements: any;
    const message = "Please stand by while we detect your location ..."; // message displayed by the geolocation step
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);

    this.displayLoader = true;

    elements = [
      {
        type: "subtitle-alternate",
        class: "increased-margin-top text-center px-4 mt-5 location-text",
        params: {
          content: this.translate.instant(
            "IDPCS.address.stepShareLocation.label2",
          ),
        },
      },
      {
        type: "paragraph",
        paragraphClass:
          "increased-margin-top text-center px-4 mt-3 location-text-2",
        params: {
          content: message,
        },
      },
      {
        type: "button_group",
        params: [
          {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.button2",
            ),
            // 'id': 'accept',
            // 'action': 'OnAcceptLocation',
            class: "form-button-0",
            display: true,
          },
          {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.button3",
            ),
            // 'id': 'rescan',
            // 'action': 'OnReScanLocation',
            class: "form-button-0",
            display: true, // TODO: check if user may edit the information
          },
        ],
      },
    ];

    const thisStep = this.stepsArray.find(
      (step) => step.id === "address_lookup",
    );
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    this.formClass = "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.stepsArray.map((step) => {
      if (step.class === "form-step-active") {
        step.name =
          this.position === null
            ? this.translate.instant("IDPCS.address.locationNotDetected")
            : this.translate.instant("IDPCS.address.shareLocation");
      }
    });
    this.lightboxClass = {
      class: lightboxClass,
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
      loaderClass: "address-loading-screen address-loading-full-screen",
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: elements,
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
    // do the actual detection:
    this.lazyLoadGeolocationScreenMobile();
  }

  async lazyLoadGeolocationScreenMobile() {
    let elements: any;
    let message = this.translate.instant(
      "IDPCS.address.stepShareLocation.label3",
    ); // message displayed by the geolocation step
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    let enableReScan = false;

    if (!navigator.geolocation) {
      message = this.translate.instant(
        "IDPCS.address.stepShareLocation.label4",
      );
    } else {
      try {
        this.position = await this.getUserLocation();
        if (this.position !== null) {
          // do nothing
        } else {
          message = this.translate.instant(
            "IDPCS.address.stepShareLocation.label5",
          );
          if (this.helper.isIOS()) {
            message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label6",
            );
            if (this.helper.isChrome()) {
              message += this.translate.instant(
                "IDPCS.address.stepShareLocation.label7",
              );
            }
          } else {
            // android
            message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label7",
            );
          }
        }
      } catch (e) {
        console.log(e.code, e.message);
        message = e.message; // 'An error occured while attempting to fetch your location info! Please try again later.';
        if (e.code === 3) {
          enableReScan = true;
        }
        if (e.code === 1 || e.code === 3) {
          if (e.code === 3 && this.helper.isMobile()) {
            message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label5",
            );
          } else {
            message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label5",
            );
          }

          if (this.helper.isIOS()) {
            message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label6",
            );
            if (this.helper.isChrome()) {
              message += this.translate.instant(
                "IDPCS.address.stepShareLocation.label7",
              );
            }
          } else {
            // android
            if (this.helper.isChrome()) {
              message += this.translate.instant(
                "IDPCS.address.stepShareLocation.label7",
                { extendedDomain: extendedDomain },
              );
            }
            message += message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label9",
            );
          }
        }
        console.log(e);
      }
    }

    this.displayLoader = false;

    if (this.position) {
      elements = [
        {
          type: "notificationParagraph",
          params: {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.label10",
            ),
          },
        },
        {
          type: "displayGoogleMap",
          class: "mobile-map-wrapper",
          params: {
            latitude: this.position.coords.latitude,
            longitude: this.position.coords.longitude,
          },
        },
        {
          type: "paragraph",
          class: "mt-3",
          paragraphClass: "mt-3",
          params: {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.label11",
            ),
          },
        },
        {
          type: "button_group",
          params: [
            {
              content: this.translate.instant("common.continue"),
              action: "OnAcceptLocation",
              class: "form-button-1 form-button-full-width",
              display: true,
            },
          ],
        },
        {
          type: "paragraph",
          class: "mt-3 text-center",
          paragraphClass: "mt-3",
          params: {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.label12",
            ),
          },
        },
        {
          type: "actionAnchor",
          class: "mt-3 text-center",
          params: {
            content: this.translate.instant("common.tryAgain"),
            clickFunction: "OnRescanLocation",
          },
        },
      ];
    } else if (enableReScan) {
      elements = [
        {
          type: "subtitle-alternate",
          class: "increased-margin-top text-center px-4 mt-5 location-text",
          params: {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.label13",
            ),
          },
        },
        {
          type: "paragraph",
          paragraphClass:
            "increased-margin-top text-center px-4 mt-3 location-text-2",
          params: {
            content: message,
          },
        },
        {
          type: "button_group",
          params: [
            {
              content: this.translate.instant(
                "IDPCS.address.stepShareLocation.button2",
              ),
              // 'id': 'accept',
              // 'action': 'OnAcceptLocation',
              class: "form-button form-button-0",
              display: true,
            },
            {
              content: this.translate.instant(
                "IDPCS.address.stepShareLocation.button3",
              ),
              // 'id': 'rescan',
              action: "OnRescanLocation",
              class: "form-button form-button-1",
              display: true, // TODO: check if user may edit the information
            },
          ],
        },
      ];
    } else {
      elements = [
        {
          type: "subtitle-alternate",
          class: "increased-margin-top text-center px-4 mt-5 location-text",
          params: {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.label13",
            ),
          },
        },
        {
          type: "paragraph",
          paragraphClass:
            "increased-margin-top text-center px-4 mt-3 location-text-2",
          params: {
            content: message,
          },
        },
        {
          type: "button_group",
          params: [
            {
              content: this.translate.instant(
                "IDPCS.address.stepShareLocation.button2",
              ),
              // 'id': 'accept',
              // 'action': 'OnAcceptLocation',
              class: "form-button form-button-0",
              display: true,
            },
            {
              content: this.translate.instant(
                "IDPCS.address.stepShareLocation.button3",
              ),
              // 'id': 'rescan',
              // 'action': 'OnRescanLocation',
              class: "form-button form-button-0",
              display: true, // TODO: check if user may edit the information
            },
          ],
        },
      ];
    }

    const thisStep = this.stepsArray.find(
      (step) => step.id === "address_lookup",
    );
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    this.formClass = "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.stepsArray.map((step) => {
      if (step.class === "form-step-active") {
        step.name =
          this.position === null
            ? this.translate.instant("IDPCS.address.locationNotDetected")
            : this.translate.instant("IDPCS.address.shareLocation");
      }
    });
    this.lightboxClass = {
      class: lightboxClass,
      loaderClass: "address-loading-full-screen",
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: elements,
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  private async getUserLocation() {
    if (!navigator.geolocation) {
      console.log("getLocation not supported or not enabled");
      alert(this.translate.instant("appSpecific.LocationDisabled"));
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, null),
      );
    }

    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        maximumAge: 0,
        timeout: 10000,
        enableHighAccuracy: true,
      }),
    );
  }

  OnRescanLocation() {
    this.displayLoader = true;
    this.geolocationScreenMobile();
  }

  OnAcceptLocation() {
    this.displayLoader = true;
    this.ipService.getIPAddress().then((resIpAddress) => {
      this.ipAddress = resIpAddress;
      this.ipService
        .getNewGeoIpAddress(resIpAddress, this.verificationLink)
        .pipe(
          tap((resGeoIpInfo) => {
            this.geoIpAddress = resGeoIpInfo["responseData"];
          }),
          takeUntil(this.unsubscribe$),
        )
        .subscribe(() => {
          this.ipInfo = {
            ipAddress: this.ipAddress,
            ipGeoInfo: this.geoIpAddress,
          };
          this.sendGeolocation();
        });
    });
  }

  sendGeolocation() {
    this.displayLoader = true;
    if (!this.position || !this.ipInfo) {
      console.log("We could not gather all location info!");
    }
    this.http
      .post(
        this.verificationLink +
          "/location",
        {
          geoLocationData: {
            geolocation: this.helper.cloneAsObject(this.position),
            ipGeolocationData: this.ipInfo,
            browser: this.helper.myBrowser(),
            device: this.helper.myDeviceType(),
          },
        },
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.verificationRecord.results.geoLocationDataCapturedOn = true;
          this.decideAddressVerificationStep(this.verificationRecord);
          this.displayLoader = false;

        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
        },
      );
  }
  // END DETECT LOCATION
  // START DOCUMENT UPLOAD
    onDocumentsUpload(event?, allowedDocuments?) {

      this.displayLoader = false;


      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      // Allowed documents must be here in order to continue. Need to redirect to failure screen after that screen is made
      if (!allowedDocuments) {
        console.error("no allowed documents");
        this.router.navigate(["/invalid-general"]);
        return;
      }
      const activeDocument = allowedDocuments.find(
        (document) => document.documentActive === true,
      );
      // If there is no active document something went wrong we need to redirect to failure screen after it is made
      if (!activeDocument) {
        this.router.navigate(["/invalid-general"]);
        return;
      }
      const thisStep = this.stepsArray.find(
        (step) => step.id === "document_upload",
      );
      thisStep.class = "form-step-active";
      const isMobile = this.helper.isMobile();
      // const isMobile = true;
      let lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable no-form-padding-address border-top-left-fix";
      if (isMobile) {
        lightboxClass =
          "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list no-form-padding-address border-top-left-fix" +
          " not-closable form-lightbox-fullscreen-mobile";
      }
      this.formClass = "";
      this.formType = "";
      this.formTitle = "";
      this.btnOkText = "";
      this.formValidator = "";
      this.btnCancelText = "";
      this.lightboxClass = {
        class: lightboxClass,
        loaderClass: "address-loading-full-screen",
        stepsTitle: this.translate.instant("IDPCS.address.title"),
        stepsSubtitle: "",
        showStepsMobile: isMobile,
        logoImage: this.logo,
        projectColor: this.projectColor,
        projectTextColorClass: this.projectTextColorClass,
        steps: this.stepsArray,
      };
      // build the content for the Create new project lightbox
      const group = {
        section_class: "results d-flex flex-column justify-items-center p-0",
        result_container_class: "",
        is_collapsable: false,
        form_id: "request_new_verification",
        result_details: [
          {
            group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
            elements: [
              {
                type: "documentUploaderSide",
                id: activeDocument.type,
                enabled: true,
                evidence: activeDocument,
                isActive: true,
                class:
                  "document-upload-no-list address-document-upload address-document-select",
                params: {
                  evidenceKey: this.currentEvidence.key ? this.currentEvidence.key  : 'separate_upload_key',
                  fundVerification: false,
                  reviewState: false,
                  evidenceTypes: undefined,
                  requestKey: undefined,
                  document_category_name: activeDocument.customEvidenceType
                    ? activeDocument.customEvidenceType
                    : this.helper.parseBackendName(activeDocument.type),
                  image_upload_id: "evidence_image_id",
                  minDate: this.helper.getAsStartDate("01.01.1915"),
                  supportedCountries:
                    this.idVerificationService.parseCountryOptions(),
                  email_pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  documents: activeDocument.documents,
                  purposesOfUse: undefined,
                  evidenceCategories:
                    this.idVerificationService.parseEvidenceFields(
                      activeDocument.type,
                      activeDocument.savedFields,
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      true
                    ),
                  stateFields: activeDocument.savedFields,
                  loadingFromBe: false,
                  flowType: "Update",
                  isFinalize: false,
                  replaceFlow: true,
                  addressDocumentUpload: true,
                  multipleChoiceDocuments: allowedDocuments,
                  isMobile: isMobile,
                  uploadNotes:
                    this.verificationRecord.verificationPresets.documentNotes,
                },
              },
            ],
          },
        ],
      };
      // set the content to the lightbox
      this.lightboxContent = [];
      this.lightboxContent.push(group);
      this.displayModal = true;
    }

    uploadAddressFields(event) {
      // CHECK IF AT LEAST ONE DOCUMENT IS UPLOADED
      if (!$(".evidence-list-image-container").length) {
        event.preventDefault();
        $(".error-field-required-image").removeClass("d-none");
        $(".dropzone").addClass("error-required");
        return;
      } else {
        this.displayLoader = true;
        // failure state
        if (!this.verificationRecord.verificationPresets.allowedDocuments) {
          return;
        }
        const activeDocument =
          this.verificationRecord.verificationPresets.allowedDocuments.find(
            (document) => document.documentActive === true,
          );
        // failure state
        if (!activeDocument) {
          return;
        }
        activeDocument.savedFields = this.idVerificationService.saveFieldsState(
          undefined,
          undefined,
        )["fields"];

        if (!activeDocument.savedFields) {
          return;
        }


        // make image upload preparatiosn
        let fd = new FormData();
        activeDocument.documents.forEach((uploadedDfile) => {
          fd.append("documents", uploadedDfile.file, uploadedDfile.filename);
        });

        Object.keys(activeDocument.savedFields).forEach(key => {
          // Copy property from source to destination
          fd.append(key, activeDocument.savedFields[key]);
        });

        fd.append('selectedDocumentType',activeDocument.type);
  
        this.http
          .post(
           this.verificationLink + "/documents",
           fd,
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            () => {           
              this.verificationRecord.documents = true;
              this.decideAddressVerificationStep(this.verificationRecord);
            },
            (error) => {
              console.log("Error occured while updating document fields", error);
            },
          );
      }
    }
    addressVerificaitonOnImageUpload(files) {
      $("#AVDocumentsNotSelected").hide();
      // let file = files[0];
      if (!files) {
        return;
      }
      // failure state
      if (!this.verificationRecord.verificationPresets.allowedDocuments) {
        return;
      }
      const activeDocument =
        this.verificationRecord.verificationPresets.allowedDocuments.find(
          (document) => document.documentActive === true,
        );
      // failure state
      if (!activeDocument) {
        return;
      }
      // check if number of uploaded files exsceeds 5
      let docNum = 0;
      if (activeDocument.documents) {
        docNum += activeDocument.documents.length;
      }
      docNum += files.length;
      if (docNum > 5) {
        $("#AVDocumentsNotSelected").text(
          this.translate.instant("errors.error13"),
        );
        $("#AVDocumentsNotSelected").show();
        return;
      }
      const match = ["image/jpeg", "image/png", "image/gif", "application/pdf"];
      // check if any file exceeds 5MB or in the wrong format
      for (let i = 0; i < files.length; i++) {
        if (
          files[i]["size"] > 5242880 &&
          files[i]["type"] === "application/pdf"
        ) {
          $("#AVDocumentsNotSelected").text(
            this.translate.instant("errors.error15"),
          );
          $("#AVDocumentsNotSelected").show();
          return;
        }
        if (
          !(
            files[i]["type"] === match[0] ||
            files[i]["type"] === match[1] ||
            files[i]["type"] === match[2] ||
            files[i]["type"] === match[3]
          )
        ) {
          $("#AVDocumentsNotSelected").text(
            this.translate.instant("errors.error12"),
          );
          $("#AVDocumentsNotSelected").show();
          return;
        }
      }
      Array.from(files).forEach((file) => {
        // check if active document has files array
        if (!activeDocument.documents) {
          activeDocument.documents = [];
        }
        activeDocument.documents.push({
          key: "DOC" + activeDocument.documents.length,
          filename: file["name"],
          file: file,
        });
        activeDocument.savedFields = this.idVerificationService.saveFieldsState(
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        )["fields"];
        this.onDocumentsUpload(
          undefined,
          this.verificationRecord.verificationPresets.allowedDocuments,
        );
      });
    }
    // document deletion
    addressVerificaitonOnDocumentDelete(document) {
      const documentKey = document["key"];
      // failure state
      if (!this.verificationRecord.verificationPresets.allowedDocuments) {
        return;
      }
      const activeDocument =
        this.verificationRecord.verificationPresets.allowedDocuments.find(
          (document1) => document1.documentActive === true,
        );
      // failure state
      if (!activeDocument) {
        return;
      }
      const indexToDelete = activeDocument.documents.findIndex(
        (document2) => document2.key === documentKey,
      );
      activeDocument.documents.splice(indexToDelete, 1);
      activeDocument.savedFields = this.idVerificationService.saveFieldsState(
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      )["fields"];
      this.onDocumentsUpload(
        undefined,
        this.verificationRecord.verificationPresets.allowedDocuments,
      );
    }
    multipleChoiceEvidenceChange(event) {
      if (!this.verificationRecord.verificationPresets.allowedDocuments) {
        return;
      }
      const activeDocument =
        this.verificationRecord.verificationPresets.allowedDocuments.find(
          (document) => document.documentActive === true,
        );
      // failure state
      if (!activeDocument) {
        return;
      }
      // save the fields
      activeDocument.savedFields = this.idVerificationService.saveFieldsState(
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      )["fields"];
      // change the active evidence
      activeDocument.documentActive = undefined;
      const newEvidence = $(event.target)
        .attr("id")
        .toString()
        .replace("multipleradio", "");

      let newActiveDocument =
        this.verificationRecord.verificationPresets.allowedDocuments.find(
          (document) => document.type === newEvidence,
        );
      if (!newActiveDocument) {
        newActiveDocument =
          this.verificationRecord.verificationPresets.allowedDocuments.find(
            (document) => document.customEvidenceType === newEvidence,
          );
      }
      newActiveDocument.documentActive = true;
      this.onDocumentsUpload(
        undefined,
        this.verificationRecord.verificationPresets.allowedDocuments,
      );
    }
    setMinEndDate() {}
    // END DOCUMENT UPLOAD
     // FINAL SCREEN
  // Verification is done and this is just a confirmation
  onAddressVerificationEnd(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    // keep the final step for AV but do not display it
    // instead return to global verifications link
    // this.helper.navigateToGeneralVerificationsLink(this.verificationKey);
    this.addressProcessStarted = false;
    this.addressProcessEnded = true;
    this.changeEvidenceStatus.emit('COMPLETED');
    return;
  }
  // END FINAL SCREEN
  // ***** END ADDRESS VERIFICATION STEP FUNCTIONS

  // HELPER FUNCTIONS FOR THE LIGHTBOX
  /**
   * handle click on lightbox confirm
   * @param event event
   */
  onLightboxConfirm(event) {
    if (typeof event.method === "string" && event.method !== "") {
      // allow only strings as acceptable method name
      let params = "";
      if (Array.isArray(event.params) || typeof event.params === "object") {
        params = event.params;
      }
      if (typeof event.params.name === "string") {
        params = event.params;
      }
      try {
        // console.log('calling', event.method, 'with params', params);
        this[event.method](params); // call if it exists
      } catch (error) {
        console.log(
          event.method + " needs to be defined before it is called",
          "sending params:",
          params,
          error,
        );
      }
    } else {
      console.log("**method name not string or empty string");
    }
    // execute default action
    if (typeof event.method === "undefined") {
      // allow only strings as acceptable method name
      // execute default OnConfirm action for this component
      this.displayLoader = true; // display the loading screen
    }
  }
  /**
   * handle click on lightbox close
   */
  onLightboxClose(event) {
    let classes = "";
    if ($(event.target).hasClass("close-form-button")) {
      classes = $(event.target).attr("class");
    } else {
      classes = $(event.target).closest(".close-form-button").attr("class");
    }
    let lightboxType = "displayModal";
    if (classes && classes.includes("top-dialog-box")) {
      lightboxType = "topDisplayModal";
    }
    if (classes && classes.includes("foremost-dialog-box")) {
      lightboxType = "foremostDisplayModal";
    }
    this[lightboxType] = false;
  }
  
  closeAddressPreview() {
    this.addressProcessStarted = !this.addressProcessStarted;
    this.refreshDataAddress();
  }

  CopyLinkToClipboard2(event) {
    const inputEl = $(event.target).siblings("input");
    const statusEl = inputEl.siblings(".input-action-status");
    this.helper.copyToClipboard(inputEl.val().toString());
    statusEl.text("Successfully copied!").fadeIn();
    setTimeout(function () {
      statusEl.fadeOut();
    }, 2500);
  }

  sendverificationLink(event) {
    let payload;
    payload = $(event.target)
      .closest(".input-text-button-wrapper")
      .find("input")
      .val();
    let requestKey = this.currentRequestKey;
    // if (!requestKey || requestKey === "") {
    //   requestKey = this.idVerificationService.getActiveInvestor();
    // }
    $(event.target)
      .closest(".element-wrapper")
      .find(".button-error-field")
      .hide();
    if (
      payload !== "" &&
      payload.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      $(event.target)
        .closest(".element-wrapper")
        .find(".input-action-status")
        .text("Link sent!")
        .fadeIn();
      setTimeout(function () {
        $(event.target)
          .closest(".element-wrapper")
          .find(".input-action-status")
          .text("Link sent!")
          .fadeOut();
      }, 2500);
      // $('#sendAddressVerificationLink').val('');
      const path =
        "/api/projects/" +
        this.idVerificationService.getActiveProject() +
        "/information/requests/" +
        requestKey +
        (this.idVerificationService.isInvestorType()
        ? "/verifications/email"
        : "/email");
      this.userService
        .postEndPoint(
          path,
          { email: payload },
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (typeof response === undefined) {
              return;
            }
          },
          (error) => {
            console.log(
              "The following error occured when generating id verification link",
              error,
            );
          },
        );
    } else {
      $(event.target)
        .closest(".element-wrapper")
        .find(".button-error-field")
        .show();
    }
  }



  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
