import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RiskAssessmentService } from 'src/app/id-verification/pages/risk-assessment/risk-assessment.service';

@Component({
  selector: 'app-risk-rating-approval',
  templateUrl: './risk-rating-approval.component.html',
  styleUrls: ['./risk-rating-approval.component.scss']
})
export class RiskRatingApprovalComponent implements OnInit {

  @Input() data;
  @Output() closeLightbox : EventEmitter<any> = new EventEmitter();
  riskRating: string;
  changedRiskRating: string;
  comment: string = '';
  changedComment: string = '';
  firstWindow: boolean = true;
  riskReviewId: string;
  riskRatingColor: string;
  riskRatingScore: number;
  
  constructor(private riskAssessmentService: RiskAssessmentService) { }

  ngOnInit(): void {
    this.riskReviewId = this.data.key;
    this.riskRating = this.data.record.riskRating;
    this.changedRiskRating = this.riskRating;
    this.riskRatingColor = this.data.record.color;
    this.riskRatingScore = this.data.record.data.riskPoints;
  }

  close(){
    this.closeLightbox.emit();
  }
  changeWindow() : void
  {
    this.firstWindow = ! this.firstWindow;
  }
  updateAndSendForApproval() : void{
    this.comment = this.changedComment;
    if(this.riskRating != this.changedRiskRating)
    {
      this.riskRating = this.changedRiskRating;
      for(let range of this.data.record.riskRanges)
      {
        if(range.label == this.riskRating)
        {
          this.riskRatingColor = range.color
          break;
        }
      }
    }
   
    this.acceptAndSendForApproval();
  }
  acceptAndSendForApproval() :  void
  {
    let payload = {};
    if(this.comment)
    {
      payload =
      {
        finalizeComment : this.comment,
        riskRating : this.riskRating
      }
    }
    else
    {
      payload =
      {
        riskRating : this.riskRating
      }
    }

    this.close();
    this.riskAssessmentService.loadRiskAssessmentLightbox();

    this.riskAssessmentService.finalizeRiskReview(this.riskReviewId, payload);
  }

}
