<div
  style="box-shadow: none; padding-bottom: 0; padding: 24px"
  class="form-container-side review-process-dv-container"
>
<!-- *ngIf="element.params.confirmPersonage === true" -->
  <div class="mt-3 element-wrapper" >
    <div>
      <img
        src="/assets/images/check-svgrepo-com.svg"
        class="result-image request-share-img-2"
      />
    </div>
    <h3 class="verify-title mt-4 mb-2">
      {{ "review.digitalVerifications.title2" | translate }}
    </h3>
    <p
      [innerHTML]="
        'review.digitalVerifications.content8'
          | translate: { entity: entityName ? entityName : 'entity' }
      "
      *ngIf="typeOfInvestor !== 'LegalPerson'"
      class="mb-0 subtext"
    ></p>
    <p
      [innerHTML]="
        'review.digitalVerifications.content9'
          | translate: { entity: entityName ? entityName : 'entity' }
      "
      *ngIf="typeOfInvestor === 'LegalPerson'"
      class="mb-0"
      style="color: #767676"
    ></p>
    <div class="mb-3 mt-3 d-flex justify-content-between flex-wrap">
      <div class="sharing-wrapper d-flex">
        <div>
          <div class="sharing-wrapper-title">
            {{ "review.digitalVerifications.button2" | translate }}
          </div>
          <div
            *ngIf="typeOfInvestor !== 'LegalPerson'"
            class="sharing-wrapper-sub"
            [innerHTML]="
              'review.digitalVerifications.content10'
                | translate: { entity: entityName ? entityName : 'entity' }
            "
          ></div>
          <div
            *ngIf="typeOfInvestor === 'LegalPerson'"
            class="sharing-wrapper-sub"
            [innerHTML]="
              'review.digitalVerifications.content10Legal'
                | translate: { entity: entityName ? entityName : 'entity' }
            "
          ></div>
        </div>
        <div>
          <a
            style="color: white !important"
            (click) = "onShareDVNextButton($event)"
            class="mt-3 ml-2 form-button start-link form-button-1 evidence-submit-button text-center">
            {{ "review.digitalVerifications.button3" | translate }} 
          </a>
        </div>
      </div>
      <div class="mt-3">
        <div class="sharing-wrapper-title">{{"review.digitalVerifications.label" | translate}}</div>
        <div
        *ngIf="typeOfInvestor !== 'LegalPerson'"
        class="sharing-wrapper-sub"
        [innerHTML]="
        'review.digitalVerifications.content11'
          | translate: { entity: entityName ? entityName : 'entity' }"
        ></div>
        <div
        *ngIf="typeOfInvestor === 'LegalPerson'"
        class="sharing-wrapper-sub"
        [innerHTML]="
          'review.digitalVerifications.content11Legal'
            | translate: { entity: entityName ? entityName : 'entity' }"
        ></div>
      </div>
      <div class="element-wrapper mt-5 w-100">

        <div class="d-flex align-items-center position-relative input-text-button-wrapper w-100 justify-content-center">
          <!-- Status notification -->
          <div class="input-action-status"></div>
          <div style="width: 70px;" class="text-button-label mr-3">{{
            "appSpecific.CopyLink" | translate
          }}</div>
          <!-- Input Text -->
          <input
            [readonly]="true"
            type="text"
            class="text-input"
            id="generatedVerificationLink"
            name="generatedVerificationLink"
            value="{{verificationLink }}"
            style="max-width: 500px "
          />
          <!-- Button -->
          <button
            class="form-button form-button-1 ml-3 mr-0"
            (click)="CopyLinkToClipboard2($event)"
          >
            {{ "common.copy" | translate }}
          </button>
        </div>
      </div>
      <div class="element-wrapper mt-3 w-100">
        <div
          class="d-flex align-items-center position-relative input-text-button-wrapper w-100 justify-content-center"
        >
          <!-- Status notification -->
          <div class="input-action-status"></div>
          <div style="width: 70px;" class="text-button-label mr-3">
            {{ "appSpecific.EmailLink" | translate }}
          </div>
          <!-- Input Text -->
          <input
            style="max-width: 500px "
            [value]=""
            placeholder="Type in an email address"
            type="text"
            class="text-input"
            id="generatedVerificationLink"
            name="generatedVerificationLink"
          />
          <!-- Button -->
          <button
            class="form-button form-button-1 ml-3 mr-0"
            (click)="sendVerificationLink($event)"
            [attr.requestKey]="requestKey"
          >
            {{ "common.send" | translate }}
          </button>
        </div>
        <div class="button-error-field">
          {{ "validation.validation5" | translate }}
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="confirmPersonage === false"
    class="element-wrapper mb-4"
  >
    <h4 class="verify-heading">
      {{ "review.digitalVerifications.title3" | translate }}
    </h4>
    <p>{{ "review.digitalVerifications.content12" | translate }}</p>
    <div class="element-wrapper">
      <span class="input-label">{{
        "review.digitalVerifications.label4" | translate
      }}</span>
      <div class="d-flex position-relative input-text-button-wrapper">
        <!-- Status notification -->
        <div class="input-action-status"></div>
        <div  class="text-button-label mr-3">
          URL
        </div>
        <!-- Input Text -->
        <input
          [readonly]="true"
          type="text"
          class="text-input"
          id="generatedverificationLink"
          name="generatedverificationLink"
          value="{{ verificationLink }}"
        />
        <!-- Button -->
        <button
          class="form-button form-button-1 ml-2 mr-0"
          [attr.clickmethod]="'CopyLinkToClipboard2'"
        >
          {{ "common.copy" | translate }}
        </button>
      </div>
    </div>
    <div class="element-wrapper">
      <span class="input-label">
        {{ "review.digitalVerifications.label3" | translate }}</span
      >
      <div class="d-flex position-relative input-text-button-wrapper">
        <!-- Status notification -->
        <div class="input-action-status"></div>
        <div  class="text-button-label mr-3">
          URL
        </div>
        <!-- Input Text -->

        <input
          type="text"
          class="text-input"
          placeholder="Type in an email address"
          id="generatedverificationLink"
          name="generatedverificationLink"
        />
        <!-- Button -->
        <button
          class="form-button form-button-1 ml-2 mr-0"
          [attr.requestKey]="rKey ? rKey : element.params.requestKey"
          [attr.clickmethod]="'sendverificationLink'"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          {{ "common.send" | translate }}
        </button>
      </div>
      <div class="button-error-field">
        {{ "validation.validation5" | translate }}
      </div>
    </div>
    <div class="d-flex justify-content-between mt-4">
      <button
        class="form-button form-button-1 evidence-submit-button"
        (click)="onShareDVNextButton()"
      >
        {{
          isLastEvidence
            ? ("common.finalise" | translate)
            : ("common.next" | translate)
        }}
      </button>
      <button
        target="_blank"
        (click)="switchScreen(true)"
        class="switching-screen-link"
      >
        {{ "review.digitalVerifications.button5" | translate }}
      </button>
    </div> 
  </div>
  <div class="mt-5">
    <button (click) = "checkDVEvidenceStatus()" class="form-button form-button-8 pl-0">
      {{ "review.digitalVerifications.button6" | translate }}
    </button>
  </div>
</div>