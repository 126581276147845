<div
  class="element-wrapper document-preview-container mt-0"
  [ngClass]="element.class ? element.class : ''"
>
  <div class="load-container">
    <img
      src="/assets/images/spinner-green.svg"
      width="65px"
      height="65px"
      class="my-auto mx-auto"
    />
  </div>
  <div
    *ngIf="element?.params?.riskAssessmentData?.record?.riskRating"
    class="d-flex"
  >

  <div class="details" *ngIf="element?.params?.riskAssessmentData?.record?.finalized ; else notFinalized">
    Completed with risk rating 
    <span 
    [style.color]="element?.params?.riskAssessmentData?.record?.color ? element.params.riskAssessmentData.record.color : null"
    class="status">
      {{element.params.riskAssessmentData.record.riskRating.toLowerCase()}}
    </span> 
     <span *ngIf="element?.params?.riskAssessmentData?.record?.finalized?.by?.email"> by <strong> {{element.params.riskAssessmentData.record.finalized.by.email}}</strong></span> 
     <span *ngIf="element?.params?.riskAssessmentData?.record?.finalized?.on"> on <strong>{{element.params.riskAssessmentData.record.finalized.on}}</strong></span>.
     <div *ngIf="element?.params?.riskAssessmentData?.record?.finalized?.comment"> comment: <i>{{element.params.riskAssessmentData.record.finalized.comment}}</i></div>
  </div>
  <ng-template #notFinalized>
  <div class="details" >{{ "riskAssessment.preview.rrStatus" | translate}} 
    <span 
    [style.color]="element?.params?.riskAssessmentData?.record?.color ? element.params.riskAssessmentData.record.color : null"
    class="status">
      {{element.params.riskAssessmentData.record.riskRating.toLowerCase()}}
    </span>
    </div>
  </ng-template>
  </div>
  <div class="document-preview-content d-flex flex-column flex-lg-row">
    <div 
    *ngIf="element?.params?.riskAssessmentData?.record?.data?.answers"
    class="document-preview-left">
    <p class="risk-indicators-label">Risk indicators</p>
    <div class="indicators">
      <div 
      *ngFor="let indicator of element.params.riskAssessmentData.record.data.answers; index as i"
      class="indicator">
        <div 
        class="circle"
        [ngClass]="!indicator.isAnswered ? 'grey' : indicator.value ? 'red' : 'green'">
        <img *ngIf="indicator.isOverwritten" class="indicator-changed" src="assets/images/risk-assessment-in-progress-white.svg"/>
        </div>
        <div class="risk-indicator-wrapper not-answered" *ngIf="!indicator.isAnswered">
          <h2 class="form-clarification-title indicator-label mb-0">
            {{ indicator.riskIndicator}} <span *ngIf="indicator.riskPoints"> ({{indicator.riskPoints}} points)</span>
          </h2>
          <div *ngIf="(element?.params?.riskAssessmentData?.record?.status && element.params.riskAssessmentData.record.status != 'COMPLETED')" class="form-button form-button-link-2 override-result" (click)="overrideRiskIndicator($event, indicator)"> set indicator </div>
        </div>
        <!-- START of the collapseble element -->
        <div class="form-clarification custom-collapse-left-border p-3 ml-3" *ngIf="indicator.isAnswered">
          <div [attr.href]="'#indicator_' + i" data-toggle="collapse" role="button" aria-expanded="true"
              aria-controls="indicator" class="d-flex collapse-header">
              <div class="risk-indicator-wrapper">
                <h2 class="form-clarification-title indicator-label mb-0" [tooltip]="indicator.questionText">
                  {{ indicator.riskIndicator}} <span *ngIf="indicator.riskPoints"> ({{indicator.riskPoints}} points)</span>
                </h2>
                <div *ngIf="(element?.params?.riskAssessmentData?.record?.status && element.params.riskAssessmentData.record.status != 'COMPLETED')" class="form-button form-button-link-2 override-result" (click)="overrideRiskIndicator($event, indicator)"> override result </div>
              </div>
              <img src="assets/images/arrow-up-teal.svg" width="20px" height="20px" class="icon-tooltip dropdown" />
          </div>

          <div class="collapse clarification-content" [id]="'indicator_' + i">
            <div *ngIf="indicator.answers?.length > 0 " class="profiles-wrapper">
              <h2 class="profiles-title heading" >Affected profiles</h2>
              <div class="profiles">
                <!-- START of the inner collapseble element -->
                <div 
                *ngFor="let profile of indicator.answers; index as j"
                class="profile">
                  <div class="form-clarification custom-collapse-no-borders inner p-3" *ngIf="profile">
                    <div [attr.href]="'#profile_' + i + j" data-toggle="collapse" role="button" aria-expanded="true"
                        aria-controls="profile" class="d-flex collapse-header">
                        <h2 class="form-clarification-title mb-0">
                          {{ profile.profileName}}
                        </h2>
                        <img src="assets/images/arrow-up-teal.svg" width="20px" height="20px" class="icon-tooltip dropdown" />
                    </div>
                    <div class="collapse clarification-content" [id]="'profile_' + i + j">
                       <!-- TUKA Ako smeneto neshto vo vrska so indikatoroto treba MArtin da mi prati history da dodadam -->
                        <div class="document-field-wrapper" *ngFor="let evidence of profile.evidence">
                          <div class="document-field">
                            <div *ngIf="evidence.evidenceType" class="document-field-label">
                              {{evidence.evidenceType}}
                            </div>
                            <div *ngIf="evidence?.answer?.label" class="document-field-value"> 
                              {{evidence.answer.label}}
                            </div>
                            
                          </div>
                        </div>
                    </div>
                </div>
                   <!-- END of the collapseble element -->
                </div> 
                <!-- END of the inner collapseble element -->
              </div>
            </div>
            <div *ngIf="indicator.answersHistory?.length > 0 " class="hit-history-wrapper">
              <h2 class="heading" >Indicator history</h2>
              <div class="hit-history-content">
                <app-activity-log
                  [activityLog]="indicator.answersHistory"
                  [type]="'hitHistory'">
                </app-activity-log>
              </div>
            </div>
          </div>
        </div>
      <!-- END of the collapseble element -->

      </div>
    </div>
    <div class="additional-info">
      <div class="risk-scoring" >
        <div class="pr-3">
          <p class="risk-indicators-label">Risk scoring</p>
          <p>Total of {{element.params.riskAssessmentData.record.data.riskPoints}} points</p>
        </div>
      </div>
      <div class="risk-approver">
        <div class="pr-3">
          <p class="risk-indicators-label">Risk approvers</p>
          <p *ngIf="element?.params?.riskAssessmentData?.record?.approvers && element.params.riskAssessmentData.record.approvers.length > 0 ; else noApproversAssigned">
            <span *ngFor="let approver of element.params.riskAssessmentData.record.approvers; index as i; count as length">
              {{approver.user.email}}
              <span *ngIf="length > 1 && i < length-2">, </span>
              <span *ngIf="length > 1 && i == length-2"> and </span>
            </span>
          </p>
          <!-- TUKA na klik treba da se otvara prozorecot za apptovers -->
          <ng-template #noApproversAssigned>To finalise the assessment, please assign an approver <a>here</a></ng-template>
        </div>
      </div>
      <div class="risk-matrix" *ngIf="element?.params?.riskAssessmentData?.record?.riskRanges">
        <div> 
          <p class="risk-indicators-label">Risk matrix</p>
            <div class="d-flex">
              <p *ngFor="let range of element.params.riskAssessmentData.record.riskRanges; last as isLast">
                {{range.label}} : [ {{range?.from ? range.from : '0'}} {{range?.to ? '- ' + range.to : '+'}} ]<span *ngIf="!isLast">,&nbsp;</span>
              </p>
            </div>
        </div>
      </div>
    </div>
   
    </div>
    <div
      class="document-preview-right"
    >
      <div
        class="document-preview-buttons"
      >
        <a
          id="complete-assessment"
          class="complete-assessment button"
          (click)="completeAssessment($event)"
          *ngIf="!(element?.params?.riskAssessmentData?.record?.status && element.params.riskAssessmentData.record.status == 'COMPLETED')"
          [class.disabled]="(element?.params?.riskAssessmentData?.record?.status && element.params.riskAssessmentData.record.status != 'IN_REVIEW') || !userIsApprover ? true: null"
          [tooltip]="getCompleteAssessmentTooltip()"
        >
          <img
            class="preview-download-icon-loading"
            src="/assets/images/spinner-green.svg"
          />
          <img
          *ngIf="(element?.params?.riskAssessmentData?.record?.status && element.params.riskAssessmentData.record.status == 'IN_REVIEW') && userIsApprover"
            class="preview-download-icon"
            src="/assets/images/rr-complete-assessment.svg"
          />
          <img
          *ngIf="(element?.params?.riskAssessmentData?.record?.status && element.params.riskAssessmentData.record.status != 'IN_REVIEW') || !userIsApprover"
          class="preview-download-icon"
          src="/assets/images/rr-complete-assessment-grey.svg"
        />
          <span>Complete assessment</span>
        </a>
        <a
        *ngIf="!(element?.params?.riskAssessmentData?.record?.status && element.params.riskAssessmentData.record.status == 'COMPLETED')"
        id="assign-contributor"
        class="recalculate-risk button"
        (click)="assignContributor($event)"
      >
        <img
          class="preview-download-icon-loading"
          src="/assets/images/spinner-green.svg"
        />
        <img
          class="preview-download-icon"
          src="/assets/images/rr-complete-assessment.svg"
        />
        <span>Assign contributor</span>
      </a>

      <a
      *ngIf="!(element?.params?.riskAssessmentData?.record?.status && element.params.riskAssessmentData.record.status == 'COMPLETED')"
      id="recalculate-assessments"
      class="complete-assessment button"
      (click)="recalculateAssessments()"
    >
      <img
        class="preview-download-icon-loading"
        src="/assets/images/spinner-green.svg"
      />
      <img
        class="preview-download-icon"
        src="/assets/images/rr-complete-assessment.svg"
      />
      <span>Recalculate </span>
    </a>

      </div>
      <div class="document-preview-tabs d-flex">
        <a
          *ngIf="element.params.historyEntries"
          id="previewHistoryTab"
          class="ev-preview-tab history-tab active"
          (click)="elementService.changePreviewClass('previewHistoryTab')"
        >
          {{ "evidencePreview.historyLabel" | translate }}
        </a>
      </div>
      <div class="document-preview-tab-content">
        <div
          *ngIf="element.params.historyEntries"
          id="previewHistoryTabContent"
          class="ev-preview-tab-content history-content"
        >
        <app-activity-log
          [activityLog]="element.params.historyEntries"
          [label]="translate.instant('evidencePreview.historyLabelRRE')"
        ></app-activity-log>
        </div>
      </div>
    </div>
  </div>
</div>