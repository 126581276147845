import { Injectable, SecurityContext } from "@angular/core";
import { DatePipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { profile_verification_country_restrictions } from "./id-verification/id-verification-const";

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: "smallchat", src: "https://embed.small.chat/TGQBPJ4PNGGXG9P3FX.js" },
];

@Injectable({
  providedIn: "root",
})
export class HelperServiceService {
  // date format variables
  date_pattern = /(\d{2}).(\d{2}).(\d{4})/;
  date_format = "$2/$1/$3";
  private pdfFormatDate = " yyyy-MM-dd HH:mm z";
  // tslint:disable-next-line:max-line-length
  private fallbackCountryISO = {
    BD: "BGD",
    BE: "BEL",
    BF: "BFA",
    BG: "BGR",
    BA: "BIH",
    BB: "BRB",
    WF: "WLF",
    BL: "BLM",
    BM: "BMU",
    BN: "BRN",
    BO: "BOL",
    BH: "BHR",
    BI: "BDI",
    BJ: "BEN",
    BT: "BTN",
    JM: "JAM",
    BV: "BVT",
    BW: "BWA",
    WS: "WSM",
    BQ: "BES",
    BR: "BRA",
    BS: "BHS",
    JE: "JEY",
    BY: "BLR",
    BZ: "BLZ",
    RU: "RUS",
    RW: "RWA",
    RS: "SRB",
    TL: "TLS",
    RE: "REU",
    TM: "TKM",
    TJ: "TJK",
    RO: "ROU",
    TK: "TKL",
    GW: "GNB",
    GU: "GUM",
    GT: "GTM",
    GS: "SGS",
    GR: "GRC",
    GQ: "GNQ",
    GP: "GLP",
    JP: "JPN",
    GY: "GUY",
    GG: "GGY",
    GF: "GUF",
    GE: "GEO",
    GD: "GRD",
    GB: "GBR",
    GA: "GAB",
    SV: "SLV",
    GN: "GIN",
    GM: "GMB",
    GL: "GRL",
    GI: "GIB",
    GH: "GHA",
    OM: "OMN",
    TN: "TUN",
    JO: "JOR",
    HR: "HRV",
    HT: "HTI",
    HU: "HUN",
    HK: "HKG",
    HN: "HND",
    HM: "HMD",
    VE: "VEN",
    PR: "PRI",
    PS: "PSE",
    PW: "PLW",
    PT: "PRT",
    SJ: "SJM",
    PY: "PRY",
    IQ: "IRQ",
    PA: "PAN",
    PF: "PYF",
    PG: "PNG",
    PE: "PER",
    PK: "PAK",
    PH: "PHL",
    PN: "PCN",
    PL: "POL",
    PM: "SPM",
    ZM: "ZMB",
    EH: "ESH",
    EE: "EST",
    EG: "EGY",
    ZA: "ZAF",
    EC: "ECU",
    IT: "ITA",
    VN: "VNM",
    SB: "SLB",
    ET: "ETH",
    SO: "SOM",
    ZW: "ZWE",
    SA: "SAU",
    ES: "ESP",
    ER: "ERI",
    ME: "MNE",
    MD: "MDA",
    MG: "MDG",
    MF: "MAF",
    MA: "MAR",
    MC: "MCO",
    UZ: "UZB",
    MM: "MMR",
    ML: "MLI",
    MO: "MAC",
    MN: "MNG",
    MH: "MHL",
    MK: "MKD",
    MU: "MUS",
    MT: "MLT",
    MW: "MWI",
    MV: "MDV",
    MQ: "MTQ",
    MP: "MNP",
    MS: "MSR",
    MR: "MRT",
    IM: "IMN",
    UG: "UGA",
    TZ: "TZA",
    MY: "MYS",
    MX: "MEX",
    IL: "ISR",
    FR: "FRA",
    IO: "IOT",
    SH: "SHN",
    FI: "FIN",
    FJ: "FJI",
    FK: "FLK",
    FM: "FSM",
    FO: "FRO",
    NI: "NIC",
    NL: "NLD",
    NO: "NOR",
    NA: "NAM",
    VU: "VUT",
    NC: "NCL",
    NE: "NER",
    NF: "NFK",
    NG: "NGA",
    NZ: "NZL",
    NP: "NPL",
    NR: "NRU",
    NU: "NIU",
    CK: "COK",
    XK: "XKX",
    CI: "CIV",
    CH: "CHE",
    CO: "COL",
    CN: "CHN",
    CM: "CMR",
    CL: "CHL",
    CC: "CCK",
    CA: "CAN",
    CG: "COG",
    CF: "CAF",
    CD: "COD",
    CZ: "CZE",
    CY: "CYP",
    CX: "CXR",
    CR: "CRI",
    CW: "CUW",
    CV: "CPV",
    CU: "CUB",
    SZ: "SWZ",
    SY: "SYR",
    SX: "SXM",
    KG: "KGZ",
    KE: "KEN",
    SS: "SSD",
    SR: "SUR",
    KI: "KIR",
    KH: "KHM",
    KN: "KNA",
    KM: "COM",
    ST: "STP",
    SK: "SVK",
    KR: "KOR",
    SI: "SVN",
    KP: "PRK",
    KW: "KWT",
    SN: "SEN",
    SM: "SMR",
    SL: "SLE",
    SC: "SYC",
    KZ: "KAZ",
    KY: "CYM",
    SG: "SGP",
    SE: "SWE",
    SD: "SDN",
    DO: "DOM",
    DM: "DMA",
    DJ: "DJI",
    DK: "DNK",
    VG: "VGB",
    DE: "DEU",
    YE: "YEM",
    DZ: "DZA",
    US: "USA",
    UY: "URY",
    YT: "MYT",
    UM: "UMI",
    LB: "LBN",
    LC: "LCA",
    LA: "LAO",
    TV: "TUV",
    TW: "TWN",
    TT: "TTO",
    TR: "TUR",
    LK: "LKA",
    LI: "LIE",
    LV: "LVA",
    TO: "TON",
    LT: "LTU",
    LU: "LUX",
    LR: "LBR",
    LS: "LSO",
    TH: "THA",
    TF: "ATF",
    TG: "TGO",
    TD: "TCD",
    TC: "TCA",
    LY: "LBY",
    VA: "VAT",
    VC: "VCT",
    AE: "ARE",
    AD: "AND",
    AG: "ATG",
    AF: "AFG",
    AI: "AIA",
    VI: "VIR",
    IS: "ISL",
    IR: "IRN",
    AM: "ARM",
    AL: "ALB",
    AO: "AGO",
    AQ: "ATA",
    AS: "ASM",
    AR: "ARG",
    AU: "AUS",
    AT: "AUT",
    AW: "ABW",
    IN: "IND",
    AX: "ALA",
    AZ: "AZE",
    IE: "IRL",
    ID: "IDN",
    UA: "UKR",
    QA: "QAT",
    MZ: "MOZ",
  };
  // tslint:disable-next-line: max-line-length
  private fallbackCountryNames = {
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BA: "Bosnia and Herzegovina",
    BB: "Barbados",
    WF: "Wallis and Futuna",
    BL: "Saint Barthelemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Bolivia",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BT: "Bhutan",
    JM: "Jamaica",
    BV: "Bouvet Island",
    BW: "Botswana",
    WS: "Samoa",
    BQ: "Bonaire, Saint Eustatius and Saba ",
    BR: "Brazil",
    BS: "Bahamas",
    JE: "Jersey",
    BY: "Belarus",
    BZ: "Belize",
    RU: "Russia",
    RW: "Rwanda",
    RS: "Serbia",
    TL: "East Timor",
    RE: "Reunion",
    TM: "Turkmenistan",
    TJ: "Tajikistan",
    RO: "Romania",
    TK: "Tokelau",
    GW: "Guinea-Bissau",
    GU: "Guam",
    GT: "Guatemala",
    GS: "South Georgia and the South Sandwich Islands",
    GR: "Greece",
    GQ: "Equatorial Guinea",
    GP: "Guadeloupe",
    JP: "Japan",
    GY: "Guyana",
    GG: "Guernsey",
    GF: "French Guiana",
    GE: "Georgia",
    GD: "Grenada",
    GB: "United Kingdom",
    GA: "Gabon",
    SV: "El Salvador",
    GN: "Guinea",
    GM: "Gambia",
    GL: "Greenland",
    GI: "Gibraltar",
    GH: "Ghana",
    OM: "Oman",
    TN: "Tunisia",
    JO: "Jordan",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    HK: "Hong Kong",
    HN: "Honduras",
    HM: "Heard Island and McDonald Islands",
    VE: "Venezuela",
    PR: "Puerto Rico",
    PS: "Palestinian Territory",
    PW: "Palau",
    PT: "Portugal",
    SJ: "Svalbard and Jan Mayen",
    PY: "Paraguay",
    IQ: "Iraq",
    PA: "Panama",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PE: "Peru",
    PK: "Pakistan",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PM: "Saint Pierre and Miquelon",
    ZM: "Zambia",
    EH: "Western Sahara",
    EE: "Estonia",
    EG: "Egypt",
    ZA: "South Africa",
    EC: "Ecuador",
    IT: "Italy",
    VN: "Vietnam",
    SB: "Solomon Islands",
    ET: "Ethiopia",
    SO: "Somalia",
    ZW: "Zimbabwe",
    SA: "Saudi Arabia",
    ES: "Spain",
    ER: "Eritrea",
    ME: "Montenegro",
    MD: "Moldova",
    MG: "Madagascar",
    MF: "Saint Martin",
    MA: "Morocco",
    MC: "Monaco",
    UZ: "Uzbekistan",
    MM: "Myanmar",
    ML: "Mali",
    MO: "Macao",
    MN: "Mongolia",
    MH: "Marshall Islands",
    MK: "Macedonia",
    MU: "Mauritius",
    MT: "Malta",
    MW: "Malawi",
    MV: "Maldives",
    MQ: "Martinique",
    MP: "Northern Mariana Islands",
    MS: "Montserrat",
    MR: "Mauritania",
    IM: "Isle of Man",
    UG: "Uganda",
    TZ: "Tanzania",
    MY: "Malaysia",
    MX: "Mexico",
    IL: "Israel",
    FR: "France",
    IO: "British Indian Ocean Territory",
    SH: "Saint Helena",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands",
    FM: "Micronesia",
    FO: "Faroe Islands",
    NI: "Nicaragua",
    NL: "Netherlands",
    NO: "Norway",
    NA: "Namibia",
    VU: "Vanuatu",
    NC: "New Caledonia",
    NE: "Niger",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NZ: "New Zealand",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    CK: "Cook Islands",
    XK: "Kosovo",
    CI: "Ivory Coast",
    CH: "Switzerland",
    CO: "Colombia",
    CN: "China",
    CM: "Cameroon",
    CL: "Chile",
    CC: "Cocos Islands",
    CA: "Canada",
    CG: "Republic of the Congo",
    CF: "Central African Republic",
    CD: "Democratic Republic of the Congo",
    CZ: "Czech Republic",
    CY: "Cyprus",
    CX: "Christmas Island",
    CR: "Costa Rica",
    CW: "Curacao",
    CV: "Cape Verde",
    CU: "Cuba",
    SZ: "Swaziland",
    SY: "Syria",
    SX: "Sint Maarten",
    KG: "Kyrgyzstan",
    KE: "Kenya",
    SS: "South Sudan",
    SR: "Suriname",
    KI: "Kiribati",
    KH: "Cambodia",
    KN: "Saint Kitts and Nevis",
    KM: "Comoros",
    ST: "Sao Tome and Principe",
    SK: "Slovakia",
    KR: "South Korea",
    SI: "Slovenia",
    KP: "North Korea",
    KW: "Kuwait",
    SN: "Senegal",
    SM: "San Marino",
    SL: "Sierra Leone",
    SC: "Seychelles",
    KZ: "Kazakhstan",
    KY: "Cayman Islands",
    SG: "Singapore",
    SE: "Sweden",
    SD: "Sudan",
    DO: "Dominican Republic",
    DM: "Dominica",
    DJ: "Djibouti",
    DK: "Denmark",
    VG: "British Virgin Islands",
    DE: "Germany",
    YE: "Yemen",
    DZ: "Algeria",
    US: "United States",
    UY: "Uruguay",
    YT: "Mayotte",
    UM: "United States Minor Outlying Islands",
    LB: "Lebanon",
    LC: "Saint Lucia",
    LA: "Laos",
    TV: "Tuvalu",
    TW: "Taiwan",
    TT: "Trinidad and Tobago",
    TR: "Turkey",
    LK: "Sri Lanka",
    LI: "Liechtenstein",
    LV: "Latvia",
    TO: "Tonga",
    LT: "Lithuania",
    LU: "Luxembourg",
    LR: "Liberia",
    LS: "Lesotho",
    TH: "Thailand",
    TF: "French Southern Territories",
    TG: "Togo",
    TD: "Chad",
    TC: "Turks and Caicos Islands",
    LY: "Libya",
    VA: "Vatican",
    VC: "Saint Vincent and the Grenadines",
    AE: "United Arab Emirates",
    AD: "Andorra",
    AG: "Antigua and Barbuda",
    AF: "Afghanistan",
    AI: "Anguilla",
    VI: "U.S. Virgin Islands",
    IS: "Iceland",
    IR: "Iran",
    AM: "Armenia",
    AL: "Albania",
    AO: "Angola",
    AQ: "Antarctica",
    AS: "American Samoa",
    AR: "Argentina",
    AU: "Australia",
    AT: "Austria",
    AW: "Aruba",
    IN: "India",
    AX: "Aland Islands",
    AZ: "Azerbaijan",
    IE: "Ireland",
    ID: "Indonesia",
    UA: "Ukraine",
    QA: "Qatar",
    MZ: "Mozambique",
  };

  private countryListAlpha3 = {
    AFG: "Afghanistan",
    ALB: "Albania",
    DZA: "Algeria",
    ASM: "American Samoa",
    AND: "Andorra",
    AGO: "Angola",
    AIA: "Anguilla",
    ATA: "Antarctica",
    ATG: "Antigua and Barbuda",
    ARG: "Argentina",
    ARM: "Armenia",
    ABW: "Aruba",
    AUS: "Australia",
    AUT: "Austria",
    AZE: "Azerbaijan",
    BHS: "Bahamas (the)",
    BHR: "Bahrain",
    BGD: "Bangladesh",
    BRB: "Barbados",
    BLR: "Belarus",
    BEL: "Belgium",
    BLZ: "Belize",
    BEN: "Benin",
    BMU: "Bermuda",
    BTN: "Bhutan",
    BOL: "Bolivia (Plurinational State of)",
    BES: "Bonaire, Sint Eustatius and Saba",
    BIH: "Bosnia and Herzegovina",
    BWA: "Botswana",
    BVT: "Bouvet Island",
    BRA: "Brazil",
    IOT: "British Indian Ocean Territory (the)",
    BRN: "Brunei Darussalam",
    BGR: "Bulgaria",
    BFA: "Burkina Faso",
    BDI: "Burundi",
    CPV: "Cabo Verde",
    KHM: "Cambodia",
    CMR: "Cameroon",
    CAN: "Canada",
    CYM: "Cayman Islands (the)",
    CAF: "Central African Republic (the)",
    TCD: "Chad",
    CHL: "Chile",
    CHN: "China",
    CXR: "Christmas Island",
    CCK: "Cocos (Keeling) Islands (the)",
    COL: "Colombia",
    COM: "Comoros (the)",
    COD: "Congo (the Democratic Republic of the)",
    COG: "Congo (the)",
    COK: "Cook Islands (the)",
    CRI: "Costa Rica",
    HRV: "Croatia",
    CUB: "Cuba",
    CUW: "Curaçao",
    CYP: "Cyprus",
    CZE: "Czechia",
    CIV: "Côte d'Ivoire",
    DNK: "Denmark",
    DJI: "Djibouti",
    DMA: "Dominica",
    DOM: "Dominican Republic (the)",
    ECU: "Ecuador",
    EGY: "Egypt",
    SLV: "El Salvador",
    GNQ: "Equatorial Guinea",
    ERI: "Eritrea",
    EST: "Estonia",
    SWZ: "Eswatini",
    ETH: "Ethiopia",
    FLK: "Falkland Islands (the) [Malvinas]",
    FRO: "Faroe Islands (the)",
    FJI: "Fiji",
    FIN: "Finland",
    FRA: "France",
    GUF: "French Guiana",
    PYF: "French Polynesia",
    ATF: "French Southern Territories (the)",
    GAB: "Gabon",
    GMB: "Gambia (the)",
    GEO: "Georgia",
    DEU: "Germany",
    GHA: "Ghana",
    GIB: "Gibraltar",
    GRC: "Greece",
    GRL: "Greenland",
    GRD: "Grenada",
    GLP: "Guadeloupe",
    GUM: "Guam",
    GTM: "Guatemala",
    GGY: "Guernsey",
    GIN: "Guinea",
    GNB: "Guinea-Bissau",
    GUY: "Guyana",
    HTI: "Haiti",
    HMD: "Heard Island and McDonald Islands",
    VAT: "Holy See (the)",
    HND: "Honduras",
    HKG: "Hong Kong",
    HUN: "Hungary",
    ISL: "Iceland",
    IND: "India",
    IDN: "Indonesia",
    IRN: "Iran (Islamic Republic of)",
    IRQ: "Iraq",
    IRL: "Ireland",
    IMN: "Isle of Man",
    ISR: "Israel",
    ITA: "Italy",
    JAM: "Jamaica",
    JPN: "Japan",
    JEY: "Jersey",
    JOR: "Jordan",
    KAZ: "Kazakhstan",
    KEN: "Kenya",
    KIR: "Kiribati",
    PRK: "Korea (the Democratic People's Republic of)",
    KOR: "Korea (the Republic of)",
    KWT: "Kuwait",
    KGZ: "Kyrgyzstan",
    LAO: "Lao People's Democratic Republic (the)",
    LVA: "Latvia",
    LBN: "Lebanon",
    LSO: "Lesotho",
    LBR: "Liberia",
    LBY: "Libya",
    LIE: "Liechtenstein",
    LTU: "Lithuania",
    LUX: "Luxembourg",
    MAC: "Macao",
    MDG: "Madagascar",
    MWI: "Malawi",
    MYS: "Malaysia",
    MDV: "Maldives",
    MLI: "Mali",
    MLT: "Malta",
    MHL: "Marshall Islands (the)",
    MTQ: "Martinique",
    MRT: "Mauritania",
    MUS: "Mauritius",
    MYT: "Mayotte",
    MEX: "Mexico",
    FSM: "Micronesia (Federated States of)",
    MDA: "Moldova (the Republic of)",
    MCO: "Monaco",
    MNG: "Mongolia",
    MNE: "Montenegro",
    MSR: "Montserrat",
    MAR: "Morocco",
    MOZ: "Mozambique",
    MMR: "Myanmar",
    NAM: "Namibia",
    NRU: "Nauru",
    NPL: "Nepal",
    NLD: "Netherlands (the)",
    NCL: "New Caledonia",
    NZL: "New Zealand",
    NIC: "Nicaragua",
    NER: "Niger (the)",
    NGA: "Nigeria",
    NIU: "Niue",
    NFK: "Norfolk Island",
    MNP: "Northern Mariana Islands (the)",
    NOR: "Norway",
    OMN: "Oman",
    PAK: "Pakistan",
    PLW: "Palau",
    PSE: "Palestine, State of",
    PAN: "Panama",
    PNG: "Papua New Guinea",
    PRY: "Paraguay",
    PER: "Peru",
    PHL: "Philippines (the)",
    PCN: "Pitcairn",
    POL: "Poland",
    PRT: "Portugal",
    PRI: "Puerto Rico",
    QAT: "Qatar",
    MKD: "Republic of North Macedonia",
    ROU: "Romania",
    RUS: "Russian Federation (the)",
    RWA: "Rwanda",
    REU: "Réunion",
    BLM: "Saint Barthélemy",
    SHN: "Saint Helena, Ascension and Tristan da Cunha",
    KNA: "Saint Kitts and Nevis",
    LCA: "Saint Lucia",
    MAF: "Saint Martin (French part)",
    SPM: "Saint Pierre and Miquelon",
    VCT: "Saint Vincent and the Grenadines",
    WSM: "Samoa",
    SMR: "San Marino",
    STP: "Sao Tome and Principe",
    SAU: "Saudi Arabia",
    SEN: "Senegal",
    SRB: "Serbia",
    SYC: "Seychelles",
    SLE: "Sierra Leone",
    SGP: "Singapore",
    SXM: "Sint Maarten (Dutch part)",
    SVK: "Slovakia",
    SVN: "Slovenia",
    SLB: "Solomon Islands",
    SOM: "Somalia",
    ZAF: "South Africa",
    SGS: "South Georgia and the South Sandwich Islands",
    SSD: "South Sudan",
    ESP: "Spain",
    LKA: "Sri Lanka",
    SDN: "Sudan (the)",
    SUR: "Suriname",
    SJM: "Svalbard and Jan Mayen",
    SWE: "Sweden",
    CHE: "Switzerland",
    SYR: "Syrian Arab Republic",
    TWN: "Taiwan",
    TJK: "Tajikistan",
    TZA: "Tanzania, United Republic of",
    THA: "Thailand",
    TLS: "Timor-Leste",
    TGO: "Togo",
    TKL: "Tokelau",
    TON: "Tonga",
    TTO: "Trinidad and Tobago",
    TUN: "Tunisia",
    TUR: "Turkey",
    TKM: "Turkmenistan",
    TCA: "Turks and Caicos Islands (the)",
    TUV: "Tuvalu",
    UGA: "Uganda",
    UKR: "Ukraine",
    ARE: "United Arab Emirates (the)",
    GBR: "United Kingdom of Great Britain and Northern Ireland (the)",
    UMI: "United States Minor Outlying Islands (the)",
    USA: "United States of America (the)",
    URY: "Uruguay",
    UZB: "Uzbekistan",
    VUT: "Vanuatu",
    VEN: "Venezuela (Bolivarian Republic of)",
    VNM: "Viet Nam",
    VGB: "Virgin Islands (British)",
    VIR: "Virgin Islands (U.S.)",
    WLF: "Wallis and Futuna",
    ESH: "Western Sahara",
    YEM: "Yemen",
    ZMB: "Zambia",
    ZWE: "Zimbabwe",
    ALA: "Åland Islands",
  };

  // dynamic loading scripts
  private scripts: any = {};
  // Default Message
  // the translations are not available at the moment the services are instantiated
  // in order for this to work an APP_INITIALIZER was added for TranslationService in app.module
  // additionally if this approach would be taken the variable would always be translated in the defaultLanguage
  // private defaultUserVerificationMessage = this.translate.instant('IDPCS.landing.content');
  private defaultUserVerificationMessage = "IDPCS.landing.content";
  InstallTrigger: any;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    public translate: TranslateService,
  ) {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }
  static generateRandomString(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
   * dynamic script load helper methods
   */
  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        // load script
        const script: any = document.createElement("script");
        script.type = "text/javascript";
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === "loaded" ||
              script.readyState === "complete"
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: "Loaded" });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: "Loaded" });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: "Loaded" });
        document.getElementsByTagName("head")[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: "Already Loaded" });
      }
    });
  }

  /**
   * helper methods
   */
  getTodayAsMaxDate() {
    const date = new Date();
    return JSON.stringify({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }
  getAdultAsMaxDate() {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return JSON.stringify({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }
  getAsStartDate(inputDate, addMonth = 1) {
    if (typeof inputDate !== "string") {
      return "";
    }
    const date = new Date(
      inputDate.replace(this.date_pattern, this.date_format),
    );
    return JSON.stringify({
      year: date.getFullYear(),
      month: date.getMonth() + addMonth,
      day: date.getDate(),
    });
  }

  getAsMinDateMinusYears(years) {
    const date = new Date();
    date.setFullYear(date.getFullYear() - years);
    return JSON.stringify({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }

  getAsMinDateMinusMonths(months) {
    const date = new Date();
    date.setMonth(date.getMonth() - months);
    return JSON.stringify({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }

  getAsMaxDatePlusMonths(months) {
    const date = new Date();
    date.setMonth(date.getMonth() + months);
    return JSON.stringify({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }

  getAsMaxDatePlusYears(years) {
    const date = new Date();
    date.setFullYear(date.getFullYear() + years);
    return JSON.stringify({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }

  getAsStartDateFromTimestamp(inputDate: string | number) {
    if (typeof inputDate === "string") {
      inputDate = parseInt(inputDate, 10);
    }
    const date = new Date(inputDate);
    return JSON.stringify({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }

  getTodayDate(delimiter = "-") {
    const date = new Date();
    return `${("0" + date.getDate()).slice(-2)}${delimiter}${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}${delimiter}${date.getFullYear()}`;
  }

  getTodayDateTime(delimiter = "-") {
    const date = new Date();
    return `${date.getFullYear()}${delimiter}${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}${delimiter}${("0" + date.getDate()).slice(
      -2,
    )} ${date.getHours()}:${date.getMinutes()}`;
    return `${("0" + date.getDate()).slice(-2)}${delimiter}${(
      "0" +
      (date.getMonth() + 1)
    ).slice(
      -2,
    )}${delimiter}${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  }


   isNumericString(value) {
    return !isNaN(value) && !isNaN(parseFloat(value));
}

  // if fromUI is set to true then convert to CET timezone
  convertToDate(inputDate, fromUI = false) {
    if (typeof inputDate !== "string") {
      return "";
    }
    // if we need to always convert to CET
    // for now this is not needed as we are using date pipe
    // and this is where the timezone information should come
    // uncomment this line only if we ever use convertToDate to directly display a date
    if (fromUI) {
      return new Date(
        inputDate.replace(this.date_pattern, this.date_format) +
          " 00:00:00 +0000",
      );
    } else {
      return new Date(inputDate.replace(this.date_pattern, this.date_format));
    }
  }

  /**
   * get a timestamp
   * @param inputDate (default: Jan 01 1970 | 'current' - returns current timestamp | strdate - returns timestamp for date)
   */
  getTimestamp(inputDate = "Jan 01 1970") {
    if (inputDate === "current") {
      return new Date().getTime();
    }
    return new Date(
      inputDate.replace(this.date_pattern, this.date_format),
    ).getTime();
  }

  getTimestampEndDay(inputDate = "Jan 01 1970") {
    if (inputDate === "current") {
      return new Date().getTime();
    }
    return (
      new Date(
        inputDate.replace(this.date_pattern, this.date_format),
      ).getTime() + 86399999
    );
  }

  getDateFromTimeStamp(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toISOString().split("T")[0].replace("-", "-");
    // return formattedDate;
  }

  getDateFromTimeStampAdditional(timestamp) {
    const date = new Date(timestamp);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };

    return date.toLocaleString("en-GB", options);
  }

  /**
   * courtesy of
   * https://flaviocopes.com/how-to-validate-email-address-javascript/
   * @param email string
   */
  validateEmail(email) {
    // tslint:disable-next-line:max-line-length
    const expression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(email).toLowerCase());
  }

  parseActivities(activities) {
    let res = "";
    activities.forEach((activity) => {
      res +=
        "<div> SBI-code: " +
        activity.sbiCode +
        " - " +
        activity.sbiCodeDescription;
    });
    return res;
  }
  isEvidenceActionRequiredOrInProgress(evidence): boolean {
    if (evidence) {
      for (let i = 0; i < evidence.length; i++) {
        if (
          evidence[i].status.toLocaleLowerCase() === "in progress" ||
          evidence[i].status.toLocaleLowerCase() === "action required"
        ) {
          return true;
        }
      }
    }
    return false;
  }

  // format date for BE
  prepareDateForApi(inputDate: string) {
    const date = this.convertToDate(inputDate, true);
    // THE ISSUE
    // MIGHT BE CHANGED LATER
    // CHECK IF ITS A VALID FORMAT IN THE ONE WE GET FROM DATEPICKER DD.MM.YYY
    const dateParts = inputDate.split(".");
    const dateObject = new Date(
      parseInt(dateParts[2], 10),
      parseInt(dateParts[1], 10) - 1,
      parseInt(dateParts[0], 10),
    );

    if (dateObject.getTime()) {
      return date["toISOString"]();
    } else {
      return "";
    }
  }

  
  // format ISO date to proper format
  formatISODate(isoDate: string, dateFormat = "yyy.MM.dd", offset = true) {
    const iso8601Regex =
      /^\d{4}-\d{2}-\d{2}(?: \d{2}:\d{2}(?::\d{2})?(?: [+-]\d{2}(?::\d{2})?)?)?$/;

    if(this.isValidDateString(isoDate)) {
      const dateTime = new Date();
      const localOffset = dateTime.getTimezoneOffset().toString();
      let serverOffset;
      if (offset) {
        serverOffset = " +0000";
      }
      const pipe = new DatePipe("en-US");
      return pipe.transform(isoDate, dateFormat, serverOffset);
    }
    else {
      return "";
    }
  }

  isValidDateString(isoDate: string): boolean {
      const date = new Date(isoDate);
      return !isNaN(date.getTime());
  }

  /**
   * copy text to clipboard
   */
  copyToClipboard(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  validateTag(tag) {
    if(tag.length > 20) {
      alert("Tags have a character limit of 20");
      return false;
    }
    const tagRegex = /^[a-zA-Z0-9\s+()\[\]-]+$/;
    if(!tagRegex.test(tag)) {
      alert("Tags must contain at least one number or character and cannot contain special characters");
      return false;
    }
    return true;
  }

  retrieveCountryIso2(iso3Code, isoCodesJSON = null) {
    let ret = "";
    let isoCodes: {} = this.fallbackCountryISO;
    if (isoCodesJSON !== null) {
      isoCodes = isoCodesJSON;
    }
    Object.keys(isoCodes).forEach((key) => {
      if (isoCodes[key] === iso3Code) {
        ret = key;
      }
    });

    return ret;
  }

  retreiveCountryIso3(iso3Code) {
    return this.countryListAlpha3["iso3Code"];
  }

  retrieveCountryName(iso2Code, isoNamesJSON = null) {
    let ret = "";
    let isoNames: {} = this.fallbackCountryNames;
    if (isoNamesJSON !== null) {
      isoNames = isoNamesJSON;
    }
    Object.keys(isoNames).forEach((key) => {
      if (key === iso2Code) {
        ret = isoNames[key];
      }
    });

    return ret;
  }

  // functions requred for implementing and manipulating the custom user message
  returnDefaultUserVerificationMessage(projectName = "") {
    const message = this.translate.instant(this.defaultUserVerificationMessage);
    return message.replace("{{PROJECT NAME}}", '"' + projectName + '"');
  }

  returnShortenedUserVerificationMessage(
    consentMessage = "",
    projectName = "",
  ) {
    let localUserVerificationMessage = "";
    if (consentMessage !== "") {
      localUserVerificationMessage = consentMessage;
    } else {
      localUserVerificationMessage = this.defaultUserVerificationMessage;
    }
    localUserVerificationMessage = localUserVerificationMessage.replace(
      "{{PROJECT NAME}}",
      '"' + projectName + '"',
    );
    if (localUserVerificationMessage.length > 300) {
      localUserVerificationMessage =
        localUserVerificationMessage.substring(0, 297) + "...";
    }
    return localUserVerificationMessage;
  }

  /**
   * helper method: convert hex to rgb
   * @param color: hex representation of a color
   */
  hexToRgb(color) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  /**
   * helper method: validate if value is a valid hex color.If not return default color.
   * @param color: hex representation of a color
   */
  hexValidator(color): boolean {
    if (/^#[0-9A-F]{6}$/i.test(color)) {
      return true;
    }
    return false;
  }
  /**
   * helper method: detrmine light or dark
   * color based on calculated hsp
   * @param rgbColor: RGB representation of a color
   */
  detectTextColor(rgbColor): string {
    const r = rgbColor["r"];
    const g = rgbColor["g"];
    const b = rgbColor["b"];

    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    if (hsp > 127.5) {
      return "dark-color";
    } else {
      return "light-color";
    }
  }
  /**
   * helper method: determine default project logo color
   * @param color: string, current project color
   */
  getDefaultProjectLogo(color: string) {
    if (color === undefined) {
      return (
        "/assets/images/ico-project-" +
        this.detectTextColor(this.hexToRgb("#ffffff")) +
        ".svg"
      );
    } else {
      return (
        "/assets/images/ico-project-" +
        this.detectTextColor(this.hexToRgb(color)) +
        ".svg"
      );
    }
  }
  /**
   * helper method: determine default project loader icon color
   * @param color: string, current project color
   */
  getDefaultProjectSpinner(color: string) {
    if (color === undefined) {
      return (
        "/assets/images/ico-project-" +
        this.detectTextColor(this.hexToRgb("#ffffff")) +
        ".svg"
      );
    } else {
      return (
        "/assets/images/ico-project-" +
        this.detectTextColor(this.hexToRgb(color)) +
        ".svg"
      );
    }
  }
  /**
   * helper method: make sure the first character
   * of the string is uppercase
   * @param subject: the string that we are manipulating
   */
  toFirstCharUppercase(subject: string) {
    return subject.charAt(0).toUpperCase() + subject.slice(1);
  }

  /**
   * helper method: it will parse all properties
   * of an object or array and return sanitzied
   * object or array
   */
  sanitize(subject, securityContext = SecurityContext.NONE) {
    if (!Array.isArray(subject) && !(subject instanceof Object)) {
      return this.sanitizePropery(
        subject,
        (securityContext = SecurityContext.NONE),
      );
    }
    for (const property in subject) {
      
      if(property === 'richDescription') {
        // skip
      }
      else if (subject[property] instanceof Object) {
        subject[property] = this.sanitize(subject[property], securityContext);
      } else {
        subject[property] = this.sanitizePropery(
          subject[property],
          securityContext,
        );
      }
    }
    return subject;
  }

  /**
   * helper method: it will parse all properties
   * of an object or array and return sanitzied
   * object or array
   */
  sanitizePropery(subject, securityContext = SecurityContext.HTML) {

    if (
      typeof subject === "boolean" ||
      typeof subject === "undefined" ||
      typeof subject === "number"
    ) {
      return subject;
    }

    return this.decodeUTFHtmlEntities(
      this.sanitizer.sanitize(securityContext, subject),
    );
  }

  VariableHasValue(variable: string | null | undefined): boolean {
    if (
      !variable ||
      variable == null ||
      variable == undefined ||
      (Array.isArray(variable) && variable.length == 0) ||
      (typeof variable == "object" && Object.keys(variable).length == 0)
    ) {
      return false;
    }
    return true;
  }

  decodeUTFHtmlEntities(text) {
    if (!text) {
      return;
    }
    // return text;
    return text
      .replace(/&amp;/g, "&")
      .replace(/&#34;/g, '"')
      .replace(/&#35;/g, '"')
      .replace(/&#10;/g, "")
      .replace(/&#39;/g, '"')
      .replace(/&#(\d+);/g, function (match, dec) {
        let ret = text;
        // console.log(dec)
        // old value dec > 188 && dec < 255 - included only latin basic UTF-16 charcodes
        if (
          (dec > 188 && dec <= 591) || // latin basic, extended A & extended B
          (dec >= 880 && dec <= 1023) || // greek letters
          (dec >= 1023 && dec <= 1365) // greek letters
        ) {
          ret = String.fromCharCode(dec);
        }
        return ret;
      });
  }
  /**
   * helper method to parse BE naming convention , example SourceOFWealth will become Source of wealth
   */
  parseBackendName(name: string) {
    if (name === undefined || name === "") {
      return name;
    }
    name = name
      .replace(/([A-Z][a-z])/g, " $1")
      .trim()
      .toLocaleLowerCase();
    let returnValue = name.charAt(0).toUpperCase() + name.slice(1);
    if (returnValue === "Aps" || returnValue === "APS") {
      returnValue = "Compliance check";
    }
    return returnValue;
  }

  parseBackendName2(name: string) {
    name = name.replace(/([A-Z][a-z])/g, " $1").trim();
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  parseCountryName(name: string) {
    name = name.replace(/_/g, " ");
    name = name.replace(/-/g, " ");
    return name;
  }

  unParseCountryName(name: string) {
    name = name.replace(/ /g, "-");
    return name;
  }

  parseTypeName(name: string) {
    name = name.replace(/_/g, " ");
    name = name
      .replace(/([A-Z][a-z])/g, " $1")
      .trim()
      .toLocaleLowerCase();
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  parseRemoveDash(name: string) {
    name = name.replace(/-/g, " ");
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  parseTagNameForBE(name: string) {
    return name.replace(/ /g, "_");
  }
  parseTagNameForFE(name: string) {
    return name.replace(/_/g, " ");
  }

  parseIDVGoLabel(label: string) {
    return this.parseBackendName2(this.parseCountryName(label).toLowerCase());
  }

  // method to decode images
  toBase64(arr) {
    if (!arr) {
      return;
    }
    // arr = new Uint8Array(arr) if it's an ArrayBuffer
    return btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), ""),
    );
  }
  isIOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  isChrome() {
    return (
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    );
  }

  isMobile() {
    // return true;
    return navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i,
    );
  }

  isAndroid() {
    return navigator.userAgent.match(/(android)|(webOS)/i);
  }

  isSamsungBrowser() {
    return navigator.userAgent.match(/(SamsungBrowser)/i);
  }

  IsMac() {
    return navigator.platform.toUpperCase().indexOf("MAC") >= 0;
  }

  myBrowser() {
    if (navigator.userAgent.indexOf("SamsungBrowser") > -1) {
      return "Samsung Internet";
    } else if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) !== -1
    ) {
      return "Opera";
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return "Firefox";
    } else if (/Edge/.test(navigator.userAgent)) {
      return "Edge";
    } else {
      return "unknown";
    }
  }

  myFullBrowser() {
    return navigator.userAgent;
  }

  myDeviceType() {
    if (!this.isMobile()) {
      if (this.IsMac()) {
        return "Mac device";
      } else {
        return "PC/Laptop";
      }
    } else {
      if (this.isAndroid()) {
        return "Android device";
      } else {
        return "iOS device";
      }
    }
  }

  filterSuggestedTags(suggestedTags, activeTags, unadedTags) {
    const newSuggestedTags = [];
    suggestedTags.forEach((element) => {
      if (
        (activeTags && activeTags.includes(element.tag)) ||
        (unadedTags && unadedTags.includes(element.tag))
      ) {
      } else {
        newSuggestedTags.push(element);
      }
    });
    return newSuggestedTags;
  }

  cloneAsObject(obj) {
    if (obj === null || !(obj instanceof Object)) {
      return obj;
    }
    const temp = obj instanceof Array ? [] : {};
    // ReSharper disable once MissingHasOwnPropertyInForeach
    for (const key in obj) {
      temp[key] = this.cloneAsObject(obj[key]);
    }
    return temp;
  }

  async cloneAsObjectAsync(obj) {
    if (obj === null || !(obj instanceof Object)) {
      return obj;
    }
    const temp = obj instanceof Array ? [] : {};
    // ReSharper disable once MissingHasOwnPropertyInForeach
    for (const key in obj) {
      if (obj["key"]) {
        temp[key] = this.cloneAsObject(obj[key]);
      }
    }
    return temp;
  }

  getSuitableIDVerificationStatuses() {
    return [
      "expired",
      "rejected_failed",
      "rejected_id_type",
      "rejected_id_country",
      "rejected_id_not_readable",
      "verified",
      "rejected_similarity",
      "rejected_validity",
    ];
  }
  getSuitableFailedIDVerificationStatuses() {
    return [
      "expired",
      "rejected_failed",
      "rejected_id_type",
      "rejected_id_country",
      "rejected_id_not_readable",
      "rejected_similarity",
      "rejected_validity",
    ];
  }
  getSuitableIDVerificationStatusesTemp() {
    return ["verified"];
  }

  getSuitableAddressVerificationStatuses() {
    return ["COMPLETED", "ACTION_REQUIRED", "FAILED"];
  }

  getSuitableAPSVerificationStatuses() {
    return ["COMPLETED", "ACTION_REQUIRED", "FAILED"];
  }

  getSuitableContractVerificationStatuses() {
    return ["COMPLETED"];
  }

  navigateToGeneralVerificationsLink(verificationKey, iframe = false) {
    if (iframe) {
      window.top.location.href = `/verifications/${verificationKey}`;
    }
    this.router.navigate([`/verifications/${verificationKey}`]);
  }

  checkIfIframe(): boolean {
    if (window.location !== window.parent.location) {
      return true;
    } else {
      return false;
    }
  }

  slugify($text, $divider = "-") {
    // trim
    $text = $text.trim();

    // replace non letter or digits by divider
    $text = $text.replace("~[^pLd]+~u", $divider);

    // replace empty space
    $text = $text.replace(/\s/g, $divider);

    // transliterate
    // $text = iconv('utf-8', 'us-ascii//TRANSLIT', $text);

    // remove unwanted characters
    $text = $text.replace("~[^-w]+~", "", $text);

    // remove duplicate divider
    $text = $text.replace("~-+~", $divider);

    // lowercase
    $text = $text.toLowerCase();

    if (!$text) {
      return "";
    }

    return $text;
  }

  public getPdfFormatDate() {
    return this.pdfFormatDate;
  }

  public capitalize(data: string) {
    if (!data) {
      return data;
    }
    data = data.replace(/  +/g, " ");
    data = data.trim();
    const arr = data.split(" ");
    return arr
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  /**
   * check the response for digital verifications that need lazy resolving
   * @param response response containing details about the investor/fund/RelatedParty
   */
  needsDigitalVerificationLazyResolve(response, recordKey) {
    if (response && response.record && response.record.isResolved === false) {
      return true;
    }

    if (response && response.message) {
      if (
        response.message.includes(
          `No verifications were resolved for ${recordKey}`,
        )
      ) {
        return true;
      }
    }
    if (response && response.record && response.record.digitalVerifications) {
      const notResolvedDigitalVerifications =
        response.record.digitalVerifications.filter(
          (dv) => dv.isResolved === false,
        );
      if (notResolvedDigitalVerifications.length > 0) {
        return true;
      }
    }

    if (response && response.records) {
      let ret = false;
      response.records.forEach((entity) => {
        if (!entity.record) {
          return false;
        }
        if (entity.record.isResolved === false) {
          ret = true;
        }

        if (entity.key === recordKey && entity.record.digitalVerifications) {
          const notResolvedDigitalVerifications =
            entity.record.digitalVerifications.filter(
              (dv) => dv.isResolved === false,
            );
          if (notResolvedDigitalVerifications.length > 0) {
            ret = true;
          }
        }
      });
      return ret;
    }

    return false;
  }

  // helper function for download preview files

  changeLanguage(lang) {
    this.translate.use(lang);
  }

  reloadLocation(publicLink?) {
    let location = "/";
    if (publicLink) {
      location = "/verifications";
    }
    const currentRoute = this.router.url;
    this.router
      .navigateByUrl(location, { skipLocationChange: true })
      .then(() => {
        this.router.navigate([currentRoute]); // navigate to same route
      });
  }

  downloadingDocument(data: string, filename: string) {
    const link = document.createElement("a");
    link.href = data;
    link.download = filename;
    link.style.display = "none";
    const evt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    document.body.appendChild(link);
    link.dispatchEvent(evt);
    document.body.removeChild(link);
  }

  private createFile(file, type) {
    let newBlob = new Blob([file], { type });
    if (type === "application/pdf") {
      newBlob = new Blob([new Uint8Array(file)], { type });
    }
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    return window.URL.createObjectURL(newBlob);
  }

  /**
   * Global method that can be used for generating download link
   * and downloading a text, html, json or pdf files
   * @param file object {fileName: string, data: string}
   * @param type mime type of the file
   * @param fileType  extension that will be appended to fileName
   */
  generateDownloadLink(file, type, fileType) {
    const link = document.createElement("a");
    link.href = this.createFile(file.data, type);
    link.target = "_blank";
    link.download = `${file.fileName}.${fileType}`;
    // document.body.append(link);
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
    // in case the Blob uses a lot of memory
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.remove();
    }, 300);
  }

  toTitleCase(phrase) {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // TRANSLATION HELPER FUNCTIONS
  translateEvidenceStatus(status) {
    let parsedStatus = this.toTitleCase(status);
    parsedStatus = "evidenceStatus." + parsedStatus.replace(/\s/g, "");
    return this.translate.instant(parsedStatus);
  }

  translateRequestStatus(status, isInvestorType?) {
    let parsedStatus = status.toLowerCase().replace(/\s/g, "");
    if (parsedStatus === "actionrequired" && isInvestorType) {
      parsedStatus = "inreview";
    }
    parsedStatus = "requestStatus." + parsedStatus;
    return this.translate.instant(parsedStatus);
  }

  translateVerificationType(type, alternateTranslation?) {
    if (
      (type === "IdentityInstantAI" && this.isInvestorType()) ||
      (type === "IdentityInstantAI" && this.isClaimScreen())
    ) {
      return this.translate.instant("verificationType." + "Identity");
    }
    if (alternateTranslation) {
      return this.translate.instant("verificationType." + alternateTranslation);
    } else {
      const parsedStatus = "verificationType." + type;
      return this.translate.instant(parsedStatus);
    }
  }

  isInvestorType() {
    if (this.router.url.includes("investor")) {
      return true;
    }
    return false;
  }

  isClaimScreen() {
    if (this.router.url.includes("share")) {
      return true;
    }
    return false;
  }

  generateTooltipText(text: string, evidenceTypes): string {
    let tooltipText = " ";
    let filterEvidenceTypes = [...evidenceTypes];
    filterEvidenceTypes = filterEvidenceTypes.filter((evidence) =>
      this.isEvidenceActionRequiredOrInProgress([evidence]),
    );
    filterEvidenceTypes.forEach((evidence, index) => {
      if (evidence.verificationType) {
        // DV
        tooltipText += this.translateVerificationType(
          evidence.verificationType,
        ).toLocaleLowerCase();
      } else if (evidence.type) {
        // Evidence
        tooltipText += this.translateEvidenceType(
          evidence.type,
        ).toLocaleLowerCase();
      }
      if (filterEvidenceTypes.length > 1) {
        if (index == filterEvidenceTypes.length - 2) {
          tooltipText += " " + this.translate.instant("common.and") + " ";
        } else if (index != filterEvidenceTypes.length - 1) {
          tooltipText += ", ";
        }
      }
    });
    return this.translate.instant(text) + tooltipText;
  }

  translateEvidenceType(type, typeOfPerson?) {
    const parsedStatus = "evidenceType." + type;
    return this.translate.instant(parsedStatus);
  }

  translateEvidenceField(field) {
    const parsedStatus = "evidenceFields." + field;
    return this.translate.instant(parsedStatus);
  }

  translatePurposesOfUse(purpose) {
    const parsedStatus = "purposesOfUse." + purpose;
    return this.translate.instant(parsedStatus);
  }

  translateLegalPersonType(type) {
    const parsedStatus = "legalType." + type;
    return this.translate.instant(parsedStatus);
  }

  parseKYCTemplateCreationName(typeName) {
    let returnValue: string;
    returnValue = this.translate.instant("relatedPartyRoles." + typeName);
    if (returnValue.includes("relatedPartyRoles.")) {
      returnValue = this.translate.instant("legalType." + typeName);
    }
    return returnValue;
  }

  getEnvironment() {
    const hostname = window.location.hostname;
    if (hostname === "meshid.app") {
      return "production";
    }
    if (hostname.includes("staging")) {
      return "staging";
    }
    if (hostname.includes("demo")) {
      return "demo";
    } else {
      return "dev";
    }
  }

  getAuth0Params() {
    const env = this.getEnvironment();
    switch (env) {
      case "demo":
        return {
          domain: "finos-dev.eu.auth0.com",
          client_id: "uZ4vxjT8SJkZz9lgXWI_c9wAnd551jBG",
          redirect_uri: `${window.location.origin}`,
          audience: "https://prod.finos.app/api",
          scopes: "openid profile email",
        };
      case "staging":
        return {
          domain: "finos-dev.eu.auth0.com",
          client_id: "XzROnudn6PWXxVL47e0uvN583K4ctQTF",
          redirect_uri: `${window.location.origin}`,
          audience: "https://prod.finos.app/api",
          scopes: "openid profile email",
        };
      case "production":
        return {
          domain: "login.meshid.app",
          client_id: "l5sYzNJ4HEWZeq2BkF20gZ3sDnV7hFcO",
          redirect_uri: `${window.location.origin}`,
          audience: "https://prod.finos.app/api",
          scopes: "openid profile email",
        };
      default:
        return {
          domain: "finos-dev.eu.auth0.com",
          client_id: "uZ4vxjT8SJkZz9lgXWI_c9wAnd551jBG",
          redirect_uri: `${window.location.origin}`,
          audience: "https://prod.finos.app/api",
          scopes: "openid profile email",
        };
    }
  }

  // get information about workflowSteps
  getDoNotDisplay(type: string, workflowSteps, isEditMode: boolean): boolean {
    let DoNotDisplay = true;
    workflowSteps?.forEach((step) => {
      if (step.type == type) {
        DoNotDisplay = step.doNotDisplay;
      }
    });
    if (isEditMode && (!workflowSteps || workflowSteps.length == 0)) {
      DoNotDisplay = false;
    }
    return DoNotDisplay;
  }
  getDescription(type: string, workflowSteps): string {
    const description = this.getDescriptionHelper(
      type,
      workflowSteps,
      this.translate.currentLang,
    );
    return description != ""
      ? description
      : this.translate.instant("ARRelatedParty." + type + ".dropdownText");
  }
  getDescriptionHelper(type: string, workflowSteps, lang: string): string {
    let description = "";
    workflowSteps?.forEach((step) => {
      if (step.type == type && step.description) {
        description = step.description[lang];
      }
    });
    return description;
  }

  isVerifyProfileDetailsEnabled(record): boolean {
    if (
      record?.investorFields &&
      record.investorFields.FirstName &&
      record.investorFields.LastName &&
      record.investorFields.DateOfBirth &&
      record.investorFields.AddressCountry &&
      record.investorFields.AddressPostalCode &&
      record.investorFields.AddressStreet &&
      profile_verification_country_restrictions.includes(
        record.investorFields.AddressCountry,
      )
    ) {
      return true;
    }
    return false;
  }

  getTooltipForVerifyProfile(record): string {
    if(record.typeOfInvestor == "LegalPerson")
    {
      return "Profile verification is currently only available for natural persons. This is a legal person."
    }
    else
    {
      if (!this.isVerifyProfileDetailsEnabled(record)) {
        if (
          record?.investorFields?.AddressCountry &&
          !profile_verification_country_restrictions.includes(
            record.investorFields.AddressCountry,
          )
        ) {
          return "It is not possible to perform a profile verification for this country.";
        }
        return "In order to verify the profile details, there needs to be at least the date of birth, postcode, street, country (from the address) available.";
      }
      return "";
    }
  }

  getIconForProfileVerification(fieldsSourceStatus): string {
    let icon: string = "";
    if (fieldsSourceStatus == "verified") {
      icon = "assets/images/icon-checkmark-prepared-green.svg";
    } else if (fieldsSourceStatus == "partially verified") {
      icon = "assets/images/in-progress.svg";
    } else if (fieldsSourceStatus == "not verified") {
      icon = "assets/images/cancel-svgrepo-com.svg";
    }
    return icon;
  }

  getStatusForProfileVerification(status : string): string
  {
    let newStatus: string = "";
    if (status == "verified") {
      newStatus = "match";
    } else if (status == "partially verified") {
      newStatus ="partial match";
    } else if (status == "not verified") {
      newStatus = "no match";
    }
    return newStatus;
  }
  // fieldsSource
  getDateOfVerification(field, source: string) {
    let temp;
    if(Array.isArray(field)) {
      temp = field.find((item) => item.source === source);
    } else {
      temp = field;
    }
    return temp;
  }
  basicFieldsPreviewHeading(
    fieldsSourceStatus: string,
    fieldsSourceValidationDate?: string,
  ) {
    if (fieldsSourceStatus) {
      let icon: string = "";
      let title: string = "";
      if (fieldsSourceStatus == "verified") {
        icon = "assets/images/icon-checkmark-prepared-green.svg";
        title = "Profile has been fully matched to checked sources on ";
      } else if (fieldsSourceStatus == "partially verified") {
        icon = "assets/images/in-progress.svg";
        title = "Profile has been partially matched to checked sources on ";
      } else if (fieldsSourceStatus == "not verified") {
        icon = "assets/images/cancel-svgrepo-com.svg";
        title = "There was no match to checked sources on ";
      }
      if (fieldsSourceValidationDate) {
        title +=
          "<strong>" +
          this.formatISODate(
            fieldsSourceValidationDate,
            "yyyy-MM-dd hh:mm z",
            false,
          );
        +"</strong>";
      }
      return [
        {
          status: title,
          icon: icon,
        },
      ];
    }
  }

  generateDocumentFormat(docResponse, filename: string): string {
    switch (docResponse["headers"].get("content-type")) {
      case "application/pdf":
        if (filename.includes(".pdf")) {
          filename = filename;
        } else {
          filename = `${filename}.pdf`;
        }
        break;
      case "image/png":
        filename = `${filename}.png`;
        break;
      case "image/jpeg":
        filename = `${filename}.jpg`;
        break;
      case "image/gif":
        filename = `${filename}.gif`;
        break;
      default:
        filename = `${filename}.png`;
        break;
    }
    return filename;
  }

  isRPKey(requestKey: string): boolean {
    if (requestKey?.toLowerCase().includes("rpe")) {
      return true;
    }
    return false;
  }
  // It can be added style and clickEvt if needed in future downloadingDocument(data: string, filename: string, style?, clickEvt)
// return 0 if the dates are the same, -1 if date is overdue, 1 if date is coming up 
  compareDateToTodays(date : string)
  {
    let currentDate = new Date();
    let compareDate = new Date(date);

    if(currentDate > compareDate)
    {
      return -1;
    }
    else if(currentDate < compareDate)
    {
      return 1;
    }
    else
    {
      return 0;
    }

  }
}
