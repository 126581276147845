import {
  Component,
  ViewEncapsulation,
  OnInit,
  HostListener,
} from "@angular/core";
import { AuthService } from "./auth/auth.service";
import { IntegrityService } from "./integrity-check/integrity.service";
import { UserService } from "./user.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = "singletruth";

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private integrityService: IntegrityService,
    public translate: TranslateService,
  ) {
    const defaultLanguage = "en";
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
    const supportedLangs = ["en", "nl"];
    supportedLangs.forEach((language) => {
      if (language != defaultLanguage) {
        // make sure the default language is loaded only once
        this.translate.reloadLang(language);
      }
    });
  }

  ngOnInit() {
    // do nothing (moved to auth-guard.service)
  }

  // on click anywhere on the document
  @HostListener("click", ["$event"]) _contextMenuHide(event) {
    this.integrityService.HideMainNav(event);
    this.integrityService.HideHotifications(event);
    this.HideProjectSettingsSubmenu(event);
    this.hideActionsMenu(event);
  }

  HideProjectSettingsSubmenu(event) {
    // console.log(event.target);
    $(".project-submenu").hide();
  }

  hideActionsMenu(event) {
    if (
      !$(event.target).hasClass("actionsMenuBtn") &&
      !$(event.target).parent().hasClass("actionsMenuBtn")
    ) {
      $(".actionsMenuWrapper ul").addClass("d-none");
      $(".actionsMenuWrapper img.action-icon").removeClass("icon-minus");
    }
  }
}
